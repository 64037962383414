<template>
  <v-btn
    text
    @click="executeFunction(actionParam)"
    :class="color + '--text '"
    style="text-transform: none !important"
  >
    <v-icon :color="color" left v-show="iconPosition === 'left'">
      {{ icon }}
    </v-icon>
    <span
      :style="
        'text-transform:' + textTransform + ';text-decoration:' + textDecoration
      "
      >{{ text }}</span
    >
    <v-icon :color="color" right v-show="iconPosition === 'right'">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "LinkComponent",
  props: {
    text: String,
    actionParam: String,
    color: {
      type: String,
      default: "black",
    },
    iconPosition: {
      type: String,
      default: "none",
    },
    icon: String,
    textTransform: {
      type: String,
      default: "none",
    },
    textDecoration: {
      type: String,
      default: "none",
    },
  },
  methods: {
    executeFunction(actionParam) {
      this.$emit("actions", actionParam);
    },
  },
};
</script>
