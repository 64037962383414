export const CITIES = [
    {
        "name": "Kota Jakarta Selatan",
        "stage": "city",
        "province": "DKI Jakarta",
    },
    {
        "name": "Kota Jakarta Utara",
        "stage": "city",
        "province": "DKI Jakarta",
    },
    {
        "name": "Kota Jakarta Timur",
        "stage": "city",
        "province": "DKI Jakarta",
    },
    {
        "name": "Kota Jakarta Barat",
        "province": "DKI Jakarta",
        "stage": "city",
    },
    {
        "name": "Kota Jakarta Pusat",
        "stage": "city",
        "province": "DKI Jakarta",
    },
    {
        "name": "Kab. Bogor",
        "stage": "city",
        "province": "Jawa Barat",
    },
    {
        "name": "Kota Bogor",
        "stage": "city",
        "province": "Jawa Barat",
    },
    {
        "name": "Kota Depok",
        "stage": "city",
        "province": "Jawa Barat",
    },
    {
        "name": "Kota Tangerang",
        "stage": "city",
        "province": "Banten",
    },
    {
        "name": "Kab. Tangerang",
        "stage": "city",
        "province": "Banten",
    },
    {
        "name": "Kota Tangerang Selatan",
        "stage": "city",
        "province": "Banten",
    },
    {
        "name": "Kota Bekasi",
        "stage": "city",
        "province": "Jawa Barat",
    },
    {
        "name": "Kab. Bekasi",
        "stage": "city",
        "province": "Jawa Barat",
    },
    {
        "name": "Kab. Badung",
        "province": "Bali",
        "stage": "city"
    },
    {
        "name": "Kab. Bangli",
        "province": "Bali",
        "stage": "city"
    },
    {
        "name": "Kab. Buleleng",
        "province": "Bali",
        "stage": "city"
    },
    {
        "name": "Kota Denpasar",
        "province": "Bali",
        "stage": "city"
    },
    {
        "name": "Kab. Gianyar",
        "province": "Bali",
        "stage": "city"
    },
    {
        "name": "Kab. Jembrana",
        "province": "Bali",
        "stage": "city"
    },
    {
        "name": "Kab. Karangasem",
        "province": "Bali",
        "stage": "city"
    },
    {
        "name": "Kab. Klungkung",
        "province": "Bali",
        "stage": "city"
    },
    {
        "name": "Kab. Tabanan",
        "province": "Bali",
        "stage": "city"
    },
    // {
    //     "name": "Tidak tersedia dalam daftar ini",
    //     "province": "DKI Jakarta",
    //     "stage": "city"
    // },
    // {
    //     "name": "Tidak tersedia dalam daftar ini",
    //     "province": "Jawa Barat",
    //     "stage": "city"
    // },
    // {
    //     "name": "Tidak tersedia dalam daftar ini",
    //     "province": "Banten",
    //     "stage": "city"
    // }
]
