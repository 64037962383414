<template>
  <div class="w-full -mb-10">
    <!-- section exlusive invites -->
    <!-- <div class="w-full py-12 px-4 md:py-20 bg-charged-grey-100">
      <div
        class="text-white text-center font-montserrat text-xl md:text-2xl font-bold mb-6 flex flex-wrap justify-center gap-2"
      >
        <span>
          {{ lang[getLanguage].TITLE_EXCLUSIVE_INVITES_P1 }}
        </span>
        <span>
          {{ lang[getLanguage].TITLE_EXCLUSIVE_INVITES_P2 }}
        </span>
      </div>
      <div class="flex flex-wrap justify-center w-full gap-y-6">
        <input
          class="bg-white focus:outline-none rounded-l-lg sm-rounded-r-none px-4 py-2 w-full sm:w-96"
          :placeholder="lang[getLanguage].PLACEHOLDER_EMAIL"
          type="text"
        />
        <span
          class="bg-charged-purple-300 px-6 py-3 normal-case rounded-r-lg sm-rounded-l-none text-white cursor-pointer hover:bg-opacity-70 ease-in-out duration-300 w-full sm:w-64 text-center truncate md:overflow-clip"
        >
          {{ lang[getLanguage].TEXT_BUTTON_EXCLUSIVE_INVITES }}
        </span>
      </div>
    </div> -->
    <!-- end section exlusive invites -->

    <!-- section footer charged -->
    <div class="w-full py-12 px-4 md:py-20 bg-charged-grey-100">
      <!-- section logo charged -->
      <div class="flex flex-wrap justify-between md:pl-16">
        <v-img
          style="cursor: pointer"
          @click="routerGo('/')"
          src="../../../public/img/logo/Charged.svg"
          min-width="125px"
          min-height="32px"
          max-width="125.05px"
          max-height="32px"
          class="mb-8 w-full sm:w-1/2 my-auto"
        />
        <!-- section icon medsos -->
        <div
          class="mb-0 w-full sm:w-1/2 sm:text-right md:pr-16 flex sm:justify-end gap-4"
        >
          <div class="inline-block">
            <a
              href="https://www.facebook.com/profile.php?id=100085417910870"
              target="_blank"
              class="w-10 h-10 group rounded-full bg-charged-grey-500 flex justify-center items-center"
            >
              <v-icon style="color: #262626" class="group-hover:text-blue-600">
                mdi-facebook</v-icon
              >
            </a>
          </div>
          <div class="inline-block">
            <a
              href="https://www.linkedin.com/company/pt-industri-charged-mobilitas/"
              target="_blank"
              class="w-10 h-10 group rounded-full bg-charged-grey-500 flex justify-center items-center"
            >
              <v-icon style="color: #262626" class="group-hover:text-blue-800"
                >mdi-linkedin</v-icon
              >
            </a>
          </div>

          <div class="inline-block">
            <a
              href="https://twitter.com/Charged_ID"
              target="_blank"
              class="w-10 h-10 group rounded-full bg-charged-grey-500 flex justify-center items-center"
            >
              <v-icon style="color: #262626" class="group-hover:text-blue-300"
                >mdi-twitter</v-icon
              >
            </a>
          </div>

          <div class="inline-block">
            <a
              href="https://www.instagram.com/charged.indonesia/"
              target="_blank"
              class="w-10 h-10 group rounded-full bg-charged-grey-500 flex justify-center items-center"
            >
              <v-icon style="color: #262626" class="group-hover:text-pink-600"
                >mdi-instagram</v-icon
              >
            </a>
          </div>
        </div>
        <!-- end section icon medsos -->
      </div>
      <!-- end section logo charged -->

      <!-- divider line-->
      <div class="md:px-16 mx-auto md:-mt-7">
        <v-divider class="mt-7" style="background: #c1c2c4; margin: 0 0 28px 0">
        </v-divider>
      </div>
      <!-- end divider line -->

      <!-- section location and menu footer -->
      <div class="md:px-16 flex flex-wrap w-full gap-y-16">
        <!-- section location -->
        <div
          class="flex flex-wrap gap-y-10 sm:gap-y-0 gap-x-16 w-full sm:w-1/2"
        >
          <div class="w-full sm:w-2/5 md:mb-8">
            <p class="work-sans text-base font-semibold text-white">
              E-SHED KEMANG
            </p>
            <a
              class="work-sans text-sm font-normal text-charged-grey-500 -mt-1 md:mt-0 inline-block"
              style="color: #c1c2c4"
              target="_blank"
              :href="mapLinkKemang"
            >
              Jl. Kemang Utara VII No. 21B, Mampang Prapatan, Jakarta Selatan,
              DKI Jakarta 12730
            </a>
          </div>

          <div class="w-full sm:w-2/5">
            <p class="work-sans text-base font-semibold text-white">
              E-SHED CIKUPA
            </p>
            <a
              class="work-sans text-sm font-normal text-charged-grey-500 -mt-1 md:mt-0 inline-block"
              style="color: #c1c2c4"
              target="_blank"
              :href="mapLinkCikupa"
            >
              Jl. Bhumimas IV No.8, Talaga, Cikupa, Tangerang Regency, Banten
              15710
            </a>
          </div>

          <!-- <div class="w-full sm:w-2/5">
            <p class="work-sans text-base font-semibold text-white">
              E-SHED KEBAYORAN
            </p>
            <a
              class="work-sans text-sm font-normal text-charged-grey-500 -mt-1 md:mt-0 inline-block"
              style="color: #c1c2c4"
              target="_blank"
              :href="mapLinkKebayoran"
            >
              Jl. Hang Lekir I No.1, RW.3, Gelora, Kec. Kby. Baru, Kota Jakarta
              Pusat, DKI Jakarta
            </a>
          </div> -->
        </div>
        <!-- end section location -->

        <!-- section menu footer -->
        <div class="flex flex-wrap sm:flex-nowrap justify-between sm:justify-end gap-y-9 w-full sm:w-1/2">
          <div class="w-full sm:w-2/5">
            <p class="work-sans text-base font-semibold text-white uppercase">
              <!-- {{ lang[getLanguage].LABEL_ABOUT }} CHARGED -->
              {{ getLanguage == "en" ? "useful links" : "Link lainnya" }}
            </p>
            <span
              class="work-sans text-sm font-normal text-charged-grey-500 cursor-pointer block mb-2"
              style="color: #c1c2c4"
              @click="routerGo('/about')"
            >
              {{ lang[getLanguage].ABOUT_US }}
            </span>
            <span
              class="work-sans text-sm font-normal text-charged-grey-500 cursor-pointer block mb-2"
              style="color: #c1c2c4"
              @click.stop="openBrochure"
            >
              {{ lang[getLanguage].CATALOG }}
            </span>
            <span
              class="work-sans text-sm font-normal text-charged-grey-500 cursor-pointer block mb-2"
              style="color: #c1c2c4"
              @click.stop="openUserManualPdf"
            >
              {{
                getLanguage == "en"
                  ? "Vehicle User Manual"
                  : "Panduan Pengguna Kendaraan"
              }}
            </span>
            <span
              class="work-sans text-sm font-normal text-charged-grey-500 cursor-pointer block mb-2"
              style="color: #c1c2c4"
              @click="routerGo('/contact#faq-dekstop')"
            >
              FAQ
            </span>
            <span
              class="work-sans text-sm font-normal text-charged-grey-500 cursor-pointer block mb-2"
              style="color: #c1c2c4"
              @click="routerGo('/contact')"
            >
              {{ lang[getLanguage].CONTACT_US }}
            </span>
          </div>

          <div class="w-full sm:w-1/4">
            <p class="work-sans text-base font-semibold text-white uppercase">
              {{ lang[getLanguage].VEHICLES }}
            </p>
            <span
              v-for="(bike, i) in bikes" :key="i"
              class="work-sans text-sm font-normal text-charged-grey-500 cursor-pointer block mb-2"
              style="color: #c1c2c4"
              @click="routerGo(`/bike/${bike?.slug}`)"
            >
              {{ bike?.name }}
            </span>
            <!-- <span
              class="work-sans text-sm font-normal text-charged-grey-500 cursor-pointer block mb-2"
              style="color: #c1c2c4"
              @click="routerGo('/bike/maleo')"
            >
              MALEO
            </span>
            <span
              class="work-sans text-sm font-normal text-charged-grey-500 cursor-pointer block mb-2"
              style="color: #c1c2c4"
              @click="routerGo('/bike/rimau')"
            >
              RIMAU
            </span> -->
          </div>

          <div class="w-full sm:w-1/5">
            <p class="work-sans text-base font-semibold text-white uppercase">
              legal
            </p>
            <span
              class="work-sans text-sm font-normal text-charged-grey-500 cursor-pointer block mb-2 whitespace-nowrap"
              style="color: #c1c2c4"
              @click="routerGo('/privacy-policy')"
            >
              {{ lang[getLanguage].ACC_APP_PRIVACY }}
            </span>
            <span
              class="work-sans text-sm font-normal text-charged-grey-500 cursor-pointer block mb-2 whitespace-nowrap"
              style="color: #c1c2c4"
              @click="routerGo('/term-of-use')"
            >
              {{ lang[getLanguage].ACC_APP_TERMS_OF_USE }}
            </span>
          </div>
        </div>
        <!-- end section menu footer -->
      </div>
      <!-- end section location and menu footer -->
    </div>
    <!-- end section footer charged -->

    <!-- secction copyright -->
    <div
      class="px-4 py-8 flex flex-wrap bg-charged-grey-200 justify-between gap-y-4"
    >
      <div class="md:pl-8">
        <span
          :class="
            getLanguage === 'en'
              ? 'work-sans text-white text-xs font-normal mr-4 cursor-pointer'
              : 'work-sans text-charged-grey-500 text-xs font-normal mr-4 cursor-pointer'
          "
          @click="changeLanguage('en')"
        >
          English
        </span>
        <span
          :class="
            getLanguage === 'id'
              ? 'work-sans text-white text-xs font-normal cursor-pointer'
              : 'work-sans text-charged-grey-500 text-xs font-normal cursor-pointer'
          "
          @click="changeLanguage('id')"
        >
          Indonesia
        </span>
      </div>
      <div class="md:pr-8">
        <span class="text-charged-grey-500 text-xs font-normal">
          {{ new Date().getFullYear() }} &copy;Copyright PT Charged Tech
          Indonesia. All Rights Reserved.
        </span>
      </div>
    </div>
    <!-- end section copy right -->
  </div>
</template>

<script>
import store from "@/store/index";

export default {
  name: "ChargedFooterPressLaunch",
  props: {
    dark: Boolean,
    drawer: Boolean,
    windowWidth: Number,
    windowHeight: Number,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
  },
  data() {
    return {
      lang: this.$store.state.lang,
      logoSrcDark: "",
      logoSrc: "",
      mapLinkCikupa: "https://goo.gl/maps/k12rZj19M9ExeZbD7",
      mapLinkKemang: "https://goo.gl/maps/tyr9qML1EprBWFaF7",
      mapLinkKebayoran: "https://goo.gl/maps/hmMDmSSZCoEcy8mYA",
      bikes: [],
    };
  },
  components: {},
  watch: {},
  created() {
    // this.windowWidth = this.$props.windowindowWidth
    this.logoSrc = "./img/logo/charged_logo_white.png";
    this.logoSrcDark = "./img/logo/charged_logo.png";
    this.bikes = this.$store.getters.vehicles_store;
  },
  methods: {
    openUserManualPdf() {
      window.open("/pdf/charged_user_manual_2023.pdf", "_blank");
    },
    openBrochure() {
      window.open("/pdf/brochure_" + this.getLanguage + ".pdf", "_blank");
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    openLinkSameTab(link) {
      window.open(link);
    },
    openTerms() {
      this.$emit("termsClicked");
    },
    openPrivacy() {
      this.$emit("privacyClicked");
    },
    routerGo(route) {
      console.log("this.drawer = false");
      if (this.isMobileDevice) {
        console.log("this.drawer = false");
        setTimeout(() => {
          this.drawer = false;
        }, 500);
      }
      this.$router.push(route);

      if (
        route == "/contact#faq-dekstop" ||
        this.$route.hash == "#faq-dekstop"
      ) {
        setTimeout(() => {
          document
            .querySelector("#faq-scroll-auto")
            .scrollIntoView({ behavior: "smooth" });
        }, 600);
      }
    },
    changeLanguage(lang) {
      store.commit("SetLanguage", {
        language: (this.getLanguage = lang),
      });
    },
  },
};
</script>

<style>
.contactText {
  font-family: "Montserrat";
  font-size: 44px;
}

.wrapper {
  background: #fafafa;
  display: flex;
  justify-content: center;
}

.sectionHeader {
  font-family: "Comfortaa";
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}

.cardsH {
  background: #fff;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: grid;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}

.cardsV {
  background: #fff;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: table-column;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}

span.v-btn__content {
  opacity: 1 !important;
}

.charged-h4-title {
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
}

.charged-h4-body {
  font-family: "Work Sans";
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #c1c2c4;
}

.charged-h5-body {
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
}

.sm-rounded-r-none {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.sm-rounded-l-none {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

@media screen and (min-width: 640px) {
  .sm-rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .sm-rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
</style>