export const Anoa = {
  vehicle_id: process.env.VUE_APP_ID_VEHICLE_ANOA,
  name: "ANOA",
  nameLowercase: "anoa",
  slug: "anoa",
  folder: "vs1",
  description: "One Trip - deliver double loading of normal delivery.",
  image: "img/shared/bikes/anoa/bike-anoa.webp",
  imageNavbar: "img/navbar/anoa-front-view.svg",
  motor: "Electric Motor - Super Soco 4000W",
  autonomy: "Autonomy 160 Km (with L1e specification)",
  speed: "90",
  topSpeed: "90",
  maxDistance: "200",
  maximumRange: "200",
  maxDistanceUnit: "km",
  battery: 2,
  recharge:
    "Recharge 3.5 h at 60V15A (fast charge with single battery)",
  count: 12,
  price: "Rp 25.880.000",
  subPrice: "Rp 1.290.000",
  batterySubscription: "Rp 350.000",
  dailyPrice: 43000,
  stock: 1,
  colorOptions: ["#FFFFFF", "#000000"],
  productTagLine: {
    en: {
      title: 'Ultimate electrified utility',
      desctiption: 'Strong and dependable, ANOA carries you and your cargo anywhere and everywhere.'
    },
    id: {
      title: 'Utilitas tak terbatas',
      desctiption: 'Kuat dan dapat diandalkan, ANOA mampu membawa kargo dan dirimu ke mana pun, kapan pun.'
    }
  },
  performanceAndSafetyDesc: {
    en: "<p>Cargo performance is redefined with the ANOA, allowing up to 150KG and 250litres of payload excluding rider, 160km of true range with our high tensile steel frames and highly efficeint drive train.</p><p>Full-LED smart lighting system (front and rear) helps you see and be seen anywhere, even in the bad weather.</p><p>Combined Brake System (CBS) features are standard for ANOA units to reduce the risk of accidents.</p>",
    id: "<p>ANOA merupakan pilihan terbaik untuk armada kargo, berdaya angkut hingga 150 kg dan 250 liter (tidak termasuk pengendara), dengan jarak tempuh sejauh 160km didukung oleh rangka baja bertarikan tinggi dan drive train yang sangat efisien.</p><p>Dapatkan visibilitas yang lebih baik dalam berbagai kondisi cuaca menggunakan sistem pencahayaan full LED di bagian depan dan belakang.</p><p>Dibekali sistem pengereman CBS, memastikan keamanan ekstra saat berkendara.</p>"
  },
  convenienceDesc: {
    en: "<p>Mounting options on the front , rear and sides of ANOA lets you customise utility to your needs.</p><p>ANOA is also equipped with a one-push reverse/creep button, fast & easy changing tyre system, fast-charging, intuitive battery operation for everyday ease of use, dedicated power supply to heat/cool rear box, left and right side stands enable comfort access from both sides of vehicle.</p>",
    id: "<p>Dengan opsi bracket di bagian depan, belakang dan samping, ANOA sangat fleksibel untuk disesuaikan dengan kebutuhanmu.</p><p>ANOA juga dilengkap one push reverse button untuk memudahkan saat mundur, sistem single-sided swingarm untuk penggantian ban belakang yang cepat, fast charger dan pengoperasian baterai intuitif untuk penggunaan sehari-hari. Untuk membantu operasional sebagai armada komersial, ANOA dibekali dengan suplai tenaga dedikatif untuk kotak pemanas / pendingin di bagian belakang serta standar dua sisi (kiri kanan) untuk memudahkan akses naik motor.</p>"
  },
  intelegencyDesc: {
    en: "<P>Integrated CAN systems and monitoring means safe storage, charging and riding regardless of weather and conditions.  Our advanced anti-theft system comes as standard on all our models so that you can focus on the day ahead.</p><p>To further assist you in keeping your connectivity, ANOA is equipped with USB Charging Port for charging your phone.</p>",
    id: "<P>Tidak perlu khawatir berkendara dalam berbagai kondisi cuaca. Sistem CAN dan pengawasan terintegrasi memastikan bagian penyimpanan dan pengecasan tetap aman. Kamu juga bisa lebih fokus bermobilisasi tanpa perlu khawatir berkat sistem antipencurian canggih yang telah disematkan pada ANOA.</p><p>Untuk membantu konektivitasmu, ANOA sudah dilengkapi USB charging port agar kamu bisa mengecas ponselmu selama di perjalanan.</p>"
  },
  isRelease: true,
  typeProduct: "standart",
  specificationDesc: {
    en: "*Data collected from real world testing on Indonesian roads, with a single rider of 70kg, not exceeding speeds of 50km/h. Your results may vary depending on riding conditions.",
    id: "*Berdasarkan hasil uji di jalanan Indonesia dengan satu pengendara, bobot 70 kg dan kecepatan berkendara tidak lebih dari 50 km/jam. Hasil mungkin bervariasi, tergantung kondisi berkendara."
  },
  specification: {
    keys: {
      en: [
        {
          description: "L*W*H",
          details: "1975*720*1132mm",
        },
        {
          description: "Wheelbase",
          details: "1365 mm",
        },
        {
          description: "Min. Ground Clearance",
          details: "145 mm",
        },
        {
          description: "Seat Height",
          details: "760 mm",
        },
        {
          description: "Net Weight",
          details: "135 kg",
        },
        {
          description: "Max Load",
          details: "150 kg (rider excluded)",
        },
      ],
      id: [
        {
          description: "P*L*T",
          details: "1975*720*1132 mm",
        },
        {
          description: "Jarak Sumbu Roda",
          details: "1365 mm",
        },
        {
          description: "Min. Ground Clearance",
          details: "145 mm",
        },
        {
          description: "Tinggi Tempat Duduk",
          details: "760 mm",
        },
        {
          description: "Bobot Bersih",
          details: "135 kg",
        },
        {
          description: "Kapasitas Beban Maksimum",
          details: "150 kg (tanpa pengendara)",
        },
      ],
    },
    powertrain: {
      en: [
        {
          description: "Range",
          details: "200 km",
        },
        {
          description: "Charge time (per battery)",
          details: "3 Hours (per battery 10-100%)",
        },
        {
          description: "Torque (peak)",
          details: "171 N.m",
        },
        {
          description: "Top Speed",
          details: "90 Km/h",
        },
      ],
      id: [
        {
          description: "Jangkauan",
          details: "200 km",
        },
        {
          description: "Waktu pengisian daya (per baterai)",
          details: "3 Jam (per baterai 10-100%)",
        },
        {
          description: "Torsi (puncaknya)",
          details: "171 N.m",
        },
        {
          description: "Kecepatan Tertinggi",
          details: "90 Km/h",
        },
      ],
    },
    other: {
      en: [
        {
          description: "Brakes (F)",
          details: "CBS, Disc, 240 mm",
        },
        {
          description: "Brakes (R)",
          details: "CBS, Disc, 180 mm",
        },
        {
          description: "Front Suspension Travel",
          details: "80 mm",
        },
        {
          description: "Rear Suspension Travel",
          details: "60 mm",
        },
        {
          description: "Front Tyre Size",
          details: "100/80-16",
        },
        {
          description: "Rear Tyre Size",
          details: "110/80-14",
        },
        {
          description: "Lighting",
          details: "F/R LED",
        },
        {
          description: "Battery",
          details: "Dual, Removable",
        },
      ],
      id: [
        {
          description: "Rem (Depan)",
          details: " CBS, Cakram, 240mm",
        },
        {
          description: "Rem (Belakang)",
          details: " CBS, Cakram, 180mm",
        },
        {
          description: "Travel Suspensi Depan",
          details: "80 mm",
        },
        {
          description: "Travel Suspensi Belakang",
          details: "60 mm",
        },
        {
          description: "Ukuran Ban Depan",
          details: "100/80-16",
        },
        {
          description: "Ukuran Ban Belakang",
          details: "110/80-14",
        },
        {
          description: "Pencahayaan",
          details: "LED (Depan, Belakang)",
        },
        {
          description: "Baterai",
          details: "Ganda, Dapat Dilepas Pasang",
        },
      ],
    }
  },
  text: {
    otr: {
      en: 'OTR Jakarta Price',
      id: 'Harga OTR Jakarta'
    },
    includeInOtr: "(Unit & Charger)",
  }
}