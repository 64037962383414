<template>
  <v-app id="charged-app">
    <v-app-bar
      v-if="!isSubscribeForm"
      :style="'background-color: white;'"
      :app="$vuetify.breakpoint.lgAndUp"
      v-show="$vuetify.breakpoint.lgAndUp"
      fixed
      :clipped-left="true"
    >
      <v-btn text v-model="logofixed" class="no-background-hover underline">
        <v-img
          :src="
            scrolledHome ||
            $route.name === 'testRide' ||
            $route.name === 'myaccount' ||
            $route.name === 'signup'
              ? logoSrcDark
              : logoSrcDark
          "
          max-width="153"
          max-height="33"
          @click="routerGo('/')"
        />
      </v-btn>

      <v-icon
        style="font-style: initial"
        :color="getColor"
        class="mx-2 font-weight-light"
        >|</v-icon
      >

      <Link
        id="btn-vmoto"
        :text="'Powered by VMOTO'"
        actionParam="https://en.vmotosoco.com/"
        @actions="goToLink"
        :color="getColor"
        style="
          font-weight: 400;
          letter-spacing: -0.32px;
          font-family: work-sans;
          text-decoration: underline;
          font-size: 16px;
        "
      />

      <v-toolbar-items class="hidden-sm-and-down" style="margin-left: 40px">
        <Link
          :text="lang[getLanguage].ABOUT_US"
          actionParam="/about"
          @actions="routerGo"
          :color="getColor"
          style="
            font-weight: 400;
            letter-spacing: -0.32px;
            font-family: work-sans;
            font-size: 16px;
          "
        />
        <!-- <MenuLink
          open-on-hover
          :text="lang[getLanguage].VEHICLES"
          icon="mdi-chevron-down"
          :items="[
            {
              name: 'Anoa',
              actionParam: '/bike/anoa',
            },
            {
              name: 'Maleo',
              actionParam: '/bike/maleo',
            },
            {
              name: 'Rimau',
              actionParam: '/bike/rimau',
            },
          ]"
          :color="getColor"
          @actions="routerGo"
        /> -->

        <v-menu
          open-on-hover
          offset-y
          v-model="menu"
          :close-on-content-click="false"
          location="end"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="white"
              @click="menu = true"
              elevation="false"
              class="lowercase-text"
              v-on="on"
              style="
                font-weight: 400;
                letter-spacing: -0.32px;
                font-family: work-sans;
                font-size: 16px;
              "
            >
              {{ lang[getLanguage].VEHICLES }}
              <v-icon style="font-size: 18px; margin-left: 8px"
                >mdi-chevron-down</v-icon
              >
            </v-btn>
          </template>

          <v-card class="custom-menu-vihecle" max-width="573" max-height="553">
            <v-tabs vertical v-model="tab">
              <v-tab
                v-for="(bike, i) in bikes"
                :key="i + 0"
                :style="
                  tab == i
                    ? 'padding: 20px 40px 20px 24px; height: calc(100% / 3); background: white !important; font-weight: 600; justify-content: start;'
                    : 'padding: 20px 40px 20px 24px; height: calc(100% / 3); background: #f5f4f6 !important; justify-content: start;'
                "
                @mouseover="tab = i"
                @click="gotoBike(bike.slug.toLowerCase())"
              >
                {{ bike.name }}
              </v-tab>

              <v-tab-item
                style="padding: 10px 32px"
                v-for="(bike, i) in bikes"
                :key="i + 0"
              >
                <v-carousel
                  :show-arrows="false"
                  hide-delimiter-background
                  hide-delimiters
                  style="width: 400px; height: 328px"
                >
                  <v-carousel-item>
                    <div class="relative">
                      <div
                        class="d-flex justify-center items-center pointer overflow-hidden box-border"
                        style="height: 315px"
                      >
                        <!-- <img
                          :src="bike.image"
                          :alt="bike.name"
                          :class="'image-cutom-' + i"
                          width="100%"
                        /> -->
                        <div class="my-auto">
                          <img
                            :src="bike.imageNavbar"
                            :alt="bike.name"
                            class="image-custom my-auto"
                          />
                        </div>
                      </div>
                      <span class="text-menu-vihecle w-full">
                        <div class="d-flex justify-between">
                          <span> CHARGED {{ bike.name }} </span>

                          <div
                            class="d-flex justify-end"
                            v-if="bike.colorOptions"
                          >
                            <div
                              v-for="color in bike.colorOptions"
                              :key="color"
                              class="mx-2"
                            >
                              <div
                                class="color-palette mx-auto"
                                :style="{ backgroundColor: color }"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-menu>

        <!-- <Link
          :text="'Test Ride'"
          actionParam="/testRide/home"
          @actions="routerGo"
          :color="getColor"
          style="
            font-weight: 400;
            letter-spacing: -0.32px;
            font-family: work-sans;
            font-size: 16px;
          "
        /> -->
        <Link
          :text="lang[getLanguage].CONTACT"
          actionParam="/contact"
          @actions="routerGo"
          :color="getColor"
          style="
            font-weight: 400;
            letter-spacing: -0.32px;
            font-family: work-sans;
            font-size: 16px;
          "
        />
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <!-- <v-btn color="#6B4693" large :to="'/maleoxdochi'" class="work-sans mr-6"
        style="color: white; padding: 12px 24px; height: 42px; border-radius: 8px;">PREORDER MALEO X DOCHI
      </v-btn> -->

      <Link
        :text="
          getUser.fullName.charAt(0).toUpperCase() + getUser.fullName.slice(1)
        "
        actionParam="/myaccount"
        @actions="routerGo"
        :iconPosition="'left'"
        :color="getColor"
        v-show="getUser.isLoggedIn"
        :icon="'mdi-account-circle-outline'"
        style="
          font-weight: 400;
          letter-spacing: -0.32px;
          font-family: work-sans;
          font-size: 16px;
        "
      />
      <!-- <Link
        :text="lang[getLanguage].BUT_SIGN_IN"
        actionParam="/signup"
        @actions="routerGo"
        :color="getColor"
        v-show="!getUser.isLoggedIn"
        :style="
          this.$route.name === 'testRide'
            ? 'border: 1px solid black; font-weight: 400; letter-spacing: -0.32px; font-family: work-sans; font-size: 16px;'
            : scrolledHome
            ? 'border: 1px solid black; font-weight: 400; letter-spacing: -0.32px; font-family: work-sans; font-size: 16px;'
            : 'border: 1px solid black; font-weight: 400; letter-spacing: -0.32px; font-family: work-sans; font-size: 16px;'
        "
      /> -->
      <v-icon
        v-if="getUser.isLoggedIn"
        style="font-style: initial"
        :color="getColor"
        class="mx-2 font-weight-light"
        >|
      </v-icon>

      <MenuLink
        :text="getLanguage === 'id' ? 'ID' : 'EN'"
        icon="mdi-chevron-down"
        :items="listLanguages"
        :color="getColor"
        @actions="changeLanguage"
        style="
          font-weight: 400;
          letter-spacing: -0.32px;
          font-family: work-sans;
          font-size: 16px !important;
        "
      />
    </v-app-bar>
    <!-- bali navbar -->
    <!-- <v-app-bar
      :style="'margin-top: 63px; backgroud-color: white !important;'"
      fixed
    >
      <div style="display: flex; padding: 0px 16px">
        <div style="align-items: center">Introducing Charged in Bali</div>
      </div>
      <div style="display: flex; padding: 0px 16px">
        <div style="align-items: center">
          <v-btn
            color="#FFF"
            large
            :to="'/testride/home'"
            class="work-sans"
            style="
              text-transform: none !important; border-radius: 8px;'
              "
          >
            Watch Video
          </v-btn>
        </div>
        <div style="align-items: center">
          <v-btn
            color="#6B4693"
            large
            :to="'/testride/home'"
            class="work-sans"
            style="
              color: #FFF;text-transform: none !important; border-radius: 8px;'
              "
          >
            PRE-ORDER NOW
          </v-btn>
        </div>
      </div>
    </v-app-bar> -->

    <v-overlay :value="drawer" z-index="4"></v-overlay>
    <v-navigation-drawer
      width="100%"
      class="pt-8"
      v-model="drawer"
      v-show="$vuetify.breakpoint.mdAndDown"
      hide-overlay
      :clipped="false"
      color="#262626"
      fixed
      :app="$vuetify.breakpoint.mdAndDown"
      :style="{ top: $vuetify.application.top + 'px', zIndex: 4 }"
    >
      <!-- <v-list-group class="mb-4 custom-icon">
        <template v-slot:activator>
          <v-list-item-title
            class="white--text work-sans font-weight-bold nav-menu-mobile"
            style="padding: 0 16px"
          >
            {{ getLanguage === "id" ? "ID" : "EN" }}
          </v-list-item-title>
        </template>
        <v-container class="mx-6">
          <v-list v-for="(item, index) in listLanguages" :key="index">
            <v-list-item-title>
              <v-btn
                text
                @click="executeFunction(item.actionParam)"
                class="work-sans nav-menu-child-mobile no-background-hover mb-2"
              >
                {{ item.name }}
              </v-btn>
            </v-list-item-title>
          </v-list>
        </v-container>
      </v-list-group> -->

      <v-list-item class="mb-4 custom-icon">
        <v-list-item-title>
          <v-bottom-sheet v-model="sheet">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                class="white--text work-sans font-semibold nav-menu-mobile no-background-hover"
                v-bind="attrs"
                v-on="on"
              >
                <div class="flex justify-between">
                  {{ getLanguage === "id" ? "ID" : "EN" }}
                  <v-icon class="-mr-4">mdi-chevron-down</v-icon>
                </div>
              </v-btn>
            </template>
            <v-list>
              <!-- <v-subheader>Open in</v-subheader> -->
              <v-list-item>
                <v-list-item-title>
                  <v-radio-group v-model="currentLang" mandatory>
                    <v-radio
                      v-for="(item, index) in listLanguages"
                      :key="index"
                      :label="item.name"
                      :value="item.actionParam"
                      @change="executeFunction(item.actionParam)"
                    ></v-radio>
                  </v-radio-group>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-bottom-sheet>
        </v-list-item-title>
      </v-list-item>

      <v-list-item class="mb-4">
        <v-list-item-title>
          <v-btn
            text
            :to="'/about'"
            class="white--text work-sans font-semibold nav-menu-mobile no-background-hover"
          >
            {{ lang[getLanguage].ABOUT_US }}
          </v-btn>
        </v-list-item-title>
      </v-list-item>

      <v-list-group class="mb-4 custom-icon">
        <template v-slot:activator>
          <v-list-item-title
            class="white--text work-sans font-semibold nav-menu-mobile"
            style="padding: 0 16px"
          >
            {{ lang[getLanguage].VEHICLES }}</v-list-item-title
          >
        </template>
        <v-container class="mx-6">
          <v-list-item-title v-for="item in bikes" :key="item.name">
            <v-btn
              text
              :to="'/bike/' + item.slug"
              class="work-sans nav-menu-child-mobile no-background-hover mb-2 capitalize"
            >
              {{ item.name }}
            </v-btn>
          </v-list-item-title>
          <!-- <v-list-item-title>
            <v-btn
              text
              :to="'/bike/anoa'"
              class="work-sans nav-menu-child-mobile no-background-hover mb-2"
            >
              Anoa
            </v-btn>
          </v-list-item-title>
          <v-list-item-title>
            <v-btn
              text
              :to="'/bike/maleo'"
              class="work-sans nav-menu-child-mobile no-background-hover mb-2"
            >
              Maleo
            </v-btn>
          </v-list-item-title>
          <v-list-item-title>
            <v-btn
              text
              :to="'/bike/rimau'"
              class="work-sans nav-menu-child-mobile no-background-hover"
            >
              Rimau
            </v-btn>
          </v-list-item-title> -->
        </v-container>
      </v-list-group>

      <!-- <v-list-item class="mb-4">
        <v-list-item-title
          class="white--text work-sans font-semibold nav-menu-mobile"
        >
          <v-btn
            text
            :to="'/testRide/home'"
            class="white--text work-sans font-semibold nav-menu-mobile no-background-hover"
          >
            Test Ride
          </v-btn>
        </v-list-item-title>
      </v-list-item> -->

      <v-list-item class="mb-4">
        <v-list-item-title
          class="white--text work-sans font-semibold nav-menu-mobile"
        >
          <v-btn
            text
            :to="'/contact'"
            class="white--text work-sans font-semibold nav-menu-mobile no-background-hover"
          >
            FAQ Contact
          </v-btn>
        </v-list-item-title>
      </v-list-item>

      <!-- <v-list-item class="mb-4">
        <v-list-item-title class="white--text work-sans font-weight-bold nav-menu-mobile">
          <v-btn color="#6B4693" large :to="'/maleoxdochi'" class="work-sans mr-6"
            style="color: white; padding: 12px 24px; height: 42px; border-radius: 8px;">PREORDER MALEO X DOCHI
          </v-btn>
        </v-list-item-title>
      </v-list-item> -->

      <v-container>
        <v-divider
          style="width: 100%; border-color: white"
          class="mb-9"
        ></v-divider>

        <v-col
          :style="window.width < 800 ? 'max-width:100%' : ''"
          class="align-self-start px-0 py-0 mb-4"
          sm="12"
        >
          <v-layout>
            <v-icon
              color="#FFF"
              @click.stop="
                openLink(
                  'https://www.facebook.com/profile.php?id=100085417910870'
                )
              "
            >
              mdi-facebook</v-icon
            >
            <v-icon
              color="#FFF"
              @click.stop="
                openLink(
                  'https://www.linkedin.com/company/pt-industri-charged-mobilitas/'
                )
              "
              style="margin-left: 20px"
              >mdi-linkedin</v-icon
            >
            <v-icon
              color="#FFF"
              @click.stop="openLink('https://twitter.com/Charged_ID')"
              style="margin-left: 20px"
            >
              mdi-twitter</v-icon
            >
            <v-icon
              color="#FFF"
              @click.stop="
                openLink('https://www.instagram.com/charged.indonesia/')
              "
              style="margin-left: 20px"
              >mdi-instagram</v-icon
            >
          </v-layout>
        </v-col>

        <v-list-item style="margin: 0; padding: 0">
          <Link
            style="margin: 0; padding: 0"
            :text="'Powered by VMOTOSOCO'"
            class="white--text"
            actionParam="https://en.vmotosoco.com/"
            @actions="goToLink"
          />
        </v-list-item>
      </v-container>
    </v-navigation-drawer>
    <v-app-bar
      v-if="!isSubscribeForm"
      v-show="$vuetify.breakpoint.mdAndDown"
      :max-width="this.$vuetify.breakpoint.width"
      :app="$vuetify.breakpoint.mdAndDown"
      :style="'background-color: white'"
      :clipped-left="false"
      :width="this.$vuetify.breakpoint.width"
    >
      <v-toolbar-items>
        <v-btn icon @click="drawer = !drawer">
          <v-icon :color="getColor">mdi-menu</v-icon>
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-btn text class="no-background-hover">
          <v-img
            :src="
              scrolledHome ||
              $route.name === 'testRide' ||
              $route.name === 'signup'
                ? logoSrcDark
                : logoSrcDark
            "
            max-width="153"
            max-height="33"
            @click="routerGo('/')"
          />
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-toolbar-items :class="!getUser.isLoggedIn ? 'ml-12' : ''">
        <v-btn
          v-if="getUser.isLoggedIn"
          icon
          color="white"
          :to="getUser.isLoggedIn ? '/myaccount' : '/signup'"
        >
          <v-icon :color="getColor">mdi-account-circle</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <router-view
        :key="$route.fullPath"
        :dark="dark"
        :windowWidth="window.width"
        :windowHeight="window.height"
        v-on:searchCleared="clearSearch"
        :showActionButtons="showActionButtons"
        :search="search"
        :trigger="triggerSearch"
        :drawer="drawer"
        :scrollY="scrollY"
        :isMobileDevice="isMobileDevice"
        v-on:termsClicked="openTerms"
        v-on:privacyClicked="openPrivacy"
        v-on:indemnityClicked="openIndemnity"
      />
    </v-main>

    <bottom-navigation-bar
      :dark="dark"
      :account="getUser.accType"
      v-if="showBottomNavBarBaseOnRoute($route.name)"
    />

    <!-- FOOTER & MENU AIR POLUTION -->

    <v-footer
      app
      :dark="!dark"
      :color="
        (window.width > 770 && scrolledHome && dark) ||
        (window.width > 770 && $route.name !== 'Home' && scrolledHome && dark)
          ? '#202020'
          : (window.width > 770 && scrolledHome && !dark) ||
            (window.width > 770 &&
              $route.name !== 'Home' &&
              scrolledHome &&
              !dark)
          ? '#353638'
          : 'transparent'
      "
      style="bottom: -10px"
    >
      <!-- BUTTON WHATSAPP STICKY-->
      <v-btn
        v-if="!isSubscribeForm"
        v-show="$vuetify.breakpoint.lgAndUp"
        href="https://wa.me/6281237686970?text=Hi+Charged+Indonesia%2C+Saya+perlu+bantuan"
        target="_blank"
        color="#E8E8E8"
        fixed
        right
        bottom
        style="
          box-shadow: 0px 2px 4px rgba(107, 70, 147, 0.35);
          border-radius: 40px;
          margin-bottom: 21px;
          margin-right: 10px;
          height: 64px;
          width: 64px;
        "
      >
        <v-icon style="font-size: 36px" dark>mdi-whatsapp</v-icon>
      </v-btn>

      <v-btn
        v-if="!isSubscribeForm"
        v-show="$vuetify.breakpoint.mdAndDown"
        href="https://wa.me/6281237686970?text=Hi+Charged+Indonesia%2C+Saya+perlu+bantuan"
        target="_blank"
        color="#E8E8E8"
        fixed
        right
        bottom
        style="
          box-shadow: 0px 2px 4px rgba(107, 70, 147, 0.35);
          border-radius: 50%;
          margin-bottom: 21px;
          margin-right: 10px;
          width: 50px;
          height: 63px;
        "
        class="rounded-full p-3"
      >
        <v-icon large dark>mdi-whatsapp</v-icon>
      </v-btn>
      <!-- END BUTTON WHATSAPP -->
    </v-footer>

    <!-- END FOOTER & MENU AIR POLUTION -->

    <v-snackbar
      :timeout="snackbarTimeout"
      v-model="snackbar"
      top
      outlined
      :color="snackbarColor"
    >
      <v-icon v-if="isOnline" :color="snackbarColor"
        >mdi-check-circle-outline</v-icon
      >
      <v-icon v-if="!isOnline" :color="snackbarColor"
        >mdi-close-octagon-outline</v-icon
      >
      <span style="padding-left: 10px" :color="snackbarColor"
        >{{ snackbarText }}
      </span>
      <v-btn
        :color="snackbarColor"
        justify="center"
        :ripple="false"
        @click.native="snackbar = false"
        class="pa-0"
        style="min-width: auto"
        absolute
        text
        right
        dense
        height="auto"
        v-if="snackbarTimeout != -1"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <!-- DIALOG FOR SUGEST CONTINUE FORM EMBEDED -->
    <v-dialog v-model="dialogSugestFormEmbeded" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5 break-words">
          Apakah anda ingin melanjutkan pendaftaran?
        </v-card-title>
        <v-card-text
          >Klik <span class="font-medium">'Ya'</span> jika anda ingin
          melanjutkan.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogSugestFormEmbeded = false"
          >
            Tidak
          </v-btn>
          <v-btn color="green darken-1" text @click="gotoEmbededForm">
            Ya
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import store from "@/store/index";
import ROUTE_INCLUDED_BOTTOM_NAVBAR from "./config/navbarRoute";
import { db, auth } from "@/main";
import { messageService } from "./helpers/message";
// import { collection, getDocs, query, where } from "firebase/firestore";
import Link from "./components/navbar/LinkComponent.vue";
import MenuLink from "./components/navbar/MenuLinkComponent.vue";
import { getProfileApi } from "./services/back-end-ruby";
export default {
  data: () => ({
    dialogSugestFormEmbeded: false,
    sheet: false,
    bikes: [],
    tab: "option-3",
    menu: false,
    currentLang: "",
    lang: "",
    chatBotBox: false,
    isMobileDevice: false,
    drawer: false,
    loading: true,
    loadingData: false,
    clipped: false,
    scrolled: false,
    scrolledHome: false,
    mouseTop: false,
    showActionButtons: false,
    showAirQuality: false,
    activeMenuAcc: false,
    fab: false,
    logofixed: false,
    airQualityDialog: false,
    connectWalletDialog: false,
    disconnectWalletDialog: false,
    learnMorelDialog: false,
    dialogFilePreview: false,
    searchDialog: false,
    fileName: "",
    resultPriceInterval: null,
    dark: false,
    trigger: false,
    nowList: new Date().getTime(),
    search: "",
    searchResults: [],
    searchTrigger: false,
    triggerSearch: false,
    showSearch: false,
    logoSrcHeadDark: "",
    logoSrc: "",
    logoSrcDark: "",
    srcPancake: "",
    srcTikTok: "",
    srcTikTokWhite: "",
    window: {
      width: 0,
      height: 0,
    },
    scrollY: 0,
    original: false,
    active4: false,
    active5: false,
    isOnline: true,
    snackbar: false,
    snackbarWarning: false,
    snackbarText: "",
    snackbarColor: "green",
    snackbarTimeout: 2000,
    messageData: [],
    botTyping: false,
    inputDisable: false,
    botOptions: {
      colorScheme: "#A020F0",
      msgBubbleBgUser: "#A020F0",
    },
    linkColor: "black",
    displayNumber: "",
    isLoggedIn2: false,
    listLanguages: [
      {
        name: "EN (English)",
        actionParam: "en",
      },
      {
        name: "ID (Indonesia)",
        actionParam: "id",
      },
    ],
    customerType: "",
    userDate: null,
    isSubscribeForm: false,
  }),
  components: {
    Link,
    MenuLink,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    show() {
      return this.$store.state.navigation.show;
    },
    isLoggedIn() {
      return this.$store.state.user.isLoggedIn;
    },
    showHome() {
      return this.$store.state.navigation.showHome;
    },
    showApp() {
      return this.$store.state.navigation.showApp;
    },
    getDark() {
      return this.$store.state.user.dark;
    },
    getTheme() {
      return this.$store.state.navigation.theme;
    },
    getUser() {
      return this.$store.state.user;
    },
    gravatar() {
      return this.$store.state.user.gravatar;
    },
    getLikes() {
      return this.$store.state.user.likes;
    },
    getColor() {
      return this.scrolledHome ||
        this.$route.name === "testRide" ||
        this.$route.name === "myaccount"
        ? "black"
        : "black";
      // "white";
    },
  },
  watch: {
    mmConnected() {
      if (this.mmConnected) {
        setTimeout(() => {
          this.connectWalletDialog = false;
        }, 2000);
      }
    },
    binanceConnected() {
      if (this.binanceConnected) {
        setTimeout(() => {
          this.connectWalletDialog = false;
        }, 2000);
      }
    },
    walletConnected() {
      if (this.walletConnected) {
        setTimeout(() => {
          this.connectWalletDialog = false;
        }, 2000);
      }
    },
    $route() {
      this.isSubscribeForm =
        this.$route.name === "form-subscribe" ||
        this.$route.name === "form-subscribe-mitra-gojek"
          ? true
          : false;
    },
  },
  mounted() {
    this.dark = this.getDark;
    this.connection();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("mousemove", this.mouseIsMoving);
    clearInterval(this.resultPriceInterval);
  },
  async created() {
    this.getBikes();
    this.lang = this.$store.state.lang;
    this.currentLang = this.getLanguage;
    // console.log(this.getLanguage);
    // console.log(this.lang);
    // this.currentUser = auth.currentUser
    // console.log('isMobileDevice')
    // console.log(this.isMobileDevice)
    this.isMobileDevice = isMobile;
    /* if (this.isMobileDevice) {
          screen.orientation.lock("portrait")
        } */
    //
    document.addEventListener("deviceready", this.onDeviceReady, false);
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("mousemove", this.mouseIsMoving);
    this.handleResize();
    this.logoSrc = "./img/logo/charged_logo_white.svg";
    this.logoSrcDark = "./img/logo/charged_logo.svg";

    this.logoSrcOri = "./img/logo/charged_logo_ori.png";
    this.logoSrcDarkOri = "./img/logo/charged_logo_ori.png";

    this.logoSrcList = "./img/logo/charged_logo_list.png";
    this.logoSrcListDark = "./img/logo/charged_logo_list_dark.png";

    this.srcPancake = "./img/icons/pancakeswap.png";
    this.srcTikTok = "./img/icons/tiktok_grey.png";
    this.srcTikTokWhite = "./img/icons/tiktok_white.png";

    this.isLoggedIn2 = this.getUser.isLoggedIn;
    //console.log("this.initUser()");
    // setTimeout(() => {
    //   this.loading = false;
    // }, 2000);
    // setTimeout(() => {
    // }, 2000);
    // this.initUser();
    setTimeout(() => {
      this.initUser();
    }, 2000);

    if (
      this.$route.path == "/sign/mitra_gojek/" ||
      this.$route.path == "/sign/mitra_gojek"
    ) {
      this.$router.push("/");
    }
  },
  methods: {
    gotoEmbededForm() {
      this.routerGo("/form-subscribe-mitra-gojek");
      this.dialogSugestFormEmbeded = false;
    },
    gotoBike(pathname) {
      this.routerGo("/bike/" + pathname);
      this.menu = false;
    },
    getBikes() {
      // this.bikes = [
      //   {
      //     name: "ANOA",
      //     nameLowercase: "anoa",
      //     folder: "vs1",
      //     description: "One Trip - deliver double loading of normal delivery.",
      //     image: "img/navbar/anoa-front-view-min.jpg",
      //     imagesGallery: [
      //       {
      //         image: "img/shared/bikes/anoa/banner-anoa-product-row-1.png",
      //       },
      //       {
      //         image: "img/shared/bikes/anoa/banner-anoa-product-row-2.png",
      //       },
      //       {
      //         image: "img/shared/bikes/anoa/banner-anoa-product-row-3.png",
      //       },
      //     ],
      //     imageBg: "img/app/bikes/logoType.png",
      //     motor: "Electric Motor - Super Soco 4000W",
      //     autonomy: "Autonomy 160 Km (with L1e specification)",
      //     speed: "90",
      //     maxDistance: "200",
      //     maxDistanceUnit: "km",
      //     recharge:
      //       "Recharge 3.5 h at 60V15A (fast charge with single battery)",
      //     count: 12,
      //     price: "Rp 32.000.000",
      //     subPrice: "Rp 1.290.000",
      //     batterySubscription: "Rp 350.000",
      //     // promoLebaranSvg: "img/home/lebaran/promo-lebaran-anoa.png",
      //   },
      //   {
      //     name: "RIMAU",
      //     nameLowercase: "rimau",
      //     folder: "cpx",
      //     description: "Meets the needs of the aspiring urban professional. ",
      //     image: "img/navbar/rimau-front-view-min.jpg",
      //     imagesGallery: [
      //       {
      //         image: "img/shared/bikes/rimau/banner-rimau-product-row-1.png",
      //       },
      //       {
      //         image: "img/shared/bikes/rimau/banner-rimau-product-row-2.png",
      //       },
      //       {
      //         image: "img/shared/bikes/rimau/banner-rimau-product-row-3.png",
      //       },
      //     ],
      //     imageBg: "img/app/bikes/logoType.png",
      //     motor: "Electric Motor - Super Soco EEC Power 4000W",
      //     autonomy:
      //       "Autonomy 140 Km (at 45 Km/h with 75 Kg driver, 2 batteries)",
      //     speed: "95",
      //     maxDistance: "200",
      //     maxDistanceUnit: "km",
      //     recharge:
      //       "Recharge 3.5 h at 60V15A (fast charge with single battery)",
      //     count: 7,
      //     price: "Rp 33.500.000",
      //     subPrice: "Rp 1.350.000",
      //     batterySubscription: "Rp 350.000",
      //     // promoLebaranSvg: "img/home/lebaran/promo-lebaran-rimau.png",
      //   },
      //   /* {
      //         name: "Charged 1.6",
      //         folder: "cux",
      //         description:
      //           "Small size big power. Excellent safety and flexibility.",
      //         image: "img/bikes/cux/home.png",
      //         motor: "Electric Motor - BOSCH 1600W",
      //         autonomy: "Autonomy 75 Km (at 45 Km/h with 75 Kg driver)",
      //         speed: "Speed 45 Km/h",
      //         recharge: "Recharge 3.5 h at 60V10A (fast charge)",
      //         count: 7,
      //       }, */
      //   {
      //     name: "MALEO",
      //     nameLowercase: "maleo",
      //     folder: "vs2",
      //     description:
      //       "The multi-functional delivery vehicle adding value for your business.",
      //     image: "img/navbar/maleo-front-view-min.jpg",
      //     imagesGallery: [
      //       {
      //         image: "img/shared/bikes/maleo/banner-maleo-product-row-1.png",
      //       },
      //       {
      //         image: "img/shared/bikes/maleo/banner-maleo-product-row-2.png",
      //       },
      //       {
      //         image: "img/shared/bikes/maleo/banner-maleo-product-row-3.png",
      //       },
      //     ],
      //     imageBg: "img/app/bikes/logoType.png",
      //     motor: "Engine Single Swingarm 3000W",
      //     autonomy:
      //       "Autonomy 75 Km (at 45 Km/h with 75 Kg driver, 2 batteries)",
      //     speed: "70",
      //     maxDistance: "125",
      //     maxDistanceUnit: "km",
      //     recharge: "Recharge 3.5 h at 60V15A (fast charge)",
      //     count: 8,
      //     price: "Rp 24.000.000",
      //     subPrice: "Rp 1.200.000",
      //     batterySubscription: "Rp 350.000",
      //     // promoLebaranSvg: "img/home/lebaran/promo-lebaran-maleo.png",
      //   },
      // ];

      this.bikes = this.$store.getters.vehicles_store;
    },
    //for vue connection listener
    executeFunction(actionParam) {
      store.commit("SetLanguage", {
        language: (this.getLanguage = actionParam),
      });
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    connection() {
      this.$bus.$on("connection", (online) => {
        if (online && !this.isOnline) {
          this.isOnline = true;
          this.snackbarColor = "green";
          this.snackbarText = this.lang[this.getLanguage].ONLINE;
          this.snackbar = true;
          this.snackbarTimeout = 2000;
        }
        if (!online) {
          this.isOnline = false;
          this.snackbarColor = "red";
          this.snackbarText = this.lang[this.getLanguage].OFFLINE;
          this.snackbar = true;
          this.snackbarTimeout = -1;
        }
      });
    },
    botStart() {
      // Get token if you want to build a private bot
      // Request first message here
      // Fake typing for the first message
      this.botTyping = true;
      setTimeout(() => {
        this.botTyping = false;
        this.messageData.push({
          agent: "bot",
          type: "text",
          text: "Hello how can I help you?",
        });
      }, 1000);
    },
    msgSend(value) {
      // Push the user's message to board
      this.messageData.push({
        agent: "user",
        type: "text",
        text: value.text,
      });
      this.getResponse();
    },
    getResponse() {
      // Loading
      this.botTyping = true;
      // Post the message from user here
      // Then get the response as below
      // Create new message from fake data
      // lookup in FAQ >> firebase actions
      // Look at result from firebase
      //
      //
      messageService.createMessage().then((response) => {
        const replyMessage = {
          agent: "bot",
          ...response,
        };
        this.inputDisable = response.disableInput;
        this.messageData.push(replyMessage);
        // finish
        this.botTyping = false;
      });
    },
    disconnecWallet() {
      if (this.mmConnected) {
        this.$refs.mmConnect.disconnectMetamask();
      } else if (this.binanceConnected) {
        this.$refs.mmConnect.disconnectBinance();
      } else if (this.walletConnected) {
        this.$refs.mmConnect.disconnecWallet();
      }
    },
    initUser() {
      // auth.onAuthStateChanged(async (user) => {
      //   if (user) {
      //     this.currentUser = user;
      //     this.displayNumber = this.currentUser.phoneNumber;
      //     this.isLoggedIn2 = true;
      //     const q = query(
      //       collection(db, "users"),
      //       where("uid", "==", this.currentUser.uid)
      //     );
      //     const querySnapshot = await getDocs(q);

      //     if (querySnapshot.empty) {
      //       const getUser = this.$store.state.user;
      //       console.log("User data not found");
      //       store.commit("SetUserDetails", {
      //         ...getUser,
      //         uid: this.currentUser.uid,
      //         phone: this.currentUser.phoneNumber,
      //       });
      //     } else {
      //       let userData = querySnapshot.docs[0].data();
      //       console.log("userData", userData, user.uid);
      //       store.commit("SetUserDetails", {
      //         // ...getUser,
      //         uid: userData?.uid,
      //         docId: user.uid,
      //         language: userData.language,
      //         displayName: userData.displayName,
      //         aboutMe: userData.about_me,
      //         address_1: userData.address?.streetLine1 ?? "",
      //         address_2: userData.address?.streetLine2 ?? "",
      //         customerType: userData.customerType,
      //         city: userData.address?.city,
      //         zip: userData.address?.postalCode,
      //         // fullName: userData.givenNames,
      //         fullName: userData.name,
      //         dob: userData.birthDate,
      //         sex: userData.gender,
      //         email: userData.email,
      //         gravatar: userData.gravatar,
      //         avatar: userData.avatar,
      //         isLoggedIn: true,
      //         isVerified: userData.emailVerified,
      //         idVerified: userData.id_verified,
      //         phoneVerified: userData.phone_verified,
      //         phone: userData.phoneNumber,
      //         accLevel: userData._level,
      //         accType: userData.acc_type,
      //         accStatus: userData.status,
      //         accBalance: userData.acc_balance,
      //         memberSince: userData.created,
      //         favorites: userData.interests_favorites,
      //         myapplications: userData.myapplications,
      //         // location: this.userData.location
      //       });
      //     }

      //     // if (querySnapshot.docs.length === 0) {
      //     //   //console.log("User data not found");
      //     //   return;
      //     // }

      //     // querySnapshot.forEach((doc) => {
      //     // console.log(doc.data()?.customerType);
      //     // this.customerType = doc.data()?.customerType;
      //     // if (!this.customerType) return;
      //     // console.log("doc", doc);
      //     // doc.data() is never undefined for query doc snapshots
      //     // console.log(doc.id, " => ", doc.data());
      //     // });
      //   } else {
      //     console.log("User is not logged in");
      //   }
      //   if (this.$store.state.user.customerType === "driver")
      //     this.changeLanguage("id");
      // });

      // if (!isLoggedIn) {
      //   this.$cookies.remove("token_api");
      // }

      let token_api = this.$cookies.get("token_api");
      if (token_api) {
        getProfileApi(token_api)
          .then((res) => {
            // console.log("RES_PROFILE", res);
            if (res.meta.code == 200) {
              store.commit("SetUserDetails", {
                ...this.getUser,
                displayName: res.data.user.full_name,
                customerType: res.data.user.customer_type,
                // customerType: "mitra_gojek",
                city: res.data.user.profile.addresses[0].city,
                fullName: res.data.user.full_name,
                // dob: userData.birthDate,
                // sex: userData.gender,
                email: res.data.user.email,
                isLoggedIn: true,
                // idVerified: userData.id_verified,
                phoneVerified: true,
                phone: res.data.user.phone_number,
              });
              this.isLoggedIn2 = true;
              this.displayNumber = res.data.user.full_name;
              // console.log("THIS_USER", this.getUser);
              store.commit("setIdSubs", res.data.subscription_id);
              // store.commit("setProfile", res.data.user.profile);
              store.commit("setVehicleId", res.data?.vehicle_id ?? null);
              if (
                res.data.subscription_id !== null &&
                res.data.user.customer_type == "mitra_driver_gojek"
              ) {
                let addressUser = res.data.user.profile.addresses.find(
                  (a) => a.address_type == "current_address"
                );
                this.$store.commit("SetServiceCity", {
                  province: addressUser.province,
                  state: "",
                  district: addressUser.city,
                  regency: addressUser.district,
                  village: addressUser.sub_district,
                  postcode: addressUser.postal_code,
                });
                if (this.$route.name == "Home") {
                  this.dialogSugestFormEmbeded = true;
                }
              }
            } else {
              this.isLoggedIn2 = false;
              this.$cookies.remove("token_api");
            }
          })
          .catch(() => {
            // console.log(err);
            this.isLoggedIn2 = false;
            this.$cookies.remove("token_api");
          });
      }
      // console.log("GET_COOKIE_VUE", token_api);
    },
    changeTheme() {
      this.dark = !this.dark;
      store.commit("SetTheme", {
        dark: this.dark,
      });
    },
    changeThemeMenu(dark) {
      this.dark = dark;
      store.commit("SetTheme", {
        dark: this.dark,
      });
    },
    openSearch() {
      this.$router.push("/tokens/search");
      // this.searchDialog = true
    },
    searchClicked(e) {
      // console.log('search')
      if (e === "click" && this.search !== "") {
        this.searchAll();
        return;
      }
      if (e.keyCode === 13 && this.search !== "") {
        // alert('Enter was pressed')
        this.searchAll();
        return;
      }
      this.searchTrigger = false;
    },
    searchAll() {
      this.loadingData = true; // .where('expire', '>', today)
      this.searchTrigger = true;
      this.searchResults = [];
      let search = this.search.toLowerCase();
      // console.log(this.search)
      let query = db
        .collection("tokens")
        .where("keywords", "array-contains-any", [search, "#" + search]);
      query
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            //console.log("No matching documents.");
            this.loadingData = false;
            return;
          }
          snapshot.forEach((doc) => {
            // console.log(doc.id, '=>', doc.data())
            var obj = doc.data();
            obj.id = doc.id;
            obj.readmore = false;
            obj.price = 0;
            obj.price_text = "0";
            obj.mc = 0;
            this.searchResults.push(obj);
            this.loadingData = false;
          });
        })
        // .catch((err) => {
        //   // console.log("Error getting documents", err);
        // });
    },
    likeThisItem(item) {
      if (this.getLikes.indexOf(item.id) > -1) {
        this.snackbarText = "You have already liked this item";
        this.snackbarWarning = true;
        return;
      }
      store.commit("SetLikes", item.id);
      let dispatchObj = {
        likes: (item.likes += 1),
      };
      db.collection("tokens")
        .doc(item.id)
        .update(dispatchObj)
        .then(() => {
          // console.log('Item likes in news bucket updated')
          this.snackbarText = "Your like has been recorded";
          this.snackbar = true;
        })
        // .catch(() => {
        //   console.log(error);
        // });
    },
    lookupLiked(item) {
      // console.log(item)
      return this.getLikes.indexOf(item.id) > -1;
    },
    routerGo(route) {
      this.$router.push(route);
    },
    goToLink(link) {
      window.open(link, "_blank");
    },
    onDeviceReady() {
      // console.log(device.cordova)
      // console.log(device.model)
      // console.log(device.platform)
      store.commit("SetDevice", {
        // platform: device.platform
      });
    },
    showSearchField() {
      this.showSearch = true;
      setTimeout(() => {
        this.$refs.search.focus();
      });
    },
    clearSearch() {
      this.search = "";
      this.searchTrigger = false;
    },
    changeLanguage(lang) {
      // console.log(this.getLanguage)
      store.commit("SetLanguage", {
        language: (this.getLanguage = lang),
      });
      /* this.userData.language = lang
          var obj = {
            language: lang
          }
          this.saveSettingsData(obj) */
    },
    openPrivacy() {
      // this.readFile()
      if (this.getLanguage === "en") {
        this.fileName = ""; // https://charged.asia/terms/Privacy%20Policy%20ENG.pdf
      } else if (this.getLanguage === "id") {
        this.fileName = "";
      }
      this.dialogFilePreview = true;
      /* if (device.platform === 'Android') {
            window.open(this.fileName, '_blank')
          } else {
            this.dialogFilePreview = true
          } */
    },
    openTerms() {
      // this.readFile()
      if (this.getLanguage === "en") {
        this.fileName = ""; // https://charged.asia/terms/Terms%20and%20Conditions%20ENG.pdf
      } else if (this.getLanguage === "id") {
        this.fileName = "";
      }
      this.dialogFilePreview = true;
      /* if (device.platform === 'Android') {
            window.open(this.fileName, '_blank')
          } else {
            this.dialogFilePreview = true
          } */
    },
    openIndemnity() {
      // this.readFile()
      if (this.getLanguage === "en") {
        this.fileName = "http://209.58.172.141:8082/terms/Indemnity%20ENG.pdf";
      } else if (this.getLanguage === "id") {
        this.fileName = "";
      }
      this.dialogFilePreview = true;
      /* if (device.platform === 'Android') {
            window.open(this.fileName, '_blank')
          } else {
            this.dialogFilePreview = true
          } */
    },
    openSubscriptionPolicy() {
      // this.readFile()
      if (this.getLanguage === "en") {
        this.fileName =
          "http://test.charged.asia:8082/terms/SubscriptionPolicy%20ENG.pdf";
      } else if (this.getLanguage === "id") {
        this.fileName = "";
      }
      this.dialogFilePreview = true;
      /* if (device.platform === 'Android') {
            window.open(this.fileName, '_blank')
          } else {
            this.dialogFilePreview = true
          } */
    },
    signOut() {
      auth.signOut().then(() => {
        this.$router.push("/");
        window.location.reload();
      });
    },
    handleResize() {
      setTimeout(() => {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
        // console.log(this.window.width)
        // console.log(this.window.height)
      }, 500);
      // this.window.width > 770 ? this.drawer = false : this.drawer = true
    },
    menuChangeClicked() {
      if (window.width < 770) {
        this.drawer = false;
      }
      // navigate to home #div
    },
    mouseIsMoving(e) {
      var x = e.pageX;
      var y = e.pageY;
      // console.log(x, y)
      if (y > 0 && y < 70 && x > 230) {
        this.mouseTop = true;
      } else if (!this.scrolledHome) {
        this.mouseTop = false;
      }

      // Show action buttons
      if (
        y > this.window.height - 250 &&
        y < this.window.height &&
        x < this.window.width - 250
      ) {
        // console.log('Show Action Buttons')
        this.showActionButtons = true;
      } else {
        // console.log('Hide Action Buttons')
        this.showActionButtons = false;
      }

      // Show air quality panel
      if (x > this.window.width - 200 && x < this.window.width && y > 100) {
        // console.log('Show Air Qualkity')
        this.showAirQuality = true;
      } else {
        // console.log('Hide Air Qualkity')
        this.showAirQuality = false;
      }
    },
    handleScroll() {
      // console.log(window.scrollY)
      this.scrollY = window.scrollY;
      if (window.scrollY > 250) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
      if (window.scrollY > 5) {
        this.scrolledHome = true;
        this.mouseTop = true;
      } else {
        this.scrolledHome = false;
        this.mouseTop = false;
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    timer(ms) {
      return new Promise((res) => setTimeout(res, ms));
    },
    routeNotIncludes(route) {
      var routes = ["about", "blog", "vacancies", "bikes", "testride"];
      return routes.includes(route);
    },
    showBottomNavBarBaseOnRoute(route) {
      var routes = ROUTE_INCLUDED_BOTTOM_NAVBAR;
      return routes.includes(route);
    },
    timeDifference(previous) {
      var current = Math.round(new Date() / 1000);
      // console.log(current)
      var msPerMinute = 60;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;
      var elapsed = current - previous;

      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + " seconds ago";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " minutes ago";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " hours ago";
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " days ago";
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " months ago";
      } else {
        return Math.round(elapsed / msPerYear) + " years ago";
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@900&display=swap");

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Work+Sans:wght@100;200;300;400;500;600;700;900&display=swap');

#charged-app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h_iframe iframe {
  width: 100%;
  height: 100%;
}

.h_iframe {
  height: 100vh;
  width: 100%;
  margin-top: -105px;
}

.h_iframe2 {
  height: 100vh;
  width: 100%;
}

/* This is for documentation purposes and will not be needed in your application */
#charged-app .v-speed-dial {
  position: absolute;
}

#charged-app .v-btn--floating {
  position: relative;
}

// Desqy add this css :
/* .v-app-bar--fixed {
    color: white !important;
  } */

/* .v-app-bar--is-scrolled {
    background-color: white !important;
    color: black !important;
  }

  .v-app-bar--fixed .v-btn {
    color: white !important;
  }

  .v-app-bar--is-scrolled .v-btn {
    color: black !important;
  }



  .v-app-bar--is-scrolled .v-icon {
    color: black !important;
  } */

// .v-application .elevation-1 {
//   box-shadow: none !important;
// }

/* .v-app-bar--fixed .v-icon {
    color: white !important;
  } */

.v-app-bar--is-scrolled {
  background-color: rgba(255, 255, 255) !important;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  background-color: white;
}

.theme--light.v-text-field--outlined:not(.v-input--is-active):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  background-color: white;
}

.no-background-hover::before {
  background-color: transparent !important;
}

.fab-whatsapp {
  position: fixed;
  bottom: 30px;
  left: 40px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px 0px;
}

.fab-whatsapp {
  left: 25px;
  bottom: 25px;
}

@media (max-width: 768px) {
  .theme--light.v-icon {
    color: rgb(255 255 255);
  }

  .nav-menu-mobile {
    color: #c1c2c4 !important;
    font-size: 21px !important;
    letter-spacing: -0.02em;
    width: 100%;
    justify-content: left;
    text-transform: unset;
  }

  .nav-menu-child-mobile {
    color: #c1c2c4 !important;
    letter-spacing: -0.02em;
    font-size: 16px;
    width: 100%;
    justify-content: left;
  }
}

span {
  font-family: Work sans;
}

.kumbh-sans {
  font-family: "Kumbh Sans";
}

.custom-icon i {
  color: white !important;
}

// END Desqy css :

.lowercase-text {
  text-transform: none;
  background: transparent;
}

.lowercase-text.theme--light {
  background: transparent !important;
}

.menuable__content__active-child {
  top: 60px !important;
  left: 35% !important;
}

.text-menu-vihecle {
  position: absolute;
  bottom: 0px;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
  color: #858688;
  z-index: 2;
}

@media screen and (min-width: 768px) {
  .v-item-group {
    margin-left: auto !important;
  }
}

.image-cutom-0 {
  position: relative;
  // width: 65%;
  // background: red;
  z-index: 1;
}
.image-cutom-1 {
  position: relative;
  // width: 65%;
  // background: red;
  z-index: 1;
}
.image-cutom-2 {
  position: relative;
  // width: 55%;
  // background: red;
  z-index: 1;
}

.image-custom {
  position: relative;
  z-index: 1;
  top: 0;
  width: 300px;
  height: 100%;
}

.custom-menu-vihecle .v-tab::before {
  background-color: #f5f4f6 !important;
}

.custom-menu-vihecle .v-tabs-slider-wrapper {
  display: none !important;
}

.custom-menu-vihecle .v-tab {
  background: #f5f4f6;
  font-weight: 400;
  color: #353638 !important;
  border-right: 2px solid #f5f4f6;
}

.custom-menu-vihecle .v-tab:hover {
  background: white !important;
  font-weight: 600;
}

#btn-vmoto.v-btn:before {
  background-color: white;
}
</style>