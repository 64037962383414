import { BeRubyAPI } from "../../../clients/BERubyAPI";
import Jwt from "jsonwebtoken";

const postSetPlansApi = (token, { subscription_id, plans_id, bank_name, holder_name, account_number }) => {

  let data = {
    plans_id,
    bank_account: {
      bank_name,
      holder_name,
      account_number,
    }
  };

  let body = {
    body_params: data,
    timestamp: new Date()
  };

  let encryptData = Jwt.sign(body, process.env.VUE_APP_END_TO_END_SECRET_KEY);

  let responseFetch = BeRubyAPI.post('/v2/embedded_forms/set_plans',
    { key_id: subscription_id, payload: encryptData },
    {
      headers: {
        'Authorization': token !== null ? 'Bearer ' + token : '',
      }
    })
    .then((res) => { return res.data })
    .catch((err) => { return err.response.data });

  return responseFetch;
};

export { postSetPlansApi };