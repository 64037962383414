import { BeRubyAPI } from "../../../clients/BERubyAPI";

const postPrivateDocApi = (token, { file_ktp, file_kk, file_npwp, file_sim_c, file_payslip, subscription_id, file_capture_dashboard_driver, file_capture_dashboard_income_driver, file_account_transaction, file_domisili }) => {

  let bodyForm = new FormData();

  if (file_ktp) bodyForm.append('[document_file_params][documents][][document_type]', 'KTP');
  if (file_ktp) bodyForm.append('[document_file_params][documents][][file]', file_ktp);
  if (file_kk) bodyForm.append('[document_file_params][documents][][document_type]', 'KK');
  if (file_kk) bodyForm.append('[document_file_params][documents][][file]', file_kk,);
  if (file_npwp) bodyForm.append('[document_file_params][documents][][document_type]', 'NPWP');
  if (file_npwp) bodyForm.append('[document_file_params][documents][][file]', file_npwp);
  if (file_sim_c) bodyForm.append('[document_file_params][documents][][document_type]', 'SIM C');
  if (file_sim_c) bodyForm.append('[document_file_params][documents][][file]', file_sim_c);

  if (file_payslip) bodyForm.append('[document_file_params][documents][][document_type]', 'Payslip');
  if (file_payslip) bodyForm.append('[document_file_params][documents][][file]', file_payslip);


  if (file_capture_dashboard_driver) bodyForm.append('[document_file_params][documents][][document_type]', 'Capture Dashboard Driver');
  if (file_capture_dashboard_driver) bodyForm.append('[document_file_params][documents][][file]', file_capture_dashboard_driver);

  if (file_capture_dashboard_income_driver) bodyForm.append('[document_file_params][documents][][document_type]', 'Capture Dashboard Income Gojek');
  if (file_capture_dashboard_income_driver) bodyForm.append('[document_file_params][documents][][file]', file_capture_dashboard_income_driver);

  if (file_account_transaction) bodyForm.append('[document_file_params][documents][][document_type]', 'Account Transaction');
  if (file_account_transaction) bodyForm.append('[document_file_params][documents][][file]', file_account_transaction);

  if (file_domisili) bodyForm.append('[document_file_params][documents][][document_type]', 'Proof of Address');
  if (file_domisili) bodyForm.append('[document_file_params][documents][][file]', file_domisili);


  bodyForm.append('subscription_id', subscription_id);

  let responseFetch = BeRubyAPI.post('/v1/embedded_forms/private_document', bodyForm, {
    headers: {
      'Authorization': token !== null ? 'Bearer ' + token : '',
      "Content-Type": "multipart/form-data"
    }
  })
    .then((res) => { return res.data })
    .catch((err) => { return err.response.data });

  return responseFetch;
};

const getPrivateDocApi = (token) => {
  let responseFetch = BeRubyAPI
    .get('/v1/user_documents',
      {
        headers: {
          'Authorization': token !== null ? 'Bearer ' + token : '',
        }
      }
    )
    .then(res => {
      return res.data;
    }).catch((err) => {
      return err.response.data
    });

  return responseFetch;
}

export { postPrivateDocApi, getPrivateDocApi };