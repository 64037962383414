export const CUSTOMER_TYPE = {
  driver: ["mitra", "driver"],
  regular: ["non_mitra", "regular"],
};

export const STAY_TYPE = {
  less_than_one_year: "< 1 Year",
  one_to_three_years: "1-3 Years",
  four_to_six_years: "4-6 Years",
  more_than_six_years: "> 6 Years",
};

export const OWNERSHIP_STATUS_TYPE = {
  monthly_rental: "Monthly Rental",
  annual_rental: "Annual Rental",
  owned: "Owned",
};

export const ADDRESS_TYPE = {
  current_address: "Current Address",
  id_address: "ID Address",
};

export const PERSONAL_INFOS = {
  employmentStatusListEnglish: [
    {
      title: "Outsourced Employee/Partners",
      value: "outsorced",
    },
    {
      title: "Contract Employee",
      value: "contract",
    },
    {
      title: "Permanent Employee",
      value: "permanent",
    },
  ],
  employmentStatusListIndo: [
    {
      title: "Pegawai Outsourcing/Mitra Kerja",
      value: "outsorced",
    },
    {
      title: "Pegawai Kontrak",
      value: "contract",
    },
    {
      title: "Pegawai Tetap",
      value: "permanent",
    },
  ],
  jobPositionListEnglish: [
    {
      title: "Staff",
      value: "staff",
    },
    {
      title: "SPV",
      value: "spv",
    },
    {
      title: "Manager",
      value: "manager",
    },
    {
      title: "Director",
      value: "director",
    },
    {
      title: "Owner",
      value: "owner",
    },
  ],
  jobPositionListIndo: [
    {
      title: "Staff",
      value: "staff",
    },
    {
      title: "Supervisior",
      value: "spv",
    },
    {
      title: "Manajer",
      value: "manager",
    },
    {
      title: "Direktur",
      value: "director",
    },
    {
      title: "Pemilik",
      value: "owner",
    },
  ],
  creditCardLimit: [
    {
      title: "< Rp 3.000.000",
      value: "less_than_three_mill",
    },
    {
      title: "Rp 3.000.000 - Rp 5.000.000",
      value: "three_to_five_mill",
    },
    {
      title: "Rp 5.000.000 - Rp 10.000.000",
      value: "five_to_ten_mill",
    },
    {
      title: "Rp 10.000.000 - Rp 20.000.000",
      value: "ten_to_twenty_mill",
    },
    {
      title: "> Rp 20.000.000",
      value: "more_than_twenty_mill",
    },
  ],
  yearOfExperienceListIndo: [
    {
      title: "< 1 tahun",
      value: "less_than_one_year",
    },
    {
      title: "1-3 tahun",
      value: "one_to_three_years",
    },
    {
      title: "4-6 tahun",
      value: "four_to_six_years",
    },
    {
      title: "> 6 tahun",
      value: "more_than_six_years",
    },
  ],
  yearOfExperienceListEnglish: [
    {
      title: "< 1 year",
      value: "less_than_one_year",
    },
    {
      title: "1-3 years",
      value: "one_to_three_years",
    },
    {
      title: "4-6 years",
      value: "four_to_six_years",
    },
    {
      title: "> 6 years",
      value: "more_than_six_years",
    },
  ],
  itemsSalary: [
    {
      title: "< Rp 5.000.000",
      value: "less_than_five_mill",
    },
    {
      title: "Rp 5.000.000 - Rp 7.500.000",
      value: "five_to_seven_point_five_mill",
    },
    {
      title: "Rp 7.500.000 - Rp 13.000.000",
      value: "seven_point_five_to_thirteen_mill",
    },
    {
      title: "Rp 13.000.000 - Rp 20.000.000",
      value: "thirteen_mill_to_twenty_mill",
    },
    {
      title: "> Rp 20.000.000",
      value: "more_than_twenty_mill",
    },
  ],
  maritalStatusList: [
    {
      en: "Married",
      id: "Sudah Menikah",
    },
    {
      en: "Single",
      id: "Belum Menikah",
    },
  ],
  spousePositionListEnglish: [
    {
      title: "Housewife",
      value: "housewife",
    },
    {
      title: "Staff",
      value: "staff",
    },
    {
      title: "SPV",
      value: "spv",
    },
    {
      title: "Manager",
      value: "manager",
    },
    {
      title: "Director",
      value: "director",
    },
    {
      title: "Owner",
      value: "owner",
    },
  ],
  spousePositionListIndo: [
    {
      title: "Ibu Rumah Tangga",
      value: "housewife",
    },
    {
      title: "Staff",
      value: "staff",
    },
    {
      title: "Supervisior",
      value: "spv",
    },
    {
      title: "Manajer",
      value: "manager",
    },
    {
      title: "Direktur",
      value: "director",
    },
    {
      title: "Pemilik",
      value: "owner",
    },
  ],
  spouseYearOfExperienceListIndo: [
    {
      title: "< 1 tahun",
      value: "less_than_one_year",
    },
    {
      title: "1-3 tahun",
      value: "one_to_three_years",
    },
    {
      title: "4-6 tahun",
      value: "four_to_six_years",
    },
    {
      title: "> 6 tahun",
      value: "more_than_six_years",
    },
  ],
  spouseYearOfExperienceListEnglish: [
    {
      title: "< 1 year",
      value: "less_than_one_year",
    },
    {
      title: "1-3 years",
      value: "one_to_three_years",
    },
    {
      title: "4-6 years",
      value: "four_to_six_years",
    },
    {
      title: "> 6 years",
      value: "more_than_six_years",
    },
  ],
  itemsSpouseSalary: [
    {
      title: "< Rp 5.000.000",
      value: "less_than_five_mill",
    },
    {
      title: "Rp 5.000.000 - Rp 7.500.000",
      value: "five_to_seven_point_five_mill",
    },
    {
      title: "Rp 7.500.000 - Rp 13.000.000",
      value: "seven_point_five_to_thirteen_mill",
    },
    {
      title: "Rp 13.000.000 - Rp 20.000.000",
      value: "thirteen_mill_to_twenty_mill",
    },
    {
      title: "> Rp 20.000.000",
      value: "more_than_twenty_mill",
    },
  ],
}

export const DOMICILE_INFOS = {
  homeOwnershipStatusListEng: [
    {
      title: "Monthly Rent",
      value: "monthly_rental",
    },
    {
      title: "Annual Rentt",
      value: "annual_rental",
    },
    {
      title: "Owned",
      value: "owned",
    },
  ],
  homeOwnershipStatusListIndo: [
    {
      title: "Kontrak Bulanan",
      value: "monthly_rental",
    },
    {
      title: "Kontrak Tahunan",
      value: "annual_rental",
    },
    {
      title: "Milik",
      value: "owned",
    },
  ],
  lengthOfStayListEnglish: [
    {
      title: "< 1 years",
      value: "less_than_one_year",
    },
    {
      title: "1-3 years",
      value: "one_to_three_years",
    },
    {
      title: "4-6 years",
      value: "four_to_six_years",
    },
    {
      title: "> 6 years",
      value: "more_than_six_years",
    },
  ],
  lengthOfStayListIndo: [
    {
      title: "< 1 tahun",
      value: "less_than_one_year",
    },
    {
      title: "1-3 tahun",
      value: "one_to_three_years",
    },
    {
      title: "4-6 tahun",
      value: "four_to_six_years",
    },
    {
      title: "> 6 tahun",
      value: "more_than_six_years",
    },
  ],
}

export const BANKS = {
  bankNameItems: [
    {
      title: "BNI",
      value: "BNI",
    },
    {
      title: "Mandiri",
      value: "Mandiri",
    },
    {
      title: "Permata",
      value: "Permata",
    },
    {
      title: "BSI",
      value: "BSI",
    },
    {
      title: "Sampoerna",
      value: "Sampoerna",
    },
    {
      title: "BJB",
      value: "BJB",
    },
    {
      title: "BRI",
      value: "BRI",
    },
    {
      title: "Bank Lainnya",
      value: "Bank Lainnya",
    },
  ],
}
