export const PROVINCE = [
    // {
    //     "name": "Bali",
    //     "stage": "province"
    // },
    {
        "name": "DKI Jakarta",
        "stage": "province"
    },
    {
        "name": "Jawa Barat",
        "stage": "province"
    },
    {
        "name": "Banten",
        "stage": "province"
    },
    // {
    //     "name": "Tidak tersedia dalam daftar ini",
    //     "stage": "province"
    // }
]