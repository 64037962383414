"use strict";
import axios from "axios";
const CHARGED_API = process.env.VUE_APP_API;

class PaymentController {
  constructor() {
    var header = {
      "Content-Type": "application/json;charset=utf-8",
      "Access-Control-Allow-Origin": process.env.VUE_APP_URL,
      "CHARGED-API-URL":CHARGED_API,
      "CHARGED-API-KEY":process.env.VUE_APP_API_KEY,
      "CHARGED-WEB-ENV":process.env.VUE_APP_ENVIRONMENT,
    };
    this.header = header;
  }

  async createOrder(invoiceData) {
    const config = {
      headers: this.header
    };
    return axios
      .post(CHARGED_API + "orders", invoiceData, config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.error(e);
      });
  }
}

export default new PaymentController();