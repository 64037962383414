const dataGojekInformasiPribadiModul = {
  state: () => ({
    name: "",
    nik: "",
    emailGojek: "",
    emailPribadi: "",
    phoneNumberGojek: "",
    phoneNumberPribadi: "",
    tempatLahir: "",
    dateTglLahir: "",
    motherName: "",
    companyName: "",
    idDriver: "",
    yearOfExperience: "",
    salary: "",
    maritalStatus: "",
    spouseCompanyName: "",
    spousePositionLabel: "",
    spouseEmploymentStatus: "",
    spouseYearOfExperience: "",
    spouseSalary: "",
  }),
  mutations: {
    setDataGojekInformasiPribadi(state, payload) {
      state.name = payload.name;
      state.nik = payload.nik;
      state.emailGojek = payload.emailGojek;
      state.emailPribadi = payload.emailPribadi;
      state.phoneNumberGojek = payload.phoneNumberGojek;
      state.phoneNumberPribadi = payload.phoneNumberPribadi;
      state.tempatLahir = payload.tempatLahir;
      state.dateTglLahir = payload.dateTglLahir;
      state.motherName = payload.motherName;
      state.companyName = payload.companyName;
      state.idDriver = payload.idDriver;
      state.yearOfExperience = payload.yearOfExperience;
      state.salary = payload.salary;
      state.maritalStatus = payload.maritalStatus;
      state.spouseCompanyName = payload.spouseCompanyName;
      state.spousePositionLabel = payload.spousePositionLabel;
      state.spouseEmploymentStatus = payload.spouseEmploymentStatus;
      state.spouseYearOfExperience = payload.spouseYearOfExperience;
      state.spouseSalary = payload.spouseSalary;
    },
  },
  actions: {},
  getters: {
    getDataGojekInformasiPribadi(state) {
      return state;
    },
  }
}

export default dataGojekInformasiPribadiModul;