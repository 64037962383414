"use strict";
// const ipfsClient = require('ipfs-http-client')
import axios from "axios";
// const request = require('request')
// import store from '@/store/index'

var CHARGED_API;

if (process.env.VUE_APP_ENVIRONMENT === "local") {
  // console.log("API LOCAL ENVIRONMENT");
  CHARGED_API = process.env.VUE_APP_CHARGED_API_LOCAL;
} else if (process.env.VUE_APP_ENVIRONMENT === "test") {
  // console.log("API TEST ENVIRONMENT");
  CHARGED_API = process.env.VUE_APP_CHARGED_API_TEST;
} else if (process.env.VUE_APP_ENVIRONMENT === "production") {
  // console.log("API PRODUCTION ENVIRONMENT");
  CHARGED_API = process.env.VUE_APP_CHARGED_API;
}

let getters = null;
class ChargedAPI {
  constructor() {
    getters = getters || this;
    return getters;
  }

  // ####################################################################
  // ######################### ADD FILES ################################
  // ####################################################################

  getReport() {
    const config = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "CHARGED-API-KEY": "CH4RG3D-4P1-K3Y:4N0AMAL30"

      },
    };
    return axios
      .get(CHARGED_API + "charged/report/testride", config)
      .then((response) => {
        return response;
      })
      // .catch((e) => {
      //   // console.log(e);
      // });
  }

  getNumJobs() {
    const config = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "CHARGED-API-KEY": "CH4RG3D-4P1-K3Y:4N0AMAL30"

      },
    };
    return axios
      .get(CHARGED_API + "charged/numjobs", config)
      .then((response) => {
        // console.log(response)
        return response;
      })
      // .catch((e) => {
      //   // console.log(e);
      // });
  }

  getResource(doctype, name, filters) {
    const requestBody = {
      doctype: doctype,
      name: name,
      filters: filters,
    };
    const config = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "CHARGED-API-KEY": "CH4RG3D-4P1-K3Y:4N0AMAL30"
      },
    };
    return axios
      .post(CHARGED_API + "charged/getresource", requestBody, config)
      .then((response) => {
        // console.log(response)
        return response;
      })
      // .catch((e) => {
      //   // console.log(e);
      // });
  }

  updatePosterAccount(id) {
    const requestBody = {
      id: id,
    };
    const config = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "CHARGED-API-KEY": "CH4RG3D-4P1-K3Y:4N0AMAL30"
      },
    };
    return axios
      .post(CHARGED_API + "charged/updateaccount", requestBody, config)
      .then((response) => {
        // console.log(response)
        return response;
      })
      // .catch((e) => {
      //   // console.log(e);
      // });
  }

  createResource(doctype, jsondoc) {
    const requestBody = {
      doctype: doctype,
      jsondoc: jsondoc,
    };
    const config = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "CHARGED-API-KEY": "CH4RG3D-4P1-K3Y:4N0AMAL30"

      },
    };
    // console.log(requestBody);
    return axios
      .post(CHARGED_API + "charged/createresource", requestBody, config)
      .then((response) => {
        // console.log(response)
        return response;
      })
      // .catch((e) => {
      //   // console.log(e);
      // });
  }

  getTimeSlot(date, location) {
    const config = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "CHARGED-API-KEY": "CH4RG3D-4P1-K3Y:4N0AMAL30"
      },
    };
    return axios
      .get(CHARGED_API + "testRide/"+location+"/"+date+"", config)
      .then((response) => {
        return response;
      })
      // .catch((e) => {
      //   // console.log(e);
      // });
  }

  // ####################################################################
  // ######################### ERP CALLS ################################
  // ####################################################################

  createTestRide(requestBody) {
    const config = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "CHARGED-API-KEY": "CH4RG3D-4P1-K3Y:4N0AMAL30"

      },
    };
    // console.log(requestBody);
    return axios
      .post(CHARGED_API + "testRide", requestBody, config)
      .then((response) => {
        return response;
      })
      // .catch((e) => {
      //   // console.log(e);
      // });
  }
}
export default new ChargedAPI();
