const privateInfoModule = {
  state: () => ({
    full_name: "",
    email: "",
    phone_number: "",
    guardian_name: "",
    company_name: "",
    job_position: "",
    employment_status: "",
    length_of_work: "",
    profit_income: "",
    credit_card_limit: "",
    employment_type: "",
    marital_status: "",
  }),
  mutations: {
    setPrivateInfo(state, payload) {
      state.full_name = payload.full_name;
      state.email = payload.email;
      state.phone_number = payload.phone_number;
      state.guardian_name = payload.guardian_name;
      state.company_name = payload.company_name;
      state.job_position = payload.job_position;
      state.employment_status = payload.employment_status;
      state.length_of_work = payload.length_of_work;
      state.profit_income = payload.profit_income;
      state.credit_card_limit = payload.credit_card_limit;
      state.employment_type = payload.employment_type;
      state.marital_status = payload.marital_status;
    },
  },
  actions: {},
  getters: {
    getPrivateInfo(state) {
      return state;
    },
  }
}

export default privateInfoModule;