<template>
  <v-bottom-navigation color="deep-purple darken-3" :dark="dark" fixed>
    <template v-for="(item, index) in menus">
      <v-btn v-if="show(item)" :key="index" :to="item.url" :style="item.style">
        <span v-if="item.isShowName">{{ item.name }}</span>
        <v-icon v-if="item.isShowName">{{ item.icon }}</v-icon>
        <v-icon large v-if="!item.isShowName">{{ item.icon }}</v-icon>
      </v-btn>
    </template>
  </v-bottom-navigation>
</template>
<script>
export default {
  name: "BottomNavigation",
  props: { account: Number, dark: Boolean },
  data: () => ({
    menus: [
      {
        url: "/accinnbox",
        isShowName: true,
        name: "Inbox",
        icon: "mdi-bell-outline",
        isContainRule: false,
        style: "padding-left:20px;padding-right:0px",
      },
      {
        url: "/accscan",
        isShowName: true,
        name: "Scan",
        icon: "mdi-barcode-scan",
        isContainRule: false,
        style: "",
      },
      {
        url: "/charged",
        isShowName: false,
        name: "",
        icon: "mdi-home-account",
        isContainRule: true,
        ruleType: 1,
        ruleValue: 0,
        style: "",
      },
      {
        url: "/account",
        isShowName: false,
        name: "",
        icon: "mdi-home-account",
        isContainRule: true,
        ruleType: 2,
        ruleValue: 9,
        style: "",
      },
      {
        url: "/history",
        isShowName: true,
        name: "History",
        icon: "mdi-history",
        isContainRule: false,
        style: "",
      },
      {
        url: "/myaccount",
        isShowName: true,
        name: "Manage",
        icon: "mdi-account-cog-outline",
        isContainRule: false,
        style: "padding-right:20px;padding-left:0px",
      },
    ],
  }),
  methods: {
    show(item) {
      if (item.isContainRule === false) {
        return true;
      } else {
        if (item.ruleType === 1) {
          return this.account === item.ruleValue;
        }
        if (item.ruleType === 2) {
          return this.account > item.ruleValue;
        }
      }
    },
  },
};
//1 for Exact type ===
//2 for More Than Type >
</script>
