const domicileModule = {
  state: () => ({
    pick_up: "",
    address_street: "",
    postal_code: "",
    ownership_status: "",
    length_of_stay: "",
    domicileKtp: null,
  }),
  mutations: {
    setdomicile(state, payload) {
      state.pick_up = payload.pick_up;
      state.address_street = payload.address_street;
      state.postal_code = payload.postal_code;
      state.ownership_status = payload.ownership_status;
      state.length_of_stay = payload.length_of_stay;

      state.domicileKtp = {
        province: payload.province_ktp,
        district: payload.district_ktp,
        regency: payload.regency_ktp,
        village: payload.village_ktp,
        address_street: payload.address_street_ktp,
        postal_code: payload.postal_code_ktp,
      }

    },
  },
  actions: {},
  getters: {
    getdomicile(state) {
      return state;
    },
  }
}

export default domicileModule;