const dataGojekPlanSubsModul = {
  state: () => ({
    bankName: "",
    accountBankNumber: "",
    accountHoldersName: "",
    plansQty: ""
  }),
  mutations: {
    setDataGojekPlanSubs(state, payload) {
      state.bankName = payload.bankName;
      state.accountBankNumber = payload.accountBankNumber;
      state.accountHoldersName = payload.accountHoldersName;
      state.plansQty = payload.plansQty;
    },
  },
  actions: {},
  getters: {
    getDataGojekPlanSubs(state) {
      return state;
    },
  }
}

export default dataGojekPlanSubsModul;