import Vue from "vue";
import App from "./App.vue";
import VueMeta from "vue-meta";
import VueGtag from "vue-gtag";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import FIREBASE_CONFIG from "./config/firebase";
import VueSocialSharing from "vue-social-sharing";
import VueScrollTo from "vue-scrollto";
import PhoneInput from "vue2-phone-input";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import VueClipboards from "vue-clipboards";
import Gravatar from "vue-gravatar";
import "tiptap-vuetify/dist/main.css";
import VueApexCharts from "vue-apexcharts";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import "./assets/fonts/stylesheet.css";
import bus from "./bus/index";
import BottomNavigation from "../src/components/shared/BottomNavigation.vue";
import TestDriveComponent from "../src/views/web/comp/Bikes/TestDriveComponent.vue";
import CalendarComponent from "../src/views/web/comp/Bikes/CalendarComponent.vue";
import ChargedTextListDialog from "../src/components/shared/ChargedTextListDialog.vue";

import VueConnectionListener from "vue-connection-listener";

import "./assets/css/style.css";
import './assets/tailwind.css'

// cookies
import VueCookies from "vue-cookies";

Vue.prototype.$bus = bus;
const connectionListener = new VueConnectionListener(bus);

Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    // config: { id: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID },
    config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID }
  },
  router
);
Vue.use(VueSocialSharing);

Vue.use(VueMeta);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 1000,
  easing: "ease",
  offset: -58,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi",
});
Vue.component("phone-input", PhoneInput);
Vue.component("bottom-navigation", BottomNavigation);
Vue.component("test-drive-component", TestDriveComponent);
Vue.component("calendar-component", CalendarComponent);
Vue.component("charged-text-list-dialog", ChargedTextListDialog);

Vue.use(VueClipboards);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.component("v-gravatar", Gravatar);
Vue.use(VuePlyr, {
  plyr: {},
});

// set use Cookie dependencis
Vue.use(VueCookies);

// console.log(process.env.VUE_APP_ENVIRONMENT);

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);

if (app) {
  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
    created() {
      connectionListener.register();
    },
    destroyed() {
      connectionListener.unregister();
    },
  }).$mount("#app");
}

