import { BeRubyAPI } from "../../../clients/BERubyAPI"

const postInterestProduct = (token, { vehicle_id, name, email, phone }) => {
  let data = JSON.stringify({
    product_interest_form: {
      vehicle_id,
      name,
      email,
      phone
    }
  })

  let responseFetch = BeRubyAPI.post("/v1/new_product_vehicles",
    data).then(res => { return res })
    .catch(err => { return err })

  return responseFetch;
}

export { postInterestProduct };