export const LANG = {
  en: {
    // menu and module titles
    APP: "ChargedAsia",
    SLOGAN: "Ride for Good!", // 'The place for soccer enthusiasts.'
    WELCOME: "WELCOME TO Charged Asia!",
    WELCOME_TEXT_UPDATED: "Welcome Text Updated",
    ONLINE: " You are online.",
    OFFLINE: " Connection lost, you are offline!",
    LANG: "Language",
    ABOUT: "About Charged Asia",
    ABOUT_TEXT: "Developed by Martin Kuijs",
    ABOUT_VERSION: "Version:",
    CHAT_WA: "Chat through WhatsApp",
    BATTERY: "Battery",
    CHARGER: "charger",
    REGULAR_CHARGER: "Regular Charger",
    BATTERIES: "battery",
    MONTH: "month",
    START_FROM: "Start from",
    PHONE_NUMBER: "Phone Number",
    EMAIL_ADDRESS: "Email Address",
    SIGNOUT: "Sign Out",

    // Get started
    LANDING: "",
    MODAL_DOCHI_TITLE: "LIMITED EDITION",
    MODAL_DOCHI_DESCRIPTION: "Now open for pre-orders!",

    // cookies
    COOKIES_DESCRIPTION: "By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.",
    COOKIES_ACCEPTED: "Accept Cookies",
    COOKIES_DECLINED: "No, Thanks",

    // Signup
    SIGNUP_TITLE: "Join our Charged Club for exclusive deals and events",
    SIGNUP_PHONE: "Phone Number",
    SIGNUP_SUBMIT: "Continue",
    SIGNUP_DESCRIPTION: "By signing in, you agree with our Terms & Conditions and Privacy & Cookie Policy.",
    YOUR_NUMBER: "What's your number?",
    THE_CODE: "Enter Verification Code",
    WE_TEXT: "We will text you a code to verify",
    SMS_SENT: "We have sent a verification code through SMS to ",
    ENTER_CODE: "Enter the code",
    RE_SEND: "Resend Code",
    DIDNT_GET_CODE: "Didn’t receive the code?",
    PROBLEMS: "Problems receiving the code?",
    INVALID_CODE: "Invalid SMS code format!",
    INV_CODE: "Invalid code!",
    CODE_MUST_BE: "The code must be 6 numbers",
    CHECK_SMS: "Please check your SMS or resend the code.",
    CHECK_NR: "Please check your number.",
    SMS_ERROR: "Error. SMS not sent!",
    ALREADY_ACCOUNT: "Already have an account?",
    LOGIN_EMAIL: "Login with your email address",
    NEW_PHONE: "New phone number?",
    CHANGE_PHONE: "Change your phone number here",

    // Sign Up Next
    IN_ORDER:
      "In order to complete the setup of your account, we need a little more information from you...",
    ACCOUNT_CREATED: "Account created successfully.",
    CONGRATULATIONS: "WELCOME TO",
    CONGRATULATIONS_A: "Charged Asia!",
    ACC_READY:
      "Your account has been successfully registered! You can now sign in on any device with the mobile number or email you’ve provided.",
    ACC_TEMP_EMAIL:
      "In the event you’ve changed your mobile number, please use either your personal email or the Charged Asia email provided to you in Your Account to log in again.",
    NO_EMAIL: "I don't have an email address",
    SET_PASSWORD: "Please, set your password",
    AGREE_TERMS: "I agree to the Terms and Privacy Policy",

    // Sign In
    SIGN_IN_HERE: "Please, Sign In here",
    PW_RECOVERY: "Password Recovery",
    ENTER_EMAIL: "Please, enter your email address",
    ENTER_NEW_EMAIL: "Please, enter your new email address",
    CHANGE_PHONE_NR: "Change Your Phone Number",
    SIGN_IN_FIRST: "Please, Sign In first",
    FORGOT_PWD: "Forgot your password?",
    NO_ACCOUNT: "No Account yet?",
    SIGN_UP_NOW: "Sign Up now!",
    SEND_RESET_LINK: "We will send a reset link to your email.",
    EMAIL_SENT: "Email Sent",
    CHECK_EMAIL: "Please check your inbox, an email is on the way.",
    EMAIL_INSTRUCTIONS:
      "All you have to do is open it and click the link. Of course, if you have any questions, you can contact us.",

    // Home
    HOME: "Home",
    POWERED_BY: "Powered by VMOTOSOCO",
    ABOUT_US: "About Us",
    CATALOG: "Charged Model Lineup 2023",
    MODEL: "Model",
    NEWS_REEL: "Sustainability Stories",
    VEHICLES: "Vehicles",
    BIKES: "Bikes",
    CAREERS: "Mission Roles",
    TEAM: "Team",
    CONTACT: "FAQ Contact",
    CONTACT_TITLE: "Contact Information and Useful Links",
    CONTACT_SUB_TITLE:
      "Learn more about Charged Asia, connect with the team and the community.",
    ADMIN_HOME: "Admin Home",
    APPLY_FOR_A_JOB: "Apply for a Job",
    SLOGAN_DESCRIPTION: "With our monthly based subscription plans, you can take control on how you ride. Enjoy full flexibility in the way you move here at Charged.",
    TEST_RIDE_SHOWROOM: "Test Ride in Our Showroom",
    EMAIL_INPUT: "Your Email Address",
    EMAIL_SUBMIT_BUTTON: "Receive Exclusive Invites",
    EMAIL_DESCRIPTION: "*By providing contact, you agree to receive news and updates on Charged products and services.",
    HOME_ICON_TITLE: "Battery Technology powered by",
    HOME_ICON_DESCRIPTION: "Find out more about CATL",
    LOW_MAINTENANCE_TITLE: "Low Maintenance",
    LOW_MAINTENANCE_DESCRIPTION: "More time riding, less time waiting",
    PORTABLE_BATTERIES_TITLE: "Portable Batteries",
    PORTABLE_BATTERIES_DESCRIPTION: "Charge anywhere, anytime",
    EASY_RIDING_TITLE: "Easy Riding",
    EASY_RIDING_DESCRIPTION: "Developed by riders for riders",
    CONNECTED_TITLE: "Connected",
    CONNECTED_DESCRIPTION: "Stay safe and updated",
    BIKE_HOME_TITLE: "CHARGED VEHICLES",
    BIKE_HOME_DESCRIPTION: "Powered by VMOTO",
    BIKE_ITEM_DESCRIPTION: "Start subscription from 6 months",
    BIKE_HOME_DISCOVER: "Discover More",
    BIKE_HOME_SCHEDULE: "Schedule Test Ride",
    HOME_SUBSCRIBE_TITLE: "How To Subscribe?",
    ORDER_TITLE: "Order",
    ORDER_DESCRIPTION: "Pick your plan and make your booking fee payment.",
    SUBMIT_DOCUMENTS_TITLE: "Submit Documents",
    SUBMIT_DOCUMENTS_DESCRIPTION: "Submit your documents through our online form provided in your Booking Fee Receipt or sent to your email.",
    MAKE_PAYMENT_TITLE: "Make Payment",
    MAKE_PAYMENT_DESCRIPTION: "We will contact you to make your deposit & 1st month subscription payment.",
    COLLECT_OR_RECEIVE_YOUR_BIKE_TITLE: "Collect or Receive Your Bike",
    COLLECT_OR_RECEIVE_YOUR_BIKE_DESCRIPTION: "Collect your bike at our showrooms or opt for delivery.",
    HOME_TEST_RIDE_TITLE: "Experience Riding with Charged.",
    HOME_TEST_RIDE_DESCRIPTION: "Drop by our E-Sheds to experience a better ride.",
    BACK_TO_HOMEPAGE: "Back to Homepage",

    // Product
    PRODUCT_SUBSCRIBE: "Subscribe Now",
    PRODUCT_SUBSCRIBE_BUY_NOW: "Buy Now",
    PRODUCT_SCHEDULE: "Schedule Test Ride",
    SAFETY: "Performance/Safety",
    CONVENIENCE: "Convenience",
    INTELLIGENCE: "Intelligence",
    AGILITY: "Agility",
    REFINED_POWER: "Refined Power",
    BAYCAT_FEATURE_TITLE: "Move in style",
    BAYCAT_FEATURE_TITLE_DESCRIPTION: "Baycat comes equipped with advanced technology designed to provide a safer, more comfortable, and enjoyable riding experience for every journey.",
    BAYCAT_FEATURE_CONVENIENCE_DESCRIPTION: "The Baycat is equipped with a reverse feature that understands your needs. Struggling to back out of a parking spot? Not a problem anymore.",
    BAYCAT_FEATURE_AGILITY_DESCRIPTION_1: "We understand that agility in navigating urban traffic is essential.",
    BAYCAT_FEATURE_AGILITY_DESCRIPTION_2: "The Baycat's tilt angle has been carefully designed to feel like an extension of the rider's body, allowing you to move forward with confidence.",
    BAYCAT_FEATURE_REFINED_POWER_1: "Baycat is a testament to refined power, delivering the perfect balance between torque and comfort.",
    BAYCAT_FEATURE_REFINED_POWER_2: "It's designed for smooth acceleration and precise control, offering strength without sacrificing a comfortable and enjoyable riding experience. Proving that power isn't just about brute force.",    
    ANOA_PRODUCT_TAGLINE_TITLE: "Ultimate electrified utility",
    ANOA_PRODUCT_TAGLINE_DESCRIPTION: "Strong and dependable, ANOA carries you and your cargo anywhere and everywhere.",
    ANOA_PERFORMANCE_SAFETY_DESCRIPTION: "Cargo performance is redefined with the ANOA, allowing up to 150KG and 250litres of payload excluding rider, 160km of true range with our high tensile steel frames and highly efficeint drive train.",
    ANOA_PERFORMANCE_SAFETY_DESCRIPTION_P2: "Full-LED smart lighting system (front and rear) helps you see and be seen anywhere, even in the bad weather.",
    ANOA_PERFORMANCE_SAFETY_DESCRIPTION_P3: "Combined Brake System (CBS) features are standard for ANOA units to reduce the risk of accidents.",
    ANOA_CONVENIENCE_DESCRIPTION: "Mounting options on the front , rear and sides of ANOA lets you customise utility to your needs.",
    ANOA_CONVENIENCE_DESCRIPTION_P2: "ANOA is also equipped with a one-push reverse/creep button, fast & easy changing tyre system, fast-charging, intuitive battery operation for everyday ease of use, dedicated power supply to heat/cool rear box, left and right side stands enable comfort access from both sides of vehicle.",
    ANOA_INTELLIGENCE_DESCRIPTION: "Integrated CAN systems and monitoring means safe storage, charging and riding regardless of weather and conditions.  Our advanced anti-theft system comes as standard on all our models so that you can focus on the day ahead.",
    ANOA_INTELLIGENCE_DESCRIPTION_P2: "To further assist you in keeping your connectivity, ANOA is equipped with USB Charging Port for charging your phone.",
    MALEO_PRODUCT_TAGLINE_TITLE: "Agile and iconic city runner",
    MALEO_PRODUCT_TAGLINE_DESCRIPTION: "Do anything, go anywhere, anytime with the MALEO.",
    MALEO_PERFORMANCE_SAFETY_DESCRIPTION: "Lightweight maneuverability meets usability with MALEO. Whether you like to do delivery or ferry your friends, MALEO can do it all at the same time. Full-LED smart lighting system (front and rear) helps you see and be seen anywhere, even in the bad weather.",
    MALEO_PERFORMANCE_SAFETY_DESCRIPTION_P2: "Combined Brake System (CBS) features are standard for MALEO units to reduce the risk of accidents.",
    MALEO_CONVENIENCE_DESCRIPTION: "Rear rack allows you to move any package and your passenger at the same time.",
    MALEO_CONVENIENCE_DESCRIPTION_P2: "MALEO is also equipped with a one-push reverse/creep button, fast & easy changing tyre system, fast-charging, easy onboard or offboard charging with easy battery access for everyday convenience, and hub driven maintenance-free drive train that moves you ahead of traffic. .",
    MALEO_INTELLIGENCE_DESCRIPTION: "Your smart apps and vehicle are connected seamlessly behind the scene, allowing you to utilise our BMOS Central Control System with information such as Vehicle Inspection, Location, AntiTheft System, Vehicle Record, and Warning.",
    MALEO_INTELLIGENCE_DESCRIPTION_P2: "To further assist you in keeping your connectivity, MALEO is equipped with USB Charging port to keep your mobile device powered up.",
    RIMAU_PRODUCT_TAGLINE_TITLE: "Effortless commutes",
    RIMAU_PRODUCT_TAGLINE_DESCRIPTION: "Designed for professionals, artists, and adventurers living an urban lifestyle. Experience effortless commuting with RIMAU.",
    RIMAU_PERFORMANCE_SAFETY_DESCRIPTION: "Designed for sustainable mobility with style. Full-LED smart lighting system (front and rear) helps you see and be seen anywhere, even in the bad weather.",
    RIMAU_PERFORMANCE_SAFETY_DESCRIPTION_P2: "Combined Brake System (CBS) features are standard for RIMAU units to reduce the risk of accidents.",
    RIMAU_CONVENIENCE_DESCRIPTION: "We understand that commuting can be a very tiring journey. Our sculpted seat provides exceptional comfort for you and your passenger.",
    RIMAU_CONVENIENCE_DESCRIPTION_P2: "RIMAU is also equipped with a one-push reverse/creep button, fast & easy changing tyre system, fast-charging, and intuitive battery operation for everyday ease of use.",
    RIMAU_INTELLIGENCE_DESCRIPTION: "Our powertrain management system enables seamless switching between two batteries as they get depleted for maximum efficiency and control.",
    RIMAU_INTELLIGENCE_DESCRIPTION_P2: "Stay connected at all times with your vehicle through our localised cloud to app integration. Monitor your vehicle condition and battery capacity from the comfort of your home or office.",
    PRODUCT_SPECIFICATION_TITLE: "TECHNICAL SPECIFICATION",
    PRODUCT_SPECIFICATION_DESCRIPTION: "*Data collected from real world testing on Indonesian roads, with a single rider of 70kg, not exceeding speeds of 50km/h. Your results may vary depending on riding conditions.",
    PRODUCT_SPEED_TITLE: "TOP SPEED",
    PRODUCT_SPEED_QUANTITY: "km/hr",
    PRODUCT_DISTANCE_TITLE: "MAXIMUM RANGE*",
    PRODUCT_DISTANCE_QUANTITY: "km",
    CATALOG_BUTTON_MALEO: "Download MALEO E-Brochure 2023",
    CATALOG_BUTTON_ANOA: "Download ANOA E-Brochure 2023",
    CATALOG_BUTTON_RIMAU: "Download RIMAU E-Brochure 2023",
    KEY_NUMBERS: "Key Numbers",
    POWERTRAIN: "Powertrain",
    VEHICLE_SPECIFICATION: "Vehicle Specification",
    LWH: "L x W x H",
    WHEELBASE: "Wheelbase",
    MIN_GROUND_CLEARANCE: "Min. Ground Clearance",
    SEAT_HEIGHT: "Seat Height",
    NET_WEIGHT: "Net Weight",
    MAX_LOAD: "Max Load",
    MAX_LOAD_QUANTITY: "rider excluded",
    PRODUCT_SUBSCRIBE_EXCLUDE: "exclude VAT",
    PRODUCT_SUBSCRIBE_PRICE_FROM: "Subscribe",
    PRODUCT_SUBSCRIBE_PRICE_NOW_TITLE: "Feel the same experience with less!",
    PRODUCT_SUBSCRIBE_PRICE_NOW_FROM: "Subscribe now from",
    PRODUCT_SUBSCRIBE_DELIVERY: "is ready for delivery in",
    PRODUCT_SUBSCRIBE_SHIPPING: "is ready for delivery in December 2022.",
    PRODUCT_SUBSCRIBE_DESCRIPTION: "All pictures shown are for illustration purpose only. Actual product may vary due to product enhancement.",
    PRODUCT_SUBSCRIBE_INCLUDE_PURCHASE: "Included in the purchase :",
    PRODUCT_SUBSCRIBE_BATTERY: "Single battery 60V/45AH with 2.7kwh capacity",
    PRODUCT_SUBSCRIBE_CHARGER: "Standard Charger, 10% to 100% in 5 hours time",
    PRODUCT_SUBSCRIBE_PRICE: "Start from Rp1.650.000*/mo",
    PRODUCT_SUBSCRIBE_PRICE_DESCRIPTION: "Includes VAT, spare parts warranty and all on the road costs. Terms and conditions applied.",
    PRODUCT_INFORMATION: "For more information & FAQ, please visit our Support Center.",
    PRODUCT_LOCATION_TITLE: "Please Select Your City of Your Residence",
    PRODUCT_LOCATION_DESCRIPTION: "In order to make sure Charged can serve you, please select or type your residential city.",
    PRODUCT_LOCATION_PLACEHOLDER: "Enter City of Residence",
    PRODUCT_LOCATION_SUBMIT: "Enter",
    PRODUCT_NO_LOCATION_TITLE: "It seems we haven’t arrived in your area yet.",
    PRODUCT_NO_LOCATION_DESCRIPTION: "Please leave us your email address so we can keep you updated about our new branches.",
    PRODUCT_NO_LOCATION_AREA_LABEL: "City of Residence",
    PRODUCT_NO_LOCATION_AREA_PLACEHOLDER: "Your City",
    PRODUCT_NO_LOCATION_EMAIL: "Your Email Address",
    PRODUCT_NO_LOCATION_EMAIL_SUBMIT: "Keep Me Updated",
    PRODUCT_NO_LOCATION_SUBMIT_TITLE: "We’ll keep you updated!",
    PRODUCT_NO_LOCATION_SUBMIT_DESCRIPTION: "Thank you for your interest in us, we will do our best to bring our products to you.",
    SUBSCRIBE_PHONE_NUMBER: "Phone Number *",
    SUBSCRIBE_EMAIL_ADDRESS: "Email Address *",
    SUBSCRIBE_PARENT_TITLE: "Parent/Guardian Information",
    SUBSCRIBE_MOTHER_LABEL: "Mother’s Name *",
    SUBSCRIBE_MOTHER_PLACEHOLDER: "ex: Jane Doe",
    SUBSCRIBE_PROFESSION_TITLE: "Profession/Employment",
    SUBSCRIBE_COMPANY_LABEL: "Company/Business Name *",
    SUBSCRIBE_COMPANY_PLACEHOLDER: "ex: PT. Indah Maju Bersama",
    SUBSCRIBE_JOB_POSITION_LABEL: "Job Position *",
    SUBSCRIBE_JOB_POSITION_PLACEHOLDER: "ex: Staff",
    SUBSCRIBE_EMPLOYMENT_STATUS_LABEL: "Employment Status *",
    SUBSCRIBE_EMPLOYMENT_STATUS_PLACEHOLDER: "ex: Permanent",
    SUBSCRIBE_WORK_LENGTH_LABEL: "Length of Work *",
    SUBSCRIBE_WORK_LENGTH_PLACEHOLDER: "ex: 1-3 years",
    SUBSCRIBE_INCOME_LABEL: "Profit Income *",
    SUBSCRIBE_INCOME_PLACEHOLDER: "ex: Rp 5.000.000 - Rp 7.500.000",
    SUBSCRIBE_CC_LABEL: "Credit Card Plafond Limit (optional)",
    SUBSCRIBE_CC_PLACEHOLDER: "ex: Rp 3.000.000 - Rp 5.000.000",
    SUBSCRIBE_MARITAL_STATUS_TITLE: "Marital Status & Income",
    SUBSCRIBE_MARITAL_STATUS_LABEL: "Marital Status *",
    SUBSCRIBE_SINGLE_LABEL: "Single",
    SUBSCRIBE_MARIED_LABEL: "Maried",
    SUBSCRIBE_SPOUSE_JOB_POSITION_LABEL: "Spouse’s Job Position *",
    SUBSCRIBE_SPOUSE_EMPLOYMENT_STATUS_LABEL: "Spouse’s Employment Status *",
    SUBSCRIBE_SPOUSE_COMPANY_NAME_LABEL: "Spouse's Company/Business Name *",
    SUBSCRIBE_SPOUSE_COMPANY_NAME_PLACEHOLDER: "ex: PT. Indah Maju Bersama",
    SUBSCRIBE_SPOUSE_YEAR_OF_EXPERIENCE_LABEL: "Spouse's Length of Work *",
    SUBSCRIBE_SPOUSE_YEAR_OF_EXPERIENCE_PLACEHOLDER: "ex: 1-3 tahun",
    SUBSCRIBE_SPOUSE_SALARY_LABEL: "Spouse's Profit Income *",
    SUBSCRIBE_SPOUSE_SALARY_PLACEHOLDER: "ex: Rp 5.000.000 - Rp 7.500.000",
    SUBSCRIBE_HOME_OWNERSHIP_LABEL: "Home Ownership Status *",
    SUBSCRIBE_HOME_OWNERSHIP_PLACEHOLDER: "ex: Monthly Rent",
    SUBSCRIBE_LENGTH_STAY_LABEL: "Length of Stay *",
    SUBSCRIBE_LENGTH_STAY_PLACEHOLDER: "ex: < 1 year",
    SUBSCRIBE_PRIVATE_DOCUMENT_TTILE: "Private Document",
    SUBSCRIBE_PRIVATE_DOCUMENT_DESCRIPTION: "In Charged, we take your privacy very seriously. We guarantee that your documents are safe and protected with us. To know more, please visit our Privacy Policy page.",
    SUBSCRIBE_PRIVATE_DOCUMENT_UPLOAD_LABEL: "Upload Image",
    SUBSCRIBE_PRIVATE_DOCUMENT_ITEM__DESCRIPTION: "Please upload file in .jpeg, .png, or .pdf format. Make sure the image you uploaded is clear and readable.",
    IDENTITYCARD: null,
    FAMILY_REGISTRATION: null,
    TAX_PAYER_IDENTIFICATION_NUMBER: null,
    DRIVING_LICENSE: null,
    DASHBOARDDRIVER: null,
    ACCOUNT_TRANSACTION: null,
    PAYSLIP: null,
    REVIEW_PRODUCT_STEP_1: "Your Order",
    REVIEW_PRODUCT_STEP_2: "Customer Information",
    REVIEW_PRODUCT_STEP_5: "Pick Up Option",
    SUBCRIPTION_TIME_LABEL: "Subscription for",
    PRODUCT_STEP_1: "Customization",
    PRODUCT_STEP_2: "Personal Info",
    PRODUCT_STEP_3: "Residence",
    PRODUCT_STEP_4: "Private Document",
    PRODUCT_STEP_5: "Subscription Plan",
    PRODUCT_STEP_6: "Review Order",
    PRODUCT_COLOR_TITLE: "Vehicle Color Selection",
    PRODUCT_CHARGER_TITLE: "Charger Type (Include PPN)",
    PRODUCT_BATTERY_TITLE: "Battery Quantity (Include PPN)",
    PRODUCT_CUSTOM_TOTAL: "Total Amount",
    PRODUCT_BOOKING_DESCRIPTION: "This fee allows us  to reserve your vehicle and process your order. Booking fee will be refunded after subscription starts .",
    PRODUCT_PRICING_TITLE: "Pricing *",
    PRODUCT_PRICING_DESCRIPTION: "Please choose one of the plans below.",
    PRODUCT_SUBSCRIBE_DETAIL: "Subscription Detail",
    PRODUCT_MONTHLY_TOTAL_TITLE: "Monthly Subscription",
    PRODUCT_DAILY_TOTAL_TITLE: "Daily Subscription",
    PRODUCT_MONTHLY_TOTAL_DESCRIPTION: "This is the total fee that must be paid every day, and will only start to be billed after the motorcycle is picked up by the customer.",
    PRODUCT_BILLED_MONTHLY: "Billed Monthly",
    PRODUCT_BILLED_DAILY: "Billed Daily",
    PRODUCT_ORDER_TITLE: "Your Order",
    PRODUCT_SUBSCRIBE_POLICY_TITLE: "Subscription Policy Contract",
    PRODUCT_SUBSCRIBE_POLICY_DESCRIPTION: "The second party declares that all data and information and financing requirements documents are complete, correct in accordance with the original; and hereby consents to and permits the First Party to contact any source to obtain or examine the necessary information. I acknowledge and agree that the data, information and documents provided belong to the first party that do not need to be returned and therefore can be used for all purposes related to business activities, including but not limited to being able to be provided to third parties, including in the process of surveys, collection of consumer obligations, product/service offerings; if there is a change, then the latest data that will be stated in the financing agreement; I will further comply with and be bound by the applicable terms and conditions and I understand that the First Party reserves the right to accept or reject this submission.",
    PRODUCT_ACCEPT: "I’ve read and accept",
    SALES_AGREEMENT: "Subscription Terms",
    BOOKING_SUBMIT: "Submit Order",
    PRODUCT_AGGREMENT: "Charged Subscription Policy Contract.",
    WAIT_TITLE: "Please wait...",
    WAIT_DESCRIPTION: "Your order is being processed.",
    BOOKING_FAILED_TITLE: "Transaction Failed",
    BOOKING_FAILED_DESCRIPTION: "We regret that the transaction was unsuccessful due to technical problems. Do you wish to try again for the payment?",
    BOOKING_CONFIRM_TITLE: "TEST RIDE BOOKING CONFIRMATION",
    BOOKING_CONFIRM_DESCRIPTION: "Thank you! A confirmation email will arrive to you shortly. You can also screenshot this page as proof of confirmation.",
    BOOKING_VEHICLE: "Preffered Vehicle",
    BOOKING_LOCATION: "Test Ride Location",
    OTP_TITLE: "We have automatically created your account so you can track your order. Please verify.",
    OTP_DESCRIPTION: "We have sent a code through SMS to.",
    OTP_NOT_SEND: "Didn’t receive the code?",
    OTP_SEND_AGAIN: "Resend code.",
    OTP_VERIVY: "Verified",
    BOOKING_FEE_RECEIPT_TITLE: "BOOKING ORDER",
    BOOKING_FEE_RECEIPT_DESCRIPTION: "We have sent this booking order to your email.",
    BOOKING_ORDER_NO: "Order no",
    BOOKING_BIKE_LABEL: "BIKE TO SUBSCRIBE",
    BOOKING_FEE_MESSAGE: "To finish your subscription, we need you to complete your data and documents by clicking on the button below. You can also find this link in your email. Don’t worry, your documents are safe with us!",
    BOOKING_FEE_LINKED: "Automatically redirecting in ",
    BOOKING_CUSTOMER: "Customer Details",
    BOOKING_SHIPPING: "Shipping Details",
    BOOKING_SHIPPING_MESSAGE: "*Your ETA will be informed to you by our sales.",
    BOOKING_SALES: "Your Sales PIC",
    PIC_NAME_LABEL: "PIC Name",
    PIC_PHONE_NUMBER_LABEL: "PIC Phone Number",
    BOOKING_SUBSCRIPTION: "Subscription & Customization Details",
    TITLE_COMMINGSOON: "Coming Soon",
    SUBTITLE_COMMINGSOON: "We’ll update soon for prices and details to keep you informed. Stay tuned!",
    SUBTITLE_COMMINGSOON_FORM: "Stay updated with our new product release! We’ll update soon for prices and details to keep you informed. Stay tuned!",
    SUGEST_COMMINGSOON_FORM: "<p>Are you interested with our new products?</p><p>Filled the form now!</p>",
    INFO_SUMIT_COMMINGSOON_FORM: "**All information provided on this page may change as needed.",
    LIMITED_EDITION: 'LIMITED EDITION',
    INFO_AVAILABLE_PURCHASE: '*Only Available for Purchase',

    // Careers
    CAREERS_HEADER: "Welcome To Our Impact Journey!",

    // User Menu
    MY_ACCOUNT: "My Account",
    ACC_VERIFICATION: "Account Verification",
    ACC_EMAIL: "Account Email",
    ACC_PHONE: "Account Phone",
    ACC_ID: "Identification",
    ACC_UPLOAD_KTP: "Upload KTP",
    ACC_TYPE: "Account Type",
    ACC_MY_VEHICLES: "My Velicle(s)",
    ACC_TIER: "Account Tier",
    ACC_STATUS: "Account Status",
    ACC_INFO: "Account Information",
    ACC_PERSONAL: "Personal Information",
    ACC_NAME: "Display Name",
    ACC_ABOUT: "About me",
    ACC_ABOUT_TEXT: "Write something about yourself...",
    ACC_FULL_NAME: "Full Legal Name",
    ACC_DOB: "Date of Birth",
    ACC_DOB_TEXT: "Add your birthday",
    ACC_GENDER: "Gender",
    ACC_PHONE_FORM: "Phone Number",
    ACC_MALE: "Male",
    ACC_FEMALE: "Female",
    ACC_NOT_SET: "Not Set",
    ACC_LANG: "Language",
    ACC_OB: "Charged Asia Settings",
    ACC_TEAMS: "My Favourite Teams",
    ACC_LEAGUES: "My Leagues",
    ACC_CH_PIC: "Change Profile Picture",
    ACC_SET_PIC: "Set as Profile Picture",
    ACC_EMAIL_NOT_SET: "Your email adress has not been set",
    ACC_EMAIL_ASS:
      "We have assigned you a temporary email address that is used for auhtentication together with your connected wallet.",
    ACC_NOT_VERIFIED: "Your email adress has not been verified",
    ACC_VERIFIED: "Your email adress has been verified",
    ACC_VERIFY: "Please follow the link below to verify your email.",
    ACC_APP: "App",
    ACC_APP_VER: "Current Version",
    ACC_APP_TERMS: "Terms and Conditions",
    ACC_APP_PRIVACY: "Privacy Policy",
    ACC_APP_POLICY: "Terms of Service",
    ACC_APP_TERMS_OF_USE: "Terms of Use",
    ACC_MENU_TITLE: "Account Settings",
    ACC_CONTACT: "Contact",
    ACC_DELETE_MODAL_TITLE: "Are you sure you want to delete your account?",
    ACC_DELETE_MODAL_DESC: "Please note that once your account is deleted, you can’t restore it back anymore.",
    ACC_DELETE_MODAL_BUTTON_YES: "Yes, Delete Account",
    ACC_DELETE_MODAL_BUTTON_NO: "No, Cancel",
    ACC_DELETE: "Delete Account",
    ACC_MAILING: "Subscribed to Mailing List",
    ACC_MAILING_DESC: "If this is turned on, you will be notified of our new sales and promotions.",
    ACC_SUBSCRIBE: "My Subscription",
    ACC_PROCESSING_TITLE: "Processing Subscription",
    ACC_PROCESSING_DESCRIPTION: "Processing Subscription",
    ACC_ONGOING_TITLE: "Ongoing Subscription",
    ACC_ONGOING_DESCRIPTION: "These are bikes that you are currently subscribed to. If you have questions, please call our sales +62 812 3768 6970.",

    // Privacy Policy
    PRIVACY_POLICY_LABEL: "Charged Indonesia Privacy Policy",
    DATE_PRIVACY_POLICY: "16th November 2022",
    PRIVACY_PARAGRAPH_1: "This policy (‘Privacy Policy’) explains how Charged Indonesia seeks to protect the Personal Information of individuals. Charged Indonesia is committed to protecting the safety and security of the personal information of individuals whose information Charged Indonesia has access to including users of the Charged Indonesia Platform (defined below), and other persons with whom Charged Indonesia interacts (each a ‘User’ or ‘you’).",
    PRIVACY_PARAGRAPH_2: "Under the Act, “Personal Information” is defined as: “Information or an opinion about an identified individual, or an individual who is reasonably identifiable:",
    PRIVACY_PARAGRAPH_2_LINE_1: "(a) whether the information or opinion is true or not; and",
    PRIVACY_PARAGRAPH_2_LINE_2: "(b) whether the information or opinion is recorded in a material form or not.”",
    PRIVACY_PARAGRAPH_3: "Please read this Privacy Policy carefully in order to understand how your Personal Information is collected, held, used, or otherwise processed by us.",
    PRIVACY_PARAGRAPH_4: "Charged Indonesia reserves the right to make changes or updates to this Privacy Policy from time to time. If this happens we will update this Privacy Policy and notify you of any changes, most likely via email or in-app notification. However, you should Charged Indonesia also periodically check this Privacy Policy for any updates.",
    PRVIACY_1_TITLE: "1. ABOUT CHARGED INDONESIA",
    PRVIACY_1_PARAGRAPH_1: "In providing the Charged Indonesia Platform, we are sensitive to Users’ concerns about the safety of their Personal Information. In essence, Charged Indonesia will typically only:",
    PRVIACY_1_PARAGRAPH_2: "1.1 collect, use or share your Personal Information with your consent (unless it is not reasonable in the circumstances to obtain your consent and it is legally permissible for us to do so) or when required by a legal obligation; and interact with your Personal Information in order to: (a) provide you with the Charged Indonesia Platform and (b) help us improve and develop the Charged Indonesia Platform.",
    PRVIACY_1_PARAGRAPH_3: "Charged Indonesia has developed our privacy framework to assist Users, and to comply with privacy legislation and regulations applicable to us and our management of your Personal Information.",
    PRVIACY_2_TITLE: "2. HOW CHARGED INDONESIA COLLECTS YOUR PERSONAL INFORMATION",
    PRIVACY_2_PARAGRAPH_1: "Charged Indonesia collects Personal Information from individuals in one of three main ways:",
    PRIVACY_2_PARAGRAPH_2: "(a) Directly from Users, when they interact with Charged Indonesia or the Charged Indonesia platform (e.g. provide feedback or register a User account);",
    PRIVACY_2_PARAGRAPH_3: "(b) Passively from Users, when they interact with and use the Charged Indonesia Platform;",
    PRIVACY_2_PARAGRAPH_4: "(c) From third-parties in certain, specific circumstances (e.g. if you sign up to the Charged Indonesia Platform through a third-party service or platform we may be provided information that you have consented to be shared).",
    PRIVACY_2_PARAGRAPH_5: "The specifics of Personal Information collected in each situation is discussed further below.",
    PRIVACY_3_TITLE: "3. WHEN CHARGED INDONESIA COLLECTS INFORMATION FROM USERS AND WHAT WE COLLECT",
    PRIVACY_3_PARAGRAPH_1: "(a) Personal Information collected directly",
    PRIVACY_3_PARAGRAPH_2: "When Users sign up and use the Charged Indonesia Platform we collect the following types of Personal Information directly and consensually from you:",
    PRIVACY_3_PARAGRAPH_2_LINE_1: "Basic User information, including your name, display name, mobile phone number, email, date of birth;",
    PRIVACY_3_PARAGRAPH_2_LINE_2: "Basic account information, including any User name or display picture you choose to associate with your account;",
    PRIVACY_3_PARAGRAPH_2_LINE_3: "If you access the Charged Indonesia Platform through a third-party service or platform (e.g. Facebook), we will collect information that is made available to Charged Indonesia by those services or platforms. You can generally control the information we receive from these sources by using the privacy settings on the third-party services or platforms.",
    PRIVACY_3_PARAGRAPH_3: "We will collect the following types of information from Users as they use the Charged Indonesia Platform:",
    PRIVACY_3_PARAGRAPH_3_LINE_1: "Basic account preferences, such as your language settings;",
    PRIVACY_3_PARAGRAPH_3_LINE_2: "Content that you post and submit to the Charged Indonesia Platform and our social media pages or blogs.",
    PRIVACY_3_PARAGRAPH_4: "If you make an enquiry or send us unsolicited feedback we may collect the following types of Personal Information directly and consensually from you:",
    PRIVACY_3_PARAGRAPH_4_LINE_1: "Basic contact information, including your name and email; and",
    PRIVACY_3_PARAGRAPH_4_LINE_2: "Feedback information and the details of your interactions with us, including communications with customer support or other Charged Indonesia personnel (e.g. the contents of an email sent to contact@charged.asia) or other information provided by you regarding your enquiry.",
    PRIVACY_3_PARAGRAPH_4_LINE_3: "When you respond to a survey we may directly and consensually collect the Personal Information disclaimed on the survey form.",
    PRIVACY_3_PARAGRAPH_5: "When you make an application for employment at Charged Indonesia, we may collect any Personal Information provided within that application, such as the contents of a personal statement made in support of your application.",
    PRIVACY_3_PARAGRAPH_6: "(b) Personal Information collected passively",
    PRIVACY_3_PARAGRAPH_7: "As you interact with the Charged Indonesia Platform or advertisements, we may collect the following types of Personal Information about your usage:",
    PRIVACY_3_PARAGRAPH_7_LINE_1: "Content that you post in-app, including in chat to other User or in threads relating to predictions or competitions, as well as similar content that is posted about you by others;",
    PRIVACY_3_PARAGRAPH_7_LINE_2: "Background account information, such as your notification settings and Charged Indonesia badges that you accrue; and",
    PRIVACY_3_PARAGRAPH_7_LINE_3: "The following types of browser, system and device information regarding devices you use to access the Charged Indonesia Platform: locational information, regarding which country you live in, for example in the form of the IP address from which you access the Charged Indonesia Platform; web data tracking information, such as data from cookies stored on your device, including cookie IDs and settings, as well as logs of your usage of the Charged Indonesia Platform; system usage information, such as logs of User you have followed or are following you, and friends that you have added and been added by.",
    PRIVACY_3_PARAGRAPH_8: "(c) Personal Information collected from third-parties ",
    PRIVACY_3_PARAGRAPH_9: "In certain specific situations, Charged Indonesia will collect Personal Information about you from third-parties. The types of Personal Information collected include:",
    PRIVACY_3_PARAGRAPH_9_LINE_1: "Third-party account information made available to us if you register with Charged Indonesia through a third-party service or platform and;",
    PRIVACY_3_PARAGRAPH_9_LINE_2: "Web data tracking information that fit certain parameters of who we think could become Charged Indonesia Users (e.g. heat maps developed through Google Analytics which track patterns of User interactions with our web pages).",
    PRIVACY_4_TITLE: "4. WHY CHARGED INDONESIA COLLECTS YOUR PERSONAL INFORMATION AND WHAT WE USE IT FOR",
    PRIVACY_4_PARAGRAPH_1: "Although Charged Indonesia collects Personal Information from Users in a number of circumstances, Charged Indonesia will only collect this information in order to provide and develop the Charged Indonesia Platform. Here are the main ways we use Personal Information to achieve these objectives:",
    PRIVACY_4_PARAGRAPH_2: "(a) Communicating with Users",
    PRIVACY_4_PARAGRAPH_3: "Charged Indonesia will use basic user account and contact details to communicate with individuals about their feedback or issues with the Charged Indonesia Platform.",
    PRIVACY_4_PARAGRAPH_4: "Charged Indonesia will also use prize delivery information to verify the identity of competition winners, and winner’s interview information to congratulate winners of our competitions over the Charged Indonesia Platform or Charged Indonesia’s social media platforms.",
    PRIVACY_4_PARAGRAPH_5: "If Users have consented, Charged Indonesia will also use these types of Personal Information to share relevant news and updates about Charged Indonesia and the Charged Indonesia Platform.",
    PRIVACY_4_PARAGRAPH_6: "(b) Administration and delivery of Charged Indonesia Platform",
    PRIVACY_4_PARAGRAPH_7: "Charged Indonesia will use basic User and account information, as well as other basic preferences to provide you with the baseline experience over the Charged Indonesia Platform (e.g. allowing you to participate in competitions).",
    PRIVACY_4_PARAGRAPH_8: "If you have registered using third-party service or platform information Charged Indonesia will also use this for the same reasons.",
    PRIVACY_4_PARAGRAPH_9: "Charged Indonesia will use your basic User information for simple administrative tasks, such as resetting account passwords.",
    PRIVACY_4_PARAGRAPH_10: "(c) Ensuring User safety",
    PRIVACY_4_PARAGRAPH_11: "Charged Indonesia will also use any type of information collected to prevent and address risks to all Users (e.g. Charged Indonesia will use the information to investigate suspicious or threatening activity).",
    PRIVACY_4_PARAGRAPH_12: "(d) Research and development",
    PRIVACY_4_PARAGRAPH_13: "Charged Indonesia will use the following types of information to develop, test and improve the Charged Indonesia Platform:",
    PRIVACY_4_PARAGRAPH_14_LINE_1: "Survey and feedback information, as well as any content that is submitted in relation to features of the Charged Indonesia Platform;",
    PRIVACY_4_PARAGRAPH_14_LINE_2: "Background account, browser, system and device information; and",
    PRIVACY_4_PARAGRAPH_14_LINE_3: "Third-party web tracking information.",
    PRIVACY_4_PARAGRAPH_15: "Together these types of Personal Information are used to provide us with an overview of how the Charged Indonesia Platform is being used, any shortcomings it may have, and subsequently to highlight what will be the best means of improving the experience for all Users.",
    PRIVACY_4_PARAGRAPH_16: "Charged Indonesia’s preference will be to de-identify these types information first, and then use it for this purpose in conjunction with de-identified browser and device information (see section 6 below for an explanation of what we mean by “de-identified”).",
    PRIVACY_4_PARAGRAPH_17: "(e) Marketing",
    PRIVACY_4_PARAGRAPH_18: "Where Users have expressly consented, Charged Indonesia will use basic contact, enquiry and account information to provide Users with relevant marketing materials and offers. Users can always opt-out of this through the functionality provided in each marketing communication (e.g. by clicking “unsubscribe” at the bottom of an email).",
    PRIVACY_5_TITLE: "5. CHARGED INDONESIA’S DISCLOSURE OF PERSONAL INFORMATION",
    PRIVACY_5_PARAGRAPH_1: "Generally, Charged Indonesia does not disclose Personal Information to any third parties except:",
    PRIVACY_5_PARAGRAPH_1_LINE_1: "Service providers Charged Indonesia engages to help us provide and develop the Charged Indonesia Platform (e.g. cloud service providers or consultants);",
    PRIVACY_5_PARAGRAPH_1_LINE_2: "In some specific jurisdictions, marketing and product development partners to help us provide local offerings of the Charged Indonesia Platform; and",
    PRIVACY_5_PARAGRAPH_1_LINE_3: "Law enforcement agencies, or another party that has a legitimate legal right to access the information.",
    PRIVACY_5_PARAGRAPH_2: "Overseas Disclosure",
    PRIVACY_5_PARAGRAPH_3: "Some of the third-parties Charged Indonesia discloses Personal Information to are located overseas.",
    PRIVACY_5_PARAGRAPH_4: "Sometimes we may also disclose the Personal Information of Users to our third-party partners located in specific jurisdictions such as Indonesia. Typically, the Personal Information disclosed in these circumstances will only relate to Users who access and use the Charged Indonesia Platform from the relevant jurisdiction.",
    PRIVACY_5_PARAGRAPH_5: "As with disclosures to third-party service providers, overseas disclosures are always made once Charged Indonesia has taken all reasonable steps to determine the information will be treated at least as favourably under the Act and other applicable privacy laws.",
    PRIVACY_6_TITLE: "6. CHARGED INDONESIA’S TREATMENT AND STORAGE OF INFORMATION",
    PRIVACY_6_PARAGRAPH_1: "Charged Indonesia’s General Approach",
    PRIVACY_6_PARAGRAPH_2: "Charged Indonesia will keep your Personal Information confidential and not sell or knowingly divulge User information to any external third parties, unless:",
    PRIVACY_6_PARAGRAPH_2_LINE_1: "We believe, in good faith, that we are required to share the Personal Information with a third party in order to comply with legitimate legal obligations;",
    PRIVACY_6_PARAGRAPH_2_LINE_2: "The disclosure is to a third-party processor of Personal Information that acts on our behalf and/or under our instruction in order to enable us to develop and deliver the Charged Indonesia Platform (e.g. a cloud service provider or local marketing and development partner);",
    PRIVACY_6_PARAGRAPH_2_LINE_3: "Other entities acquire ownership or operation of Charged Indonesia or the Charged Indonesia Platform; and/or We need to protect the safety of Users, and the security of our Charged Indonesia Platform.",
    PRIVACY_6_PARAGRAPH_2_LINE_4: "We need to protect the safety of Users, and the security of our Charged Indonesia Platform.",
    PRIVACY_6_PARAGRAPH_3: "Charged Indonesia seeks the informed and voluntary consent of individuals whenever it collects their information, or as soon as possible after.",
    PRIVACY_6_PARAGRAPH_4: "Users can always refuse or revoke this consent, but sometimes this will affect Charged Indonesia’s ability to provide them with the Charged Indonesia Platform. Charged Indonesia will advise Users if this is the case.",
    PRIVACY_6_PARAGRAPH_5: "De-identification",
    PRIVACY_6_PARAGRAPH_6: "De-identified information refers to information that cannot reasonably be used to identify a particular individual.",
    PRIVACY_6_PARAGRAPH_7: "De-identified information that will never be able to personally identify particular individuals is referred to as anonymised information (e.g. statistics that 90% of users were happy with the Charged Indonesia Platform). Additionally, de-identified information that can identify individuals only if it is combined with another, separate piece of information is referred to as pseudonymised information (e.g. account ID numbers).",
    PRIVACY_6_PARAGRAPH_8: "Where possible Charged Indonesia will aim to collect, store and use anonymised information as a first preference, and if not, then pseudonymised information.",
    PRIVACY_6_PARAGRAPH_9: "However, sometimes it will be impractical for User information to be de-identified or treated in this way, and in this case, Charged Indonesia will continue to use and hold the information in a personally identifiable state. For example, if Charged Indonesia needs to reply to a User enquiry we will have to use the contact information provided.",
    PRIVACY_6_PARAGRAPH_10: "Security",
    PRIVACY_6_PARAGRAPH_11: "Charged Indonesia is committed to information security. We will use all reasonable endeavours to keep the Personal Information we collect, hold and use in a secure environment. To this end, we have implemented technical, organisational and physical security measures that are designed to protect Personal Information and to respond appropriately if it is ever breached (e.g. Charged Indonesia has developed an extensive Data Breach Response Plan which we use to prepare and respond to data breaches).",
    PRIVACY_6_PARAGRAPH_12: "When information collected or used by Charged Indonesia is stored on third-party service providers (e.g. Google or AWS cloud servers), Charged Indonesia takes reasonable steps to ensure these third-parties use industry-standard security measures that meet the level of information security Charged Indonesia owes Users.",
    PRIVACY_6_PARAGRAPH_13: "As part of our privacy framework, we endeavour to routinely review these security procedures and consider the appropriateness of new technologies and methods.",
    PRIVACY_7_TITLE: "7. CHARGED INDONESIA’S RETENTION OF INFORMATION",
    PRIVACY_7_PARAGRAPH_1: "Charged Indonesia retains Personal Information until it is no longer needed to provide or develop the Charged Indonesia Platform, or until the individual who the Personal Information concerns asks us to delete it, whichever comes first. It may take up to 30 days to delete Personal Information from our systems following a valid request for deletion.",
    PRIVACY_7_PARAGRAPH_2: "However, Charged Indonesia will retain:",
    PRIVACY_7_PARAGRAPH_2_LINE_1: "Personal Information in circumstances where we have legal and regulatory obligations to do so (e.g. for law enforcement purposes, employment law, corporate or tax record-keeping, or where the information is relevant to legitimate legal proceedings); and",
    PRIVACY_7_PARAGRAPH_2_LINE_2: "Anonymised information for analytic and service development purposes.",
    PRIVACY_7_PARAGRAPH_3: "The information we retain will be handled in accordance with this Privacy Policy.",
    PRIVACY_8_TITLE: "8. MANAGING YOUR PERSONAL INFORMATION",
    PRIVACY_8_PARAGRAPH_1: "Accessing and ensuring the accuracy of Personal Information",
    PRIVACY_8_PARAGRAPH_2: "Charged Indonesia takes reasonable steps to ensure that the Personal Information we collect and hold is accurate, up to date and complete. Users have a right to access and request the correction of any of Personal Information we hold about them at any time. Any such requests would be made by directly contacting us at the details set out below. Charged Indonesia will grant access to the extent required or authorised by the Act and applicable laws and will take all reasonable steps to correct the relevant Personal Information where appropriate.",
    PRIVACY_8_PARAGRAPH_3: "There may be circumstances in which Charged Indonesia cannot provide Users with access to information. We will advise you of these reasons if this is the case.",
    PRIVACY_9_TITLE: "9. CONTACTING CHARGED INDONESIA",
    PRIVACY_9_PARAGRAPH_1: "Charged Indonesia has appointed a Privacy Officer to be the first point of contact for all privacy-related matters and to assist in ensuring our compliance with our privacy obligations.",
    PRIVACY_9_PARAGRAPH_2: "Privacy Officer",
    PRIVACY_9_PARAGRAPH_3: "If you have any queries or wish to make a complaint about a breach of this policy or the Act you can contact or lodge a complaint to our Privacy Officer using the contact details above. You will need to provide sufficient details regarding your complaint as well as any supporting evidence and/or information. The Privacy Offer will respond to your query or complaint as quickly as possible. Charged Indonesia will contact you if we require any additional information from you and will notify you in writing (which includes electronic communication via email) of the relevant determination.",

    // Terms of Use
    TERM_OF_USE_LABEL: "Charged Indonesia Terms of Use",

    // Manage Blogs
    MANAGE_BLOGS: "Manage Blogs",
    MANAGE_TEST_RIDES: "Manage Test Rides",
    BLOG_CREATED: "News Article Created",
    BLOG_UPDATED: "Blog  Updated",
    MAX_FILES: "Maximal 5 Files per batch",
    MAX_FILESIZE: "Maximum file size of 7 MB exeeded for: ",
    RECORD_DELETED: "Record Deleted",
    MAX_FILESIZE_F: "Maximum file size is 500 KB!",
    BLOG_SEE_MORE: "Load More >",
    BLOG_SIGN_UP: "Sign In / Sign Up to post comments",

    // Job POSTER MENU
    POST_JOBS: "Post Jobs",
    MANAGE_POSTED_JOBS: "Manage Posted Jobs",
    COMPANY_ACCOUNT: "Company Account",
    JOBS_POSTED: "Jobs Posted",
    JOBS_POSTED_TEXT: "Manage jobs posted by you",
    NEW_APPLICATIONS: "New Applications",
    NEW_APPLICATIONS_TEXT: "Applications that need to be reviewed",
    CANDIDATES_HIRED: "Candidates Hired",
    CANDIDATES_HIRED_TEXT: "Candidates hired by you",
    JOB_POST_CREATED: "Job Post Created",
    JOB_POST_UPDATED: "Job Post Updated",

    // Careers - Vacancies
    SEARCH_JOBS: "Search jobs...",
    SEARCH_RESULTS: "Search results for",
    AVAILABLE_JOBS: "Open Vacancies",
    REQUIREMENTS: "Requirements",
    POSTION_TYPE: "Position Type",
    EDUCATION_LEVEL: "Education Level",
    EXPERIENCE_LEVEL: "Experience Level",
    MY_RESUME: "My Resume",
    USE_RESUME: "Provide a link to your resume (Google Drive, Dropbox etc)",
    MY_RESUME_VIDEO: "Introduction Video",
    USE_RESUME_VIDEO:
      "Record a short introduction video of yourself and provide a link. (Youtube, Google Drive, Dropbox etc)",
    ACTIVE_RESUME: "Active Resume",
    MOTIVATION: "Motivation",
    MOTIVATION_A: "Tell us why we should hire you...",

    // Apply
    APPLY_FOR: "Apply for",
    APPLY_JOBS: "How to Apply for a Job?",
    JOB_DETAILS: "Job Details",
    ALREADY_APPLIED: "You have applied for this Job",

    // Job Seeker
    ACCOUNT_REQ: "Registration is Required",
    ACCOUNT_REQ_TEXT:
      "In order to Apply to this Job, please Sign In to your account. If you don't have a Charged Asia account yet, you can Sign Up following the link bellow.",
    DO_LATER: "I'll do this later",
    JOB_APPLICATION_SUBMITTED: "Job Application Submitted",

    // SEEKER MENU
    MY_APPLICATIONS: "Job Applications",
    MY_APPLICATIONS_U: "MY APPLICATIONS",
    MY_APPLICATIONS_TEXT: "Check for any updates",
    VERIFICATION: "VERIFICATION",
    MY_PROFILE: "My Profile",
    COMPLETE_PROFILE: "Please, complete your profile",
    NEW_JOBS: "NEW JOBS",
    NEW_JOBS_TEXT: "Find new jobs suited for you",

    // Contact Us
    CONTACT_US: "Contact Us",
    CONTACT_US_DROP: "Drop us a line.",

    // FAQ
    FAQ_WELCOME_TITLE: "Welcome!",
    FAQ_WELCOME_DESCRIPTION: "How can we help?",
    FAQ_TITLE: "Frequently Asked Questions",
    FAQ_LOCATIONS_ESHED: "E-Shed Locations",
    FAQ_LOCATIONS_GSHED: "Giga-Shed Locations",
    FAQ_MAPS: "View Direction in Maps",
    FAQ_OPENING_TITLE: "OPENING HOURS",
    FAQ_OPENING_TIME: "Mon - Sat : 09.00 - 17:00 (WIB)",
    FAQ_CLOSE_TIME: "Sun : Closed",
    FAQ_TOP: "Top Questions",
    FAQ_SUBCRIPTION: "Subscription",
    TOP: {
      QUESTION: [
        "Who is Charged?",
        "Who developed your vehicles?",
        "How many product lines that Charged have?",
        "How do i subscribe to a model?",
        "What kind of documents do i need to provide for first subscription? Will my documents be protected?",
        "How much does the monthly subscription cost?",
        "How to pay the monthly fee?",
        "Is there a maintenance fee for the subscription?",
        "When do i need to service my vehicle?",
        "What happens when i have an accident?"
      ],
      ANSWER: [
        "We are a technology company that envisions to lead the sustainable mobility in Indonesia by deploying at scale vehicles and services which are friendly to the environment, affordable and help reduce pollution of global warming and participate in assisting goverment policies to use renewable energy resources. ",
        "We are official partners with Vmoto Soco, who are the OEM suppliers of our vehicles.",
        "Currently we have 3 affordable model that serve multiple usage of our customer. Charged Anoa, Charged Rimau, and Charged Maleo. All type are developed with high quality and advanced technology supported by VMOTO SOCO to your convinience in riding for mobility",
        "All subcription is done online, either with your own device or with a service staff",
        "You will require to submitt personal information such as name, email, phone number, address, ID card [KTP], Family ID [KK], tax number [NPWP] through our website during the registration process. We will guarantee your data is safe with Charged.",
        "Monthly subscription cost is Rp1.650.000",
        "For payment of monthly fees, we will send an invoice on every 10th along with the payment link listed",
        "This is one of the benefits we provide to our customers, customers will not be charged for vehicle maintenance",
        "You need to take your e-motorcycle for regular maintenance every 3 months to CHARGED EShed.",
        "If you have an accident, call the police. If there are injuries, call paramedics. Get as much information as possible at the accident scene to furnish to your agent and/or insurance company. Immediately notify our Ranger agent and provide necessary data."
      ]
    },
    AFTERSALES: {
      QUESTION: ["When do i need to service my vehicle?", "How do i book a service date?", "How long will the servicing take?", "What happens when i have an accident?", "What do i do if i run out of power on the road?", "Can my friend borrow my vehicle?", "What do i do when something breaks", "Are there battery swap stations or charging stations?", "Can i modify my vehicle?", "Can i attach my own accessories on the vehicle?", "Do i need a special charger and charging point for my bike?", "How long to charge a battery, can I do it at home?", "If in the situation that the battery is in low level or finish, and I am still in the middle of somewhere, what I have to do?", "How to anticipate flood, since Jabodetabek mostly surrounding by flood area", "How about the legality on the road, since I don't want to be stopped by police", "When do i need to service my vehicle?", "How do i book a service date?", "How long will the servicing take?", "What happens when i have an accident?", "What do i do if i run out of power on the road?", "What do i do when something breaks?", "Are there battery swap stations or charging stations?"],
      ANSWER: [
        "You need to take your e-motorcycle for regular maintenance every 3 months to CHARGED EShed.",
        "Arrange a date and time for your service at our CHARGED EShed by contacting our Ranger agent. We are here to assist you 24 hours to any of your problems related to vehicle and subscription services.",
        "Once they are on it a service can take anything from 45 minutes to a day - depending on the problems discovered and the complexity of your bike. As a rule of thumb, about 2 hours is a reasonable average.",
        "If you have an accident, call the police. If there are injuries, call paramedics. Get as much information as possible at the accident scene to furnish to your agent and/or insurance company. Immediately notify our Ranger agent and provide necessary data.",
        "Please call our Ranger agents and get our roadside assistance support.",
        "No, you can not handover the vehicle to another 3rd party or person. The person who subscribe is the one who responsible for the vehicle (pick it up and return to our designated EShed)",
        "If you damage or break something during your subscription, please contact our Ranger and they'll let you know whether you need to replace it straight away and how to proceed (they may use your deposit to cover the damages).",
        "Currently our swap stations dedicated for B2B customers and located in designated locations as required. Though, with sufficient battery power to travel in distance will provide you  with less downtime for swapping batteries",
        "You can modify your vehicle, however modifications that are irreversible has to be reinstated upon returning the vehicle, at cost to the customer. ",
        "Yes.",
        "Our chargers are unique to our vehicles, and are provided per vehicle. You can charge from any wall socket fro your home or office.",
        "Yes, you can do charging at home, we provide two options, fast charging takes 3 hours, and slow charging takes 6 hours",
        "Call our 24 hours emergency assitance center, and we will work out on ways to help you",
        "In unavoidable circumstances, our vehicle technology is reliable and designed for you to be able to go through +/- 30cm high flood. Yet, try to avoid if flood is occured and if you have problems with the bike our 24 hours call center services will always happy to help you",
        "Vehicles from Charged Indonesia already have legal rights on the streets from the police and we will lend you STNK during the vehicle subscription period",
        "Service your vehicle once every 3 months with us for safe and problem free rides.",
        "Contact our sales experience staff to arrange for a hassle-free booking.",
        "Servicing will take us up to 2 hours to run a full diagnosis and ensure that your vehicle is in top condition. Major fixes and updates may take longer.",
        "If you have an accident, immediately call the police and if there are injuries, call the paramedics. Get as much information as possible at the accident scene to furnish to your agent and/or insurance company. Immediately notify our sales experience staff and provide necessary data after.",
        "Please call our sales experience staff and for roadside assistance support. Our 24 hours call center services will always happy to assist you",
        "If a part is damaged or breaks during your ownership, let our sales expereince staff know and they will inform you if it is necesary to change or repair. Repairs and part changes that are a result of misuse or accidents may incur costs that could be deducted from your deposit. ",
        "Currently our swap stations dedicated for B2B customers and located in designated locations as required. Though, with sufficient battery power to travel in distance will provide you  with less downtime for swapping batteries"
      ]
    },
    PRODUCT: {
      QUESTION: [
        "What is the total cost comparation of EV model to ICE vehicles?",
        "Who developed your vehicles?",
        "Is your bike faster than a honda?",
        "When do you have launch events? How do i get invited?",
        "Who is Charged?",
        "How can i learn more about your products?",
        "Can i come see the factory?",
        "How about performance of EV model in the road?",
        "How about the max range of battery?",
        "My friends and i want to go for a test ride!",
        "Will my vehicle work in flood?",
        "Can i wash my vehicle with a pressure washer?",
        "Where can i find the instruction manual for my vehicle?",
        "How far can i go with a full charge?",
        "Who developed your vehicles?",
        "How long does it take to charge the battery?",
        "Do i need a special charger and charging point for my bike?",
        "Will my vehicle work in flood?",
        "Can i wash my vehicle with a pressure washer?",
        "Where can i find the instruction manual for my vehicle?",
        "How to charge the battery? ",
        "How many product lines that Charged have?",
        "Do you provide swab stations? And how I could find it?"
      ],
      ANSWER: [
        "EV bikes operation only cost 20-30% if compared with ICE bikes utilizing fossil fuels. And Government has been deploying many incentive [i.e. electricity discounts for charging] to support transformation into electric mobility. With Charged, you dont have to worry for this transformation and prepare a lot of money to buy new electric vehicle as we are supporting your mobility transformation through an affordable subscription model.",
        "We are official partners with Vmoto Soco, who are the OEM suppliers of our vehicles.",
        "Our EV bikes has instant and responsive throttle and designed to achieve high speed movement. Our Charged Anoa and Charged Rimau able to speed up to 90km/hour and sufficient for rider to compete with ICE bikes. However, please be mind to keep it safe during driving as we dont want any casualities happen from our customer during riding our bikes",
        "Thank you for your interest, please follow us at our social media for an exicting update from us @charged.indonesia (Instagram, Tiktok) and @charged_id inTwitter ",
        "We are a technology company that envisions to lead the sustainable mobility in Indonesia by deploying at scale vehicles and services which are friendly to the environment, affordable and help reduce pollution of global warming and participate in assisting goverment policies to use renewable energy resources. ",
        "You can find our product information at our website charged.co.id ",
        "We have a factory visit program, please let us know your necessity by email us at contact@charged.asia",
        "Charged Anoa is a heavy duty electric motorcycle for commercial customers and has a maximum loading weight of 150 kilogram (excluding rider). Charged Rimau absolutely suitable for urban rides and offers powerful performance with speed, spacious seats and leg room. While Charged Maleo is designed with a slim and agile urban motorcycle, perfect for urban explorers ",
        "Charged Anoa and Charged Rimau are equipped with up to two lithium batteries which are capable of achieving 200 km maximum distance when ridden in the most efficient mode. While Charged Maleo is powered with a single battery  has capable of achieving 125km maximum distance when ridden in the most efficient mode.",
        "Drop by our showroom to experience riding with Charged.",
        "Yes, up to a pre-specified depth. However, we reccomend avoiding deep pools of water.",
        "Yes, avoid direct wash at powertrain area or under the seat",
        "You can find it on our website at charged.co.id under my account.",
        "up to 120km per battery, at 30kmh average speed",
        "We are official partners with Vmoto Soco, who are the OEM suppliers of our vehicles.",
        "Slow charger at approx 4 hours (10% to 90%), fast charger at approx 2 hours (10% to 90%). Charging rates depend on power output from mains.",
        "Our chargers are unique to our vehicles, and are provided per vehicle. You can charge from any wall socket.",
        "Yes, up to a pre-specified depth. However, we reccomend avoiding deep pools of water.",
        "Yes, avoid direct wash at powertrain area or under the seat",
        "You can find it on our website at charged.asia under my account.",
        "you can do this with the charging device that we provide and you can do charging directly on the vehicle or you can do charging directly on the battery",
        "Currently we have 3 affordable model that serve multiple usage of our customer. Charged Anoa, Charged Rimau, and Charged Maleo. All type are developed with high quality and advanced technology supported by VMOTO SOCO to your convinience in riding for mobility",
        "Currently our swap stations dedicated for B2B customers and located in designated locations as required. Though, with sufficient battery power to travel in distance will provide you  with less downtime for swapping batteries"
      ]
    },
    SUBSCRIPTION: {
      QUESTION: [
        "How do i subscribe to a model?",
        "Can i end my subscription before my lease term is finished? ",
        "What will happen when there is a default in payment",
        "Can i subscibe to more than 1 battery",
        "What kind of documents do i need to provide for first subscription? Will my documents be protected?",
        "How much can i save with an electric vehicle?",
        "Is my vehicle new or used?",
        "Is the price negotiable?",
        "What is my warranty terms?",
        "I live in East Jakarta, can i subscibe to Charged?",
        "My friends and i want to go for a test ride",
        "Do the subcriptions include insurance?",
        "What difference between subcriptions and leasing?",
        "Are there any way to own the vehicle?",
        "How is procedure to subscribe? ",
        "Is there a special driver's license for using an electric motorbike?",
        "Can I use this motorbike for business? For example, I register as a logistics courier or OJOL driver.",
        "How much does the monthly subscription cost?",
        "How to pay the monthly fee?",
        "How do i subscribe to a model?",
        "Can i end my subscription before my lease term is finished? ",
        "What will happen when there is a default in payment?",
        "Can i subscibe to more than 1 battery",
        "What kind of documents do i need to provide for first subscription? Will my documents be protected?",
        "How much can i save with an electric vehicle?",
        "Is the price negotiable?",
        "What is my warranty terms?",
        "I live outside of Jakarta, can i subscibe to Charged?",
        "What do i do at the end of my subscription?",
        "Can i modify my vehicle?",
        "Can i attach my own accessories on the vehicle?",
        "Is there a maintenance fee for the subscription?",
        "Can i change the unit from 2.0 to 4.0?",
        "Is my vehicle new or used?"
      ],
      ANSWER: [
        "All subcription is done online, either with your own device or with a service staff",
        "Please contact sales experience staff ",
        "Our team will doing our best to assist you and prevent default to happen. But when it occurs, we will keep assisting and provide you with 14 days of opportunity to make the payment. If no payment until date-14, our team will come to withdraw the bike and return it to our garage.",
        "yes, you may select additional battery at checkout page, or contact sales experience staff",
        "You will require to submitt personal information such as name, email, phone number, address, ID card [KTP], Family ID [KK], tax number [NPWP] through our website during the registration process. We will guarantee your data is safe with Charged.",
        "Depending on usage, you can expect to save up to 1mio per month from petrol and servicing",
        "Your vehicle may come with or without pre-mileage. All vehicles are professionally inspected and refursbished if needed before handover. ",
        "Our prices are non negotiable. Longer subscription periods are more cost effective.",
        "All of our vehicles are fully covered. ",
        "To ensure world class services, we currently cover west- central jkt area. you may area of availability on our website at charged.asia",
        "drop by our showrooms for fuss free, no obligation test ride",
        "we only provide insurance for vehicles, we hope you are wise and obey traffic rules",
        "Subscription is more to lifestyle that enable you with flexibility and customization during your subscription period. You will have the option to rides with different bikes after curtain period without being worry of your maintenance support, product reliability, nor hassles in asset management. While leasing will only provide you with single option of vehicle until the end of the lease period without flixibility.",
        "Yes, you can have a vehicle after doing subscriptions for 3 years, with terms and conditions",
        "To subscribe a vehicle Charged you must register first on our website and the next step we will verify your data, if your data is approved then you can enjoy our vehicle subscription service",
        "You can still use SIM C to use our vehicle",
        "Yes, but you can do this with a suitable motorbike, for example if you want to use this motorbike as a logistics vehicle, we recommend that you use a Charged Anoa motorbike",
        "Monthly subscription cost is Rp1.650.000",
        "For payment of monthly fees, we will send an invoice on every 10th along with the payment link listed",
        "All subcription is done online, either with your own device or with a service staff.",
        "Please contact sales experience staff for changes to subscription plan and we will help you.",
        "Our sales experience team will send you timely reminders. In an event where there is dfficulty in payment, contact us and we will help you.",
        "Yes, you may select additional battery at checkout page, or by contacting our sales experience staff.",
        "All documents required will be shown on our document form after booking. Please contact us for further enquiries.",
        "Depending on usage, you can expect to save up to 1mio per month from petrol and servicing.",
        "Our prices are non negotiable. Longer subscription periods are more cost effective.",
        "All of our vehicles are fully covered with insurance and third party liabilities with own risk fee applied",
        "To ensure world class services, we serve areas covered by service fleets. ",
        "You can renew your subscription online or through our sales experience staff. You may also drop your vehicle at our showrooms or contact your sales experience staff before the end of subscription period for more clarification",
        "You can modify your vehicle, however modifications that are irreversible has to be reinstated upon returning the vehicle, at cost to the customer. ",
        "Yes.",
        "This is one of the benefits we provide to our customers, customers will not be charged for vehicle maintenance",
        "Yes, you can do this after a period of 3 months",
        "Your vehicle may come with or without pre-mileage. All vehicles are professionally inspected and refursbished if needed before handover. "
      ],
    },

    // Settings
    SETTINGS: "Settings",
    PRO_CH_PL: "Please, set your password",
    SET_UPDATED: "Settings Updated",
    PWD_CHANGED: "Password has been changed",

    // Buttons
    BUT_APPLY_NOW: "Apply Now",
    BUT_RECENT: "RECENT",
    BUT_NEXT: "Next",
    BUT_CONTINUE: "Continue to your account",
    BUT_CONT_DRAFT: "Continue Writing",
    BUT_SIGN_IN: "Sign In",
    BUT_SIGN_UP: "Sign Up",
    BUT_RESET_PW: "Reset Password",
    BUT_CANCEL: "Cancel",
    BUT_BACK: "Back",
    BUT_BACK_TO_SIGN_IN: "Back to Sign In",
    BUT_VERIFY: "Verify",
    BUT_LATER: "I'll do this later",
    BUT_CHANGE_EMAIL: "Change your email address",
    BUT_CHANGE: "Change",
    BUT_DONE: "Done",
    BUT_OK: "OK",
    BUT_SAVE_DRAFT: "Save as Draft",
    BUT_SUBMIT: "Submit",
    BUT_CREATE_MORE: "Create another Job",
    BUT_SUBM_PHOTO: "Submit Photos",
    BUT_VIEW_ALL: "View Detail",
    BUT_ALL_NEWS: "See All News",
    BUT_ALL_VIDEOS: "See All Videos",
    BUT_ALL_FF: "See All in Fan Forum",
    BUT_VIEW_GRID: "View Grid",
    BUT_CONFIRM: "Confirm",
    BUT_CLOSE: "Close",
    BUT_CHECK: "Check Status",
    BUT_CHECK_PHOTO: "Check Publishing Status",
    BUT_LOAD_MORE: "Load more...",
    BUT_COMMON_CONTINUE: "Continue",
    BUT_COMPLETE: "Complete",
    BUT_SUBMIT_DOCUMENT: "SUBMIT DOCUMENTS",
    BUT_INTERESTED: "I’m Interested this product",

    //HOVER
    HOVER_TC: "Open terms and conditions!",

    //Validation
    VALIDATION_NAME_REQ: "Name is required",
    VALIDATION_EMAIL_REQ: "Email is required",
    VALIDATION_PHONE_REQ: "Phone is required",
    VALIDATION_EMAIL_VALID: "Email must be valid",
    VALIDATION_TC_AGREE: "You must agree to continue!",
    VALIDATION_FORM_VALID: "Your form is not valid.",

    //Common Labels
    LABEL_FULL_NAME: "Full Name",
    LABEL_FULL_NAME_KTP: "Full Name (Name as KTP) *",
    LABEL_FIRST_NAME: "First Name",
    LABEL_LAST_NAME: "Last Name",
    LABEL_EMAIL: "Email",
    LABEL_PHONE: "Phone",
    LABEL_DATE: "Date",
    LABEL_TIME: "Time",
    LABEL_AGREE: "I have agree to ",
    LABEL_TC: "Terms and Conditions",
    LABEL_FROM_CA: " from Charged Asia",
    LABEL_TAX: "Tax",
    LABEL_INSURANCE: "Insurance",
    LABEL_MO: "mo",
    LABEL_DAY: "day",
    LABEL_CHARGER: "Charger Type",
    LABEL_BATTERY: "Battery Quantity",
    LABEL_REQUIRED: "*Required fields",
    LABEL_RESIDENCE: "Residence Address",
    LABEL_OPENING_TITLE: "We are available on:",
    LABEL_OPENING_TIME: "Mon - Sat : 09.00 - 17:00 (WIB)",
    LABEL_CLOSING_TIME: "Sun : Closed",
    LABEL_DATE_PAYMENT: "Date of Payment",
    LABEL_SECONDS: "seconds",
    LABEL_COLOR: "Color",

    LABEL_NEED_HELP: "Need Help",
    LABEL_CHAT_WITH_US: "Chat with us",
    LABEL_SUBUSCRIPTION_PLAN: "Subscription Plan",
    LABEL_DATE_ORDER: "Date of Payment",
    LABEL_EFFECTIVE_AS_OF: "Effective as of",
    LABEL_ABOUT: "About",
    LABEL_AND: "and",
    LABEL_INCLUDE: "Include",
    LABEL_CUSTOMER_TYPE: "Customer Type",

    // common Placeholder
    PLACEHOLDER_NAME: "Your Name",
    PLACEHOLDER_EMAIL: "Your Email Address",
    PLACEHOLDER_PHONE: "Your Phone",

    //Test Drive
    TEST_DRIVE_TITLE: "Experience The Future of Mobility",
    TEST_DRIVE_SAVED: "Your test ride record has been saved.",
    TEST_DRIVE_CANCEL: "Your test ride record has been canceled.",
    TEST_DRIVE_RESCHEDULE: "Your test ride record has been rescheduled.",
    TEST_DRIVE_PERSONAL_DATA: "Personal Information",
    TEST_DRIVE_SCHEDULE_DATA: "Appointment Schedule",
    TEST_DRIVE_REVIEW_DATA: "Review",
    TEST_DRIVE_CONTACT_DESCRIPTION: "Our sales team might contact you to ask for more information in the future.",
    TEST_DRIVE_ADDRESS_TITLE: "Residence Address",
    TEST_DRIVE_ADDRESS_DESCRIPTION: "This address will also be used as your delivery address if you opt for delivery.",
    TEST_DRIVE_CITY: "City *",
    TEST_DRIVE_PROVINCE: "Province *",
    TEST_DRIVE_STATE: "Regency *",
    TEST_DRIVE_DISCTRICT: "District *",
    TEST_DRIVE_SUB_DISCTRICT: "Sub District *",
    TEST_DRIVE_ADDRESS: "Address *",
    TEST_DRIVE_ZIP_CODE: "Postal/ZIP Code *",
    TEST_DRIVE_SHIPPING: "Pick Up Option",
    TEST_DRIVE_KEMANG: "Pick Up from E-Shed Kemang",
    TEST_DRIVE_CIKUPA: "Pick Up from E-Shed Cikupa",
    TEST_DRIVE_HOME: "Delivered to your residence address",
    TEST_DRIVE_STEP_1: "Preferred Vehicle",
    TEST_DRIVE_STEP_1_TITLE: "This booking only applies for 1 test rider. If you have friends coming, please ask them to make a separate booking.",
    TEST_DRIVE_STEP_1_DESCRIPTION_P1: "This booking only applies for 1 test rider. If you have friends coming, please ask them to make a separate booking.",
    TEST_DRIVE_STEP_1_DESCRIPTION_P2: "Select the vehicle you most like to experience first. Don't worry you will be able to experience the others too.",
    TEST_DRIVE_STEP_2: "Schedule Test",
    TEST_DRIVE_STEP_2_TITLE: "This booking only applies for 1 test rider. If you have friends coming, please ask them to make a separate booking.",
    TEST_DRIVE_STEP_2_DESCRIPTION: "Any questions? Chat with us here or call us at ",
    TEST_DRIVE_STEP_3: "Personal Details",
    TEST_DRIVE_STEP_3_TITLE: "This booking only applies for 1 test rider. If you have friends coming, please ask them to make a separate booking.",
    TEST_DRIVE_STEP_3_DESCRIPTION: "Any questions? Chat with us here or call us at ",
    TEST_DRIVE_LOCATION_TITLE: "Experience Locations *",
    TEST_DRIVE_MAPS: "View in map",
    TEST_DRIVE_DATE_TITLE: "Choose Date*",
    TEST_DRIVE_DATE_PLACEHOLDER: "Select Test Ride Date",
    TEST_DRIVE_DATE_SUBMIT: "Save Date",
    TEST_DRIVE_TIME: "Choose Time *",
    TEST_DRIVE_TIME_PLACEHOLDER: "Select Test Ride Time",
    TEST_DRIVE_TERMS: "I agree to the terms set out in Charged Indemnity Policy.",
    TEST_DRIVE_POLICY: "Charged Indemnity Policy.",
    TEST_DRIVE_EXPLORE: "Explore Other Models",

    //About
    ABOUT_TITLE: "Redefine <br />Your Way of Riding",
    ABOUT_TITLE_MOBILE: "Redefine <br />Your Way of <br />Riding",
    ABOUT_DESCRIPTION_1:
      "Our constitution enshrines that we act as a B(Beneficial) Corporation and meet the highest standards of verified social and environmental performance, public transparency and legal accountability to balance profit and purpose.<br /><br /> We are required to prioritize our mission to lead the adoption of sustainable mobility, monetary profit and economic growth equally with the welfare of all our stakeholders including but not limited to our employees, customers, partners, suppliers, community and environmental concerns.<br /><br /> Our mission at Charged is 10 million electric vehicles in 10 years. We plan to start with electric motorcycles and lead the mass adoption of sustainable mobility in our region.<br /><br /> We excel by balancing profit and purpose. We act as a B(Beneficial) corporation and meet the highest standards of verified social and environmental performance, public transparency and legal accountability.<br /><br /> We welcome everyone who shares our vision to join us on this impact journey!",
    ABOUT_CAROUSEL_TITLE: "Our mission at Charged Asia",
    ABOUT_CAROUSEL_CONTENT: [
      {
        title: "Ten Million Vehicles in Ten Years",
        content:
          "At Charged, We're on a mission to increase electric vehicle adoption by making EVs more affordable and accessible. We believe electric is the future of transportation. we believe that electric vehicles will be a game changer. They will be common vehicle in the next ten years, therefore this is our mission to make 10 million electric vehicles in 10 years.",
      },
      {
        title: "Charged the Game Changer",
        content:
          "Our Mission is to be the system that moves people. Combining the beauty of design, the intelligence of software, and the kinetic energy created from perfectly balanced wheels. We’re creating a world where cities breathe easier and noise pollution is a thing of the past. Where driving is something everyone can enjoy. A world that recharges everyone it touches.",
      },
      {
        title: "Beneficial For Environment",
        content:
          "The vehicle that changed everything is back and better than ever. All-electric. No gas. And zero emissions. The key to getting the maximum benefit from your energy-efficient vehicle is ensuring that its power systems are running at peak efficiency.",
      },
    ],

    //Press Launch State
    PL_DESCRIPTION_TITLE: "Charged Indonesia is here, for good",
    PL_DESCRIPTION_TEXT:
      "We are here as a direct-to-user scale-up motorcycle manufacturer and distributor to provide sustainable, accessible and practical mobility for Indonesian customers. Our vehicles are competitive in price, performance and quality when compared to traditional combustion motorcycles. <br /> <br /> As a brand, we aim to accelerate the adoption of electric vehicles in Indonesia in order to mitigate air pollution and improve the overall mobility experience for Indonesians. <br /> <br /> As a company, we strive to operate as a B Corporation, meeting the highest standards of verified social and environmental performance, public transparency and legal accountability to balance profit and purpose.<br /> <br />We are close to rolling out our 16,000 square metre zero energy facility in Greater Jakarta to house the Charged experiential centre, research and development labs and production engineering facilities. ",
    PL_COMMITMENT_TITLE: "We believe in",
    PL_COMMITMENT_TEXT:
      "People, ideas and providing a world-class service that is consistent and ever-improving. Our team is dedicated to constantly developing new solutions and experiences so that you can continue enjoying your ride. <br /> <br /> Designing better services, products and experiences means planning for a better future by transitioning to clean and sustainable mobility. We envision a near future where we, and our generations to come, can indulge in cleaner air, clearer waters, and peaceful city streets. <br /> <br /> We are achieving this through providing sustainable mobility, working together with local communities and NGOs and ensuring long lasting relationships with our stakeholders. <br /><br />Join us, Ride for good with Charged.",
    PL_CONTACT_TITLE:
      "Want to know more? <br> Chat with us for more information.",
    PL_CONTACT_TEXT:
      "You can also leave us a call on <a style='color: white;' href='https://api.whatsapp.com/send?phone=6281237686970&text=Hello%20Charged' target='_blank'>+62 812 37 686970</a> or write to us on <a style='color: white;' href='mailto:contact@charged.asia' target='_blank'>contact@charged.asia</a>",
    PL_CONTACT_TEXT_MOBILE:
      "You can also leave us a call on <a style='color: white;' href='https://api.whatsapp.com/send?phone=6281237686970&text=Hello%20Charged' target='_blank'>+62 812 37 686970</a> <hr class='my-4'/> Write to us on <a style='color: white;' href='mailto:contact@charged.asia' target='_blank'>contact@charged.asia</a>",
    PL_CONTACT_BUTTON: "Start a Chat",
    PL_BELIEVE_TITLE:
      "We believe in people, ideas, and providing a world-class service that is consistent and ever-improving.",
    PL_BELIEVE_CONTENT:
      "Our team is dedicated to constantly developing new solutions and experiences so that you can continue enjoying your ride. <br/><br /> Designing better services, products and experiences means planning for a better future by transitioning to clean and sustainable mobility. We envision a near future where we, and our generations to come, can indulge in cleaner air, clearer waters, and peaceful city streets. <br/><br /> We are achieving this through providing sustainable mobility, working together with local communities, government and NGOs and ensuring long lasting relationships with our stakeholders. <br/><br /> Join us, Ride for Good with Charged.",
    PL_RIMBA_TITLE: "The Rimba Bike",
    PL_RIMBA_CONTENT:
      "The Rimba bike project started as a concept electric motorcycle that can endure offroad conditions. Charged Indonesia, supported by Vmoto Soco Group technology, designed the Rimba bike based on the inputs and needs of the PROFAUNA forest rangers in protecting and conserving the forests and wildlife with zero emissions, silent operations, and low carbon footprint.",
    PL_RIMBA_BUTTON: "LEARN MORE ABOUT ",

    RIMBA_TITLE: "THE RIMBA BIKE ",
    RIMBA_DESCRIPTION:
      "We are proud to support positive impact organisations that strive to conserve our environment and wildlife sanctuaries across Indonesia through our exclusive ranger electric motorbike ‘Rimba’.",
    RIMBA_ENVIRONMENT_TITLE: "Our Vision for The Environment",
    RIMBA_ENVIRONMENT_TEXT: `
           We start to actualize our vision as part of our sustainability
            movement to create a future with cleaner air, reduced pollution and
            better health for our future generations and environment. We
            launched the Rimba project to be part of a forest and animal
            conservation effort by PROFAUNA Indonesia, an NGO in East Java that
            focuses on the conservation area surrounding Malang.
            <br />
            <br />

            We understood how dangerous the roads forest rangers need to take
            every day, from narrow trails in the forest to the sheer cliff faces
            of the southern coast, Rangers uphold the dangerous duty of facing
            illegal loggers and poachers, as well as efforts of educating local
            villagers the importance of preserving their environment.
            <br />
            <br />

            Mr Rosek Nursahid, founder of PROFAUNA Indonesia, shared “We welcome
            this collaboration with Charged, because we believe that Rimba's
            eco-friendly electric motorbike will be more useful for the PROFAUNA
            team when monitoring wildlife in the forest, because of the quiet
            sound of the motor.”
              <br />
            <br />
Mr Charles Chen, CEO of Vmoto Limited shared, “It is our immense pleasure to have this partnership with Charged and Profauna Indonesia to launch the “Rimba”, an actual sustainable vehicle that is specially designed to fulfil the mission of protecting the environment and our sacred land! Going green should not only be a slogan but a call to action to work together for a better future so to the awesome people of Indonesia, may we ask all of you to join us on this meaningful mission?”
           `,
    RIMBA_ENVIRONMENT_BUTTON: "LEARN MORE ABOUT PROFAUNA",
    RIMBA_ENVIRONMENT_COLLAB: "IN COLLABORATION WITH",
    RIMBA_CAROUSEL_TITLE: "Smart Design Meets Technology",
    RIMBA_CAROUSEL_TEXT:
      "Rimba is designed for tough endurance with protective body structures, additional fog lamp, and off-road tires. It’s capable of being equipped with 3 batteries that can cover up to 300km in distance. It has a high load limit up to 140kg, with a high ground clearance reaching up to 250mm, making it possible to pass through difficult terrains.<br /> <br /> Rimba is the ideal mode of sustainable mobility for PROFAUNA’s forest rangers to do their difficult job in protecting and conserving the forests and wildlife.",
    RIMBA_PARTNER_TITLE: "Our technology is powered by ",
    RIMBA_PARTNER_DESCRIPTION:
      "Vmoto Soco Group (VMT) is a global scooter manufacturing and distribution group specialising in high quality and innovative electric powered two-wheel vehicles for B2C and B2B users in over 60 countries. <br/> <br/> Vmoto Soco Group knows that the future is fast. Innovation is always in motion. Protecting the environment needs to happen now!<br/> <br/> They strive to empower people with eco-friendly solutions that are sustainable - providing value for society, themselves, and their customers alike; they work hard so we can enjoy a ride of dreams. <br/> <br/> Ride the electric revolution with Vmoto Soco Group.",
    RIMBA_PARTNER_BUTTON: "VISIT VMOTO WEBSITE",

    MSRP: "MSRP",
    OTR: "OTR Price",
    OTR_JAKARTA: "OTR Jakarta Price",
    UNIT_AND_CHARGER: "Unit & Charger",
    OTR_FOOTNOTE: "*Price refer to Jabodetabek area",
    SUBS_PRICE_FOOTNOTE: "*Harga area Jabodetabek",
    COLOR_OPTION_TEXT: "Available In",
    EX_VAT: "(excluding VAT)",
    BUY_NOW: "Buy Now for ",
    SUB_NOW: "Subscribe Now for ",
    OR: "or",
    TOP_SPEED: "TOP SPEED",
    MAX_RANGE: "MAXIMUM RANGE*",
    MAX_BATTERIES: "(2 BATTERIES)",
    AVAILABLE_SUBS: "AVAILABLE FOR SUBSCRIPTION",
    SPEED_UNIT: "km/hr",
    BATTERY_SUBCRIPTION: "Battery Subscription",
    INCLUDE_CHARGER: "Include regular charger",
    INCLUDE_VAT: "Include VAT",
    SUBSCRIBE_FOR: "Subscribe for",

    // footer
    TITLE_EXCLUSIVE_INVITES_P1: "Stay updated on new launches? ",
    TITLE_EXCLUSIVE_INVITES_P2: "Let’s keep in touch.",
    TEXT_BUTTON_EXCLUSIVE_INVITES: "Receive Exclusive Invites",
  },
  id: {
    // menu and module titles
    APP: "ChargedAsia",
    SLOGAN: "Ride for Good!",
    WELCOME: "SELAMAT DATANG DI Charged Asia!",
    WELCOME_TEXT_UPDATED: "Teks Diperbarui",
    ONLINE: "Anda sedang terhubung!",
    OFFLINE: "Oops, koneksi anda terputus!",
    LANG: "Language",
    ABOUT: "About Charged Asia",
    ABOUT_TEXT: "Developed by Martin Kuijs",
    ABOUT_VERSION: "Version:",
    CHAT_WA: "Chat melalui WhatsApp",
    BATTERY: "Baterai",
    CHARGER: "Charger",
    REGULAR_CHARGER: "Charger",
    BATTERIES: "baterai",
    MONTH: "bulan",
    START_FROM: "Mulai dari",
    PHONE_NUMBER: "No. Handphone",
    EMAIL_ADDRESS: "Alamat Email",
    SIGNOUT: "Keluar Akun",

    // Get started
    LANDING: "Penggalangan dana untuk proyek dan orang yang berarti bagi Anda",
    MODAL_DOCHI_TITLE: "EDISI TERBATAS",
    MODAL_DOCHI_DESCRIPTION: "Sekarang dibuka untuk pre-order!",

    // cookies
    COOKIES_DESCRIPTION: "Dengan mengklik “Terima Cookies”, Anda setuju dengan penyimpanan cookie di perangkat Anda untuk meningkatkan navigasi situs, menganalisis penggunaan situs, dan membantu upaya pemasaran kami.",
    COOKIES_ACCEPTED: "Terima Cookies",
    COOKIES_DECLINED: "Tidak, Terima Kasih",

    // Signup
    SIGNUP_TITLE: "Bergabunglah dengan Charged Club untuk penawaran dan acara eksklusif",
    SIGNUP_PHONE: "No. Handphone",
    SIGNUP_SUBMIT: "Lanjutkan",
    SIGNUP_DESCRIPTION: "Dengan masuk, anda menyetujui Syarat & Ketentuan dan Kebijakan Privasi & Cookie kami.",
    YOUR_NUMBER: "Masukkan nomor telepon Anda?",
    THE_CODE: "Masukkan Kode Verifikasi",
    WE_TEXT:
      "Kami akan mengirimkan kodenya melalui SMS untuk proses verifikasi",
    SMS_SENT: "Kami telah mengirimkan kode verifikasi melalui SMS ke ",
    ENTER_CODE: "Masukkan kodenya",
    DIDNT_GET_CODE: "Tidak menerima kode?",
    RE_SEND: "Kirim ulang kode",
    PROBLEMS: "Ada masalah saat menerima kode?",
    INVALID_CODE: "Format kode SMS salah!",
    INV_CODE: "Kode tidak valid!",
    CODE_MUST_BE: "Kode harus terdiri dari 6 angka",
    CHECK_SMS: "Silahkan cek SMS Anda atau kirim ulang kodenya.",
    CHECK_NR: "Silahkan cek nomor ponsel Anda.",
    SMS_ERROR: "Terjadi kesalahan. SMS tidak terkirim!",
    ALREADY_ACCOUNT: "Sudah memiliki akun?",
    LOGIN_EMAIL: "Silahkan login dengan email Anda",
    NEW_PHONE: "Memiliki nomor ponsel yang baru?",
    CHANGE_PHONE: "Ubah nomor ponsel Anda disini",

    // Sign Up Next
    IN_ORDER:
      "Untuk melengkapi proses pembuatan akun, kami membutuhkan sedikit informasi tentang Anda...",
    ACCOUNT_CREATED: "Akun sudah berhasil dibuat",
    CONGRATULATIONS: "SELAMAT DATANG DI",
    CONGRATULATIONS_A: "Charged Asia!",
    ACC_READY:
      "Akun Anda sudah berhasil teregistrasi! Silahkan masuk dengan perangkat apa pun menggunakan nomor ponsel atau alamat email yang terdaftar.",
    ACC_TEMP_EMAIL:
      "Jika Anda telah mengubah nomor ponsel Anda, harap gunakan email pribadi Anda atau email Charged Asia yang diberikan kepada Anda di Akun Anda untuk masuk kembali.",
    NO_EMAIL: "Saya tidak punya alamat email",
    SET_PASSWORD: "Silahkan buat password Anda",
    AGREE_TERMS: "Saya mengetujui persyaratan dan kebijakan privasi",

    // Sign In
    SIGN_IN_HERE: "Silahkan, login disini",
    PW_RECOVERY: "Pemulihan Kata Sandi",
    ENTER_EMAIL: "Silahkan ketik alamat email Anda",
    ENTER_NEW_EMAIL: "Silahkan ketik alamat email baru.",
    CHANGE_PHONE_NR: "Ubah nomor ponsel Anda",
    SIGN_IN_FIRST: "Silahkan login terlebih dahulu",
    FORGOT_PWD: "Lupa password?",
    NO_ACCOUNT: "Belum punya akun?",
    SIGN_UP_NOW: "Daftar sekarang!",
    SEND_RESET_LINK: "Kami akan mengirimkan link reset ke email Anda.",
    EMAIL_SENT: "Email terkirim",
    CHECK_EMAIL: "Silahkan cek inbox Anda, kami baru saja mengirimkan email.",
    EMAIL_INSTRUCTIONS:
      "Silahkan cek inbox Anda, kami baru saja mengirimkan email. Coba buka email dari kami dan klik link yang tersedia. Tentu saja, jika Anda memiliki pertanyaan, silahkan hubungi kami.",

    // Home
    HOME: "Home",
    POWERED_BY: "Didukung oleh VMOTOSOCO",
    ABOUT_US: "Tentang Kami",
    CATALOG: "Charged Katalog Produk 2022",
    MODEL: "Model",
    NEWS_REEL: "Cerita Keberlanjutan",
    VEHICLES: "Koleksi Motor",
    BIKES: "Sepeda",
    CAREERS: "Mengemban Misi",
    TEAM: "Tim",
    CONTACT: "Bantuan",
    CONTACT_TITLE: "Informasi Kontak dan Tautan Berguna",
    CONTACT_SUB_TITLE:
      "Pelajari lebih lanjut tentang Charged Asia, terhubung dengan tim dan komunitas.",
    ADMIN_HOME: "Admin Home",
    APPLY_FOR_A_JOB: "Melamar Pekerjaan",
    SLOGAN_DESCRIPTION: "Dapatkan kendali penuh dalam berkendara dengan sistem berlangganan bulanan. Nikmati fleksibilitas dan kemudahan bersama Charged.",
    TEST_RIDE_SHOWROOM: "Test Ride di Showroom Kami",
    EMAIL_INPUT: "Alamat Email",
    EMAIL_SUBMIT_BUTTON: "Dapatkan Undangan Eksklusif",
    EMAIL_DESCRIPTION: "*Dengan memasukkan email, kamu setuju untuk menerima berbagai info seputar produk dan layanan Charged.",
    HOME_ICON_TITLE: "Teknologi kami didukung oleh",
    HOME_ICON_DESCRIPTION: "Info lebih lanjut mengenai CATL",
    LOW_MAINTENANCE_TITLE: "Minim Perawatan",
    LOW_MAINTENANCE_DESCRIPTION: "Hemat biaya, waktu dan energi",
    PORTABLE_BATTERIES_TITLE: "Baterai Portabel",
    PORTABLE_BATTERIES_DESCRIPTION: " Isi daya baterai di mana pun, kapan pun",
    EASY_RIDING_TITLE: "Mudah Dikendarai",
    EASY_RIDING_DESCRIPTION: "Dikembangkan oleh pengendara untuk pengendara",
    CONNECTED_TITLE: "Tetap Terhubung",
    CONNECTED_DESCRIPTION: "Pantau kondisi motor dalam satu genggaman",
    BIKE_HOME_TITLE: "KOLEKSI KENDARAAN CHARGED",
    BIKE_HOME_DESCRIPTION: "Didukung oleh VMOTO",
    BIKE_ITEM_DESCRIPTION: "Paket langganan mulai dari 6 bulan",
    BIKE_HOME_DISCOVER: "Info Lebih Lanjut",
    BIKE_HOME_SCHEDULE: "Daftar Test Ride",
    HOME_SUBSCRIBE_TITLE: "Bagaimana Cara Berlangganan?",
    ORDER_TITLE: "Pesan",
    ORDER_DESCRIPTION: "Pilih paket langganan yang diinginkan dan bayar booking fee.",
    SUBMIT_DOCUMENTS_TITLE: "Upload Dokumen",
    SUBMIT_DOCUMENTS_DESCRIPTION: "Kirim berkas-berkas yang diperlukan ke form online yang ada di bukti pembayaran booking fee atau dikirim ke emailmu.",
    MAKE_PAYMENT_TITLE: "Lakukan Pembayaran",
    MAKE_PAYMENT_DESCRIPTION: "Kami akan menghubungimu terkait biaya deposit dan iuran langganan  bulan pertama.",
    COLLECT_OR_RECEIVE_YOUR_BIKE_TITLE: "Ambil Motor Charged",
    COLLECT_OR_RECEIVE_YOUR_BIKE_DESCRIPTION: "Kamu bisa mengambil motor di showroom Charged atau tunggu diantar.",
    HOME_TEST_RIDE_TITLE: "Nikmati Pengalaman Berkendara dengan Charged.",
    HOME_TEST_RIDE_DESCRIPTION: "Kunjungi E-Shed kami untuk merasakan sendiri pengalaman berkendara bersama Charged.",
    BACK_TO_HOMEPAGE: "Kembali ke Beranda",

    // Product
    PRODUCT_SUBSCRIBE: "Langganan Sekarang",
    PRODUCT_SUBSCRIBE_BUY_NOW: "Beli Sekarang",
    PRODUCT_SCHEDULE: "Daftar Test Ride",
    SAFETY: "Performa dan Keamanan",
    CONVENIENCE: "Kenyamanan",
    INTELLIGENCE: "Teknologi",
    AGILITY: "Kelincahan",
    REFINED_POWER: "Tenaga yang Terkendali",
    BAYCAT_FEATURE_TITLE: "Melaju dengan kelas",
    BAYCAT_FEATURE_TITLE_DESCRIPTION: "Molis Baycat hadir dengan teknologi canggih yang dirancang untuk memberikan pengalaman berkendara yang lebih aman, nyaman, dan menyenangkan di setiap perjalananmu.",
    BAYCAT_FEATURE_CONVENIENCE_DESCRIPTION: "Baycat dilengkapi dengan fitur mundur yang mengerti kebutuhan kamu. Kesulitan saat keluar dari parkiran? Bukan halangan lagi.",
    BAYCAT_FEATURE_AGILITY_DESCRIPTION_1: "Kita paham kelincahan melesat di lalu lintas perkotaan adalah hal yang penting.",
    BAYCAT_FEATURE_AGILITY_DESCRIPTION_2: "Sudut kemiringan Baycat sudah diperhitungkan untuk menjadi perpanjangan badan pengendara, melaju bersama dengan penuh percaya diri.",
    BAYCAT_FEATURE_REFINED_POWER_1: "Baycat is a testament to refined power, delivering the perfect balance between torque and comfort.",
    BAYCAT_FEATURE_REFINED_POWER_2: "It's designed for smooth acceleration and precise control, offering strength without sacrificing a comfortable and enjoyable riding experience. Proving that power isn't just about brute force.",
    ANOA_PRODUCT_TAGLINE_TITLE: "Utilitas tak terbatas",
    ANOA_PRODUCT_TAGLINE_DESCRIPTION: "Kuat dan dapat diandalkan, ANOA mampu membawa kargo dan dirimu ke mana pun, kapan pun.",
    ANOA_PERFORMANCE_SAFETY_DESCRIPTION: "ANOA merupakan pilihan terbaik untuk armada kargo, berdaya angkut hingga 150 kg dan 250 liter (tidak termasuk pengendara), dengan jarak tempuh sejauh 160km didukung oleh rangka baja bertarikan tinggi dan drive train yang sangat efisien.",
    ANOA_PERFORMANCE_SAFETY_DESCRIPTION_P2: "Dapatkan visibilitas yang lebih baik dalam berbagai kondisi cuaca menggunakan sistem pencahayaan full LED di bagian depan dan belakang.",
    ANOA_PERFORMANCE_SAFETY_DESCRIPTION_P3: "Dibekali sistem pengereman CBS, memastikan keamanan ekstra saat berkendara.",
    ANOA_CONVENIENCE_DESCRIPTION: "Dengan opsi bracket di bagian depan, belakang dan samping, ANOA sangat fleksibel untuk disesuaikan dengan kebutuhanmu.",
    ANOA_CONVENIENCE_DESCRIPTION_P2: "ANOA juga dilengkap one push reverse button untuk memudahkan saat mundur, sistem single-sided swingarm untuk penggantian ban belakang yang cepat, fast charger dan pengoperasian baterai intuitif untuk penggunaan sehari-hari. Untuk membantu operasional sebagai armada komersial, ANOA dibekali dengan suplai tenaga dedikatif untuk kotak pemanas / pendingin di bagian belakang serta standar dua sisi (kiri kanan) untuk memudahkan akses naik motor.",
    ANOA_INTELLIGENCE_DESCRIPTION: "Tidak perlu khawatir berkendara dalam berbagai kondisi cuaca. Sistem CAN dan pengawasan terintegrasi memastikan bagian penyimpanan dan pengecasan tetap aman. Kamu juga bisa lebih fokus bermobilisasi tanpa perlu khawatir berkat sistem antipencurian canggih yang telah disematkan pada ANOA.",
    ANOA_INTELLIGENCE_DESCRIPTION_P2: "Untuk membantu konektivitasmu, ANOA sudah dilengkapi USB charging port agar kamu bisa mengecas ponselmu selama di perjalanan.",
    MALEO_PRODUCT_TAGLINE_TITLE: "Penjelajah kota yang lincah",
    MALEO_PRODUCT_TAGLINE_DESCRIPTION: "Pergi ke mana pun, lakukan apa yang kamu mau bersama MALEO.",
    MALEO_PERFORMANCE_SAFETY_DESCRIPTION: "Dapatkan kebebasan manuver yang lincah bersama MALEO. Mau untuk digunakan sehari-hari, bisa. Mau digunakan untuk mencari penghasilan tambahan, juga bisa. Dengan sistem pencahayaan full LED di bagian depan dan belakang, dapatkan visibilitas yang lebih baik dalam berbagai kondisi cuaca.",
    MALEO_PERFORMANCE_SAFETY_DESCRIPTION_P2: "Dibekali sistem pengereman CBS, dapatkan keamanan ekstra saat berkendara.",
    MALEO_CONVENIENCE_DESCRIPTION: "Tambahan rack di belakang memberikanmu fleksibilitas untuk mengangkut bawaan, paket, atau penumpang secara bersamaan.",
    MALEO_CONVENIENCE_DESCRIPTION_P2: "MALEO juga dilengkapi dengan one push reverse button untuk memudahkan saat mundur, sistem single-sided swingarm untuk penggantian ban belakang yang cepat, fast charger dan akses mudah untuk mengisi daya baterai.",
    MALEO_INTELLIGENCE_DESCRIPTION: "Hubungkan motormu dengan aplikasi pintar kami untuk mengakses BMOS Central Control System. Kamu bisa mengecek kondisi motor dan baterai, lokasi motor, sistem AntiTheft, record kendaraan, dan fitur peringatan.",
    MALEO_INTELLIGENCE_DESCRIPTION_P2: "MALEO juga dilengkapi slot USB khusus untuk pengecasan baterai ponsel agar kamu bisa terus terhubung.",
    RIMAU_PRODUCT_TAGLINE_TITLE: "Seru berkendara membelah kota",
    RIMAU_PRODUCT_TAGLINE_DESCRIPTION: "Dirancang untuk para pengendara urban dan petualang. Nikmati pengalaman berkendara nyaman dan bertenaga bersama RIMAU.",
    RIMAU_PERFORMANCE_SAFETY_DESCRIPTION: "RIMAU dirancang untuk memberikan pengalaman mobilitas yang berkelanjutan tanpa melupakan gaya. Dengan sistem pencahayaan full LED di bagian depan dan belakang, dapatkan visibilitas yang lebih baik dalam berbagai kondisi cuaca.",
    RIMAU_PERFORMANCE_SAFETY_DESCRIPTION_P2: "Dibekali sistem pengereman CBS, dapatkan keamanan ekstra saat berkendara.",
    RIMAU_CONVENIENCE_DESCRIPTION: "Perjalanan berangkat dan pulang kerja merupakan proses yang melelahkan. Inilah alasan kami merancang jok RIMAU sedemikian rupa untuk memberikan kenyamanan maksimum bagimu maupun penumpang di jok belakang.",
    RIMAU_CONVENIENCE_DESCRIPTION_P2: "Rimau juga dilengkapi one push reverse button untuk memudahkanmu saat harus mundur dan single-sided swingarm yang memungkinkan proses penggantian ban belakang dengan sangat cepat.",
    RIMAU_INTELLIGENCE_DESCRIPTION: "Sistem powertrain management memungkinkan RIMAU untuk beralih dari satu baterai ke baterai lainnya saat dayanya habis untuk efisiensi dan kendali maksimum.",
    RIMAU_INTELLIGENCE_DESCRIPTION_P2: "Tetap terhubung kapan pun dengan RIMAU melalui aplikasi Charged yang terintegrasi. Kamu bisa memantau kondisi motor dan kapasitas bateraimu dari mana pun, kapan pun.",
    PRODUCT_SPECIFICATION_TITLE: "SPESIFIKASI TEKNIS",
    PRODUCT_SPECIFICATION_DESCRIPTION: "*Berdasarkan hasil uji di jalanan Indonesia dengan satu pengendara, bobot 70 kg dan kecepatan berkendara tidak lebih dari 50 km/jam. Hasil mungkin bervariasi, tergantung kondisi berkendara.",
    PRODUCT_SPEED_TITLE: "KECEPATAN MAKSIMUM",
    PRODUCT_SPEED_QUANTITY: "km/jam",
    PRODUCT_DISTANCE_TITLE: "JARAK TEMPUH MAKSIMUM*",
    PRODUCT_DISTANCE_QUANTITY: "km",
    CATALOG_BUTTON_MALEO: "Unduh MALEO E-Brosur 2023",
    CATALOG_BUTTON_ANOA: "Unduh ANOA E-Brosur 2023",
    CATALOG_BUTTON_RIMAU: "Unduh RIMAU E-Brosur 2023",
    KEY_NUMBERS: "Info Utama",
    POWERTRAIN: "Powertrain",
    VEHICLE_SPECIFICATION: "Spesifikasi Lainnya",
    LWH: "P*L*T",
    WHEELBASE: "Jarak Sumbu Roda",
    MIN_GROUND_CLEARANCE: "Min. Ground Clearance",
    SEAT_HEIGHT: "Tinggi Tempat Duduk",
    NET_WEIGHT: "Bobot Bersih",
    MAX_LOAD: "Kapasitas Beban Maksimum",
    MAX_LOAD_QUANTITY: "tanpa pengendara",
    PRODUCT_SUBSCRIBE_EXCLUDE: "belum termasuk PPN",
    PRODUCT_SUBSCRIBE_PRICE_FROM: "Berlangganan",
    PRODUCT_SUBSCRIBE_PRICE_NOW_TITLE: "Rasakan pengalaman berkendara dengan harga lebih murah!",
    PRODUCT_SUBSCRIBE_PRICE_NOW_FROM: "Berlangganan mulai dari",
    PRODUCT_SUBSCRIBE_DELIVERY: "siap dikirim mulai",
    PRODUCT_SUBSCRIBE_SHIPPING: "siap dikirim mulai Desember 2022.",
    PRODUCT_SUBSCRIBE_DESCRIPTION: "Seluruh foto dan gambar hanya untuk keperluan ilustrasi. Tampilan produk yang sebenarnya mungkin berbeda.",
    PRODUCT_SUBSCRIBE_INCLUDE_PURCHASE: "Pembelian sudah termasuk :",
    PRODUCT_SUBSCRIBE_BATTERY: "Satu baterai 60V/45AH dengan kapasitas 2.7kwh",
    PRODUCT_SUBSCRIBE_CHARGER: "Charger Standar, 10% ke 100% hanya dalam 5 jam",
    PRODUCT_SUBSCRIBE_PRICE: "Mulai dari Rp1.650.000*/bulan",
    PRODUCT_SUBSCRIBE_PRICE_DESCRIPTION: "Termasuk PPN, garansi suku cadang dan seluruh biaya OTR (on the road). Syarat dan ketentuan berlaku.",
    PRODUCT_INFORMATION: "Untuk informasi & pertanyaan lainnya, kunjungi halaman Bantuan kami.",
    PRODUCT_LOCATION_TITLE: "Silakan Pilih Kota Domisilimu",
    PRODUCT_LOCATION_DESCRIPTION: "Agar kami dapat memastikan layanan yang tepat untukmu, silakan pilih kota domisilimu.",
    PRODUCT_LOCATION_PLACEHOLDER: "Pilih Kota",
    PRODUCT_LOCATION_SUBMIT: "Lanjut",
    PRODUCT_NO_LOCATION_TITLE: "Sepertinya kami belum tiba di daerah Anda.",
    PRODUCT_NO_LOCATION_DESCRIPTION: "Silakan tinggalkan kami kota tempat tinggal dan alamat email Anda sehingga kami dapat terus mengabari Anda tentang cabang baru kami.",
    PRODUCT_NO_LOCATION_AREA_LABEL: "Kota tempat tinggal",
    PRODUCT_NO_LOCATION_AREA_PLACEHOLDER: "Kota Anda",
    PRODUCT_NO_LOCATION_EMAIL: "Alamat Email Anda",
    PRODUCT_NO_LOCATION_EMAIL_SUBMIT: "Beritahu Saya Informasi Terbaru",
    PRODUCT_NO_LOCATION_SUBMIT_TITLE: "Kami akan terus mengabari Anda!",
    PRODUCT_NO_LOCATION_SUBMIT_DESCRIPTION: "Terima kasih atas minat Anda pada kami, kami akan melakukan yang terbaik untuk membawa produk kami kepada Anda.",
    SUBSCRIBE_PHONE_NUMBER: "No. Handphone *",
    SUBSCRIBE_EMAIL_ADDRESS: "Alamat Email *",
    SUBSCRIBE_PARENT_TITLE: "Informasi Orang Tua/Wali",
    SUBSCRIBE_MOTHER_LABEL: "Nama Ibu Kandung *",
    SUBSCRIBE_MOTHER_PLACEHOLDER: "contoh: Jane Doe",
    SUBSCRIBE_PROFESSION_TITLE: "Profesi/Pekerjaan",
    SUBSCRIBE_COMPANY_LABEL: "Nama Perusahaan *",
    SUBSCRIBE_COMPANY_PLACEHOLDER: "contoh: PT. Indah Maju Bersama",
    SUBSCRIBE_JOB_POSITION_LABEL: "Posisi/Jabatan *",
    SUBSCRIBE_EMPLOYMENT_STATUS_PLACEHOLDER: "contoh: Pegawai Tetap",
    SUBSCRIBE_EMPLOYMENT_STATUS_LABEL: "Status Pekerjaan *",
    SUBSCRIBE_JOB_POSITION_PLACEHOLDER: "contoh: Staff",
    SUBSCRIBE_WORK_LENGTH_LABEL: "Lama Bekerja *",
    SUBSCRIBE_WORK_LENGTH_PLACEHOLDER: "contoh: 1-3 years",
    SUBSCRIBE_INCOME_LABEL: "Pendapatan per Bulan *",
    SUBSCRIBE_INCOME_PLACEHOLDER: "contoh: Rp 5.000.000 - Rp 7.500.000",
    SUBSCRIBE_CC_LABEL: "Limit Plafon Kartu Kredit (opsional)",
    SUBSCRIBE_CC_PLACEHOLDER: "ex: Rp 3.000.000 - Rp 5.000.000",
    SUBSCRIBE_MARITAL_STATUS_TITLE: "Status Pernikahan & Penghasilan Pasangan",
    SUBSCRIBE_MARITAL_STATUS_LABEL: "Status Pernikahan *",
    SUBSCRIBE_SINGLE_LABEL: "Belum Menikah",
    SUBSCRIBE_MARIED_LABEL: "Sudah Menikah",
    SUBSCRIBE_SPOUSE_JOB_POSITION_LABEL: "Posisi/Jabatan Pasangan *",
    SUBSCRIBE_SPOUSE_EMPLOYMENT_STATUS_LABEL: "Status Pekerjaan Pasangan *",
    SUBSCRIBE_SPOUSE_COMPANY_NAME_LABEL: "Nama Perusahaan Pasangan *",
    SUBSCRIBE_SPOUSE_COMPANY_NAME_PLACEHOLDER: "contoh: PT. Indah Maju Bersama",
    SUBSCRIBE_SPOUSE_YEAR_OF_EXPERIENCE_LABEL: "Lama Bekerja Pasangan *",
    SUBSCRIBE_SPOUSE_YEAR_OF_EXPERIENCE_PLACEHOLDER: "contoh: 1-3 tahun",
    SUBSCRIBE_SPOUSE_SALARY_LABEL: "Pendapatan per Bulan Pasangan *",
    SUBSCRIBE_SPOUSE_SALARY_PLACEHOLDER: "contoh: Rp 5.000.000 - Rp 7.500.000",
    SUBSCRIBE_HOME_OWNERSHIP_LABEL: "Status Kepemilikan Rumah *",
    SUBSCRIBE_HOME_OWNERSHIP_PLACEHOLDER: "contoh: Kontrak Bulanan",
    SUBSCRIBE_LENGTH_STAY_LABEL: "Lama Tinggal *",
    SUBSCRIBE_LENGTH_STAY_PLACEHOLDER: "contoh: < 1 tahun",
    SUBSCRIBE_PRIVATE_DOCUMENT_TTILE: "Dokumen Pribadi",
    SUBSCRIBE_PRIVATE_DOCUMENT_DESCRIPTION: "Charged akan menjaga privasi dan kerahasiaan Anda dengan sangat serius. Dokumen Anda terjamin aman dan terlindungi bersama kami. Untuk informasi privasi lebih lanjut, silakan kunjungi halaman Kebijakan Privasi kami.",
    SUBSCRIBE_PRIVATE_DOCUMENT_UPLOAD_LABEL: "Unggah Gambar",
    SUBSCRIBE_PRIVATE_DOCUMENT_ITEM__DESCRIPTION: "Unggah file dalam format .jpeg, .png, atau .pdf. Pastikan gambar yang Anda unggah jelas dan dapat dibaca.",
    IDENTITYCARD: null,
    FAMILY_REGISTRATION: null,
    TAX_PAYER_IDENTIFICATION_NUMBER: null,
    DRIVING_LICENSE: null,
    DASHBOARDDRIVER: null,
    ACCOUNT_TRANSACTION: null,
    PAYSLIP: null,
    REVIEW_PRODUCT_STEP_1: "Pesanan Anda",
    REVIEW_PRODUCT_STEP_2: "Informasi Pelanggan",
    REVIEW_PRODUCT_STEP_5: "Opsi Pengambilan",
    SUBCRIPTION_TIME_LABEL: "Berlangganan Selama",
    PRODUCT_STEP_1: "Kustomisasi",
    PRODUCT_STEP_2: "Info Pribadi",
    PRODUCT_STEP_3: "Domisili",
    PRODUCT_STEP_4: "Dokumen Pribadi",
    PRODUCT_STEP_5: "Rencana Langganan",
    PRODUCT_STEP_6: "Review Pesanan",
    PRODUCT_COLOR_TITLE: "Pilihan Warna Kendaraan",
    PRODUCT_CHARGER_TITLE: "Tipe Charger (Harga termasuk pajak)",
    PRODUCT_BATTERY_TITLE: "Jumlah Baterai (Harga termasuk pajak)",
    PRODUCT_CUSTOM_TOTAL: "Total Biaya",
    PRODUCT_BOOKING_DESCRIPTION: "Biaya ini diperlukan untuk pemesanan motormu. Biaya akan dikembalikan setelah proses berlangganan dimulai.",
    PRODUCT_PRICING_TITLE: "Harga *",
    PRODUCT_PRICING_DESCRIPTION: "Silahkan pilih salah satu periode berlangganan berikut:",
    PRODUCT_PRICING_DETAIL: "Silahkan pilih salah satu periode berlangganan berikut:",
    PRODUCT_SUBSCRIBE_DETAIL: "Detail Langganan",
    PRODUCT_MONTHLY_TOTAL_TITLE: "Biaya Langganan per Bulan",
    PRODUCT_DAILY_TOTAL_TITLE: "Biaya Langganan per Hari",
    PRODUCT_MONTHLY_TOTAL_DESCRIPTION: "Ini adalah total biaya yang harus dibayarkan setiap hari, dan baru akan mulai ditagih setelah motor diambil oleh customer.",
    PRODUCT_BILLED_MONTHLY: "Dibayarkan Bulanan",
    PRODUCT_BILLED_DAILY: "Dibayarkan harian",
    PRODUCT_ORDER_TITLE: "Pesananmu",
    PRODUCT_SUBSCRIBE_POLICY_TITLE: "Kebijakan Kontrak Berlangganan",
    PRODUCT_SUBSCRIBE_POLICY_DESCRIPTION: "Pihak kedua menyatakan bahwa seluruh data dan informasi serta dokumen terkait pendanaan yang diunggah telah lengkap dan tepat sesuai dengan dokumen asli; mengizinkan dan memperbolehkan pihak pertama untuk menghubungi pihak mana pun untuk mendapatkan atau menganalisa informasi yang dibutuhkan. Saya paham dan menyetujui bahwa data, informasi dan dokumen yang disediakan akan menjadi milik pihak pertama, tidak perlu dikembalikan dan dapat digunakan untuk berbagai tujuan terkait aktivitas bisnis, termasuk namun tidak terbatas pada penyerahan ke pihak ketiga, termasuk untuk proses survey, pengumpulan kewajiban konsumen, penawaran produk / jasa. Jika ada perubahan, maka data terbaru akan tertera di persetujuan pendanaan. Pihak kedua bersedia untuk tunduk dan terikat pada syarat dan ketentuan yang berlaku, serta memahami bahwa pihak pertama berhak untuk menerima atau menolak pengajuan ini.",
    PRODUCT_ACCEPT: "Saya sudah membaca dan menerima",
    SALES_AGREEMENT: "Ketentuan Berlangganan",
    BOOKING_SUBMIT: "Pesan",
    PRODUCT_AGGREMENT: "Kebijakan Kontrak Berlangganan Charged.",
    WAIT_TITLE: "Silahkan tunggu..",
    WAIT_DESCRIPTION: "Pesanan Anda sedang diproses.",
    BOOKING_FAILED_TITLE: "Transaksi Gagal",
    BOOKING_FAILED_DESCRIPTION: "Maaf, pembayaranmu gagal diproses karena kendala teknis. Apakah kamu ingin mencoba lagi?",
    BOOKING_CONFIRM_TITLE: "Konfirmasi Pemesanan Test Ride",
    BOOKING_CONFIRM_DESCRIPTION: "Terima kasih. Email konfirmasi akan segera terkirim. Kamu juga bisa menggunakan halaman ini sebagai bukti booking.",
    BOOKING_VEHICLE: "Motor yang Dipilih",
    BOOKING_LOCATION: "Lokasi Test Ride",
    OTP_TITLE: "Kami telah membuatkan akun untukmu untuk kemudahan melacak pesanan. Silakan verifikasi.",
    OTP_DESCRIPTION: "Kode telah dikirimkan ke via SMS ke",
    OTP_NOT_SEND: "Tidak menerima kode?",
    OTP_SEND_AGAIN: "Kirim ulang.",
    OTP_VERIVY: "Verifikasi",
    BOOKING_FEE_RECEIPT_TITLE: "BUKTI PEMESANAN",
    BOOKING_FEE_RECEIPT_DESCRIPTION: "Kami telah mengirimkan bukti pemesanan ini ke email Anda.",
    BOOKING_ORDER_NO: "No. Pemesanan",
    BOOKING_BIKE_LABEL: "TIPE YANG DIAMBIL",
    BOOKING_FEE_MESSAGE: "Untuk menyelesaikan proses berlangganan, kamu harus melengkapi beberapa data. Jangan khawatir, datamu aman bersama kami.",
    BOOKING_FEE_LINKED: "Kamu akan diarahkan secara otomatis dalam ",
    BOOKING_CUSTOMER: "Detail Pelanggan",
    BOOKING_SHIPPING: "Detail Pengambilan",
    BOOKING_SHIPPING_MESSAGE: "*Waktu pengambilan akan diinfokan oleh sales kami.",
    BOOKING_SALES: "Sales Anda",
    PIC_NAME_LABEL: "Nama Sales",
    PIC_PHONE_NUMBER_LABEL: "No. Handphone Sales",
    BOOKING_SUBSCRIPTION: "Detail Pemesanan dan Berlangganan",
    TITLE_COMMINGSOON: "Segera Hadir",
    SUBTITLE_COMMINGSOON: "Kami akan segera memperbarui harga dan detailnya untuk memberi Anda informasi terbaru. Nantikan terus!",
    SUBTITLE_COMMINGSOON_FORM: "Terus ikuti informasi terbaru mengenai produk baru kami! Kami akan segera memperbarui harga dan detailnya untuk memberi Anda informasi terbaru. Nantikan terus!",
    SUGEST_COMMINGSOON_FORM: "<p>Apakah Anda tertarik dengan produk baru kami?</p><p>Isi formulir sekarang!</p>",
    INFO_SUMIT_COMMINGSOON_FORM: "**Semua informasi yang disediakan di halaman ini dapat berubah sesuai kebutuhan.",
    LIMITED_EDITION: 'EDISI TERBATAS',
    INFO_AVAILABLE_PURCHASE: '*Hanya Tersedia untuk Pembelian',

    // Careers
    CAREERS_HEADER: "Marilah Mencapai Tujuan Bersama Kami!",

    // User Menu
    MY_ACCOUNT: "Akun Saya",
    ACC_VERIFICATION: "Verifikasi akun",
    ACC_EMAIL: "Email akun",
    ACC_PHONE: "Nomor ponsel ",
    ACC_ID: "Identifikasi",
    ACC_UPLOAD_KTP: "Ungga KTP",
    ACC_TYPE: "Tipe akun",
    ACC_MY_VEHICLES: "",
    ACC_TIER: "Akun Tier",
    ACC_STATUS: "Status akun",
    ACC_INFO: "Informasi akun",
    ACC_PERSONAL: "Informasi pribadi",
    ACC_NAME: "Nama",
    ACC_ABOUT: "Tentang saya",
    ACC_ABOUT_TEXT: "Tulis sesuatu tentang diri Anda...",
    ACC_FULL_NAME: "Nama lengkap",
    ACC_DOB: "Tanggal lahir",
    ACC_DOB_TEXT: "Masukkan tanggal lahir Anda",
    ACC_GENDER: "Jenis kelamin",
    ACC_PHONE_FORM: "No. Telfon",
    ACC_MALE: "Pria",
    ACC_FEMALE: "Perempuan",
    ACC_NOT_SET: "Belum diatur",
    ACC_LANG: "Bahasa",
    ACC_OB: "Pengaturan Charged Asia",
    ACC_TEAMS: "Tim Favorit Saya",
    ACC_LEAGUES: "Liga pilihan saya",
    ACC_CH_PIC: "Ganti Foto Profil",
    ACC_SET_PIC: "Simpan Gambar Profil",
    ACC_EMAIL_NOT_SET: "Email Anda belum di set",
    ACC_EMAIL_ASS:
      "Kami telah memberikan Anda alamat email yang hanya dapat digunakan untuk login bersama dengan kata sandi yang Anda buat.",
    ACC_NOT_VERIFIED: "Email Anda belum diverifikasi",
    ACC_VERIFIED: "Email Anda sudah diverifikasi",
    ACC_VERIFY: "Silahkan klik link dibawah ini untuk verifikasi email Anda.",
    ACC_APP: "App",
    ACC_APP_VER: "Versi",
    ACC_APP_TERMS: "Syarat dan Ketentuan",
    ACC_APP_PRIVACY: "Kebijakan Privasi",
    ACC_APP_POLICY: "Syarat dan Ketentuan",
    ACC_APP_TERMS_OF_USE: "Syarat dan Ketentuan",
    ACC_MENU_TITLE: "Pengaturan Akun",
    ACC_CONTACT: "Kontak",
    ACC_DELETE_MODAL_TITLE: "Apakah anda yakin untuk menghapus akun ini?",
    ACC_DELETE_MODAL_DESC: "Harap diperhatikan jika anda menghapus akun ini, anda tidak akan bisa mengembalikannya lagi.",
    ACC_DELETE_MODAL_BUTTON_YES: "Ya, Hapus Akun",
    ACC_DELETE_MODAL_BUTTON_NO: "Tidak, Batalkan",
    ACC_DELETE: "Hapus Akun",
    ACC_MAILING: "Berlangganan melalui surel",
    ACC_MAILING_DESC: "Jika anda mengaktifkan fitur ini, anda akan diberitahu jika ada informasi baru tentang promosi dan penjualan.",
    ACC_SUBSCRIBE: "Langganan Saya",
    ACC_PROCESSING_TITLE: "Langganan yang Sedang Diproses",
    ACC_PROCESSING_DESCRIPTION: "Produk langganan ini sedang diproses. Apabila kamu memerlukan bantuan, silakan hubungi sales PIC-mu.",
    ACC_ONGOING_TITLE: "Langganan yang Sedang Berjalan",
    ACC_ONGOING_DESCRIPTION: "List langganan kendaraan yang sedang aktif/berjalan. Apabila memerlukan bantuan, silakan hubungi +62 812 3768 6970.",

    // Privacy Policy
    PRIVACY_POLICY_LABEL: "Kebijakan Privasi Charged Indonesia",
    DATE_PRIVACY_POLICY: "16 November 2022",
    PRIVACY_PARAGRAPH_1: "Kebijakan (“Kebijakan Privasi”) ini menjelaskan bagaimana Charged Indonesia (selanjutnya disebut sebagai KAMI) berupaya melindungi Informasi Pribadi perorangan. KAMI berkomitmen untuk melindungi keselamatan dan keamanan informasi pribadi perorangan yang informasinya dapat diakses oleh KAMI termasuk pengguna Platform KAMI (di tentukan di bawah), dan orang lain yang berinteraksi dengan KAMI (setiap 'Pengguna' atau 'Anda').",
    PRIVACY_PARAGRAPH_2: "Berdasarkan Undang-undang, “Informasi Pribadi” didefinisikan sebagai: “Informasi atau opini tentang individu yang teridentifikasi, atau individu yang dapat diidentifikasi secara wajar:",
    PRIVACY_PARAGRAPH_2_LINE_1: "(a) apakah informasi atau pendapat itu benar / tidak; dan",
    PRIVACY_PARAGRAPH_2_LINE_2: "(b) apakah informasi atau pendapat tersebut dicatat dalam bentuk yang material atau tidak.”",
    PRIVACY_PARAGRAPH_3: "Harap baca Kebijakan Privasi ini dengan cermat guna memahami bagaimana Informasi Pribadi Anda dikumpulkan, disimpan, digunakan, atau diproses oleh kami.",
    PRIVACY_PARAGRAPH_4: "Charged Indonesia berhak untuk melakukan perubahan atau pembaruan terhadap Kebijakan Privasi ini dari waktu ke waktu. Jika ini terjadi, kami akan memperbarui Kebijakan Privasi ini dan memberi tahu Anda tentang perubahan apa pun, kemungkinan besar melalui email atau pemberitahuan dalam aplikasi. Namun, Anda juga harus secara berkala memeriksa Kebijakan Privasi ini untuk setiap pembaruan.",
    PRVIACY_1_TITLE: "1. TENTANG KAMI (CHARGED INDONESIA)",
    PRVIACY_1_PARAGRAPH_1: "Dalam menyediakan Platform KAMI (Charged Indonesia), kami peka terhadap kekhawatiran Pengguna tentang keamanan Informasi Pribadi mereka. Intinya, KAMI biasanya hanya akan:",
    PRVIACY_1_PARAGRAPH_2: "1.1 mengumpulkan, menggunakan, atau membagikan Informasi Pribadi Anda dengan persetujuan Anda (kecuali dalam keadaan tidak wajar untuk mendapatkan persetujuan Anda dan secara hukum diizinkan bagi kami untuk melakukannya) atau bila diwajibkan oleh kewajiban hukum; dan berinteraksi dengan Informasi Pribadi Anda untuk: (a) memberi Anda Platform KAMI dan (b) membantu kami meningkatkan dan mengembangkan Platform KAMI.",
    PRVIACY_1_PARAGRAPH_3: "KAMI telah mengembangkan kerangka kerja rahasia pribadi kami untuk membantu para Pengguna, dan untuk memenuhi ketentuan undang-undang dan peraturan privasi yang berlaku bagi kami dan pengelolaan Informasi Pribadi Anda.",
    PRVIACY_2_TITLE: "2. BAGAIMANA KAMI (CHARGED INDONESIA) MENGUMPULKAN INFORMASI PRIBADI ANDA",
    PRIVACY_2_PARAGRAPH_1: "Kami mengumpulkan Informasi Pribadi dari individu dengan salah satu dari tiga cara utama:",
    PRIVACY_2_PARAGRAPH_2: "(a) Langsung dari Pengguna, ketika mereka berinteraksi dengan KAMI atau platform KAMI (misalnya memberikan tanggapan atau saat mendaftarkan akun Pengguna);",
    PRIVACY_2_PARAGRAPH_3: "(b) Secara pasif dari Pengguna, saat mereka berinteraksi dan menggunakan Platform KAMI;",
    PRIVACY_2_PARAGRAPH_4: "(c) Dari pihak ketiga dalam keadaan tertentu dan spesifik (misalnya, jika Anda mendaftar ke Platform KAMI melalui layanan atau platform pihak ketiga, kami dapat diberikan informasi yang telah Anda setujui untuk diberikan kepada pihak ketiga).",
    PRIVACY_2_PARAGRAPH_5: "Spesifik Informasi Pribadi yang dikumpulkan dalam setiap situasi dibahas lebih lanjut di bawah ini.",
    PRIVACY_3_TITLE: "3. KETIKA KAMI (CHARGED INDONESIA) MENGUMPULKAN INFORMASI DARI PARA PENGGUNA DAN APA YANG KAMI KUMPULKAN",
    PRIVACY_3_PARAGRAPH_1: "(a) Informasi Pribadi yang dikumpulkan secara langsung.",
    PRIVACY_3_PARAGRAPH_2: "Saat Pengguna mendaftar dan menggunakan Platform KAMI, kami mengumpulkan jenis Informasi Pribadi berikut secara langsung dan berdasarkan kesepakatan dari Anda:",
    PRIVACY_3_PARAGRAPH_2_LINE_1: "Informasi Dasar Pengguna, termasuk nama Anda, tampilan nama, nomor ponsel, email, tanggal lahir;",
    PRIVACY_3_PARAGRAPH_2_LINE_2: "Informasi akun dasar, termasuk nama Pengguna atau gambar tampilan yang Anda pilih untuk dikaitkan dengan akun Anda;",
    PRIVACY_3_PARAGRAPH_2_LINE_3: "Jika Anda mengakses Platform KAMI melalui layanan atau platform pihak ketiga (misalnya Facebook), kami akan mengumpulkan informasi yang disediakan untuk KAMI oleh layanan atau platform tersebut. Anda biasanya dapat mengatur informasi yang kami terima dari sumber-sumber ini dengan menggunakan pengaturan privasi pada layanan atau platform pihak ketiga.",
    PRIVACY_3_PARAGRAPH_3: "Kami akan mengumpulkan jenis informasi berikut dari Pengguna saat mereka menggunakan Platform KAMI:",
    PRIVACY_3_PARAGRAPH_3_LINE_1: "Preferensi akun dasar, seperti pengaturan bahasa Anda;",
    PRIVACY_3_PARAGRAPH_3_LINE_2: "Konten yang Anda posting dan kirimkan ke Platform KAMI dan halaman media sosial atau blog kami.",
    PRIVACY_3_PARAGRAPH_4: "Jika Anda mengajukan pertanyaan atau mengirimkan umpan balik yang tidak diminta kepada kami, kami dapat mengumpulkan jenis Informasi Pribadi berikut secara langsung dan berdasarkan kesepakatan dari Anda:",
    PRIVACY_3_PARAGRAPH_4_LINE_1: "Informasi dasar kontak, termasuk nama dan email Anda; dan",
    PRIVACY_3_PARAGRAPH_4_LINE_2: "Informasi tanggapan dan detail interaksi Anda dengan kami, termasuk komunikasi dengan dukungan pelanggan atau personil KAMI lainnya (misalnya, isi email yang dikirim ke contact@charged.asia) atau informasi lain yang Anda berikan terkait pertanyaan Anda.",
    PRIVACY_3_PARAGRAPH_4_LINE_3: "Saat Anda menanggapi survei, kami dapat secara langsung dan sepertujuan pihak terkait mengumpulkan Informasi Pribadi yang disangkal pada formulir survei.",
    PRIVACY_3_PARAGRAPH_5: "Saat Anda mengajukan lamaran pekerjaan pada KAMI, kami dapat mengumpulkan Informasi Pribadi apa pun yang disediakan dalam aplikasi tersebut, seperti konten pernyataan pribadi yang dibuat untuk mendukung aplikasi Anda.",
    PRIVACY_3_PARAGRAPH_6: "(b) Informasi Pribadi yang dikumpulkan secara pasif",
    PRIVACY_3_PARAGRAPH_7: "Saat Anda berinteraksi dengan Platform atau iklan KAMI, kami dapat mengumpulkan jenis Informasi Pribadi berikut tentang penggunaan Anda:",
    PRIVACY_3_PARAGRAPH_7_LINE_1: "Konten yang Anda unggah melalui aplikasi, termasuk dalam obrolan dengan Pengguna lain atau di tautan yang berkaitan dengan prediksi atau kompetisi, serta konten serupa yang diunggah tentang Anda oleh orang lain;",
    PRIVACY_3_PARAGRAPH_7_LINE_2: "Informasi latar belakang akun, seperti pengaturan notifikasi dan lencana KAMI yang Anda peroleh; dan",
    PRIVACY_3_PARAGRAPH_7_LINE_3: "Berikut jenis browser (penjelajah situs), sistem, dan informasi perangkat terkait perangkat yang Anda gunakan untuk mengakses Platform KAMI: informasi lokasi, mengenai negara tempat Anda tinggal, misalnya dalam bentuk alamat IP dari mana Anda mengakses Platform KAMI; informasi pelacakan data situs, seperti data dari cookie yang disimpan di perangkat Anda, termasuk ID dan pengaturan cookie, serta log penggunaan Anda atas Platform KAMI; informasi penggunaan sistem, seperti log Pengguna yang Anda ikuti atau ikuti, dan teman yang telah Anda tambahkan dan tambahkan.",
    PRIVACY_3_PARAGRAPH_8: "(c) Informasi Pribadi yang dikumpulkan dari pihak ketiga",
    PRIVACY_3_PARAGRAPH_9: "Dalam situasi tertentu, KAMI akan mengumpulkan Informasi Pribadi tentang Anda dari pihak ketiga. Jenis Informasi Pribadi yang dikumpulkan meliputi:",
    PRIVACY_3_PARAGRAPH_9_LINE_1: "Informasi akun pihak ketiga yang tersedia bagi kami jika Anda mendaftar pada KAMI melalui layanan atau platform pihak ketiga dan;",
    PRIVACY_3_PARAGRAPH_9_LINE_2: "Informasi pelacakan data situs yang sesuai dengan parameter tertentu yang menurut kami dapat menjadi Pengguna Indonesia yang Dikenakan Biaya (mis. peta panas yang dikembangkan melalui Google Analytics yang melacak pola interaksi Pengguna dengan halaman web kami).",
    PRIVACY_4_TITLE: "4. MENGAPA KAMI MENGUMPULKAN INFORMASI PRIBADI ANDA DAN KAMI MENGGUNAKAN UNTUK APA",
    PRIVACY_4_PARAGRAPH_1: "Meskipun KAMI mengumpulkan Informasi Pribadi dari Pengguna dalam beberapa keadaan, KAMI hanya akan mengumpulkan informasi ini untuk menyediakan dan mengembangkan Platform KAMI. Berikut adalah cara utama kami menggunakan Informasi Pribadi untuk mencapai tujuan ini:",
    PRIVACY_4_PARAGRAPH_2: "(a) Berkomunikasi dengan Pengguna",
    PRIVACY_4_PARAGRAPH_3: "KAMI akan menggunakan akun pengguna dasar dan detail kontak untuk berkomunikasi dengan individu tentang tanggapan balik atau masalah mereka dengan Platform KAMI.",
    PRIVACY_4_PARAGRAPH_4: "KAMI juga akan menggunakan informasi pengiriman hadiah untuk memverifikasi identitas pemenang kompetisi, dan informasi wawancara pemenang untuk memberi selamat kepada pemenang kompetisi kami melalui Platform KAMI atau platform media sosial KAMI.",
    PRIVACY_4_PARAGRAPH_5: "Jika Pengguna telah menyetujui, Charged Indonesia juga akan menggunakan jenis Informasi Pribadi ini untuk membagikan berita dan pembaruan yang relevan tentang KAMI dan Platform KAMI.",
    PRIVACY_4_PARAGRAPH_6: "(b) Administrasi dan penyerahan Platform KAMI",
    PRIVACY_4_PARAGRAPH_7: "KAMI akan menggunakan informasi Pengguna dan akun dasar, serta preferensi dasar lainnya untuk memberi Anda pengalaman dasar melalui Platform KAMI (mis. memungkinkan Anda untuk berpartisipasi dalam kompetisi).",
    PRIVACY_4_PARAGRAPH_8: "Jika Anda telah mendaftar menggunakan layanan atau platform informasi pihak ketiga, KAMI juga akan menggunakan ini untuk alasan yang sama.",
    PRIVACY_4_PARAGRAPH_9: "KAMI akan menggunakan informasi Pengguna dasar Anda untuk tugas administratif sederhana, seperti mengatur ulang kata sandi akun.",
    PRIVACY_4_PARAGRAPH_10: "(c) Memastikan keamanan Pengguna",
    PRIVACY_4_PARAGRAPH_11: "KAMI juga akan menggunakan segala jenis informasi yang dikumpulkan untuk mencegah dan mengatasi risiko bagi semua Pengguna (misalnya KAMI akan menggunakan informasi tersebut untuk menyelidiki aktivitas yang mencurigakan atau mengancam).",
    PRIVACY_4_PARAGRAPH_12: "(d) Penelitian dan pengembangan",
    PRIVACY_4_PARAGRAPH_13: "KAMI akan menggunakan jenis informasi berikut untuk mengembangkan, menguji, dan meningkatkan Platform KAMI:",
    PRIVACY_4_PARAGRAPH_14_LINE_1: "Informasi survei dan umpan balik, serta konten apa pun yang disampaikan sehubungan dengan fitur Platform KAMI;",
    PRIVACY_4_PARAGRAPH_14_LINE_2: "Akun latar belakang, browser, informasi sistem dan perangkat; dan",
    PRIVACY_4_PARAGRAPH_14_LINE_3: "Informasi pelacakan web pihak ketiga.",
    PRIVACY_4_PARAGRAPH_15: "Bersama-sama jenis Informasi Pribadi ini digunakan untuk memberi kami gambaran umum tentang bagaimana Platform KAMI digunakan, segala kekurangan yang mungkin ada, dan selanjutnya untuk Menggaris bawahi cara yang terbaik untuk meningkatkan pengalaman bagi semua Pengguna.",
    PRIVACY_4_PARAGRAPH_16: "Preferensi KAMI adalah untuk tidak mengidentifikasi jenis informasi ini terlebih dahulu, dan kemudian menggunakannya untuk tujuan ini bersama dengan informasi browser dan perangkat yang tidak teridentifikasi (lihat bagian 6 di bawah untuk penjelasan tentang apa yang kami maksud dengan “tidak teridentifikasi”).",
    PRIVACY_4_PARAGRAPH_17: "(e) Pemasaran",
    PRIVACY_4_PARAGRAPH_18: "Jika Pengguna telah menyetujui secara tegas, KAMI akan menggunakan kontak dasar, pertanyaan, dan informasi akun untuk memberikan materi dan penawaran pemasaran yang relevan kepada Pengguna. Pengguna selalu dapat menyisih dari ini melalui fungsi yang disediakan di setiap komunikasi pemasaran (misalnya dengan mengeklik “berhenti berlangganan” di bagian bawah email).",
    PRIVACY_5_TITLE: "5. PENGUNGKAPAN INFORMASI PRIBADI DI CHARGED INDONESIA",
    PRIVACY_5_PARAGRAPH_1: "Umumnya, KAMI tidak mengungkapkan Informasi Pribadi kepada pihak ketiga mana pun kecuali:",
    PRIVACY_5_PARAGRAPH_1_LINE_1: "Penyedia layanan KAMI terlibat untuk membantu kami menyediakan dan mengembangkan Platform KAMI (misalnya penyedia layanan cloud atau konsultan);",
    PRIVACY_5_PARAGRAPH_1_LINE_2: "Di beberapa yurisdiksi tertentu, mitra pemasaran dan pengembangan produk membantu kami menyediakan penawaran lokal dari Platform Charged Indonesia; dan",
    PRIVACY_5_PARAGRAPH_1_LINE_3: "Lembaga penegak hukum, atau pihak lain yang memiliki hak hukum yang sah untuk mengakses informasi tersebut.",
    PRIVACY_5_PARAGRAPH_2: "Pengungkapan Luar Negeri",
    PRIVACY_5_PARAGRAPH_3: "Pengungkapan di atas hanya akan dilakukan dalam keadaan di mana penerima telah berjanji bahwa mereka akan menjaga kerahasiaan informasi dan bahwa mereka mengakui batasan yang sesuai yang ditempatkan pada penggunaan informasi. Pengungkapan juga akan selalu sesuai dengan Kebijakan Privasi ini.",
    PRIVACY_5_PARAGRAPH_4: "Beberapa pihak ketiga dari Charged Indonesia mengungkapkan Informasi Pribadi berada di luar negeri.",
    PRIVACY_5_PARAGRAPH_5: "Terkadang kami juga mengungkapkan Informasi Pribadi Pengguna kepada mitra pihak ketiga kami yang berlokasi di yurisdiksi tertentu seperti Indonesia. Biasanya, Informasi Pribadi yang diungkapkan dalam keadaan ini hanya akan berkaitan dengan Pengguna yang mengakses dan menggunakan Platform KAMI dari yurisdiksi terkait.",
    PRIVACY_5_PARAGRAPH_6: "Seperti halnya pengungkapan kepada penyedia layanan pihak ketiga, pengungkapan di luar negeri selalu dilakukan setelah KAMI mengambil semua langkah yang wajar untuk menentukan bahwa informasi tersebut akan diperlakukan setidaknya dengan baik berdasarkan Undang-Undang dan undang-undang privasi lain yang berlaku.",
    PRIVACY_6_TITLE: "6. PERLAKUAN DAN PENYIMPANAN INFORMASI DI CHARGED INDONESIA",
    PRIVACY_6_PARAGRAPH_1: "Pendekatan Umum Charged Indonesia",
    PRIVACY_6_PARAGRAPH_2: "KAMI akan menjaga kerahasiaan Informasi Pribadi Anda dan tidak menjual atau dengan sengaja membocorkan informasi Pengguna kepada pihak ketiga eksternal mana pun, kecuali:",
    PRIVACY_6_PARAGRAPH_2_LINE_1: "Kami yakini, dengan itikad baik, bahwa kami diwajibkan untuk membagikan Informasi Pribadi dengan pihak ketiga untuk mematuhi kewajiban hukum yang sah;",
    PRIVACY_6_PARAGRAPH_2_LINE_2: "Pengungkapan tersebut ditujukan kepada pemroses Informasi Pribadi pihak ketiga yang bertindak atas nama kami dan/atau berdasarkan instruksi kami untuk memungkinkan kami mengembangkan dan menyampaikan Platform KAMI (misalnya penyedia layanan cloud atau mitra pemasaran dan pengembangan lokal);",
    PRIVACY_6_PARAGRAPH_2_LINE_3: "Entitas lain memperoleh kepemilikan atau pengoperasian Charged Indonesia atau Platform Charged Indonesia; dan/atau",
    PRIVACY_6_PARAGRAPH_2_LINE_4: "Kami perlu melindungi keamanan Pengguna, dan keamanan Platform Charged Indonesia kami.",
    PRIVACY_6_PARAGRAPH_3: "KAMI mencari persetujuan yang diinformasikan dan sukarela dari individu setiap kali mengumpulkan informasi mereka, atau sesegera mungkin setelahnya.",
    PRIVACY_6_PARAGRAPH_4: "Pengguna selalu dapat menolak atau mencabut persetujuan ini, tetapi terkadang hal ini akan memengaruhi kemampuan KAMI untuk menyediakan Platform KAMI kepada mereka. KAMI akan memberitahu Pengguna jika hal ini terjadi.",
    PRIVACY_6_PARAGRAPH_5: "De-identifikasi",
    PRIVACY_6_PARAGRAPH_6: "Informasi yang tidak teridentifikasi mengacu pada informasi yang tidak dapat digunakan secara wajar untuk mengidentifikasi individu tertentu.",
    PRIVACY_6_PARAGRAPH_7: "Informasi yang tidak teridentifikasi yang tidak akan pernah dapat mengidentifikasi individu tertentu secara pribadi disebut sebagai informasi anonim (misalnya statistik bahwa 90% pengguna senang dengan Platform KAMI). Selain itu, informasi yang tidak teridentifikasi yang dapat mengidentifikasi individu hanya jika digabungkan dengan informasi lain yang terpisah disebut sebagai informasi dengan nama samaran (mis. nomor ID akun).",
    PRIVACY_6_PARAGRAPH_8: "Jika memungkinkan KAMI akan bertujuan untuk mengumpulkan, menyimpan, dan menggunakan informasi anonim sebagai pilihan pertama, dan jika tidak, maka informasi dengan nama samaran.",
    PRIVACY_6_PARAGRAPH_9: "Namun, kadang-kadang informasi Pengguna tidak praktis untuk dihilangkan identitasnya atau diperlakukan dengan cara ini, dan dalam hal ini, KAMI akan terus menggunakan dan menyimpan informasi tersebut dalam keadaan yang dapat diidentifikasi secara pribadi. Misalnya, jika KAMI perlu membalas pertanyaan Pengguna, kami harus menggunakan informasi kontak yang disediakan.",
    PRIVACY_6_PARAGRAPH_10: "Keamanan",
    PRIVACY_6_PARAGRAPH_11: "KAMI berkomitmen terhadap keamanan informasi. Kami akan menggunakan semua upaya yang wajar untuk menjaga Informasi Pribadi yang kami kumpulkan, simpan, dan gunakan dalam lingkungan yang aman. Untuk tujuan ini, kami telah menerapkan langkah-langkah keamanan teknis, organisasi, dan fisik yang dirancang untuk melindungi Informasi Pribadi dan untuk merespons dengan tepat jika pernah dilanggar (mis. KAMI telah mengembangkan Rencana Respons Pelanggaran Data ekstensif yang kami gunakan untuk menyiapkan dan merespons pelanggaran data).",
    PRIVACY_6_PARAGRAPH_12: "Ketika informasi yang dikumpulkan atau digunakan oleh KAMI disimpan di penyedia layanan pihak ketiga (misalnya server cloud Google atau AWS), KAMI mengambil langkah-langkah yang wajar untuk memastikan pihak ketiga ini menggunakan tindakan keamanan standar industri yang memenuhi tingkat keamanan informasi Charged Indonesia berkewajiban terhadap para Pengguna.",
    PRIVACY_6_PARAGRAPH_13: "Sebagai bagian dari kerangka privasi kami, kami berusaha untuk meninjau prosedur keamanan ini secara rutin dan mempertimbangkan kesesuaian teknologi dan metode baru.",
    PRIVACY_7_TITLE: "7. PENYIMPANAN INFORMASI OLEH CHARGED INDONESIA",
    PRIVACY_7_PARAGRAPH_1: "KAMI menyimpan Informasi Pribadi sampai tidak diperlukan lagi untuk menyediakan atau mengembangkan Platform KAMI, atau sampai individu yang terkait dengan Informasi Pribadi tersebut meminta kami untuk menghapusnya, mana saja yang lebih dulu. Diperlukan waktu hingga 30 hari untuk menghapus Informasi Pribadi dari sistem kami setelah permintaan penghapusan yang valid.",
    PRIVACY_7_PARAGRAPH_2: "Namun, KAMI akan menyimpan/mempertahankan:",
    PRIVACY_7_PARAGRAPH_2_LINE_1: "Informasi Pribadi dalam keadaan di mana kami memiliki kewajiban hukum dan peraturan untuk melakukannya (misalnya untuk tujuan penegakan hukum, undang-undang ketenagakerjaan, pencatatan perusahaan atau pajak, atau jika informasi tersebut relevan dengan proses hukum yang sah); dan",
    PRIVACY_7_PARAGRAPH_2_LINE_2: "Informasi anonim untuk tujuan analitik dan pengembangan layanan.",
    PRIVACY_7_PARAGRAPH_3: "Informasi yang kami simpan akan ditangani sesuai dengan Kebijakan Privasi ini.",
    PRIVACY_8_TITLE: "8. MENGELOLA INFORMASI PRIBADI ANDA",
    PRIVACY_8_PARAGRAPH_1: "Mengakses dan memastikan keakuratan Informasi Pribadi",
    PRIVACY_8_PARAGRAPH_2: "KAMI mengambil langkah-langkah yang wajar untuk memastikan bahwa Informasi Pribadi yang kami kumpulkan dan simpan akurat, terkini, dan lengkap. Para pengguna memiliki hak untuk mengakses dan meminta koreksi Informasi Pribadi apa pun yang kami miliki tentang mereka kapan saja. Permintaan semacam itu akan dilakukan dengan menghubungi kami secara langsung di perincian yang tercantum di bawah ini. KAMI akan memberikan akses sejauh yang diperlukan atau diizinkan oleh Undang-Undang dan undang-undang yang berlaku dan akan mengambil semua langkah yang wajar untuk mengoreksi Informasi Pribadi yang relevan jika perlu.",
    PRIVACY_8_PARAGRAPH_3: "Mungkin ada keadaan di mana KAMI tidak dapat memberi Pengguna akses ke informasi. Kami akan memberi tahu Anda tentang alasan-alasan ini jika memang demikian.",
    PRIVACY_9_TITLE: "9. MENGHUBUNGI CHARGED INDONESIA",
    PRIVACY_9_PARAGRAPH_1: "KAMI telah menunjuk Petugas Privasi untuk menjadi titik kontak pertama untuk semua masalah terkait privasi dan untuk membantu memastikan kepatuhan kami terhadap kewajiban privasi kami.",
    PRIVACY_9_PARAGRAPH_2: "Petugas Privasi",
    PRIVACY_9_PARAGRAPH_3: "Jika Anda memiliki pertanyaan atau ingin mengajukan keluhan tentang pelanggaran kebijakan ini atau Undang-Undang, Anda dapat menghubungi atau mengajukan keluhan kepada Petugas Privasi kami menggunakan perincian kontak di atas. Anda harus memberikan perincian yang memadai mengenai keluhan Anda serta bukti dan/atau informasi pendukung. Penawaran Privasi akan menanggapi permintaan atau keluhan Anda secepat mungkin. KAMI akan menghubungi Anda jika kami memerlukan informasi tambahan dari Anda dan akan memberi tahu Anda secara tertulis (termasuk komunikasi elektronik melalui email) tentang keputusan yang relevan.",

    // Terms of Use
    TERM_OF_USE_LABEL: "Syarat dan Ketentuan Charged Indonesia",

    // Manage Blogs
    MANAGE_BLOGS: "Manage Blogs",
    MANAGE_TEST_RIDES: "Manage Test Rides",
    BLOG_CREATED: "Blog Created",
    BLOG_UPDATED: "Blog Updated",
    MAX_FILES: "Maksimal 5 file untuk sekali upload",
    MAX_FILESIZE: "Ukuran maksimum file adalah 7 MB: ",
    RECORD_DELETED: "Item Dihapus",
    MAX_FILESIZE_F: "Maximum file size is 500 KB!",
    BLOG_SEE_MORE: "Load More >",
    BLOG_SIGN_UP: "Masuk / Daftar untuk mengirim komentar",

    // POSTER MENU
    POST_JOBS: "Posting Lowongan",
    MANAGE_POSTED_JOBS: "Atur Lowongan Kerja",
    COMPANY_ACCOUNT: "Akun Perusahaan",
    JOBS_POSTED: "Pekerjaan Diposting",
    JOBS_POSTED_TEXT: "Atur pekerjaan yang diposting oleh Anda",
    NEW_APPLICATIONS: "Aplikasi Baru",
    NEW_APPLICATIONS_TEXT: "Aplikasi yang perlu ditinjau",
    CANDIDATES_HIRED: "Kandidat Dipekerjakan",
    CANDIDATES_HIRED_TEXT: "Kandidat yang dipekerjakan oleh Anda",
    JOB_POST_CREATED: "Posting Pekerjaan Dibuat",
    JOB_POST_UPDATED: "Posting Pekerjaan Diperbarui",

    // Careers - Vacancies
    SEARCH_JOBS: "Cari lowongan...",
    SEARCH_RESULTS: "Hasil Pencarian",
    AVAILABLE_JOBS: "Lowongan Tersedia",
    REQUIREMENTS: "Persyaratan",
    POSTION_TYPE: "Jenis Posisi",
    EDUCATION_LEVEL: "Pendidikan",
    EXPERIENCE_LEVEL: "Pengalaman",
    MY_RESUME: "Resume Saya",
    USE_RESUME: "Berikan tautan ke resume Anda (Google Drive, Dropbox etc.)",
    MY_RESUME_VIDEO: "Introduction Video",
    USE_RESUME_VIDEO:
      "Record a short introduction video of yourself and provide a link. (Youtube, Google Drive, Dropbox etc)",
    ACTIVE_RESUME: "Resume Aktif",
    MOTIVATION: "Surat motivasi",
    MOTIVATION_A: "Beri tahu kami mengapa kami harus mempekerjakan Anda ...",

    // Apply
    APPLY_FOR: "Apply for",
    APPLY_JOBS: "Bagaimana Cara Melamar Pekerjaan?",
    JOB_DETAILS: "Detail Pekerjaan",
    ALREADY_APPLIED: "Anda telah melamar Pekerjaan ini",

    // Job Seeker
    ACCOUNT_REQ: "Anda harus mendaftar terlebih dahulu",
    ACCOUNT_REQ_TEXT:
      "Untuk melamar pekerjaan, silahkan masuk dahulu ke akun Anda. Jika Anda belum memiliki akun, silahkan daftar dengan cara klik link dibawah ini.",
    DO_LATER: "Lakukan ini nanti",
    JOB_APPLICATION_SUBMITTED: "Lamaran Pekerjaan Diserahkan",

    // SEEKER MENU
    MY_APPLICATIONS: "Aplikasi Saya",
    MY_APPLICATIONS_U: "APLIKASI SAYA",
    MY_APPLICATIONS_TEXT: "Periksa pembaruan apa pun",
    VERIFICATION: "VERIFIKASI",
    MY_PROFILE: "Profil Saya",
    COMPLETE_PROFILE: "Silakan Lengkapi Profilmu",
    NEW_JOBS: "PEKERJAAN BARU",
    NEW_JOBS_TEXT: "Temukan pekerjaan baru untuk Anda",

    // Contact Us
    CONTACT_US: "Kontak Kami",
    CONTACT_US_DROP: "Kirimi kami pesan.",

    // FAQ
    FAQ_WELCOME_TITLE: "Halo!",
    FAQ_WELCOME_DESCRIPTION: "Apa yang bisa kami bantu?",
    FAQ_TITLE: "Pertanyaan yang Sering Ditanyakan",
    FAQ_LOCATIONS_ESHED: "Lokasi E-Shed",
    FAQ_LOCATIONS_GSHED: "Lokasi Giga-Shed",
    FAQ_MAPS: "Tunjukkan di Maps",
    FAQ_OPENING_TITLE: "JAM OPERASIONAL",
    FAQ_OPENING_TIME: "Senin - Sabtu : 09.00 - 17:00 (WIB)",
    FAQ_CLOSE_TIME: "Minggu : Tutup",
    FAQ_TOP: "Pertanyaan Favorit",
    FAQ_SUBCRIPTION: "Berlangganan",
    TOP: {
      QUESTION: [
        "Siapa itu Charged?",
        "Siapa yang mengembangkan motor Charged?",
        "Berapa banyak produk yang dimiliki Charged?",
        "Bagaimana cara berlangganan motor listrik Charged?",
        "Dokumen apa saja yang perlu saya serahkan untuk berlangganan pertama kali? Lalu apakah data saya aman?",
        "Berapa biaya langganan motor listrik Charged?",
        "Bagaimana cara bayar biaya langganan per bulannya?",
        "Apakah ada biaya perawatan yang harus saya bayar selama berlangganan?",
        "Kapan saya harus servis motor listrik Charged?",
        "Apa yang harus saya lakukan jika motor mengalami kecelakaan?"
      ],
      ANSWER: [
        "Kami merupakan perusahaan teknologi dengan misi memimpin mobilitas yang berkelanjutan di Indonesia. Untuk mewujudkan misi tersebut, kami menghadirkan layanan kendaraan yang ramah lingkungan, terjangkau, mampu mengurangi polusi udara dan polusi suara. Kami juga turut berpartisipasi dalam mendukung kebijakan pemerintah Indonesia yang ingin meningkatkan penggunaan sumber energi terbarukan.",
        "Charged Indonesia merupakan partner resmi Vmoto Soco Group, supplier OEM untuk motor listrik kami.",
        "Saat ini kami memiliki tiga model motor listrik untuk berbagai kebutuhan pengguna yaitu Charged Anoa, Charged Rimau, dan Charged Maleo. Seluruh model dikembangkan dengan kualitas tinggi dan teknologi canggih yang didukung oleh VMoto Soco demi kenyamananmu berkendara.",
        "Seluruh proses berlangganan dapat dilakukan online melalui website charged.co.id. ",
        "Ya, kamu perlu mengisi data pribadi seperti nama, email, nomor HP, alamat, KTP, kartu keluarga, NPWP melalui website kami pada proses pendaftaran. Tidak perlu khawatir, datamu aman bersama Charged.",
        "Biaya langganan motor listrik Charged per bulan adalah Rp1,650,000.",
        "Untuk pembayaran biaya langganan per bulan, kami akan mengirimkan tagihan setiap tanggal 10 beserta dengan tautan untuk pembayaran.",
        "Inilah salah satu keunggulan yang bisa kamu nikmati dengan mekanisme berlangganan di Charged. Kamu tidak perlu membayar biaya perawatan.",
        "Lakukan servis rutin ke E-Shed Kemang setiap 3 bulan sekali untuk menjaga performa motor dalam keadaan terbaik.",
        "Segera hubungi polisi dan tim medis untuk mendapatkan bantuan. Jangan lupa untuk mengumpulkan informasi atau bukti seakurat dan selengkap mungkin di lokasi kejadian untuk dilaporkan ke agen atau perusahaan asuransimu. Setelah itu hubungi agen kami untuk melaporkan kecelakaan dan menyediakan data yang diperlukan."
      ]
    },
    AFTERSALES: {
      QUESTION: [
        "Kapan saya harus servis motor listrik Charged?",
        "Bagaimana cara booking jadwal servis?",
        "Berapa lama waktu yang diperlukan untuk servis?",
        "Apa yang harus saya lakukan jika motor mengalami kecelakaan?",
        "Apa yang harus saya lakukan jika kehabisan daya baterai di jalan?",
        "Apakah saya bisa meminjamkan motor Charged ke teman saya?",
        "Apa yang harus saya lakukan jika terjadi kerusakan?",
        "Apakah Charged memiliki pusat pengisian daya atau penukaran baterai?",
        "Apakah saya boleh memodifikasi motor Charged?",
        "Apakah saya bisa memasang aksesoris pada motor?",
        "Apakah saya butuh charger khusus atau port pengisian daya khusus untuk motor Charged?",
        "Berapa lama waktu yang dibutuhkan untuk pengisian daya baterai? Apakah saya bisa mengecas baterai motor Charged di rumah?",
        "Apa yang harus saya lakukan jika baterai motor habis di tengah jalan?",
        "Jabodetabek rawan banjir, bagaimana jika saya harus berhadapan dengan banjir?",
        "Bagaimana mengenai legalitas motor Charged di jalanan? Saya tidak ingin kena masalah dengan polisi",
        "Kapan saya harus servis motor listrik Charged?",
        "Bagaimana cara booking jadwal servis?",
        "Berapa lama waktu yang dibutuhkan untuk servis?",
        "Apa yang harus saya lakukan jika motor mengalami kecelakaan?",
        "Apa yang harus saya lakukan jika baterai motor habis di tengah jalan?",
        "Apa yang harus saya lakukan jika terjadi kerusakan?",
        "Apakah Charged memiliki pusat pengisian daya atau penukaran baterai?"
      ],
      ANSWER: [
        "Kamu harus bawa motor listrik ke E-Shed Kemang untuk pengecekan berkala setiap 3 bulan sekali.",
        "Kamu bisa menghubungi agen kami via WhatsApp untuk booking jadwal servis di E-Shed.",
        "Tergantung dari masalah serta kesulitan, waktu servis bisa sangat bervariasi mulai 45 menit hingga satu hari. Namun pada umumnya, waktu servis rata-rata sekitar 2 jam.",
        "Segera hubungi polisi dan tim medis untuk mendapatkan bantuan. Jangan lupa untuk mengumpulkan informasi atau bukti seakurat dan selengkap mungkin di lokasi kejadian untuk dilaporkan ke agen atau perusahaan asuransimu. Setelah itu hubungi agen kami untuk melaporkan kecelakaan dan menyediakan data yang diperlukan.",
        "Silakan hubungi agen kami via WhatsApp untuk mendapatkan bantuan darurat di jalan.",
        "Tidak. Kamu tidak diperkenankan untuk memindahtangankan motor Charged ke orang lain atau pihak ketiga. Pihak yang mendaftar langganan merupakan pihak yang bertanggungjawab terhadap motor tersebut, termasuk dalam urusan mengambil dan mengembalikan kendaraan ke E-Shed.",
        "Jika terjadi kerusakan pada motor akibat kelalaian pengguna, silakan hubungi agen kami via WhatsApp. Kami akan menginformasikan apakah bagian yang rusak perlu segera diganti dan bagaimana proses selanjutnya. Perlu dicatat, jika ada biaya perbaikan atau penggantian mungkin akan dipotong dari depositmu.",
        "Saat ini pusat penukaran baterai kami diperuntukkan untuk pelanggan B2B. Tidak perlu khawatir, kapasitas baterai motor Charged dalam kondisi terisi penuh cukup untuk menemanimu di perjalanan tanpa harus repot mengganti baterai.",
        "Motor harus dikembalikan dalam kondisi seperti semula. Modifikasi yang bersifat permanen baik dari segi tampilan maupun kinerja motor tidak diperkenankan.",
        "Boleh.",
        "Charger kami dirancang khusus untuk digunakan hanya pada motor Charged. Kamu bisa melakukan pengisian daya dari soket listrik reguler di rumah, kantor atau tempat umum lainnya.",
        "Iya, kamu bisa mengecas baterai motor Charged di rumah. Charger reguler dapat mengisi daya baterai dari 10% ke 100% dalam 5 jam, sementara fast charger dapat mengisi daya baterai dari 10% ke 100% dalam 3 jam.",
        "Silakan hubungi pusat bantuan darurat 24 jam kami.",
        "Dalam kondisi yang tidak dapat dihindari, teknologi motor kami dapat diandalkan untuk membantumu melalui banjir dengan ketinggian hingga 30 cm. Namun kami mengimbau sebisa mungkin hindari jalanan banjir untuk mencegah kerusakan. Jika ada masalah, kamu bisa menghubungi agen kami.",
        "Seluruh motor Charged Indonesia sudah memiliki izin untuk beredar di jalan. Kami juga akan meminjamkan STNK kepadamu selama periode berlangganan.",
        "Lakukan servis rutin ke E-Shed Kemang setiap 3 bulan sekali untuk menjaga performa motor dalam keadaan terbaik.",
        "Silakan hubungi agen kami via WhatsApp untuk mendapatkan jadwal servis di E-Shed Kemang.",
        "Servis butuh waktu kurang lebih 2 jam untuk melakukan diagnosis menyeluruh dan memastikan motormu dalam kondisi prima. Kondisi perbaikan yang lebih sulit mungkin butuh waktu lebih lama.",
        "Segera hubungi polisi dan tim medis untuk mendapatkan bantuan. Jangan lupa untuk mengumpulkan informasi atau bukti seakurat dan selengkap mungkin di lokasi kejadian untuk dilaporkan ke agen atau perusahaan asuransimu. Setelah itu hubungi agen kami untuk melaporkan kecelakaan dan menyediakan data yang diperlukan.",
        "Silakan hubungi pusat bantuan darurat 24 jam kami.",
        "Jika terjadi kerusakan pada motor akibat kelalaian pengguna, silakan hubungi agen kami. Kami akan menginformasikan apakah bagian yang rusak perlu segera diganti dan bagaimana proses selanjutnya. Perlu dicatat, jika ada biaya perbaikan atau penggantian mungkin akan dipotong dari depositmu.",
        "Saat ini pusat penukaran baterai kami diperuntukkan untuk pelanggan B2B. Tidak perlu khawatir, kapasitas baterai motor Charged dalam kondisi terisi penuh cukup untuk menemanimu di perjalanan tanpa harus repot mengganti baterai."]
    },
    PRODUCT: {
      QUESTION: [
        "Berapa perbandingan biaya pengeluaran untuk motor listrik dengan motor bensin?",
        "Siapa yang mengembangkan motor Charged?",
        "Apakah motor Charged lebih cepat dibandingkan motor bensin?",
        "Kapan Charged launching? Apakah saya bisa diundang ke acara Charged?",
        "Siapa itu Charged?",
        "Bagaimana caranya jika saya ingin tahu lebih banyak mengenai produk Charged?",
        "Apakah saya bisa berkunjung ke pabrik Charged?",
        "Bagaimana performa motor listrik Charged di jalan raya?",
        "Berapa kapasitas baterai motor listrik Charged?",
        "Saya ingin test ride motor listrik Charged, bagaimana caranya?",
        "Apakah motor listrik Charged bisa melalui banjir?",
        "Apakah saya bisa menggunakan mesin semprot air tekanan tinggi untuk mencuci motor?",
        "Di mana saya bisa melihat panduan manual untuk motor Charged?",
        "Seberapa jauh saya bisa menempuh perjalanan dengan baterai penuh?",
        "Siapa yang membuat motor listrik Charged?",
        "Berapa lama waktu yang dibutuhkan untuk mengisi daya baterai?",
        "Apakah saya harus menggunakan charger atau port khusus untuk mengecas baterai?",
        "Bagaimana cara mengecas baterai motor listrik Charged?",
        "Berapa banyak produk yang dimiliki Charged?",
      ],
      ANSWER: [
        "Biaya operasional motor listrik hanya 20-30% dari motor berbahan bakar minyak (bensin). Pemerintah Indonesia juga banyak mengeluarkan insentif untuk mendukung percepatan transformasi ke kendaraan listrik. Bersama Charged, kamu bisa ikut dalam transformasi ini lebih cepat dan mudah. Kamu tidak perlu mengeluarkan banyak biaya untuk membeli motor listrik baru karena motor listrik Charged hadir dengan sistem berlangganan yang lebih terjangkau.",
        "Charged Indonesia merupakan partner resmi Vmoto Soco Group, supplier OEM untuk motor listrik kami.",
        "Motor listrik Charged memiliki throttle yang responsif dan dirancang untuk menghasilkan kecepatan serta performa yang bertenaga. Seperti model Charged Rimau misalnya memiliki kecepatan maksimum 95 km/jam, atau Charged Anoa yang memiliki kecepatan maksimum 90 km/jam. Keduanya memiliki kemampuan yang dapat bersaing dengan motor bensin. Namun kami mengimbau bagi pengendara untuk selalu mengutamakan keselamatan dan menggunakan gaya berkendara yang aman.",
        "Kamu bisa mendapatkan info tentang berbagai event dan promo terbaru melalui media sosial kami. Silakan follow @charged.indonesia di Instagram dan Tiktok, FB Charged Indonesia atau @charged_id di Twitter supaya tidak ketinggalan infonya.",
        "Kami merupakan perusahaan teknologi dengan misi memimpin mobilitas yang berkelanjutan di Indonesia. Untuk mewujudkan misi tersebut, kami menghadirkan layanan kendaraan yang ramah lingkungan, terjangkau, mampu mengurangi polusi udara dan polusi suara. Kami juga turut berpartisipasi dalam mendukung kebijakan pemerintah Indonesia yang ingin meningkatkan penggunaan sumber energi terbarukan.",
        "Kamu bisa menemukan informasi lengkap mengenai produk motor listrik Charged di website charged.co.id ",
        "Untuk program kunjungan ke pabrik, silakan ajukan permintaan resmi melalui email ke contact@charged.asia",
        "Charged Anoa merupakan motor listrik heavy duty yang cocok untuk kebutuhan komersial dengan daya angkut beban maksimum 150 kg (tidak termasuk pengendara). Charged Rimau merupakan motor listrik yang cocok untuk pengendara urban, menawarkan performa bertenaga, kecepatan tinggi, jok yang nyaman dan ruang kaki yang luas. Sementara Charged Maleo merupakan motor urban yang ramping dan lincah, cocok untuk menjelajah seluruh isi kota.",
        "Charged Anoa dan Charged Rimau dilengkapi dengan dua slot baterai litium. Dalam kondisi terisi penuh, dua baterai dapat digunakan untuk menempuh jarak 200 km dengan pola berkendara efisien. Sementara Charged Maleo memiliki satu slot baterai dan dalam kondisi terisi penuh mampu menempuh jarak 125 km dengan pola berkendara efisien.",
        "Silakan datang ke E-Shed Kemang atau E-Shed Cikupa untuk test ride.",
        "Ya, motor listrik Charged aman untuk melalui genangan air hingga ketinggian tertentu. Namun kami menyarankan sebaiknya hindari banjir yang terlalu tinggi.",
        "Ya, namun hindari menyemprot area di dekat powertrain dan di bawah jok.",
        "Kamu bisa cek di website charged.co.id, lalu pilih My Account / Akun Saya.",
        "100 km per baterai untuk Charged Rimau dan Charged Anoa; 125 km untuk Charged Maleo. Seluruhnya dengan kecepatan maksimum 30 km/jam atau Eco Mode",
        "Kami merupakan partner resmi dari Vmoto Soco, supplier OEM motor-motor kami.",
        "Charger reguler dapat mengecas baterai dalam 5 jam (dari 10% ke 100%), sementara fast charger bisa mengecas baterai dalam 3 jam (dari 10% ke 100%).",
        "Charger kami diperuntukkan khusus untuk motor listrik Charged. Untuk pengecasan, kamu bisa melakukannya di soket listrik biasa yang ada di rumah.",
        "Gunakan charger yang kami sediakan. Kamu bisa mengecas langsung ke motor atau keluarkan baterai terlebih dulu dan cas langsung ke baterai.",
        "Saat ini kami memiliki tiga model motor listrik untuk berbagai kebutuhan pengguna yaitu Charged Anoa, Charged Rimau, dan Charged Maleo. Seluruh model dikembangkan dengan kualitas tinggi dan teknologi canggih yang didukung oleh VMoto Soco demi kenyamananmu berkendara.",
      ],
    },
    SUBSCRIPTION: {
      QUESTION: [
        "Bagaimana cara berlangganan motor listrik Charged?",
        "Apakah saya bisa mengakhiri periode langganan sebelum waktunya?",
        "Apa yang terjadi jika terjadi gagal pembayaran?",
        "Apakah saya bisa berlangganan lebih dari 1 baterai?",
        "Dokumen apa saja yang perlu saya serahkan untuk berlangganan pertama kali? Lalu apakah data saya aman?",
        "Berapa banyak yang bisa saya hemat dengan beralih ke motor listrik?",
        "Apakah motor yang saya terima dalam kondisi baru atau bekas?",
        "Apakah biaya berlangganannya masih bisa dinego?",
        "Bagaimana dengan garansi dan asuransi?",
        "Saya tinggal di luar Jakarta, apakah bisa berlangganan motor Charged?",
        "Apakah biaya langganan sudah termasuk asuransi?",
        "Apa bedanya sewa dengan langganan?",
        "Saya ingin memiliki motor Charged. Apakah bisa?",
        "Apa prosedur yang harus saya lakukan untuk berlangganan motor listrik Charged?",
        "Apakah kita harus menggunakan surat izin khusus untuk mengendarai motor listrik?",
        "Apakah saya bisa menggunakan motor listrik Charged untuk bisnis atau komersial? Misalnya saya gunakan untuk mendaftar sebagai kurir atau ojek online?",
        "Berapa biaya langganan motor listrik Charged?",
        "Bagaimana cara bayar biaya langganan per bulannya?",
        "Apa yang harus saya lakukan saat periode langganan berakhir?",
        "Apakah ada biaya perawatan yang harus saya bayar selama berlangganan?",
        "Saya mau ganti model dari Charged Rimau ke Maleo atau Anoa. Apakah bisa?",
      ],
      ANSWER: [
        "Seluruh proses berlangganan dapat dilakukan online melalui website charged.co.id. ",
        "Silakan hubungi agen kami",
        "Kami akan memberikan waktu 14 hari bagimu untuk melunasi pembayaran. Jika tidak ada pembayaran hingga hari ke-14, dengan sangat maaf kami harus mengambil kembali motor yang kamu gunakan.",
        "Ya. Kamu bisa pilih opsi ekstra baterai di halaman Subscription. Kamu juga bisa menghubungi agen kami.",
        "Ya, kamu perlu mengisi data pribadi seperti nama, email, nomor HP, alamat, KTP, kartu keluarga, NPWP melalui website kami pada proses pendaftaran. Tidak perlu khawatir, datamu aman bersama Charged.",
        "Tergantung dari penggunaan, kamu bisa menghemat hingga Rp 1 juta untuk biaya operasional dan servis.",
        "Motor yang kamu terima bisa dalam kondisi baru atau sudah memiliki angka kilometer tertentu. Tapi tidak perlu khawatir, seluruh motor telah diperiksa secara menyeluruh sebelum diserahterimakan ke kamu.",
        "Seluruh harga kami tidak dapat dinego. Kamu bisa memilih periode berlangganan lebih panjang untuk lebih berhemat.",
        "Seluruh motor Charged telah diasuransikan dan termasuk dalam biaya berlanggananmu.",
        "Saat ini layanan kami mencakup area Jabodetabek. Kamu bisa mengecek ketersediaan lokasi dan layanan kami di website charged.co.id.",
        "Ya, namun asuransi hanya untuk motor.",
        "Langganan menawarkan fleksibilitas dan keleluasaan selama periode berlangganan. Kamu bisa mengubah model motor yang ingin digunakan (setelah periode tertentu), tidak perlu khawatir mengenai biaya servis, asuransi, penurunan nilai jual kembali, dan sebagainya. Sementara pada sistem leasing atau sewa, kamu hanya bisa menggunakan satu jenis motor hingga periode sewa berakhir.",
        "Ya, kamu bisa memiliki motor Charged setelah berlangganan 3 tahun berturut-turut dengan syarat dan ketentuan tertentu.",
        "Daftarkan dirimu melalui website charged.co.id, lakukan pembayaran booking fee, dan tim kami akan melakukan analisa. Setelah verifikasi data selesai, tim kami akan menghubungimu untuk proses selanjutnya.",
        "Tidak, kamu bisa menggunakan SIM C.",
        "Ya, bisa. Tapi sangat disarankan untuk menggunakan motor yang pas untuk armada logistik. Kamu dapat menggunakan Charged Anoa jika kamu berencana menggunakan motor sebagai armada logistik.",
        "Biaya langganan motor listrik Charged per bulan adalah Rp1,650,000.",
        "Untuk pembayaran biaya langganan per bulan, kami akan mengirimkan tagihan setiap tanggal 10 beserta dengan tautan untuk pembayaran.",
        "Kamu bisa memperbarui langgananmu via web atau melalui agen kami. Kamu juga bisa mengantar motor ke E-Shed kami di Kemang atau Cikupa dan menghubungi agen kami sebelum periode berlangganan berakhir.",
        "Inilah salah satu keunggulan yang bisa kamu nikmati dengan mekanisme berlangganan di Charged. Kamu tidak perlu membayar biaya perawatan.",
        "Ya, kamu bisa mengganti model motor yang digunakan setelah 3 bulan.",
      ]
    },

    // Settings
    PRO_CH_PL: "Silahkan buat password Anda",
    SET_UPDATED: "Pengaturan Disimpan",
    PWD_CHANGED: "Kata sandi telah diubah",

    // Buttons
    BUT_APPLY_NOW: "Ajukan Sekarang",
    BUT_RECENT: "RECENT",
    BUT_NEXT: "Lanjut",
    BUT_CONTINUE: "Lanjutkan ke akun Anda",
    BUT_CONT_DRAFT: "Lanjut Menulis",
    BUT_SIGN_IN: "Masuk",
    BUT_SIGN_UP: "Daftar",
    BUT_RESET_PW: "Reset Password",
    BUT_CANCEL: "Cancel",
    BUT_BACK: "Kembali",
    BUT_BACK_TO_SIGN_IN: "Kembali ke Masuk",
    BUT_VERIFY: "Verifikasi",
    BUT_LATER: "I'll do this later",
    BUT_CHANGE_EMAIL: "Ubah alamat email Anda",
    BUT_CHANGE: "Ubah",
    BUT_DONE: "Selesai",
    BUT_OK: "OK",
    BUT_SAVE_DRAFT: "Simpan sebagai draft",
    BUT_SUBMIT: "Submit",
    BUT_CREATE_MORE: "Buat Pekerjaan Lain",
    BUT_SUBM_PHOTO: "Submit Foto",
    BUT_VIEW_ALL: "Lihat Detail",
    BUT_ALL_NEWS: "Lihat Semua Berita",
    BUT_ALL_VIDEOS: "Lihat Semua Video",
    BUT_ALL_FF: "Lihat Semua Fan Forum",
    BUT_VIEW_GRID: "Lihat Grid",
    BUT_CONFIRM: "Konfirmasi",
    BUT_CLOSE: "Tutup",
    BUT_CHECK: "Cek Status",
    BUT_CHECK_PHOTO: "Cek Status Penerbitan",
    BUT_LOAD_MORE: "Muat lebih banyak...",
    BUT_COMMON_CONTINUE: "Lanjutkan",
    BUT_COMPLETE: "Selesaikan",
    BUT_SUBMIT_DOCUMENT: "UPLOAD DOKUMEN",
    BUT_INTERESTED: "Saya tertarik produk ini",

    //HOVER
    HOVER_TC: "Buka syarat dan ketentuan!",

    //Validation
    VALIDATION_NAME_REQ: "Nama wajib diisi",
    VALIDATION_EMAIL_REQ: "Email wajib diisi",
    VALIDATION_PHONE_REQ: "Nomor Handphone wajib diisi",
    VALIDATION_EMAIL_VALID: "Email harus valid",
    VALIDATION_TC_AGREE: "Anda harus menyetujui syarat dan kondisi yang ada",
    VALIDATION_FORM_VALID: "Form yang anda isi belum valid.",

    //Common Labels
    LABEL_FULL_NAME: "Nama Lengkap",
    LABEL_FULL_NAME_KTP: "Nama Lengkap (sama dengan KTP) *",
    LABEL_FIRST_NAME: "Nama Depan",
    LABEL_LAST_NAME: "Nama Belakang",
    LABEL_EMAIL: "Email",
    LABEL_PHONE: "Nomor Handphone",
    LABEL_DATE: "Tanggal",
    LABEL_TIME: "Waktu",
    LABEL_AGREE: "Saya setuju dengan ",
    LABEL_TC: "Syarat dan Ketentuan",
    LABEL_FROM_CA: " dari Charged Asia",
    LABEL_TAX: "Pajak",
    LABEL_INSURANCE: "Asuransi",
    LABEL_MO: "bulan",
    LABEL_DAY: "hari",
    LABEL_CHARGER: "Tipe Charger",
    LABEL_BATTERY: "Jumlah Baterai",
    LABEL_REQUIRED: "*Wajib diisi",
    LABEL_RESIDENCE: "Alamat Domisili",
    LABEL_OPENING_TITLE: "Jam Operasional",
    LABEL_OPENING_TIME: "Senin - Sabtu : 09.00 - 17:00 (WIB)",
    LABEL_CLOSING_TIME: "Minggu : Tutup",
    LABEL_DATE_PAYMENT: "Tanggal Pembayaran",
    LABEL_SECONDS: "detik",
    LABEL_COLOR: "Warna",
    LABEL_NEED_HELP: "Butuh Bantuan",
    LABEL_CHAT_WITH_US: "Hubungi Kami",
    LABEL_SUBUSCRIPTION_PLAN: "Rencana Langganan",
    LABEL_DATE_ORDER: "Tanggal Pembayaran",
    LABEL_EFFECTIVE_AS_OF: "Berlaku mulai",
    LABEL_ABOUT: "Tentang",
    LABEL_AND: "dan",
    LABEL_INCLUDE: "Termasuk",
    LABEL_CUSTOMER_TYPE: "Tipe Customer",

    //Common Placeholder
    PLACEHOLDER_NAME: "Nama Anda",
    PLACEHOLDER_EMAIL: "Alamat Email Anda",
    PLACEHOLDER_PHONE: "Nomor Handpone Anda",

    //Test Drive
    TEST_DRIVE_TITLE: "Rasakan Masa Depan dari Mobilitas",
    TEST_DRIVE_SAVED: "Data test ride (uji jalan) berhasil disimpan.",
    TEST_DRIVE_CANCEL: "Data test ride (uji jalan) berhasil dibatalkan.",
    TEST_DRIVE_RESCHEDULE:
      "Data test ride (uji jalan) berhasil dijadwal ulang.",
    TEST_DRIVE_PERSONAL_DATA: "Informasi Pribadi",
    TEST_DRIVE_SCHEDULE_DATA: "Jadwal Pertemuan",
    TEST_DRIVE_REVIEW_DATA: "Ulasan Data",
    TEST_DRIVE_CONTACT_DESCRIPTION: "Tim kami mungkin akan menghubungimu jika perlu konfirmasi lebih lanjut.",
    TEST_DRIVE_ADDRESS_TITLE: "Alamat Domisili",
    TEST_DRIVE_ADDRESS_DESCRIPTION: "Alamat ini juga akan berlaku sebagai alamat pengiriman.",
    TEST_DRIVE_CITY: "Kota *",
    TEST_DRIVE_PROVINCE: "Provinsi *",
    TEST_DRIVE_STATE: "Kelurahan *",
    TEST_DRIVE_DISCTRICT: "Kecamatan *",
    TEST_DRIVE_SUB_DISCTRICT: "Kelurahan *",
    TEST_DRIVE_ADDRESS: "Alamat *",
    TEST_DRIVE_ZIP_CODE: "Kode Pos *",
    TEST_DRIVE_SHIPPING: "Opsi Pengambilan",
    TEST_DRIVE_KEMANG: "Diambil di E-Shed Kemang",
    TEST_DRIVE_CIKUPA: "Diambil di E-Shed Cikupa",
    TEST_DRIVE_HOME: "Diantarkan ke alamat domisilimu",
    TEST_DRIVE_STEP_1: "Pilih Tipe Motor",
    TEST_DRIVE_STEP_1_TITLE: "Pemesanan ini hanya berlaku untuk 1 orang. Apabila kamu membawa teman, mintalah mereka untuk membuat pemesanan terpisah.",
    TEST_DRIVE_STEP_1_DESCRIPTION_P1: "Pemesanan ini hanya berlaku untuk 1 orang test rider. Jika Anda memiliki teman yang akan ikut, mohon minta mereka untuk melakukan pemesanan terpisah.",
    TEST_DRIVE_STEP_1_DESCRIPTION_P2: "Silakan pilih tipe motor yang ingin kamu coba. Tidak perlu khawatir, kamu bisa coba semua tipe motor yang tersedia.",
    TEST_DRIVE_STEP_2: "Pilih Jadwal Test Ride",
    TEST_DRIVE_STEP_2_TITLE: "This booking only applies for 1 test rider. If you have friends coming, please ask them to make a separate booking.",
    TEST_DRIVE_STEP_2_DESCRIPTION: "Butuh bantuan? Chat atau telepon kami di ",
    TEST_DRIVE_STEP_3: "Isi Data Diri",
    TEST_DRIVE_STEP_3_TITLE: "This booking only applies for 1 test rider. If you have friends coming, please ask them to make a separate booking.",
    TEST_DRIVE_STEP_3_DESCRIPTION: "Butuh bantuan? Chat atau telepon kami di ",
    TEST_DRIVE_LOCATION_TITLE: "Lokasi Test Ride *",
    TEST_DRIVE_MAPS: "Lihat Peta",
    TEST_DRIVE_DATE_TITLE: "Pilih Tanggal *",
    TEST_DRIVE_DATE_PLACEHOLDER: "Pilih Tanggal Test Ride",
    TEST_DRIVE_DATE_SUBMIT: "Simpan Tanggal",
    TEST_DRIVE_TIME: "Pilih Waktu *",
    TEST_DRIVE_TIME_PLACEHOLDER: "Pilih Waktu Test Ride",
    TEST_DRIVE_TERMS: "Saya setuju dengan syarat dan ketentuan di ",
    TEST_DRIVE_POLICY: "Kebijakan Ganti Rugi Charged.",
    TEST_DRIVE_EXPLORE: "Lihat Koleksi Lainnya",

    //About
    ABOUT_TITLE: "Ubah Kebiasaan <br />Bekendara Anda",
    ABOUT_TITLE_MOBILE: "Ubah Kebiasaan <br />Bekendara Anda",
    ABOUT_DESCRIPTION_1:
      "Misi dan tujuan kami di Charged Asia adalah memproduksi 10 juta kendaraan listrik dalam jangka waktu 10 tahun.<br /><br /> Kami berinovasi untuk masa depan produksi sepeda motor listrik, dan mengedepankan berkendara secara massal serta berkelanjutan untuk seluruh wilayah di Indonesia.<br /><br /> Charged Asia merupakan Perusahaan yang mengutamakan keseimbangan antara tehnologi dan tujuan masa depan. Perusahaan kami mengedepankan keunggulan produksi serta memenuhi standar tertinggi dalam lingkup kinerja sosial, lingkungan yang terverifikasi, transparansi publik, dan akuntabilitas hukum.<br /><br /> Kami mengutamakan misi kami untuk memimpin penerapan tehnologi masa depan yang berkelanjutan, keuntungan finansial bagi pengguna dan pertumbuhan ekonomi yang setara untuk kesejahteraan semua kalangan, termasuk karyawan, pelanggan, mitra usaha, pemasok, masyarakat dan kepedulian lingkungan.<br /><br /> Kami menyambut baik semua kalangan yang berbagi visi kami untuk bergabung dalam tujuan yang membawa perubahan ini.",
    ABOUT_CAROUSEL_TITLE: "Misi Kami di Charged Asia",
    ABOUT_CAROUSEL_CONTENT: [
      {
        title: "Sepuluh Juta Kendaraan Dalam Sepuluh Tahun",
        content:
          "At Charged, We're on a mission to increase electric vehicle adoption by making EVs more affordable and accessible. We believe electric is the future of transportation. we believe that electric vehicles will be a game changer. They will be common vehicle in the next ten years, therefore this is our mission to make 10 million electric vehicles in 10 years.",
      },
      {
        title: "Charged Pengubah Permainan",
        content:
          "Our Mission is to be the system that moves people. Combining the beauty of design, the intelligence of software, and the kinetic energy created from perfectly balanced wheels. We’re creating a world where cities breathe easier and noise pollution is a thing of the past. Where driving is something everyone can enjoy. A world that recharges everyone it touches.",
      },
      {
        title: "Manfaat Untuk Lingkungan",
        content:
          "The vehicle that changed everything is back and better than ever. All-electric. No gas. And zero emissions. The key to getting the maximum benefit from your energy-efficient vehicle is ensuring that its power systems are running at peak efficiency.",
      },
    ],
    //Press Launch State
    PL_DESCRIPTION_TITLE:
      "Charged Indonesia hadir untuk perubahan yang lebih baik",
    PL_DESCRIPTION_TEXT:
      "Kami hadir sebagai perusahaan scale-up (perusahaan dengan pertumbuhan tinggi) yang berkecimpung dalam bidang produksi dan distribusi sepeda motor listrik. Kami menghadirkan pilihan mobilitas berkelanjutan yang kompetitif dalam kepraktisan dan terjangkau bagi konsumen Indonesia dibandingkan dengan sepeda motor berbahan bakar minyak yang selama ini kita kenal.<br /><br /> Sebagai brand, kami bertujuan untuk mempercepat adopsi kendaraan listrik di Indonesia untuk mengurangi polusi udara dan meningkatkan pengalaman berkendara masyarakat Indonesia.Sebagai perusahaan kami berusaha untuk menjalankan prinsip dan kriteria dari  B-Corp movement serta memenuhi standar tertinggi dalam lingkup kinerja sosial dan lingkungan, transparansi publik, dan akuntabilitas hukum untuk menyeimbangkan keuntungan dan tujuan. <br /><br /> Saat ini kami sedang dalam proses meluncurkan sebuah komplek industri Zero energy (sumber energy mandiri yang menggunakan sumber daya energy berkelanjutan) seluas 16.000-meter persegi di Jabodetabek, sebagai lokasi untuk pusat penelitian dan pengembangan , experiential center, serta  sebagai pusat produksi.",
    PL_COMMITMENT_TITLE: "Kami Percaya bahwa,",
    PL_COMMITMENT_TEXT:
      "Setiap kontribusi Individu, ide, dan konsistensi adalah penting untuk meningkatkan pelayanan yang lebih baik. Tim kami berdedikasi untuk terus mengembangkan solusi dan pengalaman baru sehingga anda dapat menikmati pengalaman berkendara dengan lebih nyaman.<br /><br /> Merancang layanan, produk, dan menciptakan pengalaman yang baik berarti merencanakan masa depan yang lebih baik dengan beralih ke mobilitas yang ramah lingkungan dan berkelanjutan. Kami membayangkan masa depan di mana kita, dan generasi kita yang akan datang, dapat menikmati udara yang lebih bersih, air yang lebih jernih, dan jalanan kota yang lebih tenang.<br /><br /> Kami mencapai ini melalui penyediaan mobilitas yang berkelanjutan, bekerja sama dengan masyarakat lokal dan LSM dan memastikan hubungan jangka panjang dengan para pemangku kepentingan kami.",
    PL_CONTACT_TITLE:
      "Ingin tahu lebih banyak? <br> Hubungi kami untuk informasi lainnya.",
    PL_CONTACT_TEXT:
      "Anda juga dapat menghubungi kami melalui <a style='color: white;' href='https://api.whatsapp.com/send?phone=6281237686970&text=Halo%20Charged' target='_blank'>+62 812 37 686970</a> atau kirimkan email ke <a  style='color: white;'href='mailto:contact@charged.asia' target='_blank'>contact@charged.asia</a>",
    PL_CONTACT_TEXT_MOBILE:
      "Anda juga dapat menghubungi kami melalui <a style='color: white;' href='https://api.whatsapp.com/send?phone=6281237686970&text=Halo%20Charged' target='_blank'>+62 812 37 686970</a> <hr class='my-4'/> kirimkan email ke <a  style='color: white;'href='mailto:contact@charged.asia' target='_blank'>contact@charged.asia</a>",
    PL_CONTACT_BUTTON: "Mulai Chat",
    PL_BELIEVE_TITLE:
      "Kami percaya bahwa setiap kontribusi Individu, ide, dan konsistensi adalah penting untuk meningkatkan pelayanan yang lebih baik.",
    PL_BELIEVE_CONTENT:
      "Tim kami berdedikasi untuk terus mengembangkan solusi dan pengalaman baru sehingga anda dapat menikmati pengalaman berkendara dengan lebih nyaman. <br/><br /> Merancang layanan, produk, dan menciptakan pengalaman yang baik berarti merencanakan masa depan yang lebih baik dengan beralih ke mobilitas yang ramah lingkungan dan berkelanjutan. Kami membayangkan masa depan di mana kita, dan generasi kita yang akan datang, dapat menikmati udara yang lebih bersih, air yang lebih jernih, dan jalanan kota yang lebih tenang. <br/><br /> Kami mencapai ini melalui penyediaan mobilitas yang berkelanjutan, bekerja sama dengan masyarakat lokal dan LSM, dan pemerintah memastikan hubungan jangka panjang dengan para pemangku kepentingan kami.",
    PL_RIMBA_TITLE: "Motor Rimba",
    PL_RIMBA_CONTENT:
      "Projek motor Rimba dimulai dengan sebuah konsep motor listrik yang dapat melalui rintangan alam Indonesia. Charged Indonesia, didukung dengan teknologi Vmoto Soco Group, mendesain motor Rimba berdasarkan input dan kebutuhan para penjaga hutan PROFAUNA dalam melindungi hutan dan satwa liar dengan keunggulan zero emission, operasi hening, dan jejak karbon rendah.",
    PL_RIMBA_BUTTON: "PELAJARI TENTANG ",

    RIMBA_TITLE: "MOTOR RIMBA",
    RIMBA_DESCRIPTION:
      "Kami mendukung organisasi yang membawa dampak positif terhadap lingkungan dan satwa liar di <br /> seluruh Indonesia dengan meluncurkan motor Rimba, sebuah motor listrik eksklusif kami <br /> bagi para penjaga hutan.",
    RIMBA_ENVIRONMENT_TITLE: "Visi bagi Lingkungan",
    RIMBA_ENVIRONMENT_TEXT: `
           Guna mewujudkan visi kami sebagai bagian dari penggerak sustainabilitas untuk mencapai masa depan dengan udara bersih, tanpa polusi, dan kesehatan yang lebih baik bagi lingkungan dan generasi kedepan, kami meluncurkan projek motor Rimba untuk membantu konservasi hutan dan satwa liar bersama PROFAUNA Indonesia, sebuah organisasi nirlaba di Jawa Timur yang fokus pada konservasi alam di sekitar area Malang.
            <br />
            <br />

            Kami menyadari seberapa ekstrim keadaan jalan yang dilewati para penjaga hutan setiap harinya, dari jalanan sempit di dalam hutan hingga jalanan terjal di tebing dekat pantai selatan. Dan juga bahaya yang dapat ditemui saat berpapasan dengan para penebang dan pemburu liar, serta dalam menjalani tugas pengedukasian bagi para penduduk sekitar mengenai konservasi alam.
            <br />
            <br />

            Bapak Rosek Nursahid, pendiri PROFAUNA Indonesia, mengatakan, “Kami menyambut dengan positif kolaborasi bersama Charged, karena kami percaya bahwa motor listrik ramah lingkungan Rimba ini dapat berguna bagi tim PROFAUNA saat memonitor satwa liar di hutan karena suara motornya yang senyap.”
 <br />
            <br />

Bapak Charles Chen, CEO dari Vmoto Limited mengatakan, “Sungguh kegembiraan luar biasa bagi kami untuk dapat bermitra dengan Charged dan PROFAUNA Indonesia dalam meluncurkan ‘Rimba’ sebuah motor listrik ramah lingkungan yang dirancang khusus untuk memenuhi misi konservasi dan melindungi kesakralan lingkungan alam kita bersama. Going Green seharusnya tidak hanya menjadi sebatas slogan melainkan sebuah panggilan untuk mengajak masyarakat Indonesia bekerja sama demi membangun masa depan yang lebih baik, mari bergabung wujudkan impian kami ini!”
           `,
    RIMBA_ENVIRONMENT_COLLAB: "KOLABORASI DENGAN",
    RIMBA_ENVIRONMENT_BUTTON: "TENTANG PROFAUNA",
    RIMBA_CAROUSEL_TITLE: "Desain x Teknologi",
    RIMBA_CAROUSEL_TEXT:
      "Rimba didesain dengan menambahkan struktur penguat dan pelindung badan motor, lampu kabut, dan ban khusus untuk menempuh rintangan di alam Indonesia. Motor ini dilengkapi dengan 3 baterai listrik yang dapat menempuh perjalanan hingga 300 km. Berat yang dapat dibawa mencapai 140 kg dengan ground clearance sebesar 250 mm sehingga dengan mudah dapat melewati landasan yang terjal. <br /> <br /> Rimba adalah sebuah solusi yang ideal bagi penjaga hutan PROFAUNA dalam menjalankan tugas mereka untuk melindungi hutan dan satwa liar.",
    RIMBA_PARTNER_TITLE: "Teknologi Kami Didukung oleh ",
    RIMBA_PARTNER_DESCRIPTION:
      "Vmoto Soco Group (VMT) mengembangkan, memproduksi dan mendistribusikan kendaraan roda dua elektrik yang inovatif serta menyediakan solusi kendaraan listrik bagi korporasi dan konsumen di lebih dari 60 negara. <br /> <br /> Vmoto Soco Group sadar akan pesatnya pertumbuhan zaman dan inovasi yang terus berkembang. Aksi nyata untuk melindungi lingkungan harus dimulai dari sekarang!<br /> <br /> Mereka berusaha untuk memberdayakan masyarakat dengan solusi yang ramah lingkungan dan berkelanjutan, memberikan nilai bagi masyarakat, perusahaan, dan pelanggan. Mereka bekerja keras demi kenyamanan berkendara kita semua. <br /> <br /> Ayo wujudkan revolusi kendaraan listrik bersama Vmoto Soco Group.",
    RIMBA_PARTNER_BUTTON: "KUNJUNGI WEBSITE VMOTO",

    MSRP: "MSRP",
    OTR: "Harga OTR",
    OTR_JAKARTA: "Harga OTR Jakarta",
    UNIT_AND_CHARGER: "Unit & Charger",
    OTR_FOOTNOTE: "*Harga OTR area Jakarta",
    SUBS_PRICE_FOOTNOTE: "*Harga area Jabodetabek",
    COLOR_OPTION_TEXT: "Pilihan Warna",
    EX_VAT: "(belum termasuk pajak)",
    BUY_NOW: "Beli Sekarang seharga ",
    SUB_NOW: "Berlangganan seharga ",
    OR: "atau",
    TOP_SPEED: "KECEPATAN MAKSIMAL",
    MAX_RANGE: "JARAK TEMPUH MAKSIMAL*",
    MAX_BATTERIES: "(2 BATERAI)",
    AVAILABLE_SUBS: "TERSEDIA UNTUK BERLANGGANAN",
    SPEED_UNIT: "km/jam",
    BATTERY_SUBCRIPTION: "Langganan Baterai",
    INCLUDE_CHARGER: "Termasuk Charger Regular",
    INCLUDE_VAT: "Belum Termasuk PPN",
    SUBSCRIBE_FOR: "Langganan",

    // fOOTER
    TITLE_EXCLUSIVE_INVITES_P1: "Tetap terupdate dengan peluncuran baru? ",
    TITLE_EXCLUSIVE_INVITES_P2: "Mari tetap terhubung.",
    TEXT_BUTTON_EXCLUSIVE_INVITES: "Terima Undangan Eksklusif",
  },
};