import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#6B4693',
                primaryLight: '#A57FB6',
                secondary: '#F0EDF2',
                error: '#CB3A31',
            },
        },
    },
});
