<template>
  <div id="subscribe">
    <v-dialog v-model="loadingScreen" width="auto" persistent>
      <v-card>
        <v-card-actions class="loading-screen">
          <v-progress-circular color="primary" indeterminate>
          </v-progress-circular>
          <v-card-text class="subheading text-center">
            <b>{{ lang[getLanguage].WAIT_TITLE }}</b>
          </v-card-text>
          <v-card-text class="text-center">
            {{ lang[getLanguage].WAIT_DESCRIPTION }}
          </v-card-text>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDialog" persistent max-width="500">
      <v-card class="alert-dialog">
        <v-icon v-if="this.alertStatus == 'success'" class="succes-icon"
          >mdi-check-circle</v-icon
        >
        <v-icon v-if="this.alertStatus == 'failed'" class="failed-icon"
          >mdi-close-circle</v-icon
        >
        <v-card-text class="title text-center">{{
          getLanguage === "en" ? this.alertText.en : this.alertText.id
        }}</v-card-text>
        <!-- <v-card-text class="text-center">
          {{ lang[getLanguage].BOOKING_FAILED_DESCRIPTION }}
        </v-card-text> -->
        <v-btn
          color="primary"
          :width="windowWidth < 768 ? '100%' : '50%'"
          @click="closeAlertDialog"
          class="color:#2962FF;margin-left:-10px;margin-top:-4px;font-weight:normal;font-size:11px;text-transform: none !important;"
          >Ok</v-btn
        >
      </v-card>
    </v-dialog>
    <v-slide-y-transition mode="out-in">
      <v-layout column pl-2 pr-2>
        <!-- ####################### SKELETON LOADERS ####################### -->
        <v-layout column v-if="loading">
          <p class="mt-0"></p>

          <v-layout column>
            <v-spacer></v-spacer>
            <v-skeleton-loader
              class="mx-auto"
              type="card"
              width="100%"
            ></v-skeleton-loader>
            <v-spacer></v-spacer>
          </v-layout>
        </v-layout>

        <!-- ############################################## COMPONENTS ##############################################  -->

        <v-layout column align-center v-else pt-2>
          <v-stepper
            v-model="stepper"
            flat
            alt-labels
            elevation="0"
            class="subscribe md:px-32"
          >
            <v-stepper-header>
              <v-stepper-step class="px-0 w-1/6 custom-stp-subs" step="1">
                <div class="text-center stepper-title">
                  <span :style="stepper == 1 ? 'color: #6b4693' : ''">
                    {{ lang[getLanguage].PRODUCT_STEP_1 }}
                  </span>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step class="px-0 w-1/6 custom-stp-subs" step="2">
                <div class="text-center stepper-title">
                  <span :style="stepper == 2 ? 'color: #6b4693' : ''">
                    {{ lang[getLanguage].PRODUCT_STEP_2 }}
                  </span>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step class="px-0 w-1/6 custom-stp-subs" step="3">
                <div class="text-center stepper-title">
                  <span :style="stepper == 3 ? 'color: #6b4693' : ''">
                    {{ lang[getLanguage].PRODUCT_STEP_3 }}
                  </span>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step class="px-0 w-1/6 custom-stp-subs" step="4">
                <div class="text-center stepper-title">
                  <span :style="stepper == 4 ? 'color: #6b4693' : ''">
                    {{ lang[getLanguage].PRODUCT_STEP_4 }}
                  </span>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step class="px-0 w-1/6 custom-stp-subs" step="5">
                <div class="text-center stepper-title">
                  <span :style="stepper == 5 ? 'color: #6b4693' : ''">
                    {{ lang[getLanguage].PRODUCT_STEP_5 }}
                  </span>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step class="px-0 w-1/6 custom-stp-subs" step="6">
                <div class="text-center stepper-title">
                  <span :style="stepper == 6 ? 'color: #6b4693' : ''">
                    {{ lang[getLanguage].PRODUCT_STEP_6 }}
                  </span>
                </div>
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1" class="px-2">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-card flat mb-4>
                      <span id="stepTitle" class="stepTitle">{{
                        getLanguage === "en"
                          ? `${selectedBike.name} ${lang[getLanguage].PRODUCT_STEP_1}`
                          : `${lang[getLanguage].PRODUCT_STEP_1} ${selectedBike.name}`
                      }}</span>
                      <p class="mt-8 md:mt-2"></p>
                      <span class="sectionStepTitle">{{
                        lang[getLanguage].PRODUCT_COLOR_TITLE
                      }}</span>
                      <div v-for="(item, index) in listColor" :key="index">
                        <v-layout
                          class="selection"
                          v-if="item.bikes.find((t) => selectedBike.name === t)"
                          @click.stop="selectColor(item)"
                        >
                          <v-icon
                            v-if="item != selectedColor"
                            class="radio-button"
                            >mdi-radiobox-blank</v-icon
                          >
                          <v-icon v-else color="#6b4693" class="radio-button"
                            >mdi-radiobox-marked</v-icon
                          >
                          <v-card
                            width="100%"
                            height="60"
                            flat
                            :class="
                              item === selectedColor
                                ? 'radio-selection selected-selection'
                                : 'radio-selection'
                            "
                          >
                            <v-layout
                              style="margin-top: 17px; margin-left: 10px"
                            >
                              <v-icon
                                class="radio-selection-color"
                                :color="item.color"
                                >mdi-circle
                              </v-icon>

                              <span class="customization-item-title">
                                -
                                {{
                                  getLanguage === "en"
                                    ? item.title.en
                                    : item.title.id
                                }}
                              </span>

                              <v-spacer></v-spacer>
                              <span
                                v-if="isMobileDevice"
                                class="customization-item-price"
                                style="margin-right: 10px"
                                >+ Rp
                                {{
                                  selectedUser === "driver"
                                    ? formatPrice(item.price) +
                                      "/" +
                                      lang[getLanguage].LABEL_DAY
                                    : formatPrice(item.price) +
                                      "/" +
                                      lang[getLanguage].LABEL_MO
                                }}
                              </span>
                              <span
                                v-if="!isMobileDevice"
                                class="customization-item-price"
                                style="margin-right: 10px"
                                >+ Rp
                                {{
                                  selectedUser === "driver"
                                    ? formatPrice(item.price) +
                                      "/" +
                                      lang[getLanguage].LABEL_DAY
                                    : formatPrice(item.price) +
                                      "/" +
                                      lang[getLanguage].LABEL_MO
                                }}
                              </span>
                            </v-layout>
                          </v-card>
                        </v-layout>
                      </div>

                      <p class="mt-12 md:mt-2"></p>
                      <span class="sectionStepTitle">
                        <!-- {{ lang[getLanguage].PRODUCT_BATTERY_TITLE }} -->
                        {{
                          getLanguage == "en"
                            ? "Battery quantity"
                            : "Jumlah Baterai"
                        }}

                        <span
                          class="text-sm text-charged-grey-400 font-work-sans font-normal"
                        >
                          {{
                            getLanguage == "en"
                              ? "(Price includes tax)"
                              : "(Harga termasuk PPN)"
                          }}
                        </span>
                      </span>
                      <div v-if="isMobileDevice">
                        <div
                          v-for="(item, index) in listBattery"
                          :key="index + '2'"
                        >
                          <v-layout
                            class="selection"
                            @click.stop="selectBattery(item)"
                          >
                            <v-icon
                              v-if="item != selectedBattery"
                              class="radio-button"
                              >mdi-radiobox-blank</v-icon
                            >
                            <v-icon
                              v-if="item === selectedBattery"
                              color="#6b4693"
                              class="radio-button"
                              >mdi-radiobox-marked</v-icon
                            >
                            <v-card
                              :class="
                                item === selectedBattery
                                  ? 'radio-selection selected-selection'
                                  : 'radio-selection'
                              "
                              flat
                            >
                              <v-layout
                                class="my-2 mx-2"
                                style="display: block; font-size: 12px"
                              >
                                <v-row>
                                  <v-col>
                                    <v-layout>
                                      <span>
                                        <span
                                          class="customization-item-title"
                                          >{{
                                            getLanguage === "en"
                                              ? item.title.en
                                              : item.title.id
                                          }}</span
                                        >
                                      </span>
                                      <v-spacer></v-spacer>
                                      <span class="text-right">
                                        <span class="customization-item-price"
                                          >+ Rp{{
                                            selectedUser === "driver"
                                              ? formatPrice(item.dailyPrice) +
                                                "/" +
                                                lang[getLanguage].LABEL_DAY
                                              : formatPrice(item.price) +
                                                "/" +
                                                lang[getLanguage].LABEL_MO
                                          }}</span
                                        >
                                      </span>
                                    </v-layout>
                                  </v-col>
                                </v-row>
                                <v-row class="mt-0">
                                  <v-col
                                    ><span class="customization-item-desc">
                                      {{
                                        getLanguage === "en"
                                          ? item.desc.en
                                          : item.desc.id
                                      }}
                                    </span>
                                  </v-col>
                                </v-row>
                              </v-layout>
                            </v-card>
                          </v-layout>
                        </div>
                      </div>
                      <div v-if="!isMobileDevice">
                        <div v-for="(item, index) in listBattery" :key="index">
                          <v-layout
                            class="selection"
                            @click.stop="selectBattery(item)"
                          >
                            <v-icon
                              v-if="item != selectedBattery"
                              class="radio-button"
                              >mdi-radiobox-blank</v-icon
                            >
                            <v-icon
                              v-if="item === selectedBattery"
                              color="#6b4693"
                              class="radio-button"
                              >mdi-radiobox-marked</v-icon
                            >
                            <v-card
                              :class="
                                item === selectedBattery
                                  ? 'radio-selection selected-selection'
                                  : 'radio-selection'
                              "
                              flat
                            >
                              <v-layout
                                style="margin-top: 17px; margin-left: 10px"
                              >
                                <v-col
                                  col="12"
                                  md="8"
                                  class="radio-selection-battery"
                                >
                                  <v-row>
                                    <span class="customization-item-title">{{
                                      getLanguage === "en"
                                        ? item.title.en
                                        : item.title.id
                                    }}</span>
                                  </v-row>
                                  <v-row>
                                    <p class="customization-item-desc">
                                      {{
                                        getLanguage === "en"
                                          ? item.desc.en
                                          : item.desc.id
                                      }}
                                    </p>
                                  </v-row>
                                </v-col>
                                <v-spacer></v-spacer>
                                <span
                                  class="customization-item-price"
                                  style="margin-right: 10px"
                                  >+ Rp{{
                                    selectedUser === "driver"
                                      ? formatPrice(item.dailyPrice) +
                                        "/" +
                                        lang[getLanguage].LABEL_DAY
                                      : formatPrice(item.price) +
                                        "/" +
                                        lang[getLanguage].LABEL_MO
                                  }}</span
                                >
                              </v-layout>
                            </v-card>
                          </v-layout>
                        </div>
                      </div>

                      <p class="mt-12 md:mt-2"></p>
                      <span class="sectionStepTitle">
                        <!-- {{ lang[getLanguage].PRODUCT_CHARGER_TITLE }} -->
                        {{
                          getLanguage == "en" ? "Charger type" : "Tipe Charger"
                        }}

                        <span
                          class="text-sm text-charged-grey-400 font-work-sans font-normal"
                        >
                          {{
                            getLanguage == "en"
                              ? "(Price includes tax)"
                              : "(Harga termasuk PPN)"
                          }}
                        </span>
                      </span>
                      <div
                        v-for="(item, index) in listCharger"
                        :key="index + '1'"
                      >
                        <v-layout
                          class="selection"
                          @click.stop="selectCharger(item)"
                        >
                          <v-icon
                            v-if="item != selectedCharger"
                            class="radio-button"
                            >mdi-radiobox-blank</v-icon
                          >
                          <v-icon
                            v-if="item === selectedCharger"
                            color="#6b4693"
                            class="radio-button"
                            >mdi-radiobox-marked</v-icon
                          >
                          <v-card
                            :class="
                              item === selectedCharger
                                ? 'radio-selection selected-selection'
                                : 'radio-selection'
                            "
                            flat
                            :height="isMobileDevice ? 90 : 65"
                            style="display: block"
                          >
                            <div v-if="isMobileDevice">
                              <v-layout
                                class="my-2 mx-2"
                                style="display: block; font-size: 12px"
                              >
                                <v-row>
                                  <v-col>
                                    <v-layout>
                                      <span>
                                        <span class="customization-item-title">
                                          {{ item.title }}
                                        </span>
                                      </span>
                                      <v-spacer></v-spacer>
                                      <span class="text-right">
                                        <span class="customization-item-price"
                                          >+ Rp{{
                                            selectedUser === "driver"
                                              ? formatPrice(item.dailyPrice) +
                                                "/" +
                                                lang[getLanguage].LABEL_DAY
                                              : formatPrice(item.price) +
                                                "/" +
                                                lang[getLanguage].LABEL_MO
                                          }}</span
                                        >
                                      </span>
                                    </v-layout>
                                    <v-layout>
                                      <span class="customization-item-desc">{{
                                        getLanguage === "en"
                                          ? item.time.en
                                          : item.time.id
                                      }}</span>
                                    </v-layout>
                                    <v-layout>
                                      <span class="customization-item-desc">{{
                                        getLanguage == "en"
                                          ? item.description.en
                                          : item.description.id
                                      }}</span>
                                    </v-layout>
                                  </v-col>
                                </v-row>
                              </v-layout>
                            </div>
                            <div v-if="!isMobileDevice">
                              <v-layout
                                style="margin-top: 17px; margin-left: 10px"
                              >
                                <span class="customization-item-title">{{
                                  item.title
                                }}</span
                                ><span class="customization-item-desc"
                                  >&nbsp;
                                  {{
                                    getLanguage === "en"
                                      ? item.time.en
                                      : item.time.id
                                  }}</span
                                >
                                <v-spacer></v-spacer>
                                <span
                                  class="customization-item-price"
                                  style="margin-right: 10px"
                                  >+ Rp{{
                                    selectedUser === "driver"
                                      ? formatPrice(item.dailyPrice) +
                                        "/" +
                                        lang[getLanguage].LABEL_DAY
                                      : formatPrice(item.price) +
                                        "/" +
                                        lang[getLanguage].LABEL_MO
                                  }}</span
                                >
                              </v-layout>
                              <div class="pl-2 pb-5 inline-block">
                                <span
                                  class="font-work-sans text-sm font-normal text-charged-grey-400"
                                >
                                  {{
                                    getLanguage == "en"
                                      ? item.description.en
                                      : item.description.id
                                  }}
                                </span>
                              </div>
                            </div>
                          </v-card>
                        </v-layout>
                      </div>

                      <div class="mt-10" v-if="listRear.length > 0">
                        <p class="mt-2"></p>
                        <span class="sectionStepTitle">
                          {{
                            getLanguage == "en"
                              ? "Additional Accessories"
                              : "Tambahan Aksesoris"
                          }}

                          <span
                            class="text-sm text-charged-grey-400 font-work-sans font-normal"
                          >
                            {{
                              getLanguage == "en"
                                ? "(Price includes tax)"
                                : "(Harga termasuk PPN)"
                            }}
                          </span>
                        </span>
                        <div v-if="isMobileDevice">
                          <div v-for="(item, index) in listRear" :key="index">
                            <v-layout
                              class="selection"
                              @click.stop="selectRear(item)"
                            >
                              <v-icon
                                v-if="item != selectedRear"
                                class="radio-button"
                                >mdi-radiobox-blank</v-icon
                              >
                              <v-icon
                                v-if="item === selectedRear"
                                color="#6b4693"
                                class="radio-button"
                                >mdi-radiobox-marked</v-icon
                              >
                              <v-card
                                :class="
                                  item === selectedRear
                                    ? 'radio-selection selected-selection'
                                    : 'radio-selection'
                                "
                                flat
                              >
                                <v-layout
                                  class="my-2 mx-2"
                                  style="display: block; font-size: 12px"
                                >
                                  <v-row>
                                    <v-col class="col-7">
                                      <span class="customization-item-title">{{
                                        getLanguage === "en"
                                          ? item.title.en
                                          : item.title.id
                                      }}</span>
                                      <p class="customization-item-desc">
                                        {{ item.description }}
                                      </p>
                                    </v-col>
                                    <v-col class="text-right">
                                      <span class="customization-item-price"
                                        >+ Rp{{
                                          selectedUser === "driver"
                                            ? formatPrice(item.dailyPrice) +
                                              "/" +
                                              lang[getLanguage].LABEL_DAY
                                            : formatPrice(item.price) +
                                              "/" +
                                              lang[getLanguage].LABEL_MO
                                        }}</span
                                      >
                                    </v-col>
                                  </v-row>
                                </v-layout>
                              </v-card>
                            </v-layout>
                          </div>
                        </div>

                        <div v-if="!isMobileDevice">
                          <div v-for="(item, index) in listRear" :key="index">
                            <v-layout
                              class="selection"
                              @click.stop="selectRear(item)"
                            >
                              <v-icon
                                v-if="item != selectedRear"
                                class="radio-button"
                                >mdi-radiobox-blank</v-icon
                              >
                              <v-icon
                                v-if="item === selectedRear"
                                color="#6b4693"
                                class="radio-button"
                                >mdi-radiobox-marked</v-icon
                              >
                              <v-card
                                :class="
                                  item === selectedRear
                                    ? 'radio-selection selected-selection'
                                    : 'radio-selection'
                                "
                                flat
                              >
                                <v-layout
                                  style="margin-top: 17px; margin-left: 10px"
                                >
                                  <v-col
                                    col="12"
                                    md="8"
                                    class="radio-selection-battery"
                                  >
                                    <v-row>
                                      <span class="customization-item-title">{{
                                        getLanguage === "en"
                                          ? item.title.en
                                          : item.title.id
                                      }}</span>
                                    </v-row>
                                    <v-row>
                                      <p class="customization-item-desc">
                                        {{ item.description }}
                                      </p>
                                    </v-row>
                                  </v-col>
                                  <v-spacer></v-spacer>
                                  <span
                                    class="customization-item-price"
                                    style="margin-right: 10px"
                                    >+ Rp{{
                                      selectedUser === "driver"
                                        ? formatPrice(item.dailyPrice) +
                                          "/" +
                                          lang[getLanguage].LABEL_DAY
                                        : formatPrice(item.price) +
                                          "/" +
                                          lang[getLanguage].LABEL_MO
                                    }}</span
                                  >
                                </v-layout>
                              </v-card>
                            </v-layout>
                          </div>
                        </div>
                      </div>

                      <v-layout pt-5>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="continue-button"
                          :style="
                            isMobileDevice
                              ? 'width: 100%;color: #fff;text-transform: none !important;'
                              : 'width: 20%;color: #fff;text-transform: none !important;'
                          "
                          color="#6B4693"
                          @click="stepTwoChaged"
                          :disabled="
                            selectedColor == null ||
                            selectedCharger == null ||
                            selectedBattery == null ||
                            loadingBtnStep
                          "
                          :loading="loadingBtnStep"
                        >
                          {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
                        </v-btn>
                      </v-layout>
                    </v-card>
                  </v-col>
                </v-row>
                <p class="mt-4"></p>
                <p class="mt-4"></p>
              </v-stepper-content>

              <v-stepper-content step="2" class="px-2 md:px-6">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-col class="stepBack pt-0">
                      <v-btn
                        class="pl-0 md:pl-4"
                        @click.stop="backStep"
                        text
                        color="primary"
                        :ripple="false"
                        style="font-weight: 600"
                      >
                        <v-icon color="primary" class="mr-1"
                          >mdi-chevron-left</v-icon
                        >{{ lang[getLanguage].BUT_BACK }}
                      </v-btn>
                    </v-col>
                    <v-card flat mb-4>
                      <span class="stepTitle">{{
                        lang[getLanguage].TEST_DRIVE_PERSONAL_DATA
                      }}</span>
                      <v-row class="mt-6">
                        <v-col cols="12" md="6" class="py-0">
                          <span class="personal-input-title">{{
                            lang[getLanguage].LABEL_FULL_NAME_KTP
                          }}</span>
                          <v-text-field
                            class="custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            placeholder="John Doe"
                            v-model="name"
                            :disabled="name != ''"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class="mt-6">
                        <v-col cols="12" md="6" class="py-0">
                          <span class="personal-input-title">
                            {{ "NIK *" }}
                          </span>
                          <v-text-field
                            class="custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            placeholder="contoh: 3674000000"
                            v-model="nik"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6" class="py-0">
                          <span class="personal-input-title">{{
                            lang[getLanguage].SUBSCRIBE_EMAIL_ADDRESS
                          }}</span>
                          <v-text-field
                            class="custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            v-model="email"
                            placeholder="johndoe@gmail.com"
                            :rules="emailRules"
                            :disabled="email != ''"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span class="personal-input-title">{{
                            lang[getLanguage].SUBSCRIBE_PHONE_NUMBER
                          }}</span>
                          <v-text-field
                            class="custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            placeholder="e.g. +624718297372"
                            filled
                            v-model="phoneNumber"
                            :disabled="phoneNumber != ''"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6" class="py-0">
                          <span class="personal-input-title">
                            Tempat Lahir *
                          </span>
                          <v-text-field
                            class="custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            v-model="tempatLahir"
                            placeholder="contoh: Jakarta"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span class="personal-input-title">
                            Tanggal Lahir *
                          </span>
                          <v-text-field
                            class="custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            type="date"
                            v-model="dateTglLahir"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-layout
                        style="
                          width: 100%;
                          margin-top: -1.5rem;
                          margin-bottom: 2.5rem;
                        "
                      >
                        <span class="customization-item-desc">{{
                          lang[getLanguage].TEST_DRIVE_CONTACT_DESCRIPTION
                        }}</span>
                      </v-layout>

                      <v-layout>
                        <span class="stepTitle">{{
                          lang[getLanguage].SUBSCRIBE_PARENT_TITLE
                        }}</span>
                      </v-layout>

                      <v-layout class="mt-6" style="width: 100%">
                        <v-row>
                          <v-col cols="12" md="6">
                            <span class="personal-input-title">{{
                              lang[getLanguage].SUBSCRIBE_MOTHER_LABEL
                            }}</span>
                            <v-text-field
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              :placeholder="
                                lang[getLanguage].SUBSCRIBE_MOTHER_PLACEHOLDER
                              "
                              v-model="motherName"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-layout>

                      <hr
                        style="border-top: 1px dashed #c1c2c4; margin-top: 1rem"
                        class="hidden md:block"
                      />
                      <span
                        class="md:hidden inline-block h-2 bg-charged-grey-700 w-full"
                      ></span>

                      <v-layout class="mt-6">
                        <span class="stepTitle">{{
                          lang[getLanguage].SUBSCRIBE_PROFESSION_TITLE
                        }}</span>
                      </v-layout>
                      <!-- <div v-if="selectedUser === 'driver'"> -->
                      <v-layout class="mt-6" style="width: 100%">
                        <v-row>
                          <v-col cols="12" md="6">
                            <span class="personal-input-title">{{
                              lang[getLanguage].SUBSCRIBE_COMPANY_LABEL
                            }}</span>
                            <v-text-field
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              :placeholder="
                                lang[getLanguage].SUBSCRIBE_COMPANY_PLACEHOLDER
                              "
                              v-model="companyName"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-layout>

                      <v-layout
                        style="width: 100%"
                        v-if="selectedUser === 'regular'"
                      >
                        <v-row>
                          <v-col cols="12" md="6">
                            <span class="personal-input-title">{{
                              lang[getLanguage].SUBSCRIBE_JOB_POSITION_LABEL
                            }}</span>
                            <v-select
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              :placeholder="
                                lang[getLanguage]
                                  .SUBSCRIBE_JOB_POSITION_PLACEHOLDER
                              "
                              v-model="jobPosition"
                              :items="jobPositionListEnglish"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <span class="personal-input-title">
                              {{
                                lang[getLanguage]
                                  .SUBSCRIBE_EMPLOYMENT_STATUS_LABEL
                              }}
                            </span>
                            <v-select
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              :placeholder="
                                lang[getLanguage]
                                  .SUBSCRIBE_EMPLOYMENT_STATUS_PLACEHOLDER
                              "
                              v-model="employmentStatus"
                              :items="
                                getLanguage === 'en'
                                  ? employmentStatusListEnglish
                                  : employmentStatusListEIndo
                              "
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-layout>

                      <v-layout
                        style="width: 100%"
                        v-if="selectedUser === 'driver' && !isMobileDevice"
                      >
                        <v-row>
                          <v-col cols="12" md="6">
                            <span class="personal-input-title">
                              {{
                                lang[getLanguage].SUBSCRIBE_WORK_LENGTH_LABEL
                              }}
                            </span>
                            <v-select
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              :placeholder="
                                lang[getLanguage]
                                  .SUBSCRIBE_WORK_LENGTH_PLACEHOLDER
                              "
                              v-model="yearOfExperience"
                              :items="
                                getLanguage === 'en'
                                  ? yearOfExperienceListEnglish
                                  : yearOfExperienceListIndo
                              "
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <span class="personal-input-title">
                              {{ lang[getLanguage].SUBSCRIBE_INCOME_LABEL }}
                            </span>
                            <v-select
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              v-model="salary"
                              :placeholder="
                                lang[getLanguage].SUBSCRIBE_INCOME_PLACEHOLDER
                              "
                              :items="itemsSalary"
                              item-text="title"
                              item-value="value"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-layout>

                      <!-- <v-layout
                        style="width: 100%"
                        v-if="selectedUser === 'driver' && !isMobileDevice"
                      >
                        <v-row>
                          <v-col cols="12" md="6">
                            <span class="personal-input-title">
                              {{ lang[getLanguage].SUBSCRIBE_INCOME_LABEL }}
                            </span>
                            <v-select
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              v-model="salary"
                              :placeholder="
                                lang[getLanguage].SUBSCRIBE_INCOME_PLACEHOLDER
                              "
                              :items="itemsSalary"
                              item-text="title"
                              item-value="value"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-layout> -->

                      <div v-if="selectedUser === 'driver' && isMobileDevice">
                        <v-layout style="width: 100%">
                          <v-layout column>
                            <span class="personal-input-title">{{
                              lang[getLanguage].SUBSCRIBE_WORK_LENGTH_LABEL
                            }}</span>
                            <v-select
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              :placeholder="
                                lang[getLanguage]
                                  .SUBSCRIBE_WORK_LENGTH_PLACEHOLDER
                              "
                              v-model="yearOfExperience"
                              :items="
                                getLanguage === 'en'
                                  ? yearOfExperienceListEnglish
                                  : yearOfExperienceListIndo
                              "
                            >
                            </v-select>
                          </v-layout>
                        </v-layout>

                        <v-layout style="width: 100%">
                          <v-layout column>
                            <span class="personal-input-title">{{
                              lang[getLanguage].SUBSCRIBE_INCOME_LABEL
                            }}</span>
                            <v-select
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              v-model="salary"
                              :placeholder="
                                lang[getLanguage].SUBSCRIBE_INCOME_PLACEHOLDER
                              "
                              :items="itemsSalary"
                              item-text="title"
                              item-value="value"
                            >
                            </v-select>
                          </v-layout>
                        </v-layout>
                      </div>

                      <v-layout
                        style="width: 100%"
                        v-if="selectedUser === 'regular'"
                      >
                        <v-row>
                          <v-col cols="12" md="6">
                            <span class="personal-input-title">{{
                              lang[getLanguage].SUBSCRIBE_WORK_LENGTH_LABEL
                            }}</span>
                            <v-select
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              :placeholder="
                                lang[getLanguage]
                                  .SUBSCRIBE_WORK_LENGTH_PLACEHOLDER
                              "
                              v-model="yearOfExperience"
                              :items="
                                getLanguage === 'en'
                                  ? yearOfExperienceListEnglish
                                  : yearOfExperienceListIndo
                              "
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-layout>

                      <v-layout
                        style="width: 100%"
                        v-if="selectedUser === 'regular'"
                      >
                        <v-row>
                          <v-col cols="12" md="6">
                            <span class="personal-input-title">{{
                              lang[getLanguage].SUBSCRIBE_INCOME_LABEL
                            }}</span>
                            <v-select
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              v-model="salary"
                              :placeholder="
                                lang[getLanguage].SUBSCRIBE_INCOME_PLACEHOLDER
                              "
                              :items="itemsSalary"
                              item-text="title"
                              item-value="value"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <span class="personal-input-title">{{
                              lang[getLanguage].SUBSCRIBE_CC_LABEL
                            }}</span>
                            <v-select
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              v-model="creditCardLimit"
                              :placeholder="
                                lang[getLanguage].SUBSCRIBE_CC_PLACEHOLDER
                              "
                              :items="[
                                '< Rp 3.000.000',
                                'Rp 3.000.000 - Rp 5.000.000',
                                'Rp 5.000.000 - Rp 10.000.000',
                                'Rp 10.000.000 - Rp 20.000.000',
                                '> Rp 20.000.000',
                              ]"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-layout>

                      <hr
                        style="border-top: 1px dashed #c1c2c4"
                        class="hidden md:block md:mb-5"
                      />
                      <span
                        class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-4"
                      ></span>

                      <v-layout>
                        <span class="stepTitle">{{
                          lang[getLanguage].SUBSCRIBE_MARITAL_STATUS_TITLE
                        }}</span>
                      </v-layout>
                      <v-layout class="mt-6">
                        <span class="personal-input-title">{{
                          lang[getLanguage].SUBSCRIBE_MARITAL_STATUS_LABEL
                        }}</span>
                      </v-layout>
                      <v-layout
                        class="selection mt-4"
                        style="justify-content: space-between"
                        v-if="getLanguage === 'id' && isMobileDevice"
                      >
                        <div
                          class="selection"
                          @click.stop="selectMaritalStatus('Single')"
                        >
                          <v-icon
                            color="#353638"
                            style="padding-right: 0.5rem"
                            v-if="maritalStatus !== 'Single'"
                            >mdi-radiobox-blank
                          </v-icon>
                          <v-icon
                            color="#6b4693"
                            style="padding-right: 0.5rem"
                            v-if="maritalStatus === 'Single'"
                            >mdi-radiobox-marked
                          </v-icon>
                          <label class="personal-item-label">
                            {{ lang[getLanguage].SUBSCRIBE_SINGLE_LABEL }}
                            <!-- Belum Menikah -->
                          </label>
                        </div>
                        <div
                          class="selection"
                          @click.stop="selectMaritalStatus('Maried')"
                        >
                          <v-icon
                            color="#353638"
                            style="padding-right: 0.5rem"
                            v-if="maritalStatus !== 'Maried'"
                            >mdi-radiobox-blank
                          </v-icon>
                          <v-icon
                            color="#6b4693"
                            style="padding-right: 0.5rem"
                            v-if="maritalStatus === 'Maried'"
                            >mdi-radiobox-marked
                          </v-icon>
                          <label class="personal-item-label">
                            {{ lang[getLanguage].SUBSCRIBE_MARIED_LABEL }}
                            <!-- Sudah Menikah -->
                          </label>
                        </div>
                      </v-layout>
                      <v-row
                        class="selection mt-4"
                        v-if="getLanguage === 'en' || !isMobileDevice"
                      >
                        <v-col>
                          <div
                            class=""
                            @click.stop="selectMaritalStatus('Single')"
                          >
                            <v-icon
                              color="#353638"
                              style="padding-right: 0.5rem"
                              v-if="maritalStatus !== 'Single'"
                              >mdi-radiobox-blank
                            </v-icon>
                            <v-icon
                              color="#6b4693"
                              style="padding-right: 0.5rem"
                              v-if="maritalStatus === 'Single'"
                              >mdi-radiobox-marked
                            </v-icon>
                            <label
                              style="
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 22px;
                                color: #353638;
                              "
                            >
                              {{ lang[getLanguage].SUBSCRIBE_SINGLE_LABEL }}
                              <!-- Belum Menikah -->
                            </label>
                          </div></v-col
                        >
                        <v-col
                          ><div
                            class=""
                            @click.stop="selectMaritalStatus('Maried')"
                          >
                            <v-icon
                              color="#353638"
                              style="padding-right: 0.5rem"
                              v-if="maritalStatus !== 'Maried'"
                              >mdi-radiobox-blank
                            </v-icon>
                            <v-icon
                              color="#6b4693"
                              style="padding-right: 0.5rem"
                              v-if="maritalStatus === 'Maried'"
                              >mdi-radiobox-marked
                            </v-icon>
                            <label
                              style="
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 22px;
                                color: #353638;
                              "
                            >
                              {{ lang[getLanguage].SUBSCRIBE_MARIED_LABEL }}
                              <!-- Sudah Menikah -->
                            </label>
                          </div></v-col
                        >
                      </v-row>
                      <div v-if="maritalStatus === 'Maried'">
                        <v-layout class="mt-6" style="width: 100%">
                          <v-row>
                            <v-col cols="12" md="6" class="pb-0">
                              <span class="personal-input-title">{{
                                lang[getLanguage]
                                  .SUBSCRIBE_SPOUSE_JOB_POSITION_LABEL
                              }}</span>
                              <v-select
                                class="custom-combobox-d"
                                style="width: 100%"
                                outlined
                                dense
                                filled
                                :placeholder="
                                  lang[getLanguage]
                                    .SUBSCRIBE_JOB_POSITION_PLACEHOLDER
                                "
                                v-model="spousePositionLabel"
                                :items="spousePositionListEnglish"
                                @change="selectSpousePosition()"
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-layout>
                        <v-layout
                          v-if="
                            spousePositionLabel !== 'Housewife' &&
                            spousePositionLabel !== 'Ibu Rumah Tangga'
                          "
                          style="width: 100%"
                        >
                          <v-row class="mt-3">
                            <v-col cols="12" md="6" class="py-0">
                              <span class="personal-input-title">{{
                                lang[getLanguage]
                                  .SUBSCRIBE_SPOUSE_COMPANY_NAME_LABEL
                              }}</span>
                              <v-text-field
                                class="custom-combobox-d"
                                style="width: 100%"
                                outlined
                                dense
                                filled
                                :placeholder="
                                  lang[getLanguage]
                                    .SUBSCRIBE_SPOUSE_COMPANY_NAME_PLACEHOLDER
                                "
                                v-model="spouseCompanyName"
                              >
                              </v-text-field>
                            </v-col>

                            <v-col
                              class="py-0"
                              cols="12"
                              md="6"
                              v-if="
                                spousePositionLabel !== 'Housewife' &&
                                spousePositionLabel !== 'Ibu Rumah Tangga'
                              "
                            >
                              <span class="personal-input-title">{{
                                lang[getLanguage]
                                  .SUBSCRIBE_SPOUSE_EMPLOYMENT_STATUS_LABEL
                              }}</span>
                              <v-select
                                class="custom-combobox-d"
                                style="width: 100%"
                                outlined
                                dense
                                filled
                                :placeholder="
                                  lang[getLanguage]
                                    .SUBSCRIBE_EMPLOYMENT_STATUS_PLACEHOLDER
                                "
                                v-model="spouseEmploymentStatus"
                                :items="
                                  getLanguage === 'en'
                                    ? employmentStatusListEnglish
                                    : employmentStatusListEIndo
                                "
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-layout>

                        <v-layout
                          v-if="
                            spousePositionLabel !== 'Housewife' &&
                            spousePositionLabel !== 'Ibu Rumah Tangga'
                          "
                          style="width: 100%"
                        >
                          <v-row class="mt-3">
                            <v-col cols="12" md="6" class="py-0">
                              <span class="personal-input-title">{{
                                lang[getLanguage]
                                  .SUBSCRIBE_SPOUSE_YEAR_OF_EXPERIENCE_LABEL
                              }}</span>
                              <v-select
                                class="custom-combobox-d"
                                style="width: 100%"
                                outlined
                                dense
                                filled
                                :placeholder="
                                  lang[getLanguage]
                                    .SUBSCRIBE_SPOUSE_YEAR_OF_EXPERIENCE_PLACEHOLDER
                                "
                                v-model="spouseYearOfExperience"
                                :items="
                                  getLanguage === 'en'
                                    ? yearOfExperienceListEnglish
                                    : yearOfExperienceListIndo
                                "
                              >
                              </v-select>
                            </v-col>

                            <v-col cols="12" md="6" class="py-0">
                              <span class="personal-input-title">{{
                                lang[getLanguage].SUBSCRIBE_SPOUSE_SALARY_LABEL
                              }}</span>
                              <v-select
                                class="custom-combobox-d"
                                style="width: 100%"
                                outlined
                                dense
                                filled
                                v-model="spouseSalary"
                                :placeholder="
                                  lang[getLanguage]
                                    .SUBSCRIBE_SPOUSE_SALARY_PLACEHOLDER
                                "
                                :items="[
                                  '< Rp 5.000.000',
                                  'Rp 5.000.000 - Rp 7.500.000',
                                  'Rp 7.500.000 - Rp 13.000.000',
                                  'Rp 13.000.000 - Rp 20.000.000',
                                  '> Rp 20.000.000',
                                ]"
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-layout>
                      </div>

                      <span
                        class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-3 mt-6"
                      ></span>

                      <v-layout pt-4>
                        <v-spacer></v-spacer>
                        <v-btn
                          :style="
                            isMobileDevice
                              ? 'width: 100%;color: #fff;text-transform: none !important;'
                              : 'width: 20%;color: #fff;text-transform: none !important;'
                          "
                          color="#6B4693"
                          :disabled="
                            loadingBtnStep ||
                            name === '' ||
                            nik === '' ||
                            email === '' ||
                            phoneNumber === '' ||
                            tempatLahir === '' ||
                            dateTglLahir === '' ||
                            motherName === '' ||
                            (jobPosition === '' &&
                              selectedUser === 'regular') ||
                            (employmentStatus === '' &&
                              selectedUser === 'regular') ||
                            yearOfExperience === '' ||
                            salary === '' ||
                            maritalStatus === '' ||
                            (spousePositionLabel !== 'Housewife' &&
                              spousePositionLabel !== 'Ibu Rumah Tangga' &&
                              maritalStatus === 'Maried' &&
                              spouseCompanyName === '') ||
                            (spousePositionLabel !== 'Housewife' &&
                              spousePositionLabel !== 'Ibu Rumah Tangga' &&
                              maritalStatus === 'Maried' &&
                              spouseEmploymentStatus === '') ||
                            (spousePositionLabel !== 'Housewife' &&
                              spousePositionLabel !== 'Ibu Rumah Tangga' &&
                              maritalStatus === 'Maried' &&
                              spouseYearOfExperience === '') ||
                            (spousePositionLabel !== 'Housewife' &&
                              spousePositionLabel !== 'Ibu Rumah Tangga' &&
                              maritalStatus === 'Maried' &&
                              spouseSalary === '')
                          "
                          @click="stepTwoComplete"
                          :loading="loadingBtnStep"
                        >
                          {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
                        </v-btn>
                      </v-layout>
                    </v-card>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="3" class="px-2 md:px-6">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-col class="stepBack py-0">
                      <v-btn
                        class="pl-0 md:pl-4"
                        @click.stop="backStep"
                        text
                        color="primary"
                        :ripple="false"
                        ><v-icon color="primary" class="mr-1"
                          >mdi-chevron-left</v-icon
                        >{{ lang[getLanguage].BUT_BACK }}
                      </v-btn>
                    </v-col>
                    <v-card flat mb-4>
                      <v-col col="12" md="12" class="text-title">
                        <v-row>
                          <span id="stepTitle" class="stepTitle">{{
                            lang[getLanguage].TEST_DRIVE_ADDRESS_TITLE
                          }}</span>
                        </v-row>
                      </v-col>

                      <v-row class="mt-3">
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="text-base font-normal font-work-sans text-charged-grey-400"
                            >{{ lang[getLanguage].TEST_DRIVE_PROVINCE }}</span
                          >
                          <v-text-field
                            class="custom-d-input custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            disabled
                            v-model="serviceProvince"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="text-base font-normal font-work-sans text-charged-grey-400"
                            >{{ lang[getLanguage].TEST_DRIVE_CITY }}</span
                          >
                          <v-text-field
                            class="custom-d-input custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            disabled
                            v-model="serviceCity"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="text-base font-normal font-work-sans text-charged-grey-400"
                            >{{ lang[getLanguage].TEST_DRIVE_DISCTRICT }}</span
                          >
                          <v-text-field
                            class="custom-d-input custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            disabled
                            v-model="serviceDistrict"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="text-base font-normal font-work-sans text-charged-grey-400"
                            >{{
                              lang[getLanguage].TEST_DRIVE_SUB_DISCTRICT
                            }}</span
                          >
                          <v-text-field
                            class="custom-d-input custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            disabled
                            v-model="serviceSubDistrict"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <!-- <v-layout style="width: 100%" pb-4> -->
                      <v-row>
                        <v-col cols="12" class="py-0">
                          <span
                            class="text-base font-normal font-work-sans text-charged-grey-400"
                            >{{ lang[getLanguage].TEST_DRIVE_ADDRESS }}</span
                          >
                          <v-text-field
                            class="custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            v-model="address"
                            :placeholder="
                              getLanguage === 'en'
                                ? 'ex: Jl. Sudirman VII No. 01'
                                : 'contoh: Jl. Sudirman VII No. 01'
                            "
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="3" class="py-0">
                          <span
                            class="text-base font-normal font-work-sans text-charged-grey-400"
                            >{{ lang[getLanguage].TEST_DRIVE_ZIP_CODE }}</span
                          >
                          <v-text-field
                            class="custom-d-input custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            disabled
                            v-model="serviceZip"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="text-base font-normal font-work-sans text-charged-grey-400"
                            >{{
                              lang[getLanguage].SUBSCRIBE_HOME_OWNERSHIP_LABEL
                            }}</span
                          >
                          <v-select
                            class="custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            v-model="homeOwnershipStatus"
                            :placeholder="
                              lang[getLanguage]
                                .SUBSCRIBE_HOME_OWNERSHIP_PLACEHOLDER
                            "
                            :items="
                              getLanguage === 'en'
                                ? homeOwnershipStatusListEng
                                : homeOwnershipStatusListIndo
                            "
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="text-base font-normal font-work-sans text-charged-grey-400"
                            >{{
                              lang[getLanguage].SUBSCRIBE_LENGTH_STAY_LABEL
                            }}</span
                          >
                          <v-select
                            class="custom-combobox-d"
                            style="width: 100%"
                            outlined
                            dense
                            filled
                            v-model="lengthOfStay"
                            :placeholder="
                              lang[getLanguage]
                                .SUBSCRIBE_LENGTH_STAY_PLACEHOLDER
                            "
                            :items="
                              getLanguage === 'en'
                                ? lengthOfStayListEnglish
                                : lengthOfStayListIndo
                            "
                          >
                          </v-select>
                        </v-col>
                      </v-row>

                      <hr
                        style="border-top: 1px dashed #c1c2c4"
                        class="hidden md:block mt-4"
                      />

                      <span
                        class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
                      ></span>

                      <v-col col="12" md="12" class="text-title">
                        <v-row>
                          <span class="stepTitle">
                            <!-- {{ lang[getLanguage].TEST_DRIVE_SHIPPING }} -->
                            {{
                              getLanguage == "en"
                                ? "Address based on KTP"
                                : "Alamat berdasarkan KTP"
                            }}
                          </span>
                        </v-row>
                      </v-col>

                      <v-checkbox
                        v-model="sameAddress"
                        :label="
                          getLanguage == 'en'
                            ? 'Same as Residence Address'
                            : 'Sama dengan Alamat Tempat Tinggal'
                        "
                      ></v-checkbox>
                      <div v-if="!sameAddress">
                        <v-row class="mt-3">
                          <v-col cols="12" md="6" class="py-0">
                            <span
                              class="text-base font-normal font-work-sans text-charged-grey-400"
                              >{{ lang[getLanguage].TEST_DRIVE_PROVINCE }}</span
                            >
                            <v-text-field
                              class="custom-d-input custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              v-model="provinceKtp"
                              :placeholder="
                                getLanguage === 'en'
                                  ? 'ex: DKI Jakarta'
                                  : 'contoh: DKI Jakarta'
                              "
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" class="py-0">
                            <span
                              class="text-base font-normal font-work-sans text-charged-grey-400"
                              >{{ lang[getLanguage].TEST_DRIVE_CITY }}</span
                            >
                            <v-text-field
                              class="custom-d-input custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              v-model="cityKtp"
                              :placeholder="
                                getLanguage === 'en'
                                  ? 'ex: Jakarta Selatan'
                                  : 'contoh: Jakarta Selatan'
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6" class="py-0">
                            <span
                              class="text-base font-normal font-work-sans text-charged-grey-400"
                              >{{
                                lang[getLanguage].TEST_DRIVE_DISCTRICT
                              }}</span
                            >
                            <v-text-field
                              class="custom-d-input custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              v-model="districtKtp"
                              :placeholder="
                                getLanguage === 'en'
                                  ? 'ex: Cilandak'
                                  : 'contoh: Cilandak'
                              "
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" class="py-0">
                            <span
                              class="text-base font-normal font-work-sans text-charged-grey-400"
                              >{{
                                lang[getLanguage].TEST_DRIVE_SUB_DISCTRICT
                              }}</span
                            >
                            <v-text-field
                              class="custom-d-input custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              v-model="subDistrictKtp"
                              :placeholder="
                                getLanguage === 'en'
                                  ? 'ex: Lebak bulus'
                                  : 'contoh: Lebak bulus'
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <!-- <v-layout style="width: 100%" pb-4> -->
                        <v-row>
                          <v-col cols="12" class="py-0">
                            <span
                              class="text-base font-normal font-work-sans text-charged-grey-400"
                              >{{ lang[getLanguage].TEST_DRIVE_ADDRESS }}</span
                            >
                            <v-text-field
                              class="custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              v-model="addressKtp"
                              :placeholder="
                                getLanguage === 'en'
                                  ? 'ex: Jl. Sudirman VII No. 01'
                                  : 'contoh: Jl. Sudirman VII No. 01'
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="3" class="py-0">
                            <span
                              class="text-base font-normal font-work-sans text-charged-grey-400"
                              >{{ lang[getLanguage].TEST_DRIVE_ZIP_CODE }}</span
                            >
                            <v-text-field
                              class="custom-d-input custom-combobox-d"
                              style="width: 100%"
                              outlined
                              dense
                              filled
                              v-model="postalCodeKtp"
                              :placeholder="
                                getLanguage === 'en'
                                  ? 'ex: 15416'
                                  : 'contoh: 15416'
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                      <!-- <div v-if="!isMobileDevice">
                        <v-radio-group v-model="radioGroupPickup">
                          <v-radio :value="1">
                            <template v-slot:label>
                              <v-card
                                :class="
                                  radioGroupPickup === 1
                                    ? 'radio-selection-shipping selected-selection'
                                    : 'radio-selection-shipping'
                                "
                                flat
                              >
                                <v-layout class="py-2 px-1">
                                  <v-col cols="12">
                                    <v-row>
                                      <span
                                        ><b>{{
                                          lang[getLanguage].TEST_DRIVE_KEMANG
                                        }}</b></span
                                      >
                                    </v-row>
                                  </v-col>
                                </v-layout>
                              </v-card>
                            </template>
                          </v-radio>
                          <v-row class="mt-1 md:mb-4">
                            <v-col col="12" md="6">
                              <v-container fluid pa-0>
                                <div class="mapouter">
                                  <div class="gmap_canvas">
                                    <iframe
                                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126913.99706923915!2d106.68313351640629!3d-6.255508899999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f32cd05932a1%3A0xdda4027b9b1244dc!2sCharged%20Indonesia!5e0!3m2!1sen!2sid!4v1667820488650!5m2!1sen!2sid"
                                      width="100%"
                                      height="200"
                                      id="gmap_canvas"
                                      frameborder="0"
                                      scrolling="no"
                                      marginheight="0"
                                      marginwidth="0"
                                    ></iframe>
                                  </div>
                                </div>
                              </v-container>
                            </v-col>

                            <v-col col="12" md="6">
                              <v-row class="mb-2">
                                <v-col cols="1" class="py-0"
                                  ><v-icon>mdi-map-marker</v-icon></v-col
                                >
                                <v-col cols="11" class="py-0">
                                  <p class="mb-0">
                                    Jl. Kemang Utara VII RT 002 RW 004, No. 21B,
                                    Bangka - Mampang Prapatan, Jakarta Selatan
                                    12730
                                  </p>

                                  <div
                                    class="font-work-sans mb-2 text-base text-charged-grey-300"
                                    style="margin-top: 2rem"
                                  >
                                    <p class="font-normal">
                                      {{
                                        getLanguage === "en"
                                          ? "We are available on:"
                                          : "Jam Operasional:"
                                      }}
                                    </p>
                                    <v-row>
                                      <v-col cols="4" class="py-0">
                                        <p
                                          class="font-medium"
                                          style="line-height: 20px"
                                        >
                                          {{
                                            getLanguage === "en"
                                              ? "Mon - Sat"
                                              : "Senin - Sabtu"
                                          }}
                                        </p>
                                      </v-col>
                                      <v-col cols="8" class="py-0">
                                        <p
                                          class="font-medium"
                                          style="line-height: 20px"
                                        >
                                          {{
                                            getLanguage === "en"
                                              ? ": 09.00 - 17:00 (WIB)"
                                              : ": 09.00 - 17:00 (WIB)"
                                          }}
                                        </p>
                                      </v-col>
                                    </v-row>
                                    <v-row class="my-0 -mt-2">
                                      <v-col cols="4" class="py-0">
                                        <p class="font-medium">
                                          {{
                                            getLanguage === "en"
                                              ? "Sun"
                                              : "Minggu"
                                          }}
                                        </p>
                                      </v-col>
                                      <v-col cols="8" class="py-0">
                                        <p class="font-medium">
                                          {{
                                            getLanguage === "en"
                                              ? ": Closed"
                                              : ": Tutup"
                                          }}
                                        </p>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>

                          <v-radio :value="2">
                            <template v-slot:label>
                              <v-card
                                :class="
                                  radioGroupPickup === 2
                                    ? 'radio-selection-shipping selected-selection'
                                    : 'radio-selection-shipping'
                                "
                                flat
                              >
                                <v-layout class="py-2 px-1">
                                  <v-col cols="12">
                                    <v-row>
                                      <span
                                        ><b>{{
                                          lang[getLanguage].TEST_DRIVE_CIKUPA
                                        }}</b></span
                                      >
                                    </v-row>
                                  </v-col>
                                </v-layout>
                              </v-card>
                            </template>
                          </v-radio>
                          <v-row class="mt-1">
                            <v-col col="12" md="6">
                              <v-container fluid pa-0>
                                <div class="mapouter">
                                  <div class="gmap_canvas">
                                    <iframe
                                      width="100%"
                                      height="200"
                                      id="gmap_canvas"
                                      src="https://maps.google.com/maps?q=industri%20charged%20mobilitas&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                      frameborder="0"
                                      scrolling="no"
                                      marginheight="0"
                                      marginwidth="0"
                                    ></iframe>
                                  </div>
                                </div>
                              </v-container>
                            </v-col>

                            <v-col col="12" md="6">
                              <v-row class="mb-2">
                                <v-col cols="1" class="py-0"
                                  ><v-icon>mdi-map-marker</v-icon></v-col
                                >
                                <v-col cols="11" class="py-0">
                                  <p class="mb-0">
                                    Jl. Bhumimas IV No.8 Talaga, Cikupa,
                                    Tangerang Regency, Banten 15710
                                  </p>
                                  <div
                                    class="font-work-sans mb-2 text-base text-charged-grey-300"
                                    style="margin-top: 2rem"
                                  >
                                    <p class="font-normal">
                                      {{
                                        getLanguage === "en"
                                          ? "We are available on:"
                                          : "Jam Operasional:"
                                      }}
                                    </p>
                                    <v-row>
                                      <v-col cols="4" class="py-0">
                                        <p
                                          class="font-medium"
                                          style="line-height: 20px"
                                        >
                                          {{
                                            getLanguage === "en"
                                              ? "Mon - Sat"
                                              : "Senin - Sabtu"
                                          }}
                                        </p>
                                      </v-col>
                                      <v-col cols="8" class="py-0">
                                        <p
                                          class="font-medium"
                                          style="line-height: 20px"
                                        >
                                          {{
                                            getLanguage === "en"
                                              ? ": 09.00 - 17:00 (WIB)"
                                              : ": 09.00 - 17:00 (WIB)"
                                          }}
                                        </p>
                                      </v-col>
                                    </v-row>
                                    <v-row class="my-0 -mt-2">
                                      <v-col cols="4" class="py-0">
                                        <p class="font-medium">
                                          {{
                                            getLanguage === "en"
                                              ? "Sun"
                                              : "Minggu"
                                          }}
                                        </p>
                                      </v-col>
                                      <v-col cols="8" class="py-0">
                                        <p class="font-medium">
                                          {{
                                            getLanguage === "en"
                                              ? ": Closed"
                                              : ": Tutup"
                                          }}
                                        </p>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </div>
                      <div v-if="isMobileDevice">
                        <v-radio-group v-model="radioGroupPickup">
                          <v-radio :value="1">
                            <template v-slot:label>
                              <v-card
                                :class="
                                  radioGroupPickup === 1
                                    ? 'radio-selection-shipping selected-selection'
                                    : 'radio-selection-shipping'
                                "
                                flat
                              >
                                <v-layout>
                                  <span
                                    ><b>{{
                                      lang[getLanguage].TEST_DRIVE_KEMANG
                                    }}</b></span
                                  >
                                </v-layout>
                              </v-card>
                            </template>
                          </v-radio>
                          <v-row class="my-0 mt-1">
                            <v-col cols="12" md="6">
                              <v-container fluid pa-0>
                                <div class="mapouter">
                                  <div class="gmap_canvas">
                                    <iframe
                                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126913.99706923915!2d106.68313351640629!3d-6.255508899999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f32cd05932a1%3A0xdda4027b9b1244dc!2sCharged%20Indonesia!5e0!3m2!1sen!2sid!4v1667820488650!5m2!1sen!2sid"
                                      width="100%"
                                      height="200"
                                      id="gmap_canvas"
                                      frameborder="0"
                                      scrolling="no"
                                      marginheight="0"
                                      marginwidth="0"
                                      class="pl-0"
                                    ></iframe>
                                  </div>
                                </div>
                              </v-container>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-row class="mb-2">
                                <v-col cols="1" class="py-0"
                                  ><v-icon>mdi-map-marker</v-icon></v-col
                                >
                                <v-col cols="11" class="py-0">
                                  <p
                                    class="mb-0 text-sm text-charged-grey-300"
                                    style="letter-spacing: -0.28px"
                                  >
                                    Jl. Kemang Utara VII RT 002 RW 004, No. 21B,
                                    Bangka - Mampang Prapatan, Jakarta Selatan
                                    12730
                                  </p>

                                  <div
                                    class="font-work-sans mb-2 text-sm text-charged-grey-300"
                                    style="margin-top: 2rem"
                                  >
                                    <p class="font-normal">
                                      {{
                                        getLanguage === "en"
                                          ? "We are available on:"
                                          : "Jam Operasional:"
                                      }}
                                    </p>
                                    <v-row>
                                      <v-col cols="4" class="py-0">
                                        <p
                                          class="font-medium"
                                          style="line-height: 20px"
                                        >
                                          {{
                                            getLanguage === "en"
                                              ? "Mon - Sat"
                                              : "Senin - Sabtu"
                                          }}
                                        </p>
                                      </v-col>
                                      <v-col cols="8" class="py-0">
                                        <p
                                          class="font-medium"
                                          style="line-height: 20px"
                                        >
                                          {{
                                            getLanguage === "en"
                                              ? ": 09.00 - 17:00 (WIB)"
                                              : ": 09.00 - 17:00 (WIB)"
                                          }}
                                        </p>
                                      </v-col>
                                    </v-row>
                                    <v-row class="my-0 -mt-2">
                                      <v-col cols="4" class="py-0">
                                        <p class="font-medium">
                                          {{
                                            getLanguage === "en"
                                              ? "Sun"
                                              : "Minggu"
                                          }}
                                        </p>
                                      </v-col>
                                      <v-col cols="8" class="py-0">
                                        <p class="font-medium">
                                          {{
                                            getLanguage === "en"
                                              ? ": Closed"
                                              : ": Tutup"
                                          }}
                                        </p>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-radio :value="2">
                            <template v-slot:label>
                              <v-card
                                :class="
                                  radioGroupPickup === 2
                                    ? 'radio-selection-shipping selected-selection'
                                    : 'radio-selection-shipping'
                                "
                                flat
                              >
                                <v-layout>
                                  <span
                                    ><b>{{
                                      lang[getLanguage].TEST_DRIVE_CIKUPA
                                    }}</b></span
                                  >
                                </v-layout>
                              </v-card>
                            </template>
                          </v-radio>
                          <v-row class="my-0 mt-1">
                            <v-col cols="12" md="6">
                              <v-container fluid pa-0>
                                <div class="mapouter">
                                  <div class="gmap_canvas">
                                    <iframe
                                      width="100%"
                                      height="200"
                                      id="gmap_canvas"
                                      src="https://maps.google.com/maps?q=industri%20charged%20mobilitas&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                      frameborder="0"
                                      scrolling="no"
                                      marginheight="0"
                                      marginwidth="0"
                                    ></iframe>
                                  </div>
                                </div>
                              </v-container>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-row class="mb-2">
                                <v-col cols="1" class="py-0"
                                  ><v-icon>mdi-map-marker</v-icon></v-col
                                >
                                <v-col cols="11" class="py-0">
                                  <p
                                    class="mb-0 text-sm text-charged-grey-300"
                                    style="letter-spacing: -0.28px"
                                  >
                                    Jl. Bhumimas IV No.8 Talaga, Cikupa,
                                    Tangerang Regency, Banten 15710
                                  </p>

                                  <div
                                    class="font-work-sans mb-2 text-sm text-charged-grey-300"
                                    style="margin-top: 2rem"
                                  >
                                    <p>
                                      {{
                                        getLanguage === "en"
                                          ? "We are available on:"
                                          : "Jam Operasional:"
                                      }}
                                    </p>
                                    <v-row>
                                      <v-col cols="4" class="py-0">
                                        <p
                                          class="font-medium"
                                          style="line-height: 20px"
                                        >
                                          {{
                                            getLanguage === "en"
                                              ? "Mon - Sat"
                                              : "Senin - Sabtu"
                                          }}
                                        </p>
                                      </v-col>
                                      <v-col cols="8" class="py-0">
                                        <p
                                          class="font-medium"
                                          style="line-height: 20px"
                                        >
                                          {{
                                            getLanguage === "en"
                                              ? ": 09.00 - 17:00 (WIB)"
                                              : ": 09.00 - 17:00 (WIB)"
                                          }}
                                        </p>
                                      </v-col>
                                    </v-row>
                                    <v-row class="my-0 -mt-2">
                                      <v-col cols="4" class="py-0">
                                        <p class="font-medium">
                                          {{
                                            getLanguage === "en"
                                              ? "Sun"
                                              : "Minggu"
                                          }}
                                        </p>
                                      </v-col>
                                      <v-col cols="8" class="py-0">
                                        <p class="font-medium">
                                          {{
                                            getLanguage === "en"
                                              ? ": Closed"
                                              : ": Tutup"
                                          }}
                                        </p>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </div> -->

                      <span
                        class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
                      ></span>

                      <v-layout pt-4>
                        <v-spacer></v-spacer>
                        <v-btn
                          :style="
                            isMobileDevice
                              ? 'width: 100%;color: #fff;text-transform: none !important;'
                              : 'width: 20%;color: #fff;text-transform: none !important;'
                          "
                          color="#6B4693"
                          :disabled="
                            loadingBtnStep ||
                            serviceProvince === '' ||
                            serviceCity === '' ||
                            serviceDistrict === '' ||
                            serviceSubDistrict === '' ||
                            address === '' ||
                            serviceZip === '' ||
                            homeOwnershipStatus === '' ||
                            lengthOfStay === '' ||
                            (provinceKtp === '' && !sameAddress) ||
                            (cityKtp === '' && !sameAddress) ||
                            (districtKtp === '' && !sameAddress) ||
                            (subDistrictKtp === '' && !sameAddress) ||
                            (addressKtp === '' && !sameAddress) ||
                            (postalCodeKtp === '' && !sameAddress)
                          "
                          @click="stepThreeComplete"
                          :loading="loadingBtnStep"
                        >
                          {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
                          <!-- <v-icon style="color: white"
                            >mdi-chevron-right</v-icon
                          > -->
                        </v-btn>
                      </v-layout>
                    </v-card>
                  </v-col>

                  <!-- <v-col cols="12" :sm="windowWidth < 770 ? 12 : 5">
                    <v-card flat mb-4>
                      <span class="stepTitle">Your Booking Fee</span>
                      <p class="mt-2"></p>
                      <v-layout pr-2 pt-2 class="booking-fee">
                        <v-img
                          :src="selectedBike.image"
                          max-width="132"
                          max-height="86"
                        >
                        </v-img>
                        <v-col col="12" md="5" class="booking-fee-title">
                          <span v-if="getLanguage === 'en'"
                            ><b>{{ selectedBike.name }}</b> Booking Fee</span
                          >
                          <span v-else>
                            Biaya Pemesanan <b>{{ selectedBike.name }}</b></span
                          >
                          <span>Rp 100.000</span>
                        </v-col>
                      </v-layout>

                      <v-layout pr-2 class="booking-fee">
                        <span class="booking-fee-amount-title">{{
                          lang[getLanguage].PRODUCT_CUSTOM_TOTAL
                        }}</span>
                        <v-col col="12" md="5" class="booking-fee-amount">
                          <span>Rp 100.000</span>
                        </v-col>
                      </v-layout>
                    </v-card>

                    <v-layout pt-4 pl-2 pr-2>
                      <v-btn
                        style="
                          width: 100%;
                          color: #fff;
                          text-transform: none !important;
                        "
                        color="#6B4693"
                        @click="stepper = 4"
                        :disabled="selectedPricing == null"
                      >
                        {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
                      </v-btn>
                    </v-layout>
                  </v-col> -->
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="4" class="px-2 md:px-6">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-col class="stepBack pt-0">
                      <v-btn
                        class="pl-0 md:pl-4"
                        @click.stop="backStep"
                        text
                        color="primary"
                        :ripple="false"
                        ><v-icon color="primary" class="mr-1"
                          >mdi-chevron-left</v-icon
                        >{{ lang[getLanguage].BUT_BACK }}
                      </v-btn>
                    </v-col>

                    <span class="stepTitle">{{
                      lang[getLanguage].SUBSCRIBE_PRIVATE_DOCUMENT_TTILE
                    }}</span>

                    <v-col col="12" md="10" class="text-title">
                      <v-row>
                        <span class="text-info">
                          {{
                            lang[getLanguage]
                              .SUBSCRIBE_PRIVATE_DOCUMENT_DESCRIPTION
                          }}
                        </span>
                      </v-row>
                    </v-col>

                    <div v-for="(item, index) in file" :key="index.label">
                      <div
                        v-if="file[`${index}`].user.includes(selectedUser)"
                        class="mt-5"
                      >
                        <span
                          class="hidden w-full border border-dashed md:inline-block"
                        ></span>
                        <span
                          class="md:hidden inline-block h-2 bg-charged-grey-700 w-full"
                        ></span>
                        <v-layout class="mt-6" style="width: 100%">
                          <v-layout column class="relative">
                            <span
                              class="text-base text-charged-grey-400 font-normal font-work-sans"
                              >{{
                                getLanguage === "en"
                                  ? file[`${index}`].label.en
                                  : file[`${index}`].label.id
                              }}</span
                            >
                            <v-btn
                              class="w-56 capitalize rounded-lg relative z-10"
                              color="primary"
                              elevation="2"
                              outlined
                              v-if="file[`${index}`].file === null"
                              @click="fileInputClick(index)"
                              ><v-icon style="color: #6b4693" left>
                                mdi-plus </v-icon
                              >{{
                                lang[getLanguage]
                                  .SUBSCRIBE_PRIVATE_DOCUMENT_UPLOAD_LABEL
                              }}</v-btn
                            >
                            <v-row
                              v-if="file[`${index}`].file !== null"
                              class="relative z-10"
                            >
                              <v-col>
                                <v-btn
                                  class="w-56 capitalize rounded-lg"
                                  color="#858688"
                                  elevation="2"
                                  outlined
                                  v-if="file[`${index}`].file !== null"
                                >
                                  <v-layout>
                                    <v-icon
                                      class="mr-1"
                                      @click="fileInputClick(index)"
                                    >
                                      mdi-image-edit-outline
                                    </v-icon>
                                    <span
                                      class="text-truncate pt-1"
                                      :style="
                                        isMobileDevice
                                          ? 'max-width: 150px'
                                          : 'max-width: 150px'
                                      "
                                      @click="fileInputClick(index)"
                                    >
                                      {{ file[`${index}`]["file"]["name"] }}
                                    </span>
                                    <v-spacer></v-spacer>
                                  </v-layout>
                                </v-btn>

                                <v-btn
                                  class="mx-2"
                                  elevation="2"
                                  color="#ff4757"
                                  fab
                                  small
                                  outlined
                                  @click="fileDelete(index)"
                                >
                                  <v-icon color="#ff4757">
                                    mdi-delete-outline
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-file-input
                              class="style-file-input"
                              :rules="file[index].rulesFile"
                              accept="image/*,.pdf"
                              :id="index"
                              @change="onFilePicked()"
                              v-model="file[`${index}`].file"
                            ></v-file-input>
                          </v-layout>
                        </v-layout>
                        <v-col
                          col="12"
                          md="10"
                          class="text-title"
                          v-if="file[`${index}`].file === null"
                        >
                          <v-row>
                            <span class="text-info">
                              {{
                                lang[getLanguage]
                                  .SUBSCRIBE_PRIVATE_DOCUMENT_ITEM__DESCRIPTION
                              }}
                            </span>
                          </v-row>
                        </v-col>
                        <v-col
                          v-if="file[index].noted"
                          cols="12"
                          md="10"
                          class="px-0"
                        >
                          <span
                            class="font-work-sans text-sm font-normal text-charged-grey-400"
                          >
                            {{ file[index].noted }}
                          </span>
                        </v-col>
                      </div>
                    </div>

                    <span
                      class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mt-6 mb-3"
                    ></span>
                    <span
                      class="hidden w-full border border-dashed md:inline-block"
                    ></span>
                    <v-layout pt-4>
                      <v-spacer></v-spacer>
                      <v-btn
                        :style="
                          isMobileDevice
                            ? 'width: 100%;color: #fff;text-transform: none !important;'
                            : 'width: 20%;color: #fff;text-transform: none !important;'
                        "
                        color="#6B4693"
                        @click="stepFourComplete"
                        :disabled="validationStepFour"
                        :loading="loadingBtnStep"
                      >
                        {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
                        <!-- <v-icon style="color: white">mdi-chevron-right</v-icon> -->
                      </v-btn>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="5" class="px-2 md:px-6">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-col class="stepBack pt-0">
                      <v-btn
                        class="pl-0 md:pl-4"
                        @click.stop="backStep"
                        text
                        color="primary"
                        :ripple="false"
                        ><v-icon color="primary" class="mr-1"
                          >mdi-chevron-left</v-icon
                        >{{ lang[getLanguage].BUT_BACK }}
                      </v-btn>
                    </v-col>

                    <span class="stepTitle">{{
                      lang[getLanguage].PRODUCT_STEP_5
                    }}</span>

                    <span class="stepTitleSmall mt-10 inline-block">
                      {{
                        getLanguage == "en" ? "Bank Account *" : "Akun Bank *"
                      }}
                    </span>

                    <v-row class="mt-3">
                      <v-col cols="12" class="pb-0">
                        <span class="text-charged-grey-200">
                          {{
                            getLanguage == "en"
                              ? "Account Bank Name *"
                              : "Nama Akun Bank *"
                          }}
                        </span>
                        <v-select
                          class="custom-combobox-d"
                          style="width: 100%"
                          outlined
                          dense
                          filled
                          v-model="bankName"
                          :items="bankNameItems"
                          item-text="title"
                          item-value="value"
                          :placeholder="
                            getLanguage == 'en'
                              ? 'Choose Bank Name'
                              : 'Pilih Nama Bank'
                          "
                        >
                        </v-select>
                      </v-col>
                    </v-row>

                    <v-row v-if="bankName == 'Bank Lainnya'">
                      <v-col class="py-0">
                        <v-text-field
                          class="custom-d-input custom-combobox-d"
                          style="width: 100%"
                          outlined
                          dense
                          filled
                          v-model="bankNameOthers"
                          :placeholder="
                            getLanguage === 'en'
                              ? 'ex: CIMB NIAGA'
                              : 'contoh: CIMB NIAGA'
                          "
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6" class="py-0">
                        <span class="text-charged-grey-200">
                          {{
                            getLanguage == "en"
                              ? "Account Number *"
                              : "Nomor Akun *"
                          }}
                        </span>
                        <v-text-field
                          class="custom-d-input custom-combobox-d"
                          style="width: 100%"
                          outlined
                          dense
                          filled
                          v-model="accountBankNumber"
                          :placeholder="
                            getLanguage === 'en'
                              ? 'ex: 7321456789'
                              : 'contoh: 7321456789'
                          "
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <span class="text-charged-grey-200">
                          {{
                            getLanguage == "en"
                              ? "Account Holder's Name *"
                              : "Nama Pemilik Akun *"
                          }}</span
                        >
                        <v-text-field
                          class="custom-d-input custom-combobox-d"
                          style="width: 100%"
                          outlined
                          dense
                          filled
                          v-model="accountHoldersName"
                          :placeholder="
                            getLanguage === 'en'
                              ? 'ex: Jhon Doe'
                              : 'contoh: Jhon Doe'
                          "
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <hr
                      style="border-top: 1px dashed #c1c2c4"
                      class="hidden md:block md:mt-3"
                    />
                    <span
                      class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
                    ></span>

                    <v-col col="12" md="10" class="text-title">
                      <v-row>
                        <span class="stepTitleSmall">{{
                          lang[getLanguage].PRODUCT_PRICING_TITLE
                        }}</span>
                      </v-row>
                      <v-row>
                        <span
                          class="text-info text-base text-charged-grey-400 font-work-sans"
                          >{{
                            lang[getLanguage].PRODUCT_PRICING_DESCRIPTION
                          }}</span
                        >
                      </v-row>
                    </v-col>
                    <div v-if="!isMobileDevice">
                      <!-- disini -->
                      <v-radio-group v-model="selectedPricing" mandatory>
                        <v-row class="pricing ml-1 gap-x-3 flex-nowrap">
                          <v-col
                            cols="12"
                            md="3"
                            v-for="(item, index) in listPricing"
                            :key="index"
                            :class="
                              item.bikes.includes(selectedBike.name) ===
                                selectedPricing.bikes.includes(
                                  selectedBike.name
                                ) && item.plan.en === selectedPricing.plan.en
                                ? 'selected-selection p-0'
                                : 'p-0'
                            "
                            @click.stop="selectPricing(item)"
                          >
                            <v-card
                              v-if="
                                item.bikes.find((t) => selectedBike.name === t)
                              "
                              class="pricing-item"
                            >
                              <div
                                class="pricing-item-title text-center text-xl"
                                style="min-width: 160px; letter-spacing: -0.6px"
                              >
                                {{
                                  getLanguage === "en"
                                    ? item.plan.en
                                    : item.plan.id
                                }}
                              </div>
                              <div class="pricing-item-allprice">
                                <span
                                  class="font-work-sans pricing-item-price text-lg text-charged-grey-300"
                                  style="letter-spacing: -0.36px"
                                  >Rp{{
                                    selectedUser === "driver"
                                      ? formatPrice(item.dailyPrice) +
                                        "/" +
                                        lang[getLanguage].LABEL_DAY
                                      : formatPrice(item.price) +
                                        "/" +
                                        lang[getLanguage].LABEL_MO
                                  }}</span
                                >
                                <span
                                  class="font-work-sans pricing-item-billed text-charged-grey-400"
                                >
                                  {{
                                    selectedUser === "regular"
                                      ? lang[getLanguage].PRODUCT_BILLED_MONTHLY
                                      : lang[getLanguage].PRODUCT_BILLED_DAILY
                                  }}
                                </span>
                              </div>
                              <span class="pricing-item-bonus">{{
                                getLanguage === "en"
                                  ? item.bonus.en
                                  : item.bonus.id
                              }}</span>
                              <v-radio
                                label=""
                                color="primary"
                                :value="item"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </div>
                    <div v-if="isMobileDevice">
                      <!-- <div class="">
                        <div
                          :class="
                            item === selectedPricing ? 'selected-selection' : ''
                          "
                          v-for="(item, index) in listPricing"
                          :key="index"
                          @click.stop="selectPricing(item)"
                        >
                          <v-card
                            v-if="
                              item.bikes.find((t) => selectedBike.name === t)
                            "
                            class="pricing-item"
                          >
                            <span class="pricing-item-title">{{
                              getLanguage === "en" ? item.plan.en : item.plan.id
                            }}</span>
                            <div class="pricing-item-allprice">
                              <span class="pricing-item-price"
                                >Rp{{ formatPrice(item.price) }}/{{
                                  lang[getLanguage].LABEL_MO
                                }}</span
                              >
                              <span class="pricing-item-billed">{{
                                lang[getLanguage].PRODUCT_BILLED_MONTHLY
                              }}</span>
                            </div>
                            <span class="pricing-item-bonus">{{
                              getLanguage === "en"
                                ? item.bonus.en
                                : item.bonus.id
                            }}</span>
                          </v-card>
                        </div>
                      </div> -->
                      <div class="px-3">
                        <v-radio-group v-model="selectedPricing" mandatory>
                          <div
                            :class="
                              item.bikes.includes(selectedBike.name) ===
                                selectedPricing.bikes.includes(
                                  selectedBike.name
                                ) && item.plan.en === selectedPricing.plan.en
                                ? 'selected-selection mb-5 shadow-lg'
                                : 'mb-5'
                            "
                            v-for="(item, index) in listPricing"
                            :key="index"
                          >
                            <v-card
                              class="pricing-item"
                              :outlined="selectedBike.name !== t"
                              v-if="
                                item.bikes.find((t) => selectedBike.name === t)
                              "
                              @click.stop="selectPricing(item)"
                            >
                              <span class="pricing-item-title">{{
                                getLanguage === "en"
                                  ? item.plan.en
                                  : item.plan.id
                              }}</span>
                              <div class="pricing-item-allprice">
                                <span class="pricing-item-price"
                                  >Rp
                                  {{
                                    selectedUser === "driver"
                                      ? formatPrice(item.dailyPrice) +
                                        "/" +
                                        lang[getLanguage].LABEL_DAY
                                      : formatPrice(item.price) +
                                        "/" +
                                        lang[getLanguage].LABEL_MO
                                  }}
                                </span>
                                <span class="pricing-item-billed">
                                  {{
                                    selectedUser === "regular"
                                      ? lang[getLanguage].PRODUCT_BILLED_MONTHLY
                                      : lang[getLanguage].PRODUCT_BILLED_DAILY
                                  }}
                                </span>
                              </div>
                              <v-radio
                                label=""
                                color="primary"
                                :value="item"
                              ></v-radio>
                            </v-card>
                          </div>
                        </v-radio-group>
                      </div>
                    </div>

                    <span
                      class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mt-2"
                    ></span>

                    <v-col col="12" md="12" class="text-title-subscription">
                      <v-row>
                        <span
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                          >{{
                            lang[getLanguage].PRODUCT_SUBSCRIBE_DETAIL
                          }}</span
                        >
                      </v-row>
                    </v-col>

                    <v-col col="12" md="12" class="subscription-detail">
                      <v-row v-if="selectedPricing != null">
                        <p
                          v-if="getLanguage === 'en'"
                          class="text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          {{ selectedBike.name }} Subscription
                        </p>
                        <p
                          v-else
                          class="text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          Biaya Langganan Motor
                          {{ selectedBike.name }}
                        </p>
                        <v-spacer></v-spacer>
                        <p
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          Rp{{
                            selectedUser === "driver"
                              ? formatPrice(selectedPricing.dailyPrice) +
                                "/" +
                                lang[getLanguage].LABEL_DAY
                              : formatPrice(selectedPricing.price) +
                                "/" +
                                lang[getLanguage].LABEL_MO
                          }}
                        </p>
                      </v-row>
                      <v-row v-if="selectedCharger">
                        <p
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          {{ selectedCharger.title }}
                        </p>
                        <v-spacer></v-spacer>
                        <!-- <span class="text-info">Rp3.700/hari</span> -->
                        <p
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          Rp{{
                            selectedUser === "driver"
                              ? formatPrice(selectedCharger.dailyPrice) +
                                "/" +
                                lang[getLanguage].LABEL_DAY
                              : formatPrice(selectedCharger.price) +
                                "/" +
                                lang[getLanguage].LABEL_MO
                          }}
                        </p>
                      </v-row>
                      <v-row v-if="selectedBattery">
                        <p
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          {{
                            getLanguage === "en"
                              ? selectedBattery.title.en
                              : selectedBattery.title.id
                          }}
                        </p>
                        <v-spacer></v-spacer>
                        <!-- <span class="text-info">Rp15.000/hari</span> -->
                        <p
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          Rp{{
                            selectedUser === "driver"
                              ? formatPrice(selectedBattery.dailyPrice) +
                                "/" +
                                lang[getLanguage].LABEL_DAY
                              : formatPrice(selectedBattery.price) +
                                "/" +
                                lang[getLanguage].LABEL_MO
                          }}
                        </p>
                      </v-row>
                      <v-row v-if="selectedRear !== null">
                        <p
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          {{
                            getLanguage === "en"
                              ? selectedRear.conclusion.en
                              : selectedRear.conclusion.id
                          }}
                        </p>
                        <v-spacer></v-spacer>
                        <p
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          Rp{{
                            selectedUser === "driver"
                              ? formatPrice(selectedRear.dailyPrice) +
                                "/" +
                                lang[getLanguage].LABEL_DAY
                              : formatPrice(selectedRear.price) +
                                "/" +
                                lang[getLanguage].LABEL_MO
                          }}
                        </p>
                      </v-row>
                      <v-row>
                        <p
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          {{ lang[getLanguage].LABEL_TAX }}
                        </p>
                        <v-spacer></v-spacer>
                        <p
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          <!-- Rp0/{{
                            selectedUser === "driver"
                              ? lang[getLanguage].LABEL_DAY
                              : lang[getLanguage].LABEL_MO
                          }} -->
                          {{
                            getLanguage == "en" ? "Included" : "Sudah Termasuk"
                          }}
                        </p>
                      </v-row>
                      <v-row>
                        <p
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          {{ lang[getLanguage].LABEL_INSURANCE }}
                        </p>
                        <v-spacer></v-spacer>
                        <p
                          class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
                        >
                          <!-- Rp0/{{
                            selectedUser === "driver"
                              ? lang[getLanguage].LABEL_DAY
                              : lang[getLanguage].LABEL_MO
                          }} -->
                          {{
                            getLanguage == "en" ? "Included" : "Sudah Termasuk"
                          }}
                        </p>
                      </v-row>
                      <!-- <v-row v-if="selectedPricing != null">
                        <span class="text-info">{{
                          lang[getLanguage].LABEL_TAX
                        }}</span>
                        <v-spacer></v-spacer>
                        <span class="text-info">Included</span>
                      </v-row> -->
                      <!-- <v-row v-if="selectedPricing != null">
                        <span class="text-info">{{
                          lang[getLanguage].LABEL_INSURANCE
                        }}</span>
                        <v-spacer></v-spacer>
                        <span class="text-info">Included</span>
                      </v-row> -->
                    </v-col>

                    <v-layout>
                      <v-col col="12" md="9" class="text-title">
                        <v-row>
                          <span
                            class="stepTitle text-sm md:text-lg font-semibold font-work-sans"
                          >
                            <!-- {{
                            selectedUser === "driver"
                              ? lang[getLanguage].PRODUCT_DAILY_TOTAL_TITLE
                              : lang[getLanguage].PRODUCT_MONTHLY_TOTAL_TITLE
                          }} -->
                            {{
                              selectedUser === "driver"
                                ? getLanguage == "en"
                                  ? "Total Subscription Cost per Day"
                                  : "Total Biaya Langganan per Hari"
                                : getLanguage == "en"
                                ? "Total Subscription Cost per Month"
                                : "Total Biaya Langganan per Bulan"
                            }}
                          </span>
                        </v-row>
                        <!-- <v-row>
                          <span class="text-info">{{
                            lang[getLanguage].PRODUCT_MONTHLY_TOTAL_DESCRIPTION
                          }}</span>
                        </v-row> -->
                      </v-col>
                      <v-col col="12" md="3" class="text-title font-semibold">
                        <v-row>
                          <v-spacer></v-spacer>
                          <span
                            class="text-info text-sm md:text-lg font-semibold"
                          >
                            Rp
                            {{ formatPrice(totalPrice) }}/{{
                              selectedUser === "driver"
                                ? lang[getLanguage].LABEL_DAY
                                : lang[getLanguage].LABEL_MO
                            }}
                          </span>
                        </v-row>
                      </v-col>
                    </v-layout>
                    <v-layout>
                      <v-col cols="12" md="8" class="text-title pt-0">
                        <v-row>
                          <span
                            class="text-info text-sm text-charged-grey-400 font-work-sans"
                            >{{
                              lang[getLanguage]
                                .PRODUCT_MONTHLY_TOTAL_DESCRIPTION
                            }}</span
                          >
                        </v-row>
                      </v-col>
                    </v-layout>

                    <v-row class="mt-3">
                      <v-col class="pb-0">
                        <p
                          class="text-sm md:text-lg font-semibold font-work-sans"
                        >
                          {{
                            getLanguage == "en"
                              ? "Deposit Fee"
                              : "Biaya Deposit"
                          }}
                          <span class="cursor-pointer">
                            <v-icon size="medium" @click="dialogDeposit = true"
                              >mdi-information-outline</v-icon
                            >
                          </span>
                        </p>
                      </v-col>
                      <v-col class="pb-0">
                        <p
                          class="text-sm md:text-lg font-semibold font-work-sans text-right"
                        >
                          {{
                            selectedUser === "driver"
                              ? "Rp 300.000"
                              : "Rp" + formatPrice(selectedPricing.price)
                          }}
                        </p>
                        <p
                          v-if="selectedUser !== 'driver'"
                          class="text-sm text-charged-grey-200 font-work-sans text-right -mt-5"
                        >
                          {{
                            getLanguage == "en"
                              ? "(1 Month Subscription Fee)"
                              : "(Biaya Berlangganan 1 Bulan)"
                          }}
                        </p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="8" class="pt-0">
                        <p
                          v-if="getLanguage == 'id'"
                          class="font-work-sans text-sm font-normal text-charged-grey-400"
                        >
                          Ini adalah biaya deposit yang perlu dibayar sebelum
                          memulai berlangganan motor Charged. Deposit hanya
                          dibayarkan satu kali saat pengambilan motor dan bisa
                          dikembalikan setelah berlangganan berakhir dengan
                          ketentuan berlaku.
                        </p>
                        <p
                          v-if="getLanguage == 'en'"
                          class="font-work-sans text-sm font-normal text-charged-grey-400"
                        >
                          This is a deposit fee that needs to be paid before
                          starting a Charged motorcycle subscription. The
                          deposit is only paid once when picking up the
                          motorbike and can be returned after the subscription
                          ends with the conditions in effect.
                        </p>
                      </v-col>
                    </v-row>

                    <hr
                      style="border-top: 1px dashed #c1c2c4"
                      class="hidden md:block"
                    />
                    <span
                      class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
                    ></span>

                    <!-- <v-layout pt-4 pl-2 pr-2> -->
                    <v-layout pt-4>
                      <v-spacer></v-spacer>
                      <v-btn
                        :style="
                          isMobileDevice
                            ? 'width: 100%;color: #fff;text-transform: none !important;'
                            : 'width: 20%;color: #fff;text-transform: none !important;'
                        "
                        color="#6B4693"
                        @click="stepFiveComplete"
                        :disabled="
                          loadingBtnStep ||
                          bankName == '' ||
                          accountBankNumber == '' ||
                          accountHoldersName == '' ||
                          selectedPricing == null
                        "
                        :loading="loadingBtnStep"
                      >
                        {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
                        <!-- <v-icon style="color: white">mdi-chevron-right</v-icon> -->
                      </v-btn>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="6" class="px-2 md:px-6">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-col class="stepBack pt-0">
                      <v-btn
                        class="pl-0 md:pl-4"
                        @click.stop="backStep"
                        text
                        color="primary"
                        :ripple="false"
                        ><v-icon color="primary" class="mr-1"
                          >mdi-chevron-left</v-icon
                        >{{ lang[getLanguage].BUT_BACK }}
                      </v-btn>
                    </v-col>

                    <span class="stepTitle">{{
                      lang[getLanguage].REVIEW_PRODUCT_STEP_1
                    }}</span>

                    <v-layout>
                      <v-col col="12" md="12">
                        <!-- <v-col col="12" md="10" class="text-title">
                          <v-row>
                            <span class="stepTitleSmall">{{
                              lang[getLanguage].PRODUCT_ORDER_TITLE
                            }}</span>
                          </v-row>
                        </v-col> -->
                        <v-row>
                          <v-col col="12" md="5">
                            <v-container fluid pa-0>
                              <v-img
                                :src="selectedBike.image"
                                max-width="250"
                              ></v-img>
                            </v-container>
                          </v-col>

                          <v-col col="12" md="7">
                            <v-row class="your-order">
                              <p>
                                <b>{{ selectedBike.name }}</b>
                              </p>
                              <v-layout
                                v-if="selectedColor != null"
                                class="mb-3"
                              >
                                <v-icon
                                  class="radio-selection-color"
                                  :color="selectedColor.color"
                                  >mdi-circle
                                </v-icon>
                                -
                                {{
                                  getLanguage === "en"
                                    ? selectedColor.title.en
                                    : selectedColor.title.id
                                }}
                              </v-layout>
                              <!-- <v-row>
                                <v-col col="12" md="5">
                                  <p>{{ lang[getLanguage].LABEL_CHARGER }}</p>
                                  <p>
                                    {{ lang[getLanguage].LABEL_BATTERY }}
                                  </p>
                                  <p>Rear Box</p>
                                </v-col>
                                <v-col col="12" md="7">
                                  <p v-if="selectedCharger != null">
                                    :
                                    {{ selectedCharger.title }}
                                  </p>
                                  <p v-if="selectedBattery != null">
                                    :
                                    {{
                                      getLanguage === "en"
                                        ? selectedBattery.title.en
                                        : selectedBattery.title.id
                                    }}
                                  </p>
                                  <p v-if="selectedRear != null">
                                    :
                                    {{
                                      getLanguage === "en"
                                        ? selectedRear.title.en
                                        : selectedRear.title.id
                                    }}
                                  </p>
                                </v-col>
                              </v-row> -->
                              <v-layout style="width: 100%">
                                <v-layout>
                                  <span
                                    class="review-order-item-label"
                                    style="min-width: 150px"
                                    >{{ lang[getLanguage].LABEL_CHARGER }}</span
                                  >
                                  :
                                  <span
                                    v-if="selectedCharger != null"
                                    class="review-order-item-label"
                                    style="margin-left: 20px"
                                    >{{ selectedCharger.title }}</span
                                  >
                                </v-layout>
                              </v-layout>
                              <v-layout style="width: 100%">
                                <v-layout>
                                  <span
                                    class="review-order-item-label"
                                    style="min-width: 150px"
                                    >{{ lang[getLanguage].LABEL_BATTERY }}</span
                                  >
                                  <span class="review-order-item-label">:</span>
                                  <span
                                    v-if="selectedBattery != null"
                                    class="review-order-item-label"
                                    style="margin-left: 20px"
                                  >
                                    {{
                                      getLanguage === "en"
                                        ? selectedBattery.qty
                                        : selectedBattery.qty
                                    }}
                                    <span
                                      v-if="
                                        getLanguage == 'en' &&
                                        selectedBattery.qty > 1
                                      "
                                      class="text-sm text-charged-grey-400"
                                      >(Additional battery included)</span
                                    >
                                    <span
                                      v-if="
                                        getLanguage == 'id' &&
                                        selectedBattery.qty > 1
                                      "
                                      class="text-sm text-charged-grey-400"
                                      >(Termasuk tambahan baterai)</span
                                    >
                                  </span>
                                </v-layout>
                              </v-layout>
                              <!-- <v-row>
                                <v-col class="col-5">
                                  <p>{{ lang[getLanguage].LABEL_CHARGER }}</p>
                                </v-col>
                                <v-col class="col-7">
                                  <p v-if="selectedCharger != null">
                                    :
                                    {{ selectedCharger.title }}
                                  </p>
                                </v-col>
                              </v-row> -->
                              <!-- <v-row>
                                <v-col class="col-5">
                                  <p>
                                    {{ lang[getLanguage].LABEL_BATTERY }}
                                  </p>
                                </v-col>
                                <v-col class="col-7">
                                  <p v-if="selectedBattery != null">
                                    :
                                    {{
                                      getLanguage === "en"
                                        ? selectedBattery.conclusion.en
                                        : selectedBattery.conclusion.id
                                    }}
                                  </p>
                                </v-col>
                              </v-row> -->
                              <!-- <v-layout style="width: 100%">
                                <v-layout>
                                  <span
                                    class="review-order-item-label"
                                    style="min-width: 150px"
                                    >{{ lang[getLanguage].LABEL_BATTERY }}</span
                                  >
                                  <span class="review-order-item-label">:</span>
                                  <span
                                    v-if="selectedBattery != null"
                                    class="review-order-item-label"
                                    style="margin-left: 20px"
                                    >{{
                                      getLanguage === "en"
                                        ? selectedBattery.conclusion.en
                                        : selectedBattery.conclusion.id
                                    }}</span
                                  >
                                </v-layout>
                              </v-layout> -->
                              <!-- <v-row v-if="selectedUser === 'driver'">
                                <v-col class="col-5">
                                  <p>Rear Box</p>
                                </v-col>
                                <v-col class="col-7">
                                  <p v-if="selectedRear != null">
                                    :
                                    {{
                                      getLanguage === "en"
                                        ? selectedRear.conclusion.en
                                        : selectedRear.conclusion.id
                                    }}
                                  </p>
                                </v-col>
                              </v-row> -->
                              <v-layout
                                style="width: 100%"
                                v-if="selectedRear != null"
                              >
                                <v-layout class="mt-1">
                                  <span
                                    class="review-order-item-label"
                                    style="min-width: 150px"
                                    >Tambahan Aksesoris</span
                                  >
                                  <span class="review-order-item-label">:</span>
                                  <span
                                    v-if="selectedRear != null"
                                    class="review-order-item-label"
                                    style="margin-left: 20px"
                                    >{{
                                      getLanguage === "en"
                                        ? selectedRear.conclusion.en
                                        : selectedRear.conclusion.id
                                    }}</span
                                  >
                                </v-layout>
                              </v-layout>
                              <p
                                v-if="selectedPricing != null"
                                class="mt-5 font-bold"
                              >
                                {{ lang[getLanguage].SUBCRIPTION_TIME_LABEL }}
                                <b>
                                  {{
                                    getLanguage === "en"
                                      ? selectedPricing.plan.en
                                      : selectedPricing.plan.id
                                  }}</b
                                >
                                <!-- ({{
                                  getLanguage === "en"
                                    ? selectedPricing.bonus.en
                                    : selectedPricing.bonus.id
                                }}) -->
                              </p>

                              <v-btn
                                @click="stepChanged(1)"
                                text
                                color="primary"
                                :ripple="false"
                                class="text-capitalize no-background-hover px-3"
                              >
                                <v-row>
                                  <p class="edit-button">
                                    {{ getLanguage == "en" ? "Edit" : "Ubah" }}
                                  </p>
                                </v-row>
                              </v-btn>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-layout>

                    <hr
                      style="border-top: 1px dashed #c1c2c4; margin: 1rem 0"
                      class="hidden md:block"
                    />
                    <span
                      class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
                    ></span>

                    <v-layout>
                      <!-- <v-col cols="12"> -->
                      <div>
                        <v-col class="text-title">
                          <v-row>
                            <span class="stepTitleSmall">{{
                              lang[getLanguage].REVIEW_PRODUCT_STEP_2
                            }}</span>
                          </v-row>
                        </v-col>
                        <div v-if="isMobileDevice">
                          <v-row class="mt-3">
                            <v-col
                              col="12"
                              md="12"
                              class="customer-information"
                            >
                              <span class="customer-information-title mb-2">{{
                                lang[getLanguage].ACC_PERSONAL
                              }}</span>
                              <span class="customer-information-name">{{
                                name
                              }}</span>
                              <span class="customer-information-phone">{{
                                phoneNumber
                              }}</span>
                              <span class="customer-information-email">{{
                                email
                              }}</span>
                            </v-col>
                          </v-row>
                          <v-row class="mb-6">
                            <v-col
                              col="12"
                              md="12"
                              class="customer-information"
                            >
                              <span class="customer-information-title mb-2">{{
                                lang[getLanguage].LABEL_RESIDENCE
                              }}</span>
                              <span class="customer-information-email"
                                >{{ address }},</span
                              >
                              <span class="customer-information-email"
                                >{{ serviceDistrict }},
                                {{ serviceSubDistrict }},
                                {{ serviceCity }},</span
                              >
                              <span class="customer-information-email"
                                >{{ serviceProvince }} {{ serviceZip }}</span
                              >
                            </v-col>
                          </v-row>
                        </div>
                        <div v-if="!isMobileDevice">
                          <v-row class="mt-3 mb-6">
                            <v-col
                              col="6"
                              md="6"
                              class="customer-information"
                              style="width: 600px"
                            >
                              <span class="customer-information-title mb-2">{{
                                lang[getLanguage].ACC_PERSONAL
                              }}</span>
                              <span class="customer-information-name">{{
                                name
                              }}</span>
                              <span class="customer-information-phone">{{
                                phoneNumber
                              }}</span>
                              <span class="customer-information-email">{{
                                email
                              }}</span>
                            </v-col>
                            <!-- </v-row> -->
                            <!-- <v-row class="mb-6"> -->
                            <v-col col="6" md="6" class="customer-information">
                              <span class="customer-information-title mb-2">{{
                                lang[getLanguage].LABEL_RESIDENCE
                              }}</span>
                              <span class="customer-information-email"
                                >{{ address }},</span
                              >
                              <span class="customer-information-email"
                                >{{ serviceDistrict }},
                                {{ serviceSubDistrict }},
                                {{ serviceCity }},</span
                              >
                              <span class="customer-information-email"
                                >{{ serviceProvince }} {{ serviceZip }}</span
                              >
                            </v-col>
                          </v-row>
                        </div>

                        <v-btn
                          @click="stepChanged(3)"
                          text
                          color="primary"
                          :ripple="false"
                          class="text-capitalize no-background-hover px-3"
                        >
                          <v-row class="">
                            <p class="edit-button">
                              {{ getLanguage == "en" ? "Edit" : "Ubah" }}
                            </p>
                          </v-row>
                        </v-btn>
                      </div>
                    </v-layout>

                    <hr
                      style="border-top: 1px dashed #c1c2c4; margin: 1rem 0"
                      class="hidden md:block"
                    />
                    <span
                      class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
                    ></span>

                    <v-card flat mb-4>
                      <span class="stepTitle">{{
                        lang[getLanguage].PRODUCT_SUBSCRIBE_POLICY_TITLE
                      }}</span>
                      <p class="mt-2"></p>
                      <!--  <span class="font-weight-medium">Congratulations!</span><br> -->
                      <!-- <span class="subInfo">
                        {{
                          getLanguage == "en"
                            ? subscriptionPolicyTrim.en
                            : subscriptionPolicyTrim.id
                        }} </span> -->
                      <span
                        class="subInfo text-sm md:text-base"
                        style="transition: height 2s"
                        v-if="subscriptionPolicyFull.readMoreActive"
                        >{{
                          getLanguage == "en"
                            ? subscriptionPolicyFull.en
                            : subscriptionPolicyFull.id
                        }}</span
                      >
                      <span
                        class="subInfo"
                        v-if="!subscriptionPolicyFull.readMoreActive"
                        >..</span
                      >
                      <!-- <span
                        v-if="!subscriptionPolicyFull.readMoreActive"
                        @click.stop="activateReadMore"
                        style="
                          font-family: 'Work Sans';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 15px;
                          line-height: 20px;
                          letter-spacing: -0.02em;
                          color: #6b4693;
                        "
                      >
                        Read More
                      </span>
                      <span
                        v-if="subscriptionPolicyFull.readMoreActive"
                        @click.stop="deactivateReadMore"
                        style="
                          font-family: 'Work Sans';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 15px;
                          line-height: 20px;
                          letter-spacing: -0.02em;
                          color: #6b4693;
                        "
                      >
                        Read Less
                      </span> -->
                      <br />
                      <!-- <span class="subInfo">You have selected {{ selectedBike.name }} for a 36 Month subscription plan. </span><br>
                      <span class="subInfo">In order to process your order, please settle the deposit via the "Make Payment" in the Purchase Summary.</span><br><br>
                      <span class="subInfo">To track the status of your order you will be asked to onboard your Charged Account where you will be updated on the Pickup/Delivery of your {{ selectedBike.name }}.</span><br><br>
                      <span class="subInfo">As part of the onboarding process, you will be asked to provide documents like KTP and proof of address. These documents can be uploaded through your Charged Account.</span><br><br>
                      <span class="subInfo">A few days before the Pickup/Delivery of your Electric Vehicle we will send the information to start the Subscription. During this whole process we will also keep you closely updated via email.</span> -->
                      <p class="mt-2"></p>
                      <v-checkbox
                        v-model="subscriptionPolicy"
                        required
                        :rules="[(v) => !!v]"
                      >
                        <template v-slot:label>
                          <div
                            :style="
                              windowWidth < 325
                                ? 'font-size:11px'
                                : 'font-size:14px'
                            "
                          >
                            {{ lang[getLanguage].PRODUCT_ACCEPT }}
                            <span
                              style="
                                cursor: pointer;
                                text-decoration: underline;
                                color: #6b4693;
                                font-weight: 500;
                              "
                              @click="openPolicy"
                              >{{ lang[getLanguage].SALES_AGREEMENT }}</span
                            >
                            {{ lang[getLanguage].LABEL_AND }}
                            <span
                              style="
                                cursor: pointer;
                                text-decoration: underline;
                                color: #6b4693;
                                font-weight: 500;
                              "
                              @click="routerGo('/term-of-use')"
                              >{{
                                getLanguage == "en"
                                  ? "Terms of Service"
                                  : "Ketentuan Layanan"
                              }}.</span
                            >
                          </div>
                        </template>
                      </v-checkbox>

                      <hr
                        style="border-top: 1px dashed #c1c2c4"
                        class="hidden md:block"
                      />
                      <span
                        class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
                      ></span>

                      <v-layout pt-4>
                        <v-spacer></v-spacer>
                        <v-btn
                          style="
                            width: 100%;
                            color: #fff;
                            text-transform: none !important;
                          "
                          color="#6B4693"
                          :disabled="!subscriptionPolicy || loadingData"
                          @click="makePayment()"
                          :loading="loadingData"
                        >
                          {{ lang[getLanguage].BOOKING_SUBMIT }}
                        </v-btn>
                      </v-layout>
                    </v-card>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-layout>

        <!-- ############################################ DIALOGS ################################################# -->
      </v-layout>
      <!-- <canvas id="canvas"></canvas> -->
    </v-slide-y-transition>

    <v-dialog v-model="dialogDeposit" persistent max-width="650">
      <v-card class="p-4">
        <v-card-title class="text-2xl text-h5 font-bold font-montserrat">
          {{ getLanguage == "en" ? "Deposit Fee" : "Biaya Deposit" }}
        </v-card-title>
        <v-card-text class="font-work-sans text-sm font-normal">
          <p>
            {{
              getLanguage == "en"
                ? "This payment helps us to process your order. Deposit payments are fully refundable at the end of the subscription period."
                : "Pembayaran ini membantu kami untuk memproses pesanan Anda. Pembayaran deposit dapat dikembalikan sepenuhnya pada akhir periode berlangganan."
            }}
          </p>
          <div class="flex mb-4">
            <div class="w-5">1.</div>
            <div class="w-full">
              {{
                getLanguage == "en"
                  ? "By providing this deposit, the customer acknowledges the subscription order for the selected vehicle model."
                  : "Dengan memberikan deposit ini, pelanggan mengakui pesanan berlangganan untuk model kendaraan yang dipilih."
              }}
            </div>
          </div>

          <div class="flex mb-4">
            <div class="w-5">2.</div>
            <div class="w-full">
              {{
                getLanguage == "en"
                  ? "After the subscription period ends, the deposit will be returned to the customer (maximum 5 working days), when the vehicle has passed the inspection process by our team."
                  : "Setelah masa berlangganan berakhir, deposit akan dikembalikan kepada pelanggan (maksimal 5 hari kerja), ketika kendaraan telah melewati proses pemeriksaan oleh tim kami."
              }}
            </div>
          </div>
          <div class="flex mb-4">
            <div class="w-5">3.</div>
            <div class="w-full">
              {{
                getLanguage == "en"
                  ? "However, if damage is found to the vehicle, the refund of the deposit will be deducted according to the cost of repairs."
                  : "Namun jika ditemukan kerusakan pada kendaraan, maka pengembalian deposit akan dipotong sesuai biaya perbaikan."
              }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-btn
            class="mx-auto capitalize font-work-sans font-medium"
            depressed
            color="primary"
            @click="dialogDeposit = false"
          >
            {{ getLanguage == "en" ? "I Understand" : "Saya Mengerti" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store/index";
import { auth } from "@/main";
// import { auth, db } from "@/main";
// import { collection, query, where, getDocs } from "firebase/firestore";
// import formSubsStep1 from "./form-subscribe/step-1";
// import formSubsStep2 from "./form-subscribe/step-2";
// import formSubsStep3 from "./form-subscribe/step-3";
import PaymentController from "@/clients/PaymentAPI";
import {
  getProfileApi,
  postPersonalInfoApi,
  postResidenceInfoApi,
  postPrivateDocApi,
  getPrivateDocApi,
} from "../../../services/back-end-ruby";
import {
  postCustomizationApi,
  postOrderApi,
  postSetPlansApi,
} from "../../../services/back-end-ruby/Vehicle";
export default {
  name: "subscribe",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:og:image", content: this.metaImg },
        { property: "og:og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    windowHeight: Number,
    // selectedBike: Object,
    index: Number,
    profile: Object,
  },
  data() {
    return {
      dialogDeposit: false,
      loadingBtnStep: false,
      selectedBike: "",
      // value Address KTP
      provinceKtp: "",
      cityKtp: "",
      districtKtp: "",
      subDistrictKtp: "",
      addressKtp: "",
      postalCodeKtp: "",
      // value Address KTP
      // logic condition already send data
      sameAddress: false,
      sendCustomization: false,
      sendPersonalInfo: false,
      sendResidenceInfo: false,
      sendPrivateDoc: false,
      sendPlanPrice: false,
      // logic condition already send data
      // data akun bank
      bankName: "",
      bankNameOthers: null,
      bankNameItems: [
        {
          title: "BNI",
          value: "BNI",
        },
        {
          title: "Mandiri",
          value: "Mandiri",
        },
        {
          title: "Permata",
          value: "Permata",
        },
        {
          title: "BSI",
          value: "BSI",
        },
        {
          title: "Sampoerna",
          value: "Sampoerna",
        },
        {
          title: "BJB",
          value: "BJB",
        },
        {
          title: "BRI",
          value: "BRI",
        },
        {
          title: "Bank Lainnya",
          value: "Bank Lainnya",
        },
      ],
      accountBankNumber: "",
      accountHoldersName: "",
      // data akun bank
      alertText: "",
      alertStatus: "",
      alertDialog: false,
      currentUser: {},
      name: "",
      nik: "",
      email: "",
      phoneNumber: "",
      tempatLahir: "",
      dateTglLahir: "",
      address: "",
      motherName: "",
      companyName: "",
      jobPosition: "",
      employmentStatus: "",
      employmentStatusListEnglish: [
        "Outsourced Employee/Partners",
        "Contract Employee",
        "Permanent Employee",
      ],
      employmentStatusListEIndo: [
        "Pegawai Outsourcing/Mitra Kerja",
        "Pegawai Kontrak",
        "Pegawai Tetap",
      ],
      jobPositionListEnglish: ["Staff", "SPV", "Manager", "Director", "Owner"],
      jobPositionListIndo: [
        "Staff",
        "Supervisor",
        "Manajer",
        "Direktur",
        "Pemilik",
      ],
      yearOfExperience: "",
      yearOfExperienceListIndo: [
        "< 1 tahun",
        "1-3 tahun",
        "4-6 tahun",
        "> 6 tahun",
      ],
      yearOfExperienceListEnglish: [
        "< 1 year",
        "1-3 years",
        "4-6 years",
        "> 6 years",
      ],
      salary: "",
      // itemsSalary: [
      //   {
      //     title: "< Rp 2.500.000",
      //     value: "less_than_two_point_five_mill",
      //   },
      //   {
      //     title: "Rp 2.500.000 - Rp 5.000.000",
      //     value: "two_point_five_to_five_mill",
      //   },
      //   {
      //     title: "Rp 5.000.000 - Rp 7.500.000",
      //     value: "five_to_seven_point_five_mill",
      //   },
      //   {
      //     title: "Rp 7.500.000 - Rp 10.000.000",
      //     value: "seven_point_five_to_ten_mill",
      //   },
      //   {
      //     title: "> Rp 10.000.000",
      //     value: "more_than_ten_mill",
      //   },
      // ],
      itemsSalary: [
        {
          title: "< Rp 5.000.000",
          value: "less_than_five_mill",
        },
        {
          title: "Rp 5.000.000 - Rp 7.500.000",
          value: "five_to_seven_point_five_mill",
        },
        {
          title: "Rp 7.500.000 - Rp 13.000.000",
          value: "seven_point_five_to_thirteen_mill",
        },
        {
          title: "Rp 13.000.000 - Rp 20.000.000",
          value: "thirteen_mill_to_twenty_mill",
        },
        {
          title: "> Rp 20.000.000",
          value: "more_than_twenty_mill",
        },
      ],
      creditCardLimit: "",
      maritalStatus: "",
      maritalStatusList: [
        {
          en: "Maried",
          id: "Sudah Menikah",
        },
        {
          en: "Single",
          id: "Belum Menikah",
        },
      ],
      spouseCompanyName: "",
      spousePositionLabel: "",
      spouseEmploymentStatus: "",
      spousePositionListEnglish: [
        "Housewife",
        "Staff",
        "SPV",
        "Manager",
        "Director",
        "Owner",
      ],
      spousePositionListIndo: [
        "Ibu Rumah Tangga",
        "Staff",
        "Supervisor",
        "Manajer",
        "Direktur",
        "Pemilik",
      ],
      spouseYearOfExperience: "",
      spouseSalary: "",
      homeOwnershipStatus: "",
      homeOwnershipStatusListEng: ["Monthly Rent", "Annual Rent", "Owned"],
      homeOwnershipStatusListIndo: [
        "Kontrak Bulanan",
        "Kontrak Tahunan",
        "Milik",
      ],
      lengthOfStay: "",
      lengthOfStayListEnglish: [
        "< 1 years",
        "1-3 years",
        "4-6 years",
        "> 6 years",
      ],
      lengthOfStayListIndo: [
        "< 1 tahun",
        "1-3 tahun",
        "4-6 tahun",
        "> 6 tahun",
      ],
      IDFile: "",
      IDFileName: "",
      file: {
        // identityCard: null,
        // familyRegistration: null,
        // taxpayerIdentificationNumber: null,
        // drivingLicense: null,
        // dashboardDriver: null,
        // accountTransaction: null,
        // payslip: null,
        identityCard: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          user: ["driver", "regular"],
          label: { en: "KTP *", id: "KTP *" },
          file: null,
        },
        familyRegistration: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          user: ["driver", "regular"],
          label: { en: "KK *", id: "KK *" },
          file: null,
        },
        taxpayerIdentificationNumber: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          user: ["driver", "regular"],
          label: { en: "NPWP (Optional)", id: "NPWP (Opsional)" },
          file: null,
          noted:
            "Note : Jika Anda belum bisa mengunggah NPWP, Anda bisa melampirkan NPWP pada saat sesi tanda tangan kontrak.",
        },
        drivingLicense: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          user: ["driver", "regular"],
          label: { en: "SIM C *", id: "SIM C *" },
          file: null,
        },
        dashboardDriver: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          user: ["driver"],
          label: {
            en: "Capture Dashboard Driver *",
            id: "Capture Dashboard Driver *",
          },
          file: null,
        },
        dashboardDriverIncomeOneMonth: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          user: ["driver"],
          label: {
            en: "Capture Dashboard Penghasilan Mitra Selama 1 Bulan *",
            id: "Capture Dashboard Penghasilan Mitra Selama 1 Bulan *",
          },
          file: null,
        },
        accountTransaction: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          user: ["driver", "regular"],
          label: {
            en: "Saving Account Transaction in the Last 3 Months *",
            id: "Transaksi Rekening Tabungan selama 3 bulan terakhir *",
          },
          file: null,
        },
        payslip: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          user: ["regular"],
          label: { en: "Payslip (optional)", id: "Payslip (optional)" },
          file: null,
        },
        domisili: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          user: ["driver", "regular"],
          label: {
            en: "Domicile Letter (Optional)",
            id: "Surat Domisili (Optional)",
          },
          file: null,
          noted:
            "Note: Jika Anda belum bisa mengunggah Surat Domisili, Anda bisa melampirkan Surat Domisili pada saat sesi tanda tangan kontrak.",
        },
      },
      loadingData: false,
      loadingScreen: false,
      metaTitle: "Charged Asia - Ride for Good!",
      metaDesc:
        "Our mission at Charged is 10 million electric vehicles in 10 years. We plan to start with electric motorcycles and lead the mass adoption of sustainable mobility in our region.",
      metaImg: "",
      metaUrl: "",
      lang: this.$store.state.lang,
      loading: true,
      showBottomNav: false,
      imgSrc: "",
      imgSrc2: "",
      signUpDialog: false,
      videoDialog: false,
      signUpView: 1,
      installMM: false,
      carousel: 0,
      count: 0,
      show: false,
      resultInterval: null,
      countDown: 8,
      toggle: "left",
      stepper: 1,
      sliderHide: false,
      radioGroupColor: 1,
      radioGroupChargerType: 1,
      radioGroupBatteryQty: 1,
      radioGroupPackaging: 1,
      radioGroupPickup: 0,
      radioGroupPricing: 1,
      subscriptionPlan: "Subscription Not Owned",
      items: ["Subscription Not Owned"], // ["Subscription to Own", "Subscription Not Owned"],
      slider: 0,
      deposit: 0,
      tax: 40000,
      insurance: 20000,
      monthlySubscriptionPrice: 0,
      monthlySubscriptionPriceTotal: 0,
      firstPaymentAmount: 0,
      subNotOwned: [
        { name: "MALEO", price: "2664", deposit: "3000000" },
        { name: "ANOA", price: "3456", deposit: "4000000" },
        { name: "RIMAU", price: "4176", deposit: "5000000" },
      ],
      subOwned: [
        { name: "MALEO", price: "3384", deposit: "5000000" },
        { name: "ANOA", price: "4176", deposit: "6000000" },
        { name: "RIMAU", price: "4896", deposit: "7000000" },
      ],
      invoiceUrl: "",
      isFormValid: false,
      serviceProvince: "",
      serviceCity: "",
      serviceDistrict: "",
      serviceSubDistrict: "",
      serviceVillage: "",
      serviceZip: "",
      state: "",
      subscriptionPolicy: false,
      emailRules: [],
      // listColor: [
      //   {
      //     title: { en: "Jet Black", id: "Hitam" },
      //     color: "#000",
      //     price: "0",
      //     bikes: ["RIMAU", "MALEO", "ANOA"],
      //   },
      //   {
      //     title: { en: "Arctic White", id: "Putih" },
      //     color: "#fff",
      //     price: "0",
      //     bikes: ["RIMAU", "MALEO", "ANOA"],
      //   },
      //   {
      //     title: { en: "Charcoal Grey", id: "Abu - Abu" },
      //     color: "#808080",
      //     price: "0",
      //     bikes: ["RIMAU"],
      //   },
      // ],
      listColor: [],
      // listCharger: [
      //   {
      //     title: "Standard Charger",
      //     time: { en: "(10-100% in 5 Hours)", id: "(10-100% dalam 5 Jam)" },
      //     priceLabel: "0",
      //     dailyPriceLabel: "0",
      //     price: "0",
      //     dailyPrice: "0",
      //   },
      //   {
      //     title: "Fast Charger",
      //     time: { en: "(10-100% in 3 Hours)", id: "(10-100% dalam 3 Jam)" },
      //     priceLabel: "111",
      //     dailyPriceLabel: "3.7",
      //     price: "111000",
      //     dailyPrice: "3700",
      //   },
      // ],
      listCharger: [],
      // listBattery: [
      //   {
      //     title: { en: "No Additional Battery", id: "Tanpa Baterai Tambahan" },
      //     conclusion: { en: "No Additional Battery", id: "1" },
      //     desc: {
      //       en: "You’ll get 1 battery without any additional fee.",
      //       id: "Anda akan mendapatkan 1 baterai tanpa biaya tambahan.",
      //     },
      //     priceLabel: "0",
      //     dailyPriceLabel: "0",
      //     price: "0",
      //     dailyPrice: "0",
      //   },
      //   {
      //     title: { en: "Extra Battery", id: "Dengan Baterai Tambahan" },
      //     conclusion: {
      //       en: "Extra Battery",
      //       id: "2 (termasuk tambahan baterai)",
      //     },
      //     desc: {
      //       en: "Add 1 extra battery for your bike. Please note that you can only subscribe maximum 2 Batteries for 1 vehicle. Contact us if you need more.",
      //       id: "Tambahkan 1 baterai cadangan untuk motor Anda. Setiap 1 motor hanya dapat memiliki maksimum 2 baterai.",
      //     },
      //     price: "350000",
      //     dailyPrice: "10000",
      //     priceLabel: "350",
      //     dailyPriceLabel: "10",
      //   },
      // ],
      listBattery: [],
      // listRear: [
      //   {
      //     title: { en: "No Additional Rear Box", id: "Tanpa Rear Box" },
      //     conclusion: { en: "No Additional Rear Box", id: "Tanpa Rear Box" },
      //     priceLabel: "0",
      //     dailyPriceLabel: "0",
      //     price: "0",
      //     dailyPrice: "0",
      //     description: "",
      //   },
      //   {
      //     title: {
      //       en: "Extra Rear Box",
      //       id: "Dengan Rear Box",
      //     },

      //     conclusion: {
      //       en: "Extra Rear Box",
      //       id: "Dengan Rear Box (Pre-Order selama 30 hari)",
      //     },
      //     priceLabel: "111",
      //     dailyPriceLabel: "3.7",
      //     price: "111000",
      //     dailyPrice: "3700",
      //     description: "Pre Order 30 Hari",
      //   },
      // ],
      listRear: [],
      // listPricing: [
      //   // {
      //   //   plan: { en: "3 Months Plan", id: "3 Bulan" },

      //   //   price: "1550000",
      //   //   dailyPrice: "43000",

      //   //   bonus: "-",
      //   //   bikes: ["ANOA"],
      //   // },
      //   {
      //     plan: { en: "6 Months Plan", id: "6 Bulan" },

      //     price: "1550000",
      //     dailyPrice: "43000",

      //     bonus: "-",
      //     bikes: ["ANOA"],
      //   },
      //   {
      //     plan: { en: "9 Months Plan", id: "9 Bulan" },

      //     price: "1550000",
      //     dailyPrice: "43000",

      //     bonus: { en: "", id: "" },
      //     bikes: ["ANOA"],
      //   },
      //   {
      //     plan: { en: "12 Months Plan", id: "12 Bulan" },

      //     price: "1550000",
      //     dailyPrice: "43000",

      //     bonus: { en: "", id: "" },
      //     bikes: ["ANOA"],
      //   },
      //   {
      //     plan: { en: "3 Months Plan", id: "3 Bulan" },

      //     price: "1450000",
      //     dailyPrice: "40000",

      //     bonus: { en: "", id: "" },
      //     bikes: ["MALEO"],
      //   },
      //   {
      //     plan: { en: "6 Months Plan", id: "6 Bulan" },

      //     price: "1450000",
      //     dailyPrice: "40000",

      //     bonus: { en: "", id: "" },
      //     bikes: ["MALEO"],
      //   },
      //   {
      //     plan: { en: "9 Months Plan", id: "9 Bulan" },

      //     price: "1450000",
      //     dailyPrice: "40000",

      //     bonus: { en: "", id: "" },
      //     bikes: ["MALEO"],
      //   },
      //   {
      //     plan: { en: "12 Months Plan", id: "12 Bulan" },

      //     price: "1450000",
      //     dailyPrice: "40000",

      //     bonus: { en: "", id: "" },
      //     bikes: ["MALEO"],
      //   },
      //   // {
      //   //   plan: { en: "3 Months Plan", id: "3 Bulan" },

      //   //   price: "1650000",
      //   //   dailyPrice: "45000",

      //   //   bonus: { en: "", id: "" },
      //   //   bikes: ["RIMAU"],
      //   // },
      //   {
      //     plan: { en: "6 Months Plan", id: "6 Bulan" },

      //     price: "1650000",
      //     dailyPrice: "45000",

      //     bonus: { en: "", id: "" },
      //     bikes: ["RIMAU"],
      //   },
      //   {
      //     plan: { en: "9 Months Plan", id: "9 Bulan" },

      //     price: "1650000",
      //     dailyPrice: "45000",

      //     bonus: { en: "", id: "" },
      //     bikes: ["RIMAU"],
      //   },
      //   {
      //     plan: { en: "12 Months Plan", id: "12 Bulan" },

      //     price: "1650000",
      //     dailyPrice: "45000",
      //     bonus: { en: "", id: "" },
      //     bikes: ["RIMAU"],
      //   },
      // ],
      listPricing: [],
      driverSubcriptionPlanList: [
        {
          plan: { en: "3 Months Plan", id: "3 Bulan" },
          price: 53000,
          bonus: { en: "", id: "" },
          bikes: ["ANOA"],
        },
        {
          plan: { en: "3 Months Plan", id: "3 Bulan" },
          price: 53000,
          bonus: { en: "", id: "" },
          bikes: ["MALEO"],
        },
        {
          plan: { en: "3 Months Plan", id: "3 Bulan" },
          price: 53000,

          bonus: { en: "", id: "" },
          bikes: ["RIMAU"],
        },
      ],
      listUsers: [
        {
          user: "regular",
          lang: "en",
        },
        {
          user: "driver",
          lang: "id",
        },
      ],
      // selectedUser: "regular",
      selectedUser: "",
      selectedColor: null,
      selectedCharger: null,
      selectedBattery: null,
      selectedRear: null,
      selectedPricing:
        this.$store.getters.getSessionUser.bikeSubs?.name === "ANOA"
          ? {
              plan: { en: "3 Months Plan", id: "3 Bulan" },
              price: "1550000",
              dailyPrice: "43000",
              bonus: "-",
              bikes: ["ANOA"],
            }
          : this.$store.getters.getSessionUser.bikeSubs?.name === "MALEO"
          ? {
              plan: { en: "3 Months Plan", id: "3 Bulan" },
              price: "1450000",
              dailyPrice: "40000",
              bonus: { en: "", id: "" },
              bikes: ["MALEO"],
            }
          : this.$store.getters.getSessionUser.bikeSubs?.name === "RIMAU"
          ? {
              plan: { en: "3 Months Plan", id: "3 Bulan" },
              price: "1650000",
              dailyPrice: "45000",
              bonus: { en: "", id: "" },
              bikes: ["RIMAU"],
            }
          : "",
      totalPrice: "0",
      subscriptionPolicyTrim: {
        en: "The second party declares that all data and information and financing requirements documents are complete,",
        id: "Pihak kedua menyatakan bahwa semua data dan informasi serta dokumen persyaratan telah lengkap,",
      },
      subscriptionPolicyFull: {
        en: "The second party declares that all data and information and required documents are complete, true to the original; and hereby agree and authorize the First Party to contact any source to obtain or check the necessary information. I acknowledge and agree that the data, information and documents provided are the property of the first party which does not need to be returned and therefore can be used for all purposes related to business activities, including but not limited to being able to be provided to third parties, including in the survey process, collection of consumer obligations, product / service offerings, and all other necessary needs; if there is a change, the data that will be included in the financing agreement is the latest data; I will further comply with and be bound by the applicable terms and conditions and I understand that the First Party has the right to accept or reject this submission.",
        id: "Pihak kedua menyatakan bahwa semua data dan informasi serta dokumen persyaratan telah lengkap, benar sesuai dengan aslinya; dan dengan ini menyetujui dan mengizinkan Pihak Pertama untuk menghubungi sumber manapun untuk mendapatkan atau memeriksa informasi yang diperlukan. Saya mengetahui dan menyetujui bahwa data, informasi dan dokumen yang diberikan adalah milik pihak pertama yang tidak perlu dikembalikan dan karenanya dapat digunakan untuk segala keperluan yang berkaitan dengan kegiatan usaha, termasuk namun tidak terbatas untuk dapat diberikan kepada pihak ketiga, termasuk dalam proses survei, pengumpulan kewajiban konsumen, penawaran produk/jasa, dan semua kebutuhan lain yang diperlukan; apabila terjadi perubahan, maka data yang akan dicantumkan dalam perjanjian pembiayaan adalah data yang terbaru; Saya selanjutnya akan mematuhi dan terikat dengan syarat dan ketentuan yang berlaku dan saya memahami bahwa Pihak Pertama berhak untuk menerima atau menolak pengajuan ini.",
        readMoreActive: true,
      },
      validationStepFour: true,
    };
  },
  components: {
    // formSubsStep1,
    // formSubsStep2,
    // formSubsStep3,
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
    },
    stepper() {
      this.updateMonthlySubscriptionPrice();
    },
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getServiceCity() {
      return this.$store.state.user.serviceCity;
    },
    getProfile() {
      return this.$store.getters.getProfile;
    },
    getListColorsVehicle() {
      return this.$store.getters.getListColorsVehicle;
    },
    getListChargersVehicle() {
      return this.$store.getters.getListChargersVehicle;
    },
    getListBatteryVehicle() {
      return this.$store.getters.getListBatteryVehicle;
    },
    getListRearboxVehicle() {
      return this.$store.getters.getListRearboxVehicle;
    },
    getListVehiclePrice() {
      return this.$store.getters.getListVehiclePrice;
    },
    getbikeSubs() {
      return this.$store.getters.getSessionUser.bikeSubs;
    },
    getSelectedVehiclePrice() {
      return this.$store.getters.getSelectedVehiclePrice;
    },
    getListAksesorisVehicle() {
      return this.$store.getters.getListAksesorisVehicle;
    },
  },
  mounted() {},
  async created() {
    // Load List Vehicle
    this.listPricing = await this.getListVehiclePrice;
    this.listColor = await this.getListColorsVehicle;
    this.listCharger = await this.getListChargersVehicle;
    this.listBattery = await this.getListBatteryVehicle;
    this.listRear = await this.getListAksesorisVehicle;
    // Load List Vehicle

    setTimeout(() => {
      // Auto Selected
      this.selectedBike = this.getbikeSubs;
      this.selectedColor = this.getListColorsVehicle[0];
      this.selectedBattery = this.getListBatteryVehicle[0];
      this.selectedCharger = this.getListChargersVehicle[0];
      this.selectedRear = this.getListAksesorisVehicle[0] ?? null;
      // Auto Selected
    }, 1000);

    this.getProfile;
    this.getUser;
    this.stepper = this.$route.params.id;
    // console.log("THIS_PARAMS_SUBS", this.$route.params.id);
    // console.log("SELECTED_BIKE", this.listColor);
    // console.log("LIST_CHARGED", this.listCharger);
    // console.log("LIST_BATTERY", this.listBattery);
    // console.log("LIST_REAR", this.listRear);
    // console.log("LIST_PRICING", this.listPricing);
    // console.log("SELECTED_REAR", this.selectedRear);
    store.commit("ShowNav");
    store.commit("ShowNavHome");
    this.emailRules = [
      (v) => !!v || this.lang[this.getLanguage].VALIDATION_EMAIL_REQ,
      (v) =>
        /.+@.+\..+/.test(v) ||
        this.lang[this.getLanguage].VALIDATION_EMAIL_VALID,
      (v) =>
        (v || "").indexOf(" ") < 0 ||
        this.lang[this.getLanguage].VALIDATION_EMAIL_VALID,
    ];
    setTimeout(() => {
      this.currentUser = auth.currentUser;
      this.checkUser();
    }, 1000);
    this.init();
  },
  methods: {
    closeAlertDialog() {
      this.alertDialog = false;
    },
    async checkUser() {
      let getProfile = this.getUser;
      this.name = getProfile.fullName || "";
      this.email = getProfile.email || "";
      this.phoneNumber = getProfile.phoneNumber || getProfile.phone;
      this.selectedUser =
        getProfile.customerType == "non_mitra" ||
        getProfile.customerType == "regular"
          ? "regular"
          : "driver";

      // console.log("CHECK_USER", getProfile);
      // console.log("selectedUser", this.selectedUser, this.name);

      // if (this.currentUser) {
      //   // const getUser = this.$store.state.user;
      //   // console.log("getUser", getUser);
      //   // this.name = getUser.fullName;
      //   // console.log(this.name);
      //   // this.email = getUser.email;
      //   // this.phoneNumber = getUser.phone;

      //   // console.log("currentUser");
      //   // console.log(this.currentUser);
      //   const orderRef = collection(db, "users");

      //   const q = query(
      //     orderRef,
      //     where("phoneNumber", "==", this.currentUser.phoneNumber)
      //   );
      //   const querySnapshot = await getDocs(q);

      //   // querySnapshot.forEach((doc) => {
      //   querySnapshot.forEach(() => {
      //     // doc.data() is never undefined for query doc snapshots
      //     // console.log(doc.id, " => ", doc.data());
      //     // this.name = doc.data().displayName;
      //     // this.email = doc.data().email;
      //     // this.phoneNumber = doc.data().phoneNumber;
      //   });

      //   console.log(this.profile);
      //   if (this.name == "") {
      //     console.log("name tidak ada");
      //     this.name = this.profile.name || "";
      //     this.email = this.profile.email || "";
      //     this.phoneNumber =
      //       this.currentUser.phoneNumber || this.$store.state.user.phone || "";
      //     this.selectedUser = this.profile.customerType;
      //   }
      //   console.log("selectedUser", this.selectedUser, this.name);
      // }
    },
    openPolicy() {
      window.open("/pdf/sales_agreement.pdf", "_blank");
    },
    countTotalPrice() {
      var batteryPrice = 0;
      var chargerPrice = 0;
      var bikePrice = 0;
      var rear = 0;
      let totalPrice = 0;

      if (this.selectedUser === "driver") {
        if (this.selectedBattery != null) {
          batteryPrice = parseInt(this.selectedBattery.dailyPrice);
        }
        if (this.selectedCharger != null) {
          chargerPrice = parseInt(this.selectedCharger.dailyPrice);
        }
        if (this.selectedPricing != null) {
          bikePrice = parseInt(this.selectedPricing.dailyPrice);
        }
        if (this.selectedRear != null) {
          rear = parseInt(this.selectedRear.dailyPrice);
        }
        totalPrice = batteryPrice + chargerPrice + bikePrice + rear;
      } else {
        if (this.selectedBattery != null) {
          batteryPrice = parseInt(this.selectedBattery.price);
        }
        if (this.selectedCharger != null) {
          chargerPrice = parseInt(this.selectedCharger.price);
        }
        if (this.selectedPricing != null) {
          bikePrice = parseInt(this.selectedPricing.price);
        }
        if (this.selectedRear != null) {
          rear = parseInt(this.selectedRear.price);
        }
        totalPrice = batteryPrice + chargerPrice + bikePrice + rear;
      }
      this.totalPrice = `${totalPrice}`;
    },
    selectColor(item) {
      this.selectedColor = null;
      this.selectedColor = item;
    },
    selectCharger(item) {
      this.selectedCharger = null;
      this.selectedCharger = item;
      this.countTotalPrice();
    },
    selectBattery(item) {
      this.selectedBattery = null;
      this.selectedBattery = item;
      this.countTotalPrice();
    },
    selectRear(item) {
      this.selectedRear = false;
      this.selectedRear = item;
      this.countTotalPrice();
    },
    selectMaritalStatus(item) {
      this.maritalStatus = "";
      this.maritalStatus = item;
      if (item === "Maried") {
        this.spousePositionLabel = "";
        this.spouseEmploymentStatus = "";
        this.spouseCompanyName = "";
        this.spouseYearOfExperience = "";
        this.spouseSalary = "";
      }
    },
    selectSpousePosition() {
      if (
        this.spousePositionLabel === "Housewife" ||
        this.spousePositionLabel === "Ibu Rumah Tangga"
      ) {
        this.spouseEmploymentStatus = "";
        this.spouseCompanyName = "";
        this.spouseYearOfExperience = "";
        this.spouseSalary = "";
      }
    },
    selectPricing(item) {
      // console.log("selectedPricing", this.selectedPricing);
      this.selectedPricing = null;
      this.selectedPricing = item;
      // console.log("item", item);
      this.countTotalPrice();
    },
    init() {
      this.showButtons();
      this.subscriptionPlanChanged();
    },
    stepTwoChaged() {
      this.loadingBtnStep = true;
      // this.$router.push("/form-subscribe/2");
      // start mengisi data personal info jika sudah ada
      if (this.getProfile.profile.employments.length >= 1) {
        this.companyName = this.getProfile.profile.employments[0].company_name;
        this.jobPosition =
          this.getProfile.customer_type !== "mitra"
            ? this.getProfile.profile.employments[0]?.job_position.replace(
                /^\w/,
                (c) => c.toUpperCase()
              )
            : "";
        let employmentStatusVal =
          this.getProfile.profile.employments[0].employment_status;
        if (employmentStatusVal == "outsorced" && this.getLanguage == "id") {
          this.employmentStatus = "Pegawai Outsourcing/Mitra Kerja";
        }
        if (employmentStatusVal == "outsorced" && this.getLanguage == "en") {
          this.employmentStatus = "Outsourced Employee/Partners";
        }
        if (employmentStatusVal == "contract" && this.getLanguage == "id") {
          this.employmentStatus = "Pegawai Kontrak";
        }
        if (employmentStatusVal == "contract" && this.getLanguage == "en") {
          this.employmentStatus = "Contract Employee";
        }
        if (employmentStatusVal == "permanent" && this.getLanguage == "id") {
          this.employmentStatus = "Pegawai Tetap";
        }
        if (employmentStatusVal == "permanent" && this.getLanguage == "en") {
          this.employmentStatus = "Permanent Employee";
        }
        let yearOfExperienceVal =
          this.getProfile.profile.employments[0].length_of_work;
        if (
          yearOfExperienceVal == "less_than_one_year" &&
          this.getLanguage == "en"
        ) {
          this.yearOfExperience = "< 1 year";
        }
        if (
          yearOfExperienceVal == "less_than_one_year" &&
          this.getLanguage == "id"
        ) {
          this.yearOfExperience = "< 1 tahun";
        }
        if (
          yearOfExperienceVal == "one_to_three_years" &&
          this.getLanguage == "en"
        ) {
          this.yearOfExperience = "1-3 year";
        }
        if (
          yearOfExperienceVal == "one_to_three_years" &&
          this.getLanguage == "id"
        ) {
          this.yearOfExperience = "1-3 tahun";
        }
        if (
          yearOfExperienceVal == "four_to_six_years" &&
          this.getLanguage == "en"
        ) {
          this.yearOfExperience = "4-6 year";
        }
        if (
          yearOfExperienceVal == "four_to_six_years" &&
          this.getLanguage == "id"
        ) {
          this.yearOfExperience = "4-6 tahun";
        }
        if (
          yearOfExperienceVal == "more_than_six_years" &&
          this.getLanguage == "en"
        ) {
          this.yearOfExperience = "> 6 year";
        }
        if (
          yearOfExperienceVal == "more_than_six_years" &&
          this.getLanguage == "id"
        ) {
          this.yearOfExperience = "> 6 tahun";
        }

        // let salaryVal = this.getProfile.profile.employments[0].profit_income;
        // if (salaryVal == "less_than_five_mill") {
        //   this.salary = "< Rp 5.000.000";
        // } else if (salaryVal == "five_to_seven_point_five_mill") {
        //   this.salary == "Rp 5.000.000 - Rp 7.500.000";
        // } else if (salaryVal == "seven_point_five_to_thirteen_mill") {
        //   this.salary == "Rp 7.500.000 - Rp 13.000.000";
        // } else if (salaryVal == "thirteen_mill_to_twenty_mill") {
        //   this.salary == "Rp 13.000.000 - Rp 20.000.000";
        // } else if (salaryVal == "more_than_twenty_mill") {
        //   this.salary == "> Rp 20.000.000";
        // }

        if (this.getProfile.profile.employments.length > 1) {
          this.maritalStatus = "Maried";
        }
      }
      // end mengisi data personal info jika sudah ada
      this.serviceProvince = this.getServiceCity[0].province;
      this.serviceCity = this.getServiceCity[0].district;
      this.serviceDistrict = this.getServiceCity[0].regency;
      this.serviceSubDistrict = this.getServiceCity[0].village;
      this.serviceVillage = this.getServiceCity[0].village;
      this.serviceZip = this.getServiceCity[0].postcode;
      // this.stepper = 2;

      // send to API Customization
      let token = this.$cookies.get("token_api");
      if (!this.sendCustomization) {
        postCustomizationApi(token, {
          vehicle_color: this.selectedColor.id,
          charger: this.selectedCharger.id,
          battery: this.selectedBattery.id,
          rearbox: this.selectedRear?.id ?? "",
          subscription: this.$store.getters.getIdSubs,
        }).then((res) => {
          // console.log("SEND_CUSTOMIZATION", res);
          if (res.meta.code == 200) {
            this.stepper = 2;
            this.loadingBtnStep = false;
          }
        });
      } else {
        this.stepper = 2;
        this.loadingBtnStep = false;
      }
    },
    stepTwoComplete() {
      this.loadingBtnStep = true;
      let token = this.$cookies.get("token_api");
      if (
        !this.sendPersonalInfo ||
        this.getProfile.profile.employments.length == 0
      ) {
        let yearOfExperienceVal = "";
        // let salaryVal = "";
        let creditCardLimitVal = "";
        let employmentStatusVal = "";
        if (
          this.yearOfExperience == "< 1 tahun" ||
          this.yearOfExperience == "< 1 year"
        ) {
          yearOfExperienceVal = "less_than_one_year";
        } else if (
          this.yearOfExperience == "1-3 tahun" ||
          this.yearOfExperience == "1-3 year"
        ) {
          yearOfExperienceVal = "one_to_three_years";
        } else if (
          this.yearOfExperience == "4-6 tahun" ||
          this.yearOfExperience == "4-6 year"
        ) {
          yearOfExperienceVal = "four_to_six_years";
        } else if (
          this.yearOfExperience == "> 6 tahun" ||
          this.yearOfExperience == "> 6 year"
        ) {
          yearOfExperienceVal = "more_than_six_years";
        }

        // if (this.salary == "< Rp 5.000.000") {
        //   salaryVal = "less_than_five_mill";
        // } else if (this.salary == "Rp 5.000.000 - Rp 7.500.000") {
        //   salaryVal = "five_to_seven_point_five_mill";
        // } else if (this.salary == "Rp 7.500.000 - Rp 13.000.000") {
        //   salaryVal = "seven_point_five_to_thirteen_mill";
        // } else if (this.salary == "Rp 13.000.000 - Rp 20.000.000") {
        //   salaryVal = "thirteen_mill_to_twenty_mill";
        // } else if (this.salary == "> Rp 20.000.000") {
        //   salaryVal = "more_than_twenty_mill";
        // }

        if (this.creditCardLimit == "< Rp 3.000.000") {
          creditCardLimitVal = "less_than_three_mill";
        } else if (this.creditCardLimit == "Rp 3.000.000 - Rp 5.000.000") {
          creditCardLimitVal = "three_to_five_mill";
        } else if (this.creditCardLimit == "Rp 5.000.000 - Rp 10.000.000") {
          creditCardLimitVal = "five_to_ten_mill";
        } else if (this.creditCardLimit == "Rp 10.000.000 - Rp 20.000.000") {
          creditCardLimitVal = "ten_to_twenty_mill";
        } else if (this.creditCardLimit == "> Rp 20.000.000") {
          creditCardLimitVal = "more_than_twenty_mill";
        }

        if (
          this.employmentStatus == "Pegawai Outsourcing/Mitra Kerja" ||
          this.employmentStatus == "Outsourced Employee/Partners"
        ) {
          employmentStatusVal = "outsorced";
        } else if (
          this.employmentStatus == "Pegawai Kontrak" ||
          this.employmentStatus == "Contract Employee"
        ) {
          employmentStatusVal = "contract";
        } else if (
          this.employmentStatus == "Pegawai Tetap" ||
          this.employmentStatus == "Permanent Employee"
        ) {
          employmentStatusVal = "permanent";
        }

        let spouseYearOfExperienceVal = "";
        let spouseSalaryVal = "";
        let spouseEmploymentStatusVal = "";
        if (
          this.spouseYearOfExperience == "< 1 tahun" ||
          this.spouseYearOfExperience == "< 1 year"
        ) {
          spouseYearOfExperienceVal = "less_than_one_year";
        } else if (
          this.spouseYearOfExperience == "1-3 tahun" ||
          this.spouseYearOfExperience == "1-3 year"
        ) {
          spouseYearOfExperienceVal = "one_to_three_years";
        } else if (
          this.spouseYearOfExperience == "4-6 tahun" ||
          this.spouseYearOfExperience == "4-6 year"
        ) {
          spouseYearOfExperienceVal = "four_to_six_years";
        } else if (
          this.spouseYearOfExperience == "> 6 tahun" ||
          this.spouseYearOfExperience == "> 6 year"
        ) {
          spouseYearOfExperienceVal = "more_than_six_years";
        }

        if (this.spouseSalary == "< Rp 5.000.000") {
          spouseSalaryVal = "less_than_five_mill";
        } else if (this.spouseSalary == "Rp 5.000.000 - Rp 7.500.000") {
          spouseSalaryVal = "five_to_seven_point_five_mill";
        } else if (this.spouseSalary == "Rp 7.500.000 - Rp 13.000.000") {
          spouseSalaryVal = "seven_point_five_to_thirteen_mill";
        } else if (this.spouseSalary == "Rp 13.000.000 - Rp 20.000.000") {
          spouseSalaryVal = "thirteen_mill_to_twenty_mill";
        } else if (this.spouseSalary == "> Rp 20.000.000") {
          spouseSalaryVal = "more_than_twenty_mill";
        }

        if (
          this.spouseEmploymentStatus == "Pegawai Outsourcing/Mitra Kerja" ||
          this.spouseEmploymentStatus == "Outsourced Employee/Partners"
        ) {
          spouseEmploymentStatusVal = "outsorced";
        } else if (
          this.spouseEmploymentStatus == "Pegawai Kontrak" ||
          this.spouseEmploymentStatus == "Contract Employee"
        ) {
          spouseEmploymentStatusVal = "contract";
        } else if (
          this.spouseEmploymentStatus == "Pegawai Tetap" ||
          this.spouseEmploymentStatus == "Permanent Employee"
        ) {
          spouseEmploymentStatusVal = "permanent";
        }

        let opsiSingle = {
          company_name: this.companyName,
          job_position:
            this.jobPosition.toLowerCase() == "supervisor"
              ? "spv"
              : this.jobPosition.toLowerCase(),
          employment_status: employmentStatusVal,
          length_of_work: yearOfExperienceVal,
          profit_income: this.salary,
          credit_card_limit: creditCardLimitVal,
          employment_type: "user",
        };

        let opsiMerried = {
          company_name: this.spouseCompanyName,
          job_position:
            this.spousePositionLabel.toLowerCase() == "supervisor"
              ? "spv"
              : this.spousePositionLabel.toLowerCase(),
          employment_status: spouseEmploymentStatusVal,
          length_of_work: spouseYearOfExperienceVal,
          profit_income: spouseSalaryVal,
          credit_card_limit: "",
          employment_type: "spouse",
        };

        let compireOpsi =
          this.maritalStatus == "Single"
            ? [opsiSingle]
            : [opsiSingle, opsiMerried];

        console.log(token, compireOpsi);

        postPersonalInfoApi(token, {
          subscription_id: this.$store.getters.getIdSubs,
          guardian_name: this.motherName,
          marital_status: this.maritalStatus == "Maried" ? "Married" : "Single",
          nik: this.nik,
          birth_place: this.tempatLahir,
          birth_date: this.formatDate(this.dateTglLahir),
          employment: compireOpsi,
        }).then((res) => {
          console.log("RESULTS_PERSONAL", res);
          if (res.meta.code == 200) {
            this.stepper = 3;
            // this.$router.push("/form-subscribe/3");
            this.sendPersonalInfo = true;
            this.serviceProvince = this.getServiceCity[0].province;
            this.serviceCity = this.getServiceCity[0].district;
            this.serviceDistrict = this.getServiceCity[0].regency;
            this.serviceSubDistrict = this.getServiceCity[0].village;
            this.serviceVillage = this.getServiceCity[0].village;
            this.serviceZip = this.getServiceCity[0].postcode;

            this.$store.commit("setVehiclePrice", {
              token: token,
              subscription_id: this.$store.getters.getIdSubs,
              customer_type: this.getProfile.customer_type,
            });

            getProfileApi(token).then((res) => {
              this.$store.commit("setProfile", res.data.user);
            });

            this.loadingBtnStep = false;
          }
        });
      } else {
        this.stepper = 3;
        this.loadingBtnStep = false;
      }
    },
    stepThreeComplete() {
      this.loadingBtnStep = true;
      if (!this.sendResidenceInfo) {
        // let getProfileData = this.getProfile;

        let shippingMethodFix = "";
        let homeOwnershipStatusVal = "";
        let lengthOfStayVal = "";

        if (this.radioGroupPickup == 1) {
          shippingMethodFix = "Kemang";
        } else if (this.radioGroupPickup == 2) {
          shippingMethodFix = "Cikupa";
        } else if (this.radioGroupPickup == 3) {
          shippingMethodFix = "Home";
        }

        if (
          this.homeOwnershipStatus == "Monthly Rent" ||
          this.homeOwnershipStatus == "Kontrak Bulanan"
        ) {
          homeOwnershipStatusVal = "monthly_rental";
        } else if (
          this.homeOwnershipStatus == "Annual Rent" ||
          this.homeOwnershipStatus == "Kontrak Tahunan"
        ) {
          homeOwnershipStatusVal = "annual_rental";
        } else if (
          this.homeOwnershipStatus == "Owned" ||
          this.homeOwnershipStatus == "Milik"
        ) {
          homeOwnershipStatusVal = "owned";
        }

        if (
          this.lengthOfStay == "< 1 years" ||
          this.lengthOfStay == "< 1 tahun"
        ) {
          lengthOfStayVal = "less_than_one_year";
        } else if (
          this.lengthOfStay == "1-3 years" ||
          this.lengthOfStay == "1-3 tahun"
        ) {
          lengthOfStayVal = "one_to_three_years";
        } else if (
          this.lengthOfStay == "4-6 years" ||
          this.lengthOfStay == "4-6 tahun"
        ) {
          lengthOfStayVal = "four_to_six_years";
        } else if (
          this.lengthOfStay == "> 6 years" ||
          this.lengthOfStay == "> 6 tahun"
        ) {
          lengthOfStayVal = "more_than_six_years";
        }

        let residence_infoVal = "";
        if (this.sameAddress) {
          residence_infoVal = [
            {
              address_street: this.address,
              postal_code: this.serviceZip,
              ownership_status: homeOwnershipStatusVal,
              length_of_stay: lengthOfStayVal,
              address_type: "Current Address",
            },
            {
              address_street: this.address,
              postal_code: this.serviceZip,
              ownership_status: homeOwnershipStatusVal,
              length_of_stay: lengthOfStayVal,
              address_type: "ID Address",
              province: this.serviceProvince,
              city: this.serviceCity,
              district: this.serviceDistrict,
              sub_district: this.serviceSubDistrict,
            },
          ];
        } else {
          residence_infoVal = [
            {
              address_street: this.address,
              postal_code: this.serviceZip,
              ownership_status: homeOwnershipStatusVal,
              length_of_stay: lengthOfStayVal,
              address_type: "Current Address",
            },
            {
              address_street: this.addressKtp,
              postal_code: this.postalCodeKtp,
              ownership_status: homeOwnershipStatusVal,
              length_of_stay: lengthOfStayVal,
              address_type: "ID Address",
              province: this.provinceKtp,
              city: this.cityKtp,
              district: this.districtKtp,
              sub_district: this.subDistrictKtp,
            },
          ];
        }

        postResidenceInfoApi(this.$cookies.get("token_api"), {
          subscription_id: this.$store.getters.getIdSubs,
          pick_up: shippingMethodFix,
          residence_info_data: residence_infoVal,
        }).then((res) => {
          if (res.meta.code == 200) {
            this.stepper = 4;
            // this.$router.push("/form-subscribe/4");
            this.sendResidenceInfo = true;
            this.selectedPricing = this.listPricing[0];
            this.loadingBtnStep = false;
            getPrivateDocApi(this.$cookies.get("token_api")).then((ress) => {
              console.log("GET_FILE_DOC", ress);

              // const fileName = ress.data.user_documents[0].document_type;
              // this.file.identityCard.file = new File([ress.data.user_documents[0].file.url], fileName, { type: 'image/png' });
            });
          }
        });
      } else {
        this.stepper = 4;
        this.loadingBtnStep = false;
      }
    },
    validationStepFourCompleted() {
      if (
        this.file.identityCard.file == null ||
        this.file.familyRegistration.file == null ||
        this.file.drivingLicense.file == null ||
        (this.file.dashboardDriver.file == null &&
          this.selectedUser === "driver") ||
        (this.file.dashboardDriverIncomeOneMonth.file == null &&
          this.selectedUser === "driver") ||
        this.file.accountTransaction.file == null ||
        this.file.identityCard.rulesFile.find(
          (rule) => rule(this.file.identityCard.file) !== true
        ) ||
        this.file.familyRegistration.rulesFile.find(
          (rule) => rule(this.file.familyRegistration.file) !== true
        ) ||
        this.file.drivingLicense.rulesFile.find(
          (rule) => rule(this.file.drivingLicense.file) !== true
        ) ||
        (this.file.dashboardDriver.rulesFile.find(
          (rule) => rule(this.file.dashboardDriver.file) !== true
        ) &&
          this.selectedUser === "driver") ||
        (this.file.dashboardDriverIncomeOneMonth.rulesFile.find(
          (rule) => rule(this.file.dashboardDriverIncomeOneMonth.file) !== true
        ) &&
          this.selectedUser === "driver") ||
        this.file.accountTransaction.rulesFile.find(
          (rule) => rule(this.file.accountTransaction.file) !== true
        )
      ) {
        // console.log("VALIDATION", true);
        this.validationStepFour = true;
      } else {
        this.validationStepFour = false;
        // console.log("VALIDATION", false);
        // return false;
      }

      // console.log("FILE_ACCESS", this.file);
    },
    stepFourComplete() {
      this.loadingBtnStep = true;
      console.log("FILE_WILL_UPLOAD", this.file);
      // console.log("LIST_PRICING", this.listPricing);
      // console.log("SELECTED_BIKE", this.selectedBike);
      let token = this.$cookies.get("token_api");
      // console.log("GET_LIST_PRICE", this.$store.getters);
      // this.selectedPricing = this.getSelectedVehiclePrice;
      this.selectPricing(this.selectedPricing);

      if (
        this.selectedRear == null ||
        this.selectedRear.title.id == "Tanpa Tambahan Aksesoris"
      ) {
        this.selectedRear = null;
      }

      if (!this.sendPrivateDoc) {
        postPrivateDocApi(token, {
          file_ktp: this.file.identityCard.file,
          file_kk: this.file.familyRegistration.file,
          file_npwp: this.file.taxpayerIdentificationNumber.file,
          file_sim_c: this.file.drivingLicense.file,
          file_payslip: this.file.payslip.file,
          file_capture_dashboard_driver: this.file.dashboardDriver.file,
          file_capture_dashboard_income_driver:
            this.file.dashboardDriverIncomeOneMonth.file,
          file_account_transaction: this.file.accountTransaction.file,
          subscription_id: this.$store.getters.getIdSubs,
        }).then((res) => {
          // console.log("RESULT_UPLOAD_DOC", res);
          if (res.meta.code == 200) {
            this.stepper = 5;
            this.sendPrivateDoc = true;
            this.loadingBtnStep = false;
          }
        });
      } else {
        this.stepper = 5;
        this.loadingBtnStep = false;
      }
    },
    stepFiveComplete() {
      this.loadingBtnStep = true;
      if (!this.sendPlanPrice) {
        let token = this.$cookies.get("token_api");
        postSetPlansApi(token, {
          subscription_id: this.$store.getters.getIdSubs,
          plans_id: this.selectedPricing.id,
          bank_name:
            this.bankName == "Bank Lainnya"
              ? this.bankNameOthers
              : this.bankName,
          holder_name: this.accountHoldersName,
          account_number: this.accountBankNumber,
        }).then((res) => {
          if (res.meta.code == 200) {
            this.stepper = 6;
            this.this.sendPlanPrice = true;
            this.loadingBtnStep = false;
          }
        });
      } else {
        this.stepper = 6;
        this.loadingBtnStep = false;
      }
    },
    backStep() {
      if (this.stepper > 1) {
        this.stepper = this.stepper - 1;
        // let back = this.stepper - 1;
        // this.$router.push("/form-subscribe/" + back);

        this.sendCustomization = false;
        this.sendPersonalInfo = false;
        this.sendResidenceInfo = false;
        this.sendPrivateDoc = false;
        this.sendPlanPrice = false;

        this.loadingBtnStep = false;
      }
    },
    nextStep(step) {
      this.stepper = step;
      console.log(step);
      document.getElementById("stepTitle").scrollIntoView();
    },
    stepChanged(step) {
      console.log("step is clickled", step);
      this.stepper = step;

      this.sendCustomization = false;
      this.sendPersonalInfo = false;
      this.sendResidenceInfo = false;
      this.sendPrivateDoc = false;
      this.sendPlanPrice = false;

      this.loadingBtnStep = false;
    },
    showButtons() {
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
    updateMonthlySubscriptionPrice() {
      // console.log("step changed");
      if (this.subscriptionPlan == "") return;
      var price;
      var packaging;
      if (this.subscriptionPlan === "Subscription to Own") {
        price = parseInt(this.subOwned[this.index].price) * 14000; // USD <> IDR Exchange Rate
        this.deposit = this.slider; // parseInt(this.subOwned[this.index].deposit)
        this.monthlySubscriptionPrice = Math.round(price / 36); // 36 Months

        var toPayOff = price - this.slider;
        this.monthlySubscriptionPrice = Math.round(toPayOff / 36); // 36 Months

        // Monthly Subscription Price Total
        packaging = this.radioGroupPackaging === 1 ? 0 : 20000;
        this.monthlySubscriptionPriceTotal =
          this.monthlySubscriptionPrice + this.tax + this.insurance + packaging;
        this.firstPaymentAmount = this.slider; // + this.monthlySubscriptionPriceTotal;
      } else {
        price = parseInt(this.subNotOwned[this.index].price) * 14000; // USD <> IDR Exchange Rate
        this.deposit = parseInt(this.subNotOwned[this.index].deposit);
        this.monthlySubscriptionPrice = Math.round(price / 36); // 36 Months

        // Monthly Subscription Price Total
        packaging = this.radioGroupPackaging === 1 ? 0 : 20000;
        this.monthlySubscriptionPriceTotal =
          this.monthlySubscriptionPrice + this.tax + this.insurance + packaging;
        this.firstPaymentAmount = this.deposit; // + this.monthlySubscriptionPriceTotal;
      }
    },
    subscriptionPlanChanged() {
      var packaging;
      var price;
      if (this.subscriptionPlan === "Subscription Not Owned") {
        this.sliderHide = true;
        this.slider = 0;
        price = parseInt(this.subNotOwned[this.index].price) * 14000;
        this.monthlySubscriptionPrice = Math.round(price / 36); // 36 Months
        this.deposit = parseInt(this.subNotOwned[this.index].deposit);

        // Monthly Subscription Price Total
        packaging = this.radioGroupPackaging === 1 ? 0 : 20000;
        this.monthlySubscriptionPriceTotal =
          this.monthlySubscriptionPrice + this.tax + this.insurance + packaging;
        this.firstPaymentAmount = this.deposit; // + this.monthlySubscriptionPriceTotal;
      } else {
        this.deposit = this.slider;
        this.sliderHide = false;
        price = parseInt(this.subOwned[this.index].price) * 14000;
        this.monthlySubscriptionPrice = Math.round(price / 36); // 36 Months

        var toPayOff = price - this.slider;
        this.monthlySubscriptionPrice = Math.round(toPayOff / 36); // 36 Months

        // Monthly Subscription Price Total
        packaging = this.radioGroupPackaging === 1 ? 0 : 20000;
        this.monthlySubscriptionPriceTotal =
          this.monthlySubscriptionPrice + this.tax + this.insurance + packaging;
        this.firstPaymentAmount = this.slider; // + this.monthlySubscriptionPriceTotal;
      }
    },
    subscribeClosed() {
      console.log("Subscribe closed");
      this.stepper = 1;
      setTimeout(() => {
        this.monthlySubscriptionPrice = 0;
        this.monthlySubscriptionPriceTotal = 0;
        this.firstPaymentAmount = 0;
        this.slider = 0;
      }, 1000);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    timeDifference(previous) {
      var current = Math.round(new Date() / 1000);
      console.log(current);
      var msPerMinute = 60;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;
      var elapsed = current - previous;

      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + " seconds ago";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " minutes ago";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " hours ago";
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " days ago";
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " months ago";
      } else {
        return Math.round(elapsed / msPerYear) + " years ago";
      }
    },
    redirectToInvoice(url) {
      // location.href = url;
      this.$router.push(url);
    },
    IDFileInputClick() {
      const element = document.getElementById("IDFileInput");
      return element.click();
    },
    onIDFilePicked() {
      const { name } = this.IDFile;
      this.IDFileName = name;
    },
    IDFileDelete() {
      this.IDFile = "";
      this.IDFileName = "";
    },
    fileInputClick(inputElementId) {
      const element = document.getElementById(inputElementId);
      return element.click();
    },
    async onFilePicked() {
      this.validationStepFourCompleted();
      // this.fileRules.find(rule => rule(this.selectedFile) !== true);
    },
    fileDelete(document) {
      this.file[document].file = null;
      this.validationStepFourCompleted();
    },
    generateImageToBase64(label) {
      const image = this.file[`${label}`].file;
      if (!image) return null;
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const srcData = fileReader.result;
        this.file[`${label}`].file = srcData;
      };
      this.file[`${label}`].file = fileReader.readAsDataURL(image);
    },
    makePayment() {
      var shippingMethodFix = "";
      if (this.radioGroupPickup == 1) {
        shippingMethodFix = "Kemang";
      } else if (this.radioGroupPickup == 2) {
        shippingMethodFix = "Cikupa";
      } else if (this.radioGroupPickup == 3) {
        shippingMethodFix = "Home";
      }
      // this.loadingData = true;
      this.loadingScreen = true;

      // for (const label in this.file) {
      //   this.generateImageToBase64(label);
      // }

      let invoiceData = {
        invoice: {
          amount: 100000,
          description: "Order Deposit Payment",
          purpose: "Booking",
          customer: {
            givenNames: this.name,
            surname: this.name,
            nik: this.nik,
            email: this.email,
            phoneNumber: this.phoneNumber,
            address: {
              subDistrict: this.serviceSubDistrict,
              district: this.serviceDistrict,
              city: this.serviceCity,
              province: this.serviceProvince,
              country: "Indonesia",
              postalCode: this.serviceZip,
              state: this.serviceVillage,
              streetLine1: this.address,
              streetLine2: this.address,
            },
            homeOwnershipStatus: this.homeOwnershipStatus,
            lengthOfStay: this.lengthOfStay,
            customerType: this.selectedUser,
            referralCode: "",
            motherName: this.motherName,
            companyName: this.companyName,
            jobPosition: this.jobPosition,
            employmentStatus: this.employmentStatus,
            yearOfExperience: this.yearOfExperience,
            salary: this.salary,
            creditCardLimit: this.creditCardLimit,
            maritalStatus: this.maritalStatus,
            spouseCompanyName: this.spouseCompanyName,
            spousePosition: this.spousePositionLabel,
            spouseEmploymentStatus: this.spouseEmploymentStatus,
            spouseYearOfExperience: this.spouseYearOfExperience,
            spouseSalary: this.spouseSalary,
            newsletter: this.newsletter || "",
            // files: this.file,
            // file: {
            //   identityCard: this.file.identityCard.file,
            //   familyRegistration: this.file.familyRegistration.file,
            //   taxpayerIdentificationNumber:
            //     this.file.taxpayerIdentificationNumber.file,
            //   drivingLicense: this.file.drivingLicense.file,
            //   dashboardDriver: this.file.dashboardDriver.file,
            //   accountTransaction: this.file.accountTransaction.file,
            // },
            selectedRear: this.selectedRear?.title?.en || "",
          },
        },
        bikeType: this.selectedBike.name,
        chargerType: this.selectedCharger.title,
        batteryQuantity: this.selectedBattery.qty,
        subscriptionPlan: this.selectedPricing.plan,
        subscriptionBonus: this.selectedPricing.bonus.en,
        depositAmount: 0,
        subscriptionAmount: this.totalPrice,
        subscriptionTax: 0,
        subscriptionInsurance: 0,
        bikeColor: this.selectedColor.title.en,
        bikeColorHex: this.selectedColor.color,
        shippingMethod: shippingMethodFix,
        bikeAccessories: this.selectedRear,
      };

      console.log("invoice", invoiceData);
      // Promise.resolve(this.createOrder(invoiceData))
      //   .then((result) => {
      //     console.log("data", result.data);
      //     let invoiceUrl = "booking/" + result.data.data["orderId"];
      //     this.redirectToInvoice(invoiceUrl);
      //     this.loadingScreen = false;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.loadingScreen = false;
      //     this.alertDialog = true;
      //     this.loadingData = false;
      //     this.alertStatus = "failed";
      //     this.alertStatus = "failed";
      //     this.alertText = { en: "Transaction Failed", id: "Transaksi Gagal" };
      //   });

      let token = this.$cookies.get("token_api");
      postOrderApi(token, {
        subscription_checkbox: this.subscriptionPolicy,
        subscription_id: this.$store.getters.getIdSubs,
      }).then((res) => {
        if (res.meta.code == 200) {
          console.log("BERHASIL_SUBMIT", res);
          let invoiceUrl = "booking/" + this.$store.getters.getIdSubs;
          this.redirectToInvoice(invoiceUrl);
          this.loadingScreen = false;
          this.$store.commit("setInvoice", invoiceData);
        } else {
          this.loadingScreen = false;
          this.alertDialog = true;
          this.loadingData = false;
          this.alertStatus = "failed";
          this.alertStatus = "failed";
          this.alertText = { en: "Transaction Failed", id: "Transaksi Gagal" };
        }
      });
    },
    openSubscriptionPolicy() {
      this.$emit("subscriptionPolicyClicked");
    },
    createOrder: async (invoiceData) => {
      const invoice = await PaymentController.createOrder(invoiceData);
      return invoice;
    },
    routerGo(route) {
      window.open(route, "_blank");
    },
    changeLanguage(lang) {
      store.commit("SetLanguage", {
        language: (this.getLanguage = lang),
      });
    },
    activateReadMore() {
      this.subscriptionPolicyFull.readMoreActive = true;
    },
    deactivateReadMore() {
      this.subscriptionPolicyFull.readMoreActive = false;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.wrapperDark {
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.wrapperLight {
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.wrap-text {
  word-break: normal;
}
.stepTitleLarge {
  color: #000000;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
}
.subInfo {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #858688;
}
.stepTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #353638;
}
.sectionStepTitle {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #353638;
}
.stepTitleSmall {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #353638;
}
.v-stepper__label {
  width: 180px;
}
.v-stepper {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.v-stepper__header {
  box-shadow: none;
  border-bottom: 1px solid #c1c2c4;
}

.stepper-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
}

.subscribe {
  width: 100%;
}

.radio-selection {
  width: 100%;
  background-color: #fafafa !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  margin: 0.5rem 0;
}

.radio-selection-shipping {
  width: 100%;
  background-color: white !important;
}

.radio-selection-color {
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.selected-selection {
  border: 2px solid !important;
  border-color: #6b4693 !important;
  border-radius: 12px;
}

.mdi-checkbox-blank-outline {
  color: #6b4693 !important;
}

.radio-selection-battery p {
  font-size: 14px;
  font-weight: 400;
  color: #858688;
}

.booking-fee-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}

.booking-fee-amount {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}

.booking-fee-title span {
  color: #858688;
  font-size: 14px;
}

.booking-fee-amount span {
  color: #858688;
  font-size: 14px;
  font-weight: 600;
}

.booking-fee-amount-title {
  color: #858688;
  font-size: 14px;
  font-weight: 600;
}

.booking-fee {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #c1c2c4;
  padding-bottom: 1rem;
  align-items: center;
}

.stepBack {
  color: #6b4693;
  font-size: 16px;
  font-weight: 500;
  padding: 2rem 0;
}

.text-info {
  font-size: 16px;
  font-weight: 400;
}

.text-info-shipping {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 2rem;
}

.text-title {
  margin: 1rem 0;
}

.text-title-subscription {
  margin-top: 2rem;
}

.pricing-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
  padding: 1rem;
  border-radius: 12px !important;
}

.pricing-item-title {
  font-size: 20px;
  font-weight: 700;
}

.pricing-item-price {
  font-size: 18px;
  font-weight: 400;
}

.pricing-item-billed {
  font-size: 16px;
  font-weight: 400;
}

.pricing-item-bonus {
  font-size: 18px;
  font-weight: 500;
  color: #6b4693;
}

.pricing-item-allprice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pricing {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: center;
}

.pricing-card {
  width: 200px;
}
.subscription-detail {
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #c1c2c4;
}

.your-order {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.your-order p {
  margin-bottom: 0.5rem;
}

.customer-information {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  font-size: 16px;
  font-weight: 400;
}

.customer-information-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #858688;
}

.customer-information-name {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #262626;
}

.customer-information-email {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #535456;
}

.customer-information-phone {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #535456;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.alert-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.succes-icon {
  font-size: 36px !important;
  color: #4fbd6d !important;
}

.failed-icon {
  font-size: 36px !important;
  color: #cb3a31 !important;
}

.selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.radio-button {
  padding: 0.5rem;
}

.theme--light.v-icon {
  color: rgba(0, 0, 0, 0.54);
}

.style-file-input.v-file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  /* display: none; */
  top: 10px;
  z-index: 1;
}
.style-file-input.v-file-input .v-input__slot,
.v-input__prepend-outer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  top: 10px;
  z-index: 1;
}
.style-file-input.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}
.style-file-input.v-file-input .v-text-field__details {
  position: absolute;
  bottom: -5px;
  z-index: 1;
}

.font-family-work-sans {
  font-family: "Work Sans";
}

.customization-item-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #353638;
}

.customization-item-price {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #535456;
}

.customization-item-desc {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #858688;
}

.continue-button {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.personal-input-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #858688;
}

.personal-input-value {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #353638;
}

.personal-input-value.v-input--is-disabled
  .v-input__control
  > .v-input__slot
  fieldset {
  background: rgba(0, 0, 0, 0.12) !important;
}

.custom-d-input.v-input--is-disabled
  .v-input__control
  > .v-input__slot
  fieldset {
  background: rgba(0, 0, 0, 0.12) !important;
}

.personal-item-label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
}
.edit-button {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #6b4693;
}
.review-order-item-label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #353638;
}

.pricing-item.v-card--link:before {
  background: none;
}

@media (max-width: 575.98px) {
  .v-stepper--alt-labels .custom-stp-subs.v-stepper__step {
    flex-basis: auto;
  }

  .custom-stp-subs ~ .v-divider {
    max-width: 10%;
  }
}
</style>
