import { BeRubyAPI } from "../../../clients/BERubyAPI"

const RegisterUserApi = ({ phone_number, email, full_name, customer_type, newsletter, referral_code, province, city, district, sub_district, vehicle_id }) => {

  let data = JSON.stringify({
    account: {
      users_attributes: {
        phone_number: phone_number ?? '',
        email: email ?? '',
        full_name: full_name ?? '',
        customer_type: customer_type ?? '',
        newsletter: newsletter ?? '',
        referral_code: referral_code ?? '',
      },
      addresses_attributes: {
        province: province ?? '',
        city: city ?? '',
        district: district ?? '',
        sub_district: sub_district ?? '',
        address_type: "Current Address",
      },
      subscriptions_attributes: {
        vehicle_id: vehicle_id ?? '',
      },
    },
  });

  let responseFetch = BeRubyAPI.post("/v1/accounts", data)
    .then((res) => { return res.data })
    .catch((err) => { return err.response.data });

  return responseFetch;
}

const LoginUserApi = ({ phone_number }) => {

  let data = JSON.stringify({
    phone_number,
  });

  let responseFetch = BeRubyAPI.post('/v1/authentication/login', data)
    .then((res) => { return res.data })
    .catch((err) => { return err.response.data });

  return responseFetch;
}

export { RegisterUserApi, LoginUserApi };