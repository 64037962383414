import { BeRubyAPI } from "../../../clients/BERubyAPI";
// import Vue from "vue";

const getProfileApi = (token) => {
  let responseFetch = BeRubyAPI
    .get('/v1/profiles/get_profile',
      {
        headers: {
          'Authorization': token !== null ? 'Bearer ' + token : '',
        }
      }
    )
    .then(res => {
      return res.data;
    }).catch((err) => {
      return err.response.data
    });

  return responseFetch;
}

export { getProfileApi };