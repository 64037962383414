import { Anoa, Maleo, Rimau, Senta, RimauS, Baycat } from "../vehicles_store/vehicle_models"

const vehiclesStoreModel = {
  state: () => ({
    list: [Anoa, Maleo, Rimau, RimauS, Senta, Baycat],
    show: {}
  }),
  mutations: {
    set_vehicle_show_store(state, payload) {
      state.show = state.list.find(v => v.slug == payload)
    }
  },
  actions: {
    set_vehicle_show_store({ commit }, payload) {
      commit("set_vehicle_show_store", payload)
    }
  },
  getters: {
    vehicles_store(state) {
      return state.list
    },
    vehicle_show_store(state) {
      return state.show
    }
  },
}

export default vehiclesStoreModel;