import { getProfileApi } from "../../../../services/back-end-ruby";
import { CUSTOMER_TYPE } from "./const";

const dataAuthModul = {
  state: () => ({
    isLogin: false,
  }),
  mutations: {
    setDataAuth(state, payload) {
      state.isLogin = payload;
    },
  },
  actions: {
    async setDataAuth({ commit }, token) {
      commit(
        "setDataAuth",
        await getProfileApi(token).then((res) => {
          if (
            res.meta.code == 200 &&
            (CUSTOMER_TYPE.regular.includes(res.data.user.customer_type) ||
              CUSTOMER_TYPE.driver.includes(res.data.user.customer_type))
          ) {
            return true;
          } else {
            return false;
          }
        })
      );
    },
  },
  getters: {
    getDataAuth(state) {
      return state.isLogin;
    },
  },
};

export default dataAuthModul;
