export const Baycat = {
  vehicle_id: process.env.VUE_APP_ID_VEHICLE_RIMAU,
  name: "BAYCAT",
  nameLowercase: "baycat",
  slug: "baycat",
  folder: "citi",
  description: "Meets the needs of the aspiring urban professional. ",
  image: "img/shared/bikes/baycat/654x440/baycat-website_654x440.png",
  images: {
    hero: 'img/shared/bikes/baycat/hero banner/Baycat website_desktop 1440x1024.jpg',
    heroMedium: 'img/shared/bikes/baycat/hero banner/Baycat website_tablet 1024x768.jpg',
    heroSmall: 'img/shared/bikes/baycat/hero banner/Baycat website_mobile 390x844.jpg',
    logo: 'img/shared/bikes/baycat/logo SVG/baycat logo white.svg',
    tab_1: "",
    tab_2: "",
    tab_3: "",
    product: "",
    product_row_1: "",
    product_row_2: '',
    product_row_3: '',
  },
  imageNavbar: "img/shared/bikes/baycat 2/200x200/Baycat website_200x200.png",
  // motor: "Electric Motor - Super Soco EEC Power 4000W",
  // autonomy: "Autonomy 140 Km (at 45 Km/h with 75 Kg driver, 2 batteries)",
  speed: "85",
  topSpeed: "85",
  maxDistance: "170",
  maximumRange: "170",
  maxDistanceUnit: "km",
  battery: "2",
  // recharge: "Recharge 3.5 h at 60V15A (fast charge with single battery)",
  // count: 7,
  price: "Rp 29.880.000",
  subPrice: null,
  batterySubscription: null,
  dailyPrice: 45000,
  stock: 1,
  colorOptions: ["#FFFFFF", "#E30613", "#575756"],
  colorOptionsDes: [
    {
      hex: '#FFFFFF', name: 'Arctic White'
    },
    {
      hex: '#E30613', name: 'Mystic Red'
    },
    {
      hex: '#575756', name: 'Gunmetal Grey'
    }
  ],
  productTagLine: {
    en: {
      title: 'Move in style',
      desctiption: 'Baycat comes equipped with advanced technology designed to provide a safer, more comfortable, and enjoyable riding experience for every journey.'
    },
    id: {
      title: 'Melaju dengan kelas',
      desctiption: 'Molis Baycat hadir dengan teknologi canggih yang dirancang untuk memberikan pengalaman berkendara yang lebih aman, nyaman, dan menyenangkan di setiap perjalananmu.'
    }
  },
  quotes: null,
  quotesForm: null,
  assignQuotes: null,
  productInspirationDesign: null,
  refinedPowerDesc: {
    en: "<p>Baycat is a testament to refined power, delivering the perfect balance between torque and comfort.</p> <p>It's designed for smooth acceleration and precise control, offering strength without sacrificing a comfortable and enjoyable riding experience. Proving that power isn't just about brute force.</p>",
    id: "<p>Baycat adalah bukti tenaga yang disempurnakan, memberikan keseimbangan sempurna antara torsi dan kenyamanan.</p> <p>Dirancang untuk akselerasi yang mulus dan pengendalian yang presisi, menawarkan kekuatan tanpa mengorbankan pengalaman berkendara yang nyaman dan menyenangkan. Membuktikan bahwa kekuasaan bukan sekedar kekerasan.</p>",
  },
  convenienceDesc: {
    en: "<p>The Baycat is equipped with a reverse feature that understands your needs. Struggling to back out of a parking spot? Not a problem anymore.</p>",
    id: "<p>Baycat dilengkapi dengan fitur mundur yang mengerti kebutuhan kamu. Kesulitan saat keluar dari parkiran? Bukan halangan lagi.</p>"
  },
  agilityDesc: {
    en: "<p>We understand that agility in navigating urban traffic is essential.</p><p>The Baycat's tilt angle has been carefully designed to feel like an extension of the rider's body, allowing you to move forward with confidence.</p>",
    id: "<p>Kita paham kelincahan melesat di lalu lintas perkotaan adalah hal yang penting.</p><p>Sudut kemiringan Baycat sudah diperhitungkan untuk menjadi perpanjangan badan pengendara, melaju bersama dengan penuh percaya diri.</p>"
  },
  isRelease: true,
  typeProduct: "baycat",
  specificationDesc: {
    en: "*Data collected from real world testing on Indonesian roads, with a single rider of 70kg, not exceeding speeds of 50km/h. Your results may vary depending on riding conditions.",
    id: "*Berdasarkan hasil uji di jalanan Indonesia dengan satu pengendara, bobot 70 kg dan kecepatan berkendara tidak lebih dari 50 km/jam. Hasil mungkin bervariasi, tergantung kondisi berkendara."
  },
  specification: {
    keys: {
      en: [
        {
          description: "L*W*H",
          details: "1940*730*1080mm",
        },
        {
          description: "Wheelbase",
          details: "1327mm",
        },
        {
          description: "Ground Clearance",
          details: "130mm",
        },
        {
          description: "Net Weight",
          details: "92 kg (without battery)",
        },
        {
          description: "Max Load",
          details: "150 kg (rider excluded)",
        },
      ],
      id: [
        {
          description: "P*L*T",
          details: "1940*730*1080mm",
        },
        {
          description: "Jarak Sumbu Roda",
          details: "1327mm",
        },
        {
          description: "Ground Clearance",
          details: "130mm",
        },
        {
          description: "Bobot Bersih",
          details: "92 kg (without battery)",
        },
        {
          description: "Kapasitas Beban Maksimum",
          details: "150 kg (rider excluded)",
        },
      ],
    },
    powertrain: {
      en: [
        {
          description: "Charge time (per battery)",
          details: "3 Hours",
        },
        {
          description: "Input Voltage",
          details: "700 W",
        },
      ],
      id: [
        {
          description: "Waktu pengisian daya (per baterai)",
          details: "3 Jam",
        },
        {
          description: "Tegangan Masuk",
          details: "700 W",
        },
      ],
    },
    other: {
      en: [
        {
          description: "Brakes",
          details: "CBS with Double Disc Calipers",
        },
        {
          description: "Suspension",
          details: "Hydraulic Damping System",
        },
        {
          description: "Rims (Fr)",
          details: "2.15-16",
        },
        {
          description: "Rims (Rr)",
          details: "2.50-14",
        },
        {
          description: "Front Tyre Size",
          details: "90/80-R16",
        },
        {
          description: "Rear Tyre Size",
          details: "90/90-R14",
        },
        {
          description: "Battery",
          details: "Dual, Removable",
        },
      ],
      id: [
        {
          description: "Pengereman",
          details: "CBS dengan kaliper cakram ganda",
        },
        {
          description: "Suspensi",
          details: "System Peredaman Hidrolik",
        },
        {
          description: "Velg (Fr)",
          details: "2.15-16",
        },
        {
          description: "Velg (Rr)",
          details: "2.50-14",
        },
        {
          description: "Ukuran Ban Depan",
          details: "90/80-R16",
        },
        {
          description: "Ukuran Ban Belakang",
          details: "90/90-R14",
        },
        {
          description: "Baterai",
          details: "Dua Baterai, Lepas Pasang",
        },
      ],
    }
  },
  priceDescription: {
    en: 'Price refer to OTR Jakarta, includes VAT, spare parts warranty and all on the road costs. Terms and conditions applied.',
    id: 'Harga mengacu pada OTR Jakarta, sudah termasuk PPN, garansi suku cadang dan biaya on the road. Syarat dan ketentuan berlaku.',
  },
  actualPrice: null,
  text: {
    otr: {
      en: 'OTR Jakarta Price',
      id: 'Harga OTR Jakarta'
    },
    includeInOtr: "(Unit, Battery, & Charger)",
    includeInTheOtrPrice: {
      en: 'Included in the OTR price:',
      id: 'Sudah termasuk dalam harga OTR:',
    },
    includeInTheOtrDescription: {
      en: [
        {
          name: 'Unit with 1 battery',
          description: 'Baycat has 2 batteries capacity'
        },
        {
          name: 'Standard Charger',
          description: '10% to 100% in 3 hours time'
        },
        {
          name: 'Fast Charger (Optional)',
          description: '10% - 100% in 40 minutes time* Additional cost Rp 10.000.000'
        }
      ],
      id: [
        {
          name: 'Unit dengan 1 baterai',
          description: 'Baycat memiliki kapasitas baterai 2pcs'
        },
        {
          name: 'Standard Charger',
          description: '10% - 100% dalam waktu 3 jam'
        },
        {
          name: 'Pengisi Daya Cepat (Opsional)',
          description: '10% - 100% dalam waktu 40 menit* Harga tambahan Rp 10.000.000'
        }
      ]
    }
  }
}